import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IGridConfig } from '../../../types/grid';
import { useTranslation } from 'react-i18next';
import Grid from '../../common/grid/Grid';
import MapViewModal from '../map-view-modal/MapViewModal';
import {
  AisPosition,
  complianceStatusList,
  Coordinates,
  Events,
  MapConfig,
  riskRatingList,
  StsComplianceData,
  StsData,
  stsMapLegends,
} from '../../../types/compliance-check';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import { mapColors, Markers, PolylineConfig } from '../../../types/map';
import {
  CellPreparedEvent,
  DataRowTemplateData,
} from 'devextreme/ui/data_grid';
import {
  ComplianceStatusEnum,
  ComplianceTabType,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import { mapInfoWindow } from '../map-info-window/MapInfoWindow';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { VesselDetails } from '../../../types/vessel-general';
import {
  getCompanyId,
  getCompanyType,
  getCurrentBuId,
} from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';
import images from '../../../assets/images';
import { ReportGeneratedDetails } from '../../../types/compliance-report';
import toast from '../../../utils/toast';

export default function StsGrid(
  props: Readonly<{
    imoNumber: string;
    vesselDetails: VesselDetails;
    reportDetails?: ReportGeneratedDetails;
    vetRequestId?: string;
  }>
) {
  const { vesselDetails, imoNumber, reportDetails, vetRequestId } = props;
  const complianceCheckContext = useContext(ComplianceCheckContext);
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const [stsData, setStsData] = useState<StsComplianceData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [mapConfig, setMapConfig] = useState<MapConfig>();
  const [eventData, setEventData] = useState<Events[]>([]);
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<StsComplianceData>();
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const companyType = getCompanyType();

  useEffect(() => {
    if (complianceCheckContext?.complianceCheckId) {
      getSTSData();
    }
  }, [complianceCheckContext]);

  const getSTSData = async () => {
    setLoaderMessage?.(t('toast.fetchingSTS'));
    await api
      .get(
        {
          url: apiConfig.getStsDataList,
          params: {
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            imo: imoNumber,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setStsData(res);
        setIsApiLoaded(true);
        setLoaderMessage?.();
      })
      .catch((error) => {
        if (error?.response?.data?.Message === 'AISDataNotFound') {
          toast.error({
            title: t('toast.liveTrackingNoDataTxt'),
          });
        }
        setIsApiLoaded(true);
        setIsApiError(true);
        setLoaderMessage?.();
      });
  };

  const cellPrepared = useCallback((e: CellPreparedEvent) => {
    if (e.rowType !== 'data') return;
    const riskClassMapping = {
      [RiskRatingEnum.Medium]: 'status-pending-wtout',
      [RiskRatingEnum.High]: 'status-inactive-wtout',
      default: 'status-active-wtout',
    };

    const statusClassMapping = {
      [ComplianceStatusEnum.VerifiedFalse]: 'status-active-wtout',
      [ComplianceStatusEnum.VerifiedTrue]: 'status-inactive-wtout',
      default: 'status-pending-wtout',
    };

    const getClassName = (value: string, mapping: Record<string, string>) =>
      mapping[value] || mapping.default;

    const fieldClassMapping: Record<string, () => string> = {
      marsStatus: () => getClassName(e.data.marsStatus, statusClassMapping),
      marsRiskRating: () =>
        getClassName(e.data.marsRiskRating, riskClassMapping),
      riskRating: () => getClassName(e.data.riskRating, riskClassMapping),
      status: () => getClassName(e.data.status, statusClassMapping),
    };

    if (fieldClassMapping[e.column.dataField as string]) {
      e.cellElement.className =
        fieldClassMapping[e.column.dataField as string]();
    }
  }, []);

  const getNoDataText = useCallback(() => {
    return isApiLoaded
      ? isApiError
        ? t('toast.liveTrackingNoDataTxt')
        : t('labels.stsNoDataTxt')
      : t('labels.fetchingData');
  }, [isApiLoaded, isApiError]);

  const gridConfig: IGridConfig = {
    dataSource: stsData,
    remoteOperations: false,
    showSelection: false,
    noDataText: getNoDataText(),
    cellPrepared: cellPrepared,
    rowClick: (e) => getSelectedItemIndex(e?.data),
    defaultColumns: [
      {
        caption: t('labels.voyageStart'),
        dataField: 'voyageStart',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.voyageEnd'),
        dataField: 'voyageEnd',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.proximityVessel'),
        dataField: 'proximityVessel',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.proximityVesselImo'),
        dataField: 'proximityVesselIMO',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.proximityVesselType'),
        dataField: 'proximityVesselType',
        dataType: 'string',
        minWidth: 150,
      },

      {
        caption: t('labels.startCoordinates'),
        dataField: 'startCoordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: StsComplianceData) =>
          data.startCoordinates
            ? calculateCoordinatesCellValue(data.startCoordinates)
            : '',
      },
      {
        caption: t('labels.endCoordinates'),
        dataField: 'endCoordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: StsComplianceData) =>
          data.endCoordinates
            ? calculateCoordinatesCellValue(data.endCoordinates)
            : '',
      },
      {
        caption: t('labels.avgVesselSpeed'),
        dataField: 'vesselAverageSpeed',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.fromTime'),
        dataField: 'fromDate',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.toTime'),
        dataField: 'toDate',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.durationHrs'),
        dataField: 'duration',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.area'),
        dataField: 'area',
        dataType: 'string',
        alignment: 'left',
        minWidth: 150,
      },
      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsRiskRating'
            : 'labels.riskRating'
        ),
        dataField: 'marsRiskRating',
        dataType: 'string',
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
      },
      {
        caption: t('labels.riskRating'),
        dataField: 'riskRating',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible: companyType !== CompanyType.Mars,
      },

      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsStatus'
            : 'labels.status'
        ),
        dataField: 'marsStatus',
        dataType: 'string',
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
        visible: companyType !== CompanyType.Mars,
      },
      {
        caption: t('Action'),
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
  };

  const calculateCoordinatesCellValue = useCallback(
    (data: Coordinates) =>
      `${convertDecimalToMinutes(data?.lat, true)} / ${convertDecimalToMinutes(
        data?.lon
      )}`,
    []
  );

  const getIconName = (
    position: AisPosition,
    stsGridData: StsComplianceData
  ) => {
    if (position.lastPositionUTC === stsGridData.fromDate) {
      return 'stsStart';
    } else if (position.lastPositionUTC === stsGridData?.toDate) {
      return 'stsEnd';
    }
    return 'arrowSymbolBlue';
  };

  const getInfoWindowContent = (
    iconName: string,
    position: AisPosition,
    stsSelectedData: StsComplianceData,
    vesselName?: string,
    vesselType?: string
  ) => {
    const titles: Record<string, string> = {
      stsStart: t('labels.stsStart'),
      stsEnd: t('labels.stsEnd'),
    };

    return mapInfoWindow(
      position,
      titles[iconName] || (vesselName ?? vesselDetails?.vesselName) || '',
      stsSelectedData,
      t,
      ComplianceTabType.STS,
      vesselType
    );
  };

  const formatCoordinatesData = (
    stsData: StsData,
    stsGridData: StsComplianceData
  ) => {
    const center = stsData.vesselOneStartCoordinates;
    let vessel2Markers: Markers[] | undefined =
      stsData?.vesselTwoAISPosition?.map((item: AisPosition) => {
        const iconName = 'arrowSymbol';
        const infoWindow = getInfoWindowContent(
          iconName,
          item,
          stsGridData,
          stsGridData.proximityVessel,
          'proximity'
        );
        return {
          iconName: iconName,
          coordinates: {
            lat: item?.lat,
            lng: item?.lon,
          },
          iconRotation: item.heading,
          infoWindowContent: infoWindow,
        };
      });
    let vessel1Markers: Markers[] | undefined =
      stsData?.vesselOneAISPosition?.map((item: AisPosition, index: number) => {
        const iconName =
          index === 0 ? 'anchorageStart' : getIconName(item, stsGridData);
        return {
          iconName: iconName,
          coordinates: {
            lat: item?.lat,
            lng: item?.lon,
          },
          iconRotation: item.heading,
          infoWindowContent: getInfoWindowContent(
            iconName,
            item,
            stsGridData,
            stsGridData.vesselOneName
          ),
        };
      });
    const updatedPositions = getPolylineConfigList(vessel1Markers);
    const vessel2PolylineConfig = getVesselTwoPolylineConfig(
      stsData,
      stsGridData
    );
    const polylineConfig = [...vessel2PolylineConfig, ...updatedPositions];
    vessel1Markers = vessel1Markers ?? [];
    vessel2Markers = vessel2Markers ?? [];
    const combinedMarkers = vessel2Markers?.concat(vessel1Markers);
    if (polylineConfig) {
      setMapConfig({
        center: center ? { lat: center?.lat, lng: center?.lon } : null,
        markers: combinedMarkers,
        polylineConfig: polylineConfig,
        legendsList: [
          ...stsMapLegends,
          {
            label: vesselDetails.vesselName,
            iconUrl: images.arrowSymbolBlue,
          },
          {
            label: stsGridData.proximityVessel,
            iconUrl: images.arrowSymbolRed,
          },
        ],
      });
    }
  };

  const getVesselTwoPolylineConfig = (
    data: StsData,
    stsGridData: StsComplianceData
  ) => {
    const loiteringStartIndex = data.vesselTwoAISPosition.findIndex(
      (item) =>
        new Date(item.lastPositionUTC.replace(/Z$/, '')) >
        new Date(stsGridData.fromDate)
    );
    let loiteringEndIndex = data.vesselTwoAISPosition.findIndex(
      (item) =>
        new Date(item.lastPositionUTC.replace(/Z$/, '')) >
        new Date(stsGridData.toDate)
    );
    if (loiteringEndIndex === -1) {
      loiteringEndIndex = data?.vesselTwoAISPosition?.length
        ? data.vesselTwoAISPosition.length - 2
        : loiteringEndIndex;
    }
    const poly: PolylineConfig[] = [
      {
        position: data.vesselTwoAISPosition
          .slice(0, loiteringStartIndex + 1)
          .map((item) => ({ lat: item.lat, lng: item.lon })),
        strokeColor: mapColors.red,
      },
      {
        position: data.vesselTwoAISPosition
          .slice(loiteringStartIndex, loiteringEndIndex + 1)
          .map((item) => ({ lat: item.lat, lng: item.lon })),
        strokeColor: mapColors.red,
        isDashedLine: true,
      },
      {
        position: data.vesselTwoAISPosition
          .slice(loiteringEndIndex)
          .map((item) => ({ lat: item.lat, lng: item.lon })),
        strokeColor: mapColors.red,
      },
    ];
    return poly;
  };

  const getPolylineConfigList = (data: Markers[]) => {
    const loiteringStartIndex = data.findIndex(
      (item) => item.iconName === 'stsStart'
    );
    const loiteringEndIndex = data.findIndex(
      (item) => item.iconName === 'stsEnd'
    );
    const poly: PolylineConfig[] = [
      {
        position: data
          .slice(0, loiteringStartIndex + 1)
          .map((item) => item.coordinates),
        strokeColor: mapColors.blue,
      },
      {
        position: data
          .slice(loiteringStartIndex, loiteringEndIndex + 1)
          .map((item) => item.coordinates),
        strokeColor: mapColors.blue,
        isDashedLine: true,
      },
      {
        position: data.slice(loiteringEndIndex).map((item) => item.coordinates),
        strokeColor: mapColors.blue,
      },
    ];
    return poly;
  };

  const onClickMapIcon = async (index: number) => {
    const data = stsData[index];
    const companyId = getCompanyId();
    const buId = getCurrentBuId();
    await api
      .get(
        {
          url: apiConfig.vesselPositionDetails,
          params: {
            id: data.stsDetailId,
            voyageStartDate: data.voyageStartDate,
            voyageEndDate: data.voyageEndDate,
            companyId: companyId,
            buId: buId,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setSelectedRow(data);
        setSelectedIndex(index);
        formatCoordinatesData(res, data);
        setEventsGridData(data);
        setShowMapModal(true);
      });
  };

  const handleGridRefresh = () => {
    getSTSData();
  };

  const setEventsGridData = (data: StsComplianceData) => {
    const events: Events[] = [
      {
        event: 'Voyage Start',
        coordinates: data.voyageStartCoordinates,
        dateTime: data.voyageStartDate,
        vesselInContact: '',
        STSProneArea: '',
      },
      {
        event: 'STS Start',
        coordinates: {
          lat: data.startCoordinates.lat,
          lon: data.startCoordinates.lon,
        },
        dateTime: data.fromDate,
        vesselInContact: data.proximityVessel,
        STSProneArea: data.area,
      },
      {
        event: 'STS End',
        coordinates: {
          lat: data.endCoordinates.lat,
          lon: data.endCoordinates.lon,
        },
        dateTime: data.toDate,
        vesselInContact: data.proximityVessel,
        STSProneArea: data.area,
      },
      {
        event: 'Voyage End',
        coordinates: data.voyageEndCoordinates,
        dateTime: data.voyageEndDate,
        vesselInContact: '',
        STSProneArea: '',
      },
    ];
    setEventData(events);
  };

  const getSelectedItemIndex = (data: StsData) => {
    const itemIndex = stsData.findIndex(
      (item) => item.stsDetailId === data.stsDetailId
    );
    if (itemIndex != -1) {
      onClickMapIcon(itemIndex);
    }
  };

  const actionCellTemplate = (data: DataRowTemplateData) => {
    return (
      <div>
        <i
          className="dx-icon-map dx-custom-icon"
          onClick={() => getSelectedItemIndex(data.data)}
        ></i>
      </div>
    );
  };

  const handleCloseMapModal = (isVisible: boolean, refreshData?: boolean) => {
    setShowMapModal(isVisible);
    if (refreshData) {
      getSTSData();
    }
  };

  return (
    <div className={'m-c-grid m-c-block-grid-list'}>
      <Grid gridConfig={gridConfig} actionTemplate={actionCellTemplate} />
      {showMapModal && mapConfig && selectedRow && (
        <MapViewModal
          isReportGenerated={reportDetails?.isReportGenerated ?? false}
          eventData={eventData}
          detailId={selectedRow.stsDetailId}
          complianceType={ComplianceTabType.STS}
          selectedIndex={selectedIndex}
          gridDataLength={stsData.length}
          handleNextPrev={onClickMapIcon}
          mapConfig={mapConfig}
          vesselDetails={vesselDetails}
          isVisible={showMapModal}
          selectedRow={selectedRow}
          setIsVisible={handleCloseMapModal}
          vetRequestId={vetRequestId}
          handleGridRefresh={handleGridRefresh}
        />
      )}
    </div>
  );
}
