import React, { useState, useMemo, useEffect, useRef } from 'react';
import TextBox from 'devextreme-react/text-box';
import icons from '../../../common/icons/icons';
import Modal from '../../../common/modal/Modal';
import { IModalConfig } from '../../../../types/modal';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import toast from '../../../../utils/toast';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../../types/dialog';
import RFIHtmlEditor from '../../../common/rfi-template/html-editor/RfiHtmlEditor';
import { IMasterEntity } from '../../../../types/master-data';
import Validator from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';
import FileUploaderModal from '../../../common/file-uploader-modal/FileUploaderModal';
import { blackListedEmails } from '../../../../types/requestor-company';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import FileSaver from 'file-saver';

export default function RecommendationEmail(props: {
  isVisible: any;
  setIsVisible?: any;
  reviewComment?: any;
  setEnabledStatus?: any;
  reviewStatus?: any;
  getVetReviewComments?: any;
  vetReviewTitle?: any;
  template?: any;
  toEmail?: any;
  mailSubject?: any;
  setVetData?: any;
  vetData?: any;
  setGetVetData?: any;
  vetRequestId?: any;
  berthFitComment?: string;
  vetReports?: any[];
  imoNumber?: string;
  berthFitCheck?: boolean;
  complianceCheckComments?: string;
  sanctionCheckComments?: string;
}) {
  const {
    isVisible,
    setIsVisible,
    reviewComment,
    setEnabledStatus,
    reviewStatus,
    getVetReviewComments,
    vetReviewTitle,
    template,
    toEmail,
    mailSubject,
    vetData,
    setGetVetData,
    vetRequestId,
    berthFitComment,
    vetReports,
    imoNumber,
    berthFitCheck,
    complianceCheckComments,
    sanctionCheckComments,
  } = props;
  const { t } = useTranslation();
  const [subject, setSubject] = useState<any>(mailSubject);
  const [confirmSave, setConfirmSave] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [entities, setEntities] = useState<IMasterEntity[]>([]);
  const [ccAddress, setCcAddress] = useState<any>('');
  const [toAddress, setToAddress] = useState(toEmail);
  const [isNotValid, setIsNotValid] = useState(false);
  const [defaultValues, setDefaultValues] = useState({
    to: toEmail,
    cc: '',
    subject: mailSubject,
    template: template,
  });
  const [content, setContent] = useState<any>(template);
  const recommendationEmailValidation: any = useRef();
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>();
  const [showEditor, setShowEditor] = useState(false);
  const [addToMailList, setAddToMailList] = useState<any>(false);
  const { setIsLoading } = useLoaderContext();
  useEffect(() => {
    if (content) {
      setShowEditor(true);
    }
  }, [content]);

  useEffect(() => {
    if (vetReports && vetReports.length > 0) {
      setUploadedFiles(vetReports);
    }
  }, [vetReports]);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal-mailcontent',
      'data-testid': 'recommendationEmailModal',
    };
  }, []);

  const modalConfig: IModalConfig = {
    width: 896,
    height: 680,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.sendRecommendEmail'),
    handleSubmit: () => {
      sendEmail();
      setConfirmSave(false);
    },
    handleClose: () => {
      setConfirmSave(false);
    },
  };

  const confirmSendDialogConfig: IDialogConfig = {
    id: 'confirmSend',
    content: t('toast.sendWithoutAttachment'),
    handleSubmit: () => {
      sendEmail();
      setConfirmSend(false);
    },
    handleClose: () => {
      setConfirmSend(false);
    },
  };

  const confirmCloseDialogConfig: IDialogConfig = {
    id: 'confirmClose',
    content: t('toast.closeRecommendEmail'),
    handleSubmit: () => {
      setIsVisible(false);
      setConfirmClose(false);
    },
    handleClose: () => {
      setConfirmClose(false);
    },
  };

  const confirmCancelDialogConfig: IDialogConfig = {
    id: 'confirmCancel',
    content: t('toast.cancelRecommendEmail'),
    handleSubmit: () => {
      setIsVisible(false);
      setConfirmCancel(false);
    },
    handleClose: () => {
      setConfirmCancel(false);
    },
  };

  const handleCancelClick = () => {
    if (isValueChange()) {
      setConfirmCancel(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleCloseClick = () => {
    if (isValueChange()) {
      setConfirmClose(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleSaveClick = () => {
    if (recommendationEmailValidation.current?.instance.validate().isValid) {
      if (vetRequestId && !content) {
        setIsNotValid(true);
      } else {
        setIsNotValid(false);
        if (uploadedFiles?.length > 0) {
          setConfirmSave(true);
        } else {
          setConfirmSend(true);
        }
      }
    } else {
      setIsVisible(true);
    }
  };

  const isValueChange = () => {
    if (isChanged) {
      const currentCC = ccAddress == undefined ? '' : ccAddress;
      const currentTo = toAddress == undefined ? '' : toAddress;
      if (
        currentTo === defaultValues.to &&
        currentCC == defaultValues.cc &&
        subject === defaultValues.subject &&
        content == defaultValues.template
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  const sendEmail = async () => {
    if (berthFitCheck) {
      await berthFitExecution();
    }
    const toArray = toAddress?.split(',')?.map(function (value: any) {
      return value.trim();
    });
    const ccArray = ccAddress?.split(',')?.map(function (value: any) {
      return value.trim();
    });
    const toEmailsStr = toArray?.join(',');
    const ccEmailsStr = ccArray?.join(',');

    const bodyFormData = new FormData();
    bodyFormData.append('vetRequestId', vetRequestId ? vetRequestId : '');
    bodyFormData.append('status', reviewStatus);
    bodyFormData.append('vetRecommendationComment', reviewComment);
    bodyFormData.append(
      'berthFitRecommendationComment',
      berthFitComment ? berthFitComment : ''
    );
    bodyFormData.append(
      'ComplianceReportSummary',
      complianceCheckComments ?? ''
    );
    bodyFormData.append('SanctionReportSummary', sanctionCheckComments ?? '');
    bodyFormData.append('toEmails', toEmailsStr);
    bodyFormData.append('subject', subject);
    bodyFormData.append('mailContent', content);
    bodyFormData.append('ccEmails', ccEmailsStr ? ccEmailsStr : '');
    bodyFormData.append('addToMailList', addToMailList);

    for (const item in uploadedFiles) {
      bodyFormData.append('attachedFiles', uploadedFiles[item]);
    }
    await api
      .put(
        {
          url: apiConfig.vetRequestRecommend,
          data: bodyFormData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.success({
            title: t('toast.vettingCompletedSuccessfully'),
          });
          setIsVisible(false);
          setEnabledStatus(false);
          getVetReviewComments();
          setGetVetData(true);
        }
      })
      .catch((e) => {
        toast.error({
          title: e?.Message,
        });
      });
  };

  const downloadAttachment = (file: any) => {
    FileSaver.saveAs(new Blob([file]), file.name);
  };

  const validateEmails = () => (params: any) => {
    if (params) {
      let emails = params?.value;
      emails = emails?.split(',')?.map(function (value: any) {
        return value.trim();
      });
      const regex = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      const valid = (emails || []).reduce(
        (previousValue: boolean, item: any) => {
          if (item == '' || !regex.test(item)) {
            return false;
          }
          return previousValue;
        },
        true
      );
      return Boolean(emails?.length) && valid;
    }
    return;
  };

  const checkEmailvalid = () => (params: any) => {
    if (params) {
      let emails = params?.value;
      emails = emails?.split(',')?.map(function (value: any) {
        return value.trim();
      });
      const regex = new RegExp(blackListedEmails.join('|'));
      const valid = (emails || []).reduce(
        (previousValue: boolean, item: any) => {
          if (regex.test(item)) {
            return false;
          }
          return previousValue;
        },
        true
      );
      return Boolean(emails?.length) && valid;
    }
    return;
  };

  const onCloseAttachment = () => {
    setIsAddVisible(false);
  };

  const getUploadedFiles = (data: any) => {
    if (uploadedFiles) {
      setUploadedFiles([...uploadedFiles, ...data]);
    } else {
      setUploadedFiles(data);
    }
  };

  const removeFile = (index: number) => {
    const file = uploadedFiles?.slice();
    file.splice(index, 1);
    setUploadedFiles(file);
  };

  const berthFitExecution = async () => {
    await api
      .put(
        {
          url: apiConfig.berthFitExecution,
          params: {
            imo: imoNumber,
            vetRequestId: vetRequestId,
            buId: vetData?.buId,
          },
        },
        setIsLoading
      )
      .then();
  };

  return (
    <>
      <div className="m-l-model-popup__wrap m-l-model-popup--rfi-vessal-owner">
        <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
          <div className="m-l-modal__header">
            <h2 className="modal-title">{vetReviewTitle}</h2>
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={handleCloseClick}
                elementAttr={{ 'data-testid': 'closeClick' }}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body">
            {/* <ScrollView width="100%" height="100%"> */}
            <ValidationGroup ref={recommendationEmailValidation}>
              <div className="row mail-list-form">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group mb-btm-space-22">
                    <div className="m-c-mail-compose-to-select-box">
                      <span>{t('labels.to')}:</span>
                      <TextBox
                        className="m-c-input-control m-c-select-box m-c-select-for-to"
                        value={toAddress}
                        onValueChanged={(e) => {
                          setToAddress(e.value);
                          setIsChanged(true);
                        }}
                        inputAttr={{ 'data-testid': 'toInput' }}
                      >
                        <Validator
                          validationRules={[
                            {
                              type: 'required',
                              message: t('errors.required'),
                            },
                            {
                              type: 'custom',
                              validationCallback: validateEmails(),
                              message: t('errors.emailInvalidMultpleError'),
                            },
                            {
                              type: 'custom',
                              validationCallback: checkEmailvalid(),
                              message: 'public domain emails not allowed',
                            },
                          ]}
                        />
                      </TextBox>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group mb-btm-space-22">
                    <div className="m-c-mail-compose-to-select-box">
                      <span>{t('labels.cc')}:</span>
                      <TextBox
                        className="m-c-input-control m-c-select-box m-c-select-for-cc"
                        value={ccAddress}
                        onValueChanged={(e) => {
                          setCcAddress(e.value);
                          setIsChanged(true);
                        }}
                        inputAttr={{ 'data-testid': 'ccInput' }}
                      >
                        <Validator
                          validationRules={[
                            {
                              ignoreEmptyValue: true,
                              type: 'custom',
                              validationCallback: validateEmails(),
                              message: t('errors.emailInvalidMultpleError'),
                            },
                            {
                              ignoreEmptyValue: true,
                              type: 'custom',
                              validationCallback: checkEmailvalid(),
                              message: 'public domain emails not allowed',
                            },
                          ]}
                        />
                      </TextBox>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className="m-c-form-group mb-btm-space">
                    <div className="m-c-mail-compose-subject-input-box">
                      <span>{t('labels.subject')}:</span>
                      <TextBox
                        className="m-c-input-control m-c-input-for-subject"
                        value={subject}
                        onValueChanged={(e) => {
                          setSubject(e.value);
                          setIsChanged(true);
                        }}
                        inputAttr={{ 'data-testid': 'subjectInput' }}
                      ></TextBox>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 m-c-flex-fill">
                  <div
                    className="m-c-form-group mb-btm-space m-c-html-editor-wrapper"
                    data-testid={'content'}
                  >
                    <RFIHtmlEditor
                      content={content}
                      setContent={setContent}
                      setIsChanged={setIsChanged}
                      entities={entities}
                      showEditor={showEditor}
                      isNotValid={isNotValid}
                    />
                  </div>
                </div>
              </div>
            </ValidationGroup>
            {/* </ScrollView> */}
          </div>
          <div className="m-l-modal__footer">
            <div className="m-l-footer-left-block">
              <a
                onClick={() => setIsAddVisible(true)}
                className="m-l-email-atch-icon-hldr"
              >
                <icons.Attach />
              </a>
              <div className="m-l-files-attached-list">
                {uploadedFiles &&
                  uploadedFiles?.map((file: any, index: number) => {
                    return (
                      file?.name && (
                        <div className="m-l-files-attached-block" key={index}>
                          <div
                            className="m-l-files-attached-list m-l-files-download"
                            key={index}
                            onClick={() => {
                              downloadAttachment(file);
                            }}
                          >
                            <span>{file.name}</span>
                          </div>
                          <div
                            className="m-l-files-close-icon"
                            onClick={() => removeFile(index)}
                          >
                            <icons.Close />
                          </div>
                        </div>
                      )
                    );
                  })}
              </div>
            </div>

            <div className="m-l-footer-right-block">
              <Button
                text="cancel"
                type="default"
                stylingMode="outlined"
                onClick={handleCancelClick}
                className="app-c-btn app-c-btn--secondary min-btn-width"
                elementAttr={{ 'data-testid': 'cancelClick' }}
              />
              <Button
                text="Send"
                type="default"
                stylingMode="contained"
                onClick={handleSaveClick}
                className="app-c-btn app-c-btn--primary min-btn-width"
                elementAttr={{ 'data-testid': 'sendClick' }}
              />
            </div>
          </div>
          <FileUploaderModal
            isVisible={isAddVisible}
            handleCancelClick={onCloseAttachment}
            attachmentType={undefined}
            attachmentTypeVet={undefined}
            getUploadedFiles={getUploadedFiles}
          />
          <ConfirmDialog
            dialogConfig={confirmSaveDialogConfig}
            isOpen={confirmSave}
          />
          <ConfirmDialog
            dialogConfig={confirmCancelDialogConfig}
            isOpen={confirmCancel}
          />
          <ConfirmDialog
            dialogConfig={confirmCloseDialogConfig}
            isOpen={confirmClose}
          />
          <ConfirmDialog
            dialogConfig={confirmSendDialogConfig}
            isOpen={confirmSend}
          />
        </Modal>
      </div>
    </>
  );
}
