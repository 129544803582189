import React, { useCallback, useContext, useEffect, useState } from 'react';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { VesselDetails } from '../../../types/vessel-general';
import MapViewModal from '../map-view-modal/MapViewModal';
import { useTranslation } from 'react-i18next';
import {
  AisPosition,
  complianceStatusList,
  Events,
  LoiteringCoordinatesData,
  LoiteringData,
  loiteringMapLegends,
  MapConfig,
  riskRatingList,
} from '../../../types/compliance-check';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import {
  ComplianceStatusEnum,
  ComplianceTabType,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import {
  CellPreparedEvent,
  DataRowTemplateData,
} from 'devextreme/ui/data_grid';
import { mapColors, Markers, PolylineConfig } from '../../../types/map';
import { mapInfoWindow } from '../map-info-window/MapInfoWindow';
import {
  getBuList,
  getCompanyId,
  getCompanyType,
  getCurrentBuId,
  getLoginBUName,
} from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { ReportGeneratedDetails } from '../../../types/compliance-report';
import toast from '../../../utils/toast';

export default function LoiteringGrid(props: {
  imoNumber: string;
  vesselDetails: VesselDetails;
  reportDetails?: ReportGeneratedDetails;
  vetRequestId?: string;
}) {
  const { t } = useTranslation();
  const { imoNumber, vesselDetails, reportDetails, vetRequestId } = props;
  const complianceCheckContext = useContext(ComplianceCheckContext);
  const [loiteringData, setLoiteringData] = useState<LoiteringData[]>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<LoiteringData>();
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const [mapConfig, setMapConfig] = useState<MapConfig>();
  const [eventData, setEventData] = useState<Events[]>([]);
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const companyType = getCompanyType();
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.find((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;

  useEffect(() => {
    if (complianceCheckContext?.complianceCheckId) {
      getLoiteringData();
    }
  }, [complianceCheckContext]);

  const getLoiteringData = async () => {
    setLoaderMessage?.(t('toast.fetchingLoitering'));
    await api
      .get(
        {
          url: apiConfig.loiteringList,
          params: {
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            imo: imoNumber,
            buid: filteredBU.buId,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setLoaderMessage?.();
        setLoiteringData(res);
        setIsApiLoaded(true);
      })
      .catch((error) => {
        setIsApiLoaded(true);
        setIsApiError(true);
        setLoaderMessage?.();
        if (error?.response?.data?.Message === 'AISDataNotFound') {
          toast.error({
            title: t('toast.liveTrackingNoDataTxt'),
          });
        }
      });
  };

  const cellPrepared = useCallback((e: CellPreparedEvent) => {
    if (e.rowType !== 'data') return;

    const statusClassMapping = {
      [ComplianceStatusEnum.VerifiedFalse]: 'status-active-wtout',
      [ComplianceStatusEnum.VerifiedTrue]: 'status-inactive-wtout',
      default: 'status-pending-wtout',
    };

    const riskClassMapping = {
      [RiskRatingEnum.Low]: 'status-active-wtout',
      [RiskRatingEnum.High]: 'status-inactive-wtout',
      default: 'status-pending-wtout',
    };

    const getClassName = (value: string, mapping: Record<string, string>) =>
      mapping[value] || mapping.default;

    const fieldClassMapping: Record<string, () => string> = {
      status: () => getClassName(e.data.status, statusClassMapping),
      riskRating: () => getClassName(e.data.riskRating, riskClassMapping),
      marsStatus: () => getClassName(e.data.marsStatus, statusClassMapping),
      marsRiskRating: () =>
        getClassName(e.data.marsRiskRating, riskClassMapping),
    };

    if (fieldClassMapping[e.column.dataField as string]) {
      e.cellElement.className =
        fieldClassMapping[e.column.dataField as string]();
    }
  }, []);

  const getNoDataText = useCallback(() => {
    return isApiLoaded
      ? isApiError
        ? t('toast.liveTrackingNoDataTxt')
        : t('labels.loiteringNoDataTxt')
      : t('labels.fetchingData');
  }, [isApiLoaded, isApiError]);

  const gridConfig: IGridConfig = {
    dataSource: loiteringData,
    remoteOperations: false,
    noDataText: getNoDataText(),
    cellPrepared: cellPrepared,
    rowClick: (e) => getSelectedItemIndex(e?.data),
    defaultColumns: [
      {
        caption: t('labels.voyageStart'),
        dataField: 'voyageStart',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.voyageEnd'),
        dataField: 'voyageEnd',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.startCoordinates'),
        dataField: 'startCoord',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: LoiteringData) =>
          calculateCoordinatesCellValue(data.startCoordinate),
      },
      {
        caption: t('labels.endCoordinates'),
        dataField: 'endCoord',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: LoiteringData) =>
          calculateCoordinatesCellValue(data.endCoordinate),
      },
      {
        caption: t('labels.averageClusterSpeed'),
        dataField: 'avgClusterSpeed',
        alignment: 'left',
        dataType: 'number',
        minWidth: 150,
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
      },
      {
        caption: t('labels.fromTime'),
        dataField: 'startCoordinate.lastPositionUTC',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.toTime'),
        dataField: 'endCoordinate.lastPositionUTC',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.durationOfLoitering'),
        dataField: 'loiteringDurationinHr',
        dataType: 'number',
        alignment: 'left',
        minWidth: 150,
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
      },
      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsRiskRating'
            : 'labels.riskRating'
        ),
        dataField: 'marsRiskRating',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.riskRating'),
        dataField: 'riskRating',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible: companyType !== CompanyType.Mars,
      },
      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsStatus'
            : 'labels.status'
        ),
        dataField: 'marsStatus',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        minWidth: 150,
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        visible: companyType !== CompanyType.Mars,
      },
      {
        caption: t('labels.action'),
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
  };

  const calculateCoordinatesCellValue = useCallback(
    (data: AisPosition) =>
      `${convertDecimalToMinutes(data.lat, true)} / ${convertDecimalToMinutes(
        data.lon
      )}`,
    []
  );

  let isLoitering = false;
  const getIconName = (
    position: AisPosition,
    loiteringCoordinatesData: LoiteringCoordinatesData
  ) => {
    if (
      position.vesselAISPositionDetailId ===
      loiteringCoordinatesData.startCoordinate.vesselAISPositionDetailId
    ) {
      isLoitering = true;
      return 'loiteringStart';
    } else if (
      position.vesselAISPositionDetailId ===
      loiteringCoordinatesData.endCoordinate.vesselAISPositionDetailId
    ) {
      isLoitering = false;
      return 'loiteringEnd';
    }
    return isLoitering ? 'arrowSymbol' : 'arrowSymbolBlue';
  };

  const getInfoWindowContent = (
    iconName: string,
    position: AisPosition,
    loiteringSelectedData: LoiteringData
  ) => {
    const titles: Record<string, string> = {
      loiteringStart: t('labels.loiteringStart'),
      loiteringEnd: t('labels.loiteringEnd'),
    };

    return mapInfoWindow(
      position,
      titles[iconName] || vesselDetails?.vesselName || '',
      loiteringSelectedData,
      t
    );
  };

  const formatCoordinatesData = (
    loiteringCoordinatesData: LoiteringCoordinatesData,
    data: LoiteringData
  ) => {
    const markerData: Markers[] = loiteringCoordinatesData.coordinates.map(
      (item, index) => {
        const iconName =
          index === 0
            ? 'anchorageStart'
            : getIconName(item, loiteringCoordinatesData);
        return {
          iconName: iconName,
          iconRotation: item.heading,
          coordinates: {
            lat: item.lat,
            lng: item.lon,
          },
          infoWindowContent: getInfoWindowContent(iconName, item, data),
        };
      }
    );
    setMapConfig({
      center: {
        lat: loiteringCoordinatesData.startCoordinate.lat,
        lng: loiteringCoordinatesData.startCoordinate.lon,
      },
      markers: markerData,
      polylineConfig: getPolylineConfigList(markerData),
      legendsList: loiteringMapLegends,
    });
  };

  const getPolylineConfigList = (data: Markers[]) => {
    const loiteringStartIndex = data.findIndex(
      (item) => item.iconName === 'loiteringStart'
    );
    const loiteringEndIndex = data.findIndex(
      (item) => item.iconName === 'loiteringEnd'
    );
    const poly: PolylineConfig[] = [
      {
        position: data
          .slice(0, loiteringStartIndex + 1)
          .map((item) => item.coordinates),
        strokeColor: mapColors.blue,
      },
      {
        position: data
          .slice(loiteringStartIndex, loiteringEndIndex + 1)
          .map((item) => item.coordinates),
        strokeColor: mapColors.red,
      },
      {
        position: data.slice(loiteringEndIndex).map((item) => item.coordinates),
        strokeColor: mapColors.blue,
      },
    ];
    return poly;
  };

  const actionCellTemplate = (data: DataRowTemplateData) => {
    return (
      <div>
        <i
          className="dx-icon-map dx-custom-icon"
          onClick={() => getSelectedItemIndex(data.data)}
        ></i>
      </div>
    );
  };

  const onClickMap = async (index: number) => {
    const companyId = getCompanyId();
    const buId = getCurrentBuId();
    const data = loiteringData[index];
    await api
      .get(
        {
          url: apiConfig.loiteringPositions,
          params: {
            loiteringDetailId: data.loiteringDetailId,
            voyageStartDate: data.voyageStartDate,
            voyageEndDate: data.voyageEndDate,
            companyId: companyId,
            buId: buId,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setSelectedRow(data);
        setSelectedIndex(index);
        setShowMapModal(true);
        setEventsGridData(data);
        formatCoordinatesData(res, data);
      });
  };

  const handleGridRefresh = () => {
    getLoiteringData();
  };

  const setEventsGridData = (data: LoiteringData) => {
    const events: Events[] = [
      {
        event: 'Voyage Start',
        coordinates: data.voyageStartCoordinates,
        dateTime: data.voyageStartDate,
        destination: data.voyageStartDestination,
      },
      {
        event: 'Loitering Start',
        coordinates: {
          lat: data.startCoordinate.lat,
          lon: data.startCoordinate.lon,
        },
        dateTime: data.startCoordinate.lastPositionUTC,
        destination: data.startCoordinate.destination,
      },
      {
        event: 'Loitering End',
        coordinates: {
          lat: data.endCoordinate.lat,
          lon: data.endCoordinate.lon,
        },
        dateTime: data.endCoordinate.lastPositionUTC,
        destination: data.endCoordinate.destination,
      },
      {
        event: 'Voyage End',
        coordinates: data.voyageEndCoordinates,
        dateTime: data.voyageEndDate,
        destination: data.voyageEndDestination,
      },
    ];
    setEventData(events);
  };

  const handleCloseMapModal = (isVisible: boolean, refreshData?: boolean) => {
    setShowMapModal(isVisible);
    if (refreshData) {
      getLoiteringData();
    }
  };

  const getSelectedItemIndex = (data: LoiteringData) => {
    const itemIndex = loiteringData.findIndex(
      (item) => item.loiteringDetailId === data.loiteringDetailId
    );
    if (itemIndex != -1) {
      onClickMap(itemIndex);
    }
  };

  return (
    <div className={'m-c-grid m-c-block-grid-list'}>
      <Grid gridConfig={gridConfig} actionTemplate={actionCellTemplate} />
      {showMapModal && mapConfig && selectedRow && (
        <MapViewModal
          isReportGenerated={reportDetails?.isReportGenerated ?? false}
          eventData={eventData}
          isVisible={showMapModal}
          selectedIndex={selectedIndex}
          gridDataLength={loiteringData?.length}
          handleNextPrev={onClickMap}
          setIsVisible={handleCloseMapModal}
          vesselDetails={vesselDetails}
          mapConfig={mapConfig}
          detailId={selectedRow.loiteringDetailId}
          selectedRow={selectedRow}
          complianceType={ComplianceTabType.Loitering}
          vetRequestId={vetRequestId}
          handleGridRefresh={handleGridRefresh}
        ></MapViewModal>
      )}
    </div>
  );
}
