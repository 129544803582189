import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import CustomCircleControl from './draw-control/CustomDraw';

export default function CustomDrawIcon(
  props: Readonly<{
    handleCircleDraw?: (
      lat: number,
      lng: number,
      radius: number,
      areaName: string
    ) => void;
  }>
) {
  const { handleCircleDraw } = props;
  const map = useMap();
  const [isFormVisible, setIsFormVisible] = useState(false);
  useEffect(() => {
    const CustomControl = L.Control.extend({
      options: {
        position: 'topright',
      },
      onAdd: function () {
        const container = L.DomUtil.create(
          'div',
          'leaflet-bar leaflet-control'
        );
        const icon = L.DomUtil.create('div', 'dx-icon-plus', container);
        icon.onclick = () => setIsFormVisible(!isFormVisible);
        icon.title = 'Enter circle details to draw the area';

        return container;
      },
    });

    const customControl = new CustomControl();
    map.addControl(customControl);
    return () => {
      map.removeControl(customControl);
    };
  }, []);

  return (
    <>
      {isFormVisible && (
        <CustomCircleControl
          onCircleDraw={handleCircleDraw}
          onClose={setIsFormVisible}
        />
      )}
    </>
  );
}
