import { LatLngExpression } from 'leaflet';
import { Legend, mapColors, Markers, PolylineConfig } from './map';
import images from '../assets/images';

export type AisGapData = {
  aisGapDetailId: number;
  voyageStart?: string;
  voyageEnd?: string;
  marsRiskRating: number;
  riskRating: number;
  startPosition: AisPosition;
  endPosition: AisPosition;
  distanceBetweenPositions: number;
  status: number;
  marsStatus: number;
  tImeTakenInHr: number;
  darkPortCalls: PortDetails[] | null;
  vesselAvgSpeed: number;
  maxDistanceCoverage: number;
  timeTakenInHr: number;
  comment: string;
  marsComment: string;
  companyLastModifiedDate: string;
  companyLastModifiedUser: string;
  marsLastModifiedDate: string;
  marsLastModifiedUser: string;
  voyageStartCoordinates: AisPosition;
  voyageEndCoordinates: AisPosition;
  voyageStartDate: string;
  voyageEndDate: string;
};

export type PortDetails = {
  entryName: string;
  portName: string;
  countryCode: string;
  name: string;
  countryName: string;
  latitude: number;
  longitude: number;
  iso3: string;
};

export type AisPosition = {
  vesselAISPositionDetailId: number;
  imoNumber: string;
  lat: number;
  lon: number;
  speed: number;
  course: number;
  heading: number;
  destination: string;
  lastPositionEpoch: number;
  lastPositionUTC: string;
  portDetails?: PortDetails;
};

export type MapConfig = {
  markers: Markers[];
  polylineConfig: PolylineConfig[];
  center?: LatLngExpression | null;
  legendsList?: Legend[];
};

export type LoiteringData = {
  loiteringDetailId: number;
  voyageStart?: string;
  voyageEnd?: string;
  riskRating: number;
  marsRiskRating: number;
  status: number;
  marsStatus: number;
  startCoordinate: AisPosition;
  endCoordinate: AisPosition;
  avgClusterSpeed: number;
  loiteringDurationinHr?: number;
  comment: string;
  marsComment: string;
  companyLastModifiedDate: string;
  companyLastModifiedUser: string;
  marsLastModifiedDate: string;
  marsLastModifiedUser: string;
  voyageStartCoordinates: AisPosition;
  voyageEndCoordinates: AisPosition;
  voyageStartDate: string;
  voyageEndDate: string;
  voyageStartDestination: string;
  voyageEndDestination: string;
};

export type StsData = {
  stsDetailId: number;
  voyageStart?: string;
  voyageEnd?: string;
  vesselOneAISPosition: AisPosition[];
  vesselTwoAISPosition: AisPosition[];
  vesselOneStartCoordinates: Coordinates;
  vesselOneEndCoordinates: Coordinates;
  vesselTwoStartCoordinates: Coordinates;
  vesselTwoEndCoordinates: Coordinates;
};

export type AisGapCoordinatesData = {
  aisGapDetailId: number;
  darkPortCalls: PortDetails[];
  startPosition: AisPosition;
  endPosition: AisPosition;
  vesselPositionsBeforeAndAfterGap: AisPosition[];
};

export type LoiteringCoordinatesData = {
  loiteringDetailId: number;
  startCoordinate: AisPosition;
  endCoordinate: AisPosition;
  coordinates: AisPosition[];
};

export type ComplianceOptionvalue = {
  optionValueInt: number;
  optionValueCode?: number;
  optionValueText: string;
  optionValueDesc?: string;
  optionTypeCode?: string;
  isDefault?: boolean;
  displayOrder?: number;
  isSystemDefined?: boolean;
  visible?: boolean;
  parentOptionTypeCode?: string;
  parentOptionValueCode?: string;
};

export type StsComplianceData = {
  stsDetailId: number;
  voyageStart: string | null;
  voyageEnd: string | null;
  vesselOneIMO: number;
  vesselTwoIMO: number;
  vesselOneName: string;
  vesselTwoName: string;
  marsRiskRating: number;
  proximityVessel: string;
  proximityVesselVoyageStart: string;
  proximityVesselVoyageEnd: string;
  proximityVesselDuration?: number;
  startCoordinates: Coordinates;
  endCoordinates: Coordinates;
  vesselSpeed: number;
  fromDate: string;
  toDate: string;
  duration: number;
  area: string;
  marsStatus: number;
  status: number;
  riskRating: number;
  comment: string;
  marsComment: string;
  companyLastModifiedDate: string;
  companyLastModifiedUser: string;
  marsLastModifiedDate: string;
  marsLastModifiedUser: string;
  voyageStartCoordinates: AisPosition;
  voyageEndCoordinates: AisPosition;
  voyageStartDate: string;
  voyageEndDate: string;
  proximityVesselVoyageEndPortName?: string;
  proximityVesselVoyageStartPortName?: string;
};

export type Coordinates = {
  lat: number;
  lon: number;
};

export type ComplianceCheckComment = {
  detailId: number;
  complianceCheckId: string;
  marsRiskRating?: number;
  marsStatus?: number;
  marsComment?: string;
  riskRating: number | null;
  status: number | null;
  comment: string;
  complianceType?: number;
  attachMents?: File;
};

export type Events = {
  event: string;
  dateTime: string;
  coordinates: {
    lat: number;
    lon: number;
  };
  nearByPort?: PortDetails[] | null;
  vesselInContact?: string;
  STSProneArea?: string;
  destination?: string;
};

export type ComplianceCheckList = {
  complianceCheckId: string;
  imoNumber: string;
  vesselName: string;
  requestedUserId: number;
  requestedUserName: string;
  companyId: number;
  companyName: string;
  buId: number;
  buName: string;
  requestedDateTime: string;
  vetRequestId: string | null;
};

export const complianceStatusList = [
  {
    optionValueInt: 3,
    optionValueCode: 3,
    optionValueText: 'Open',
  },
  {
    optionValueInt: 1,
    optionValueCode: 1,
    optionValueText: 'Verified - True',
  },
  {
    optionValueInt: 2,
    optionValueCode: 2,
    optionValueText: 'Verified - False',
  },
];

export const riskRatingList = [
  {
    optionValueInt: 1,
    optionValueCode: 1,
    optionValueText: 'Low',
  },
  {
    optionValueInt: 2,
    optionValueCode: 2,
    optionValueText: 'Medium',
  },
  {
    optionValueInt: 3,
    optionValueCode: 3,
    optionValueText: 'High',
  },
];

export const aisGapMapLegends: Legend[] = [
  {
    iconUrl: images.aisGapStart,
    label: 'AIS Gap Start',
  },
  {
    iconUrl: images.aisGapEnd,
    label: 'AIS Gap End',
  },
  {
    iconUrl: images.departure,
    label: 'Departure',
  },
  {
    iconUrl: images.pinIconRed,
    label: 'Dark Port Call',
  },
  {
    isLine: true,
    isDashed: true,
    label: 'AIS Gap',
    color: mapColors.red,
  },
];

export const loiteringMapLegends: Legend[] = [
  {
    iconUrl: images.loiteringStart,
    label: 'Loitering Start',
  },
  {
    iconUrl: images.loiteringEnd,
    label: 'Loitering End',
  },
  {
    iconUrl: images.departure,
    label: 'Departure',
  },
  {
    isLine: true,
    label: 'Loitering',
    color: mapColors.red,
  },
];

export const stsMapLegends: Legend[] = [
  {
    iconUrl: images.stsStart,
    label: 'STS Start',
  },
  {
    iconUrl: images.stsEnd,
    label: 'STS End',
  },
  {
    iconUrl: images.departure,
    label: 'Departure',
  },
  {
    isMultiline: true,
    label: 'STS',
    color: mapColors.blue,
    isDashed: true,
  },
];
