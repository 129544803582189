import React from 'react';
import { VesselPositionLive } from '../../../types/live-tracking';
import { VesselDetails } from '../../../types/vessel-general';
import { format } from 'date-fns';
import { TFunction } from 'react-i18next';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import Tooltip from 'devextreme-react/tooltip';

export default function LiveTrackingInfoWindow(
  position: VesselPositionLive,
  vesselDetails: VesselDetails,
  flag: string,
  t: TFunction<'translation', undefined>
) {
  const dateInGMTFormat = (date: string, isUtc?: boolean) => {
    const dateObj = new Date(date);
    if (isUtc) {
      return format(dateObj, "dd MMM yyyy HH:mm:ss 'GMT'");
    }
    return format(
      new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000),
      "dd MMM yyyy HH:mm:ss 'GMT'"
    );
  };
  return (
    <div className="map-info-window-container">
      <div className="vessel-tracking-info-window-header">
        <span className="header-text">{vesselDetails?.vesselName}</span>
        {vesselDetails?.isSanction && (
          // <div>
          <i className="dx-icon-warning custom-icon"></i>
          // </div>
        )}
      </div>
      <div className="time-block">
        {dateInGMTFormat(position.lastPositionUtc)}
      </div>
      <div className="flag-container">
        <p className="map-info-window-label">Flag:</p>
        <img
          src={flag}
          className="flag-image"
          alt="flag"
          height={20}
          width={20}
        />
        <p className="map-info-window-label">
          <strong>{vesselDetails.flagName}</strong>
        </p>
      </div>
      <p className="map-info-window-label">
        {t('labels.imo')}
        <strong>{vesselDetails.imoDisplayName}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.vesselType')}: <strong>{vesselDetails.vesselType}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.coordinates')}:{' '}
        <strong>
          {convertDecimalToMinutes(position.lat, true) +
            '/' +
            convertDecimalToMinutes(position.lon)}
        </strong>
      </p>
      <div className="horizontal-line"></div>
      <p className="map-info-window-label">
        {t('labels.lastPort')}: <strong>{position.depPort}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.intendedPort')}: <strong>{position.destPort}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.estimatedCarbonEmission')}:{' '}
        {position.carbonEmission && (
          <strong>{position.carbonEmission?.toFixed(2)} gCO&#8322;/ton</strong>
        )}
      </p>
      <div className="horizontal-line"></div>
      <p className="map-info-window-label">
        {t('labels.navigationStatus')}:{' '}
        <strong>{`${position.navigationStatus}`}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.speed')}: <strong>{`${position.speed} kn`}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.course')}: <strong>{position.course}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.heading')}: <strong>{position.heading}</strong>
      </p>
      <p className="map-info-window-label">
        {t('labels.draught')}: <strong>{`${position.currentDraught} m`}</strong>
      </p>
      <div className="horizontal-line"></div>
      <p className="map-info-window-label" id="atd">
        {t('labels.atd')}: <strong>{dateInGMTFormat(position.atdUtc)}</strong>
      </p>
      <Tooltip
        target={'#atd'}
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        position={{ at: 'left', of: '#atd', my: 'right' }}
        contentRender={() => {
          return <div className="m-l-tooltip-wrapper">{t('tooltips.atd')}</div>;
        }}
      />
      {position.etaUtc && (
        <>
          <p className="map-info-window-label" id="eta">
            {t('labels.eta')}:{' '}
            <strong>{dateInGMTFormat(position.etaUtc)}</strong>
          </p>
          <Tooltip
            target={'#eta'}
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
            position={{ at: 'left', of: '#eta', my: 'right' }}
            contentRender={() => {
              return (
                <div className="m-l-tooltip-wrapper">{t('tooltips.eta')}</div>
              );
            }}
          />
        </>
      )}
    </div>
  );
}
