export enum SanctionsSettingType {
  SanctionedPortList = 'RestrictedPortList',
  SanctionedCountryList = 'SanctionedCountryList',
  SanctionedVesselList = 'SanctionedVesselList',
  SanctionedBareboatOwner = 'RestrictedBareboatOwner',
  SanctionedDOCCompany = 'RestrictedDOCCompany',
  SanctionedGroupOwner = 'RestrictedGroupOwner',
  SanctionedOperator = 'RestrictedOperator',
  SanctionedRegisteredOwner = 'RestrictedRegisteredOwner',
  SanctionedShipManager = 'RestrictedShipManager',
  SanctionedTechnicalManager = 'RestrictedTechnicalManager',
  ApprovedPortList = 'ApprovedPortList',
  ApprovedTerminalList = 'ApprovedTerminalList',
  ApprovedBerthList = 'ApprovedBerthList',
  RestrictedVesselList = 'RestrictedVesselList',
  HighRiskPortList = 'HighRiskPortList',
}

export enum EmailSettingType {
  Confidentiality = 'Confidentiality',
  RFIToClientSalutation = 'RFIToClientSalutation',
  RFIToVesselOwnerSalutation = 'RFIToVesselOwnerSalutation',
  RFIToClientSignature = 'RFIToClientSignature',
  RFIToVesselOwnerSignature = 'RFIToVesselOwnerSignature',
}

export enum SanctionEntity {
  Ship = 'Ship',
  Port = 'Ports',
  Terminal = 'Terminal',
  Berth = 'Berth',
  None = 'None',
  Companies = 'Companies',
  Countries = 'Countries',
}

export enum SanctionAttribute {
  ShipManagerCompanyCode = 'ShipManagerCompanyCode',
  TechnicalManagerCode = 'TechnicalManagerCode',
  FlagCode = 'FlagCode',
  BareboatCharterCompanyCode = 'BareboatCharterCompanyCode',
  DOCCompanyCode = 'DOCCompanyCode',
  OperatorCompanyCode = 'OperatorCompanyCode',
  GroupBeneficialOwnerCompanyCode = 'GroupBeneficialOwnerCompanyCode',
  RegisteredOwnerCode = 'RegisteredOwnerCode',
  IMONumber = 'IMONumber',
  IMODisplayName = 'imoDisplayName',
  PortName = 'PortName',
  PortCode = 'PortCode',
  TerminalName = 'TerminalCode',
  BerthName = 'BerthCode',
  None = 'None',
  CompanyName = 'CompanyName',
  CountryCode = 'CountryCode',
}

export enum BerthFitReportSettings {
  VesselDetails = 'VesselDetails',
  VetDetails = 'VetDetails',
  BerthExecutionDetails = 'BerthExecutionDetails',
  MARSDisclaimer = 'MARSDisclaimer',
  RequestorDisclaimer = 'RequestorDisclaimer',
  Rules = 'Rules',
  RequestorComments = 'RequestorComments',
  AppealStatusCommentsInBerth = 'AppealStatusComments',
  AppealStatusComments = 'AppealStatusComments',
  BerthFitComments = 'BerthFitComments',
}

export enum StsType {
  ProximityPeriod = 'ProximityPeriod',
  ThresholdPeriod = 'ThresholdPeriod',
  MaximumSpeedDuringSTS = 'MaximumSpeedDuringSTS',
  VesselContactDistanceThreshold = 'VesselContactDistanceThreshold',
}

export enum LoiteringType {
  AisDataPointsForLoitering = 'AisDataPointsForLoitering',
  MaximumSpeed = 'MaximumSpeed',
  MinimumDurationForLoitering = 'MinimumDurationForLoitering',
  VesselClusterPointsDistance = 'VesselClusterPointsDistance',
}

export enum AisType {
  AisGapDuration = 'AisGapDuration',
  SpeedCalculationPercentage = 'SpeedCalculationPercentage',
}

export enum ComplianceType {
  Compliance = 'Compliance',
  CompliancePeriod = 'CompliancePeriod',
}

export enum ComplianceReportSettingsType {
  VesselDetails = 'VesselDetails',
  AISDetails = 'AISDetails',
  STSDetails = 'STSDetails',
  LoiteringDetails = 'LoiteringDetails',
  MovementRiskDetails = 'MovementRiskDetails',
  MARSDisclaimer = 'MarsDisclaimer',
  RequestorDisclaimer = 'CompanyDisclaimer',
  complianceReport = 'complianceReport',
  complianceReportPost = 'ComplianceReport',
}

export enum SanctionCheckReportSettingsType {
  SanctionsCheckReport = 'SanctionReport',
  VesselDetails = 'VesselDetail',
  CompanyDetails = 'CompanyDetail',
  MARSDisclaimer = 'MarsDisclaimer',
  RequestorDisclaimer = 'CompanyDisclaimer',
  sanctionSourceOverView = 'SanctionsSourceOverview',
  sanctionSourceList = 'SanctionsSourceList',
}
