import Button from 'devextreme-react/button';
import CheckBox from 'devextreme-react/check-box';
import Popover from 'devextreme-react/popover';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useState } from 'react';
import icons from '../../common/icons/icons';
import { useResize } from '../../../hooks/useResize';

export default function DashBoardListPopover(props: {
  dashboardList: any[];
  showPopover: boolean;
  target: string;
  closeOnOutsideClick: boolean;
  onHidingPopover: any;
  onSelectDashboard: any;
}) {
  const {
    showPopover,
    target,
    closeOnOutsideClick,
    onHidingPopover,
    dashboardList,
    onSelectDashboard,
  } = props;

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-add-roles__popup',
      'data-testid': 'rolePopover',
    };
  }, []);

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <Popover
      id="rolesPopover"
      target={target}
      position={position}
      width={300}
      closeOnOutsideClick={closeOnOutsideClick}
      visible={showPopover}
      onHiding={onHidingPopover}
      wrapperAttr={popupAttributes}
      hideOnParentScroll={false}
    >
      <div className="m-l-grid-clone-popup-wrap">
        <div className="m-l-grid-clone-popup-header">
          {/* Dashboard List */}
        </div>
        <ScrollView>
          <div className="m-l-grid-clone-popup-body u-pl0 u-pr0 u-pt0">
            <ul className="m-l-checkbox-list-wrap">
              {dashboardList.map((role) => {
                return (
                  <li key={role.featureId}>
                    <div className="m-c-checkbox m-c-checkbox-dark">
                      <CheckBox
                        // elementAttr={{ 'data-testId': role.isDeleted }}
                        defaultValue={!role.isDeleted}
                        text={role.featureDescription}
                        onValueChanged={(e: any) => onSelectDashboard(e, role)}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </ScrollView>
        <div className="m-l-grid-clone-popup-footer">{/* footer  */}</div>
      </div>
    </Popover>
  );
}
