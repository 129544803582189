import React, { useMemo } from 'react';
import { getBuList, getLoginBUName } from '../../../utils/jwt-decode';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';

export default function ComplianceCheckButton(props: { imoNumber: string }) {
  const { imoNumber } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.find((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;
  const messages = useMemo(() => {
    return [
      t('toast.fetchingAISGap'),
      t('toast.fetchingLoitering'),
      t('toast.fetchingSTS'),
      t('toast.fetchingMovementRisk'),
      t('toast.loading'),
    ];
  }, []);

  const complianceProcessingScheduler = async (complianceId: string) => {
    await api
      .schedulerPost(
        {
          url: apiConfig.complianceScheduler + complianceId,
        },
        setIsLoading
      )
      .then(() => {
        navigate('/compliance-check', {
          state: { imoNumber: imoNumber, complianceCheckId: complianceId },
        });
      });
  };

  const onClickComplianceCheck = async () => {
    setIsLoading(true);
    let index = 0;
    const interval = setInterval(() => {
      setLoaderMessage?.(messages[index]);
      index = (index + 1) % messages.length;
    }, 4000);
    await api
      .get({
        url: apiConfig.complianceCheck,
        params: { imo: imoNumber, buid: filteredBU.buId },
      })
      .then((res) => {
        setLoaderMessage?.();
        setIsLoading(false);
        complianceProcessingScheduler(res);
        clearInterval(interval);
      })
      .catch(() => {
        setLoaderMessage?.();
        setIsLoading(false);
        clearInterval(interval);
      });
  };
  return (
    <Button
      className="app-c-btn app-c-btn--primary min-btn-width d-none d-md-block"
      onClick={onClickComplianceCheck}
    >
      {t('buttons.complianceCheck')}
    </Button>
  );
}
