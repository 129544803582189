import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { usePermission } from '../../../../../contexts/PermissionContext';

export default function AISConfigForm(
  props: Readonly<{
    buId: string;
  }>
) {
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { control } = useFormContext();

  const { buId } = props;
  const maxValueValidation = (e: any) => {
    return e.value <= 100;
  };

  const aisFormConfig = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'aisGapDuration',
      label: t('labels.aisGapDuration'),
      control: control,
      fieldType: 'number',
      id: 'aisGapDuration',
      isRequired: true,
      allowDecimal: false,
      helpText: t('toast.aisGapMinimumDurationDesc'),
      showHelpIcon: true,
      maxLength: 100,
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditAISGapConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditAISGapConfigurationSettings'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'speedCalculationPercentage',
      label: t('labels.speedCalculationPercentage'),
      control: control,
      fieldType: 'number',
      id: 'speedCalculationPercentage',
      isRequired: true,
      showHelpIcon: true,
      helpText: t('toast.speedCalculationPercentageDesc'),
      maxLength: 3,
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditAISGapConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditAISGapConfigurationSettings'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'custom',
          validationCallback: maxValueValidation,
          message: t('errors.maxHundred'),
        },
      ],
    },
  ];
  return (
    <div className="row">
      <FormBuilder formConfig={aisFormConfig} />
    </div>
  );
}
