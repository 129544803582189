import React, { useState } from 'react';
import Icons from '../../common/icons/icons';
import { VesselDetails } from '../../../types/vessel-general';
import LiveTrackingModal from '../live-tracking-modal/LiveTrackingModal';

export default function LiveTracking(props: {
  vesselDetails: VesselDetails;
  flagUrl: string;
  imoNumber: string;
}) {
  const { vesselDetails, flagUrl, imoNumber } = props;
  const [showMapModal, setShowMapModal] = useState(false);
  return (
    <>
      <div className="app-l-vertical-seperator"></div>
      <div
        className="m-l-flag-wrap"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowMapModal(true)}
      >
        <span className="ml-ads-tbl-flag">
          <Icons.LiveTracking />
        </span>
        <a className="m-l-page-id-text">Live Tracking</a>
      </div>
      {showMapModal && (
        <LiveTrackingModal
          vesselDetails={vesselDetails}
          flagUrl={flagUrl}
          imoNumber={imoNumber}
          isVisible={showMapModal}
          setIsVisible={(value: boolean) => setShowMapModal(value)}
        />
      )}
    </>
  );
}
