import React from 'react';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { usePermission } from '../../../../../contexts/PermissionContext';

export default function LoiteringConfigForm(
  props: Readonly<{
    buId: string;
  }>
) {
  const { buId } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { control } = useFormContext();

  const loiteringFormConfig = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'maximumSpeed',
      label: t('labels.maximumSpeedLoitering'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      showHelpIcon: true,
      helpText: t('toast.maximumSpeedDesc'),
      maxLength: 100,
      id: 'maximumSpeed',
      allowDecimal: true,
      disabled: buId
        ? !findFeature(
            'BUCompanyConfiguration.EditLoiteringConfigurationSettings'
          )
        : !findFeature(
            'CompanyConfiguration.EditLoiteringConfigurationSettings'
          ),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'minimumDurationLoitering',
      label: t('labels.minimumDurationOfLoitering'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      showHelpIcon: true,
      allowDecimal: true,
      id: 'minimumDurationLoitering',
      helpText: t('toast.minimumDurationOfLoiteringDesc'),
      maxLength: 100,
      disabled: buId
        ? !findFeature(
            'BUCompanyConfiguration.EditLoiteringConfigurationSettings'
          )
        : !findFeature(
            'CompanyConfiguration.EditLoiteringConfigurationSettings'
          ),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'aisDataPointsLoitering',
      label: t('labels.aisDataPointsForLoitering'),
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      isRequired: true,
      showHelpIcon: true,
      id: 'aisDataPointsForLoitering',
      helpText: t('toast.aisDataPointsForLoiteringDesc'),
      disabled: buId
        ? !findFeature(
            'BUCompanyConfiguration.EditLoiteringConfigurationSettings'
          )
        : !findFeature(
            'CompanyConfiguration.EditLoiteringConfigurationSettings'
          ),
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesselClusterPointsDistance',
      label: t('labels.vesselClusterPointsDistance'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      showHelpIcon: true,
      id: 'vesselClusterPointsDistance',
      helpText: t('toast.vesselClusterPointsDistanceDesc'),
      disabled: buId
        ? !findFeature(
            'BUCompanyConfiguration.EditLoiteringConfigurationSettings'
          )
        : !findFeature(
            'CompanyConfiguration.EditLoiteringConfigurationSettings'
          ),
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];
  return (
    <div className="row">
      <FormBuilder formConfig={loiteringFormConfig} />
    </div>
  );
}
