import Button from 'devextreme-react/button';
import SelectBox from 'devextreme-react/select-box';
import TextBox from 'devextreme-react/text-box';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  IEntityAttribute,
  IMasterEntity,
  IOptionvalue,
} from '../../../types/master-data';
import { IRuleConditionFields } from '../../../types/rules';
import icons from '../../common/icons/icons';
import { CustomRule, Validator } from 'devextreme-react/validator';
import { Relation } from '../../../enums/relation-enum';
import { useTranslation } from 'react-i18next';
import Tooltip from 'devextreme-react/tooltip';
import RuleConditionValueField from '../rule-condition-value-field/RuleConditionValueField';
import toast from '../../../utils/toast';
import { Entity } from '../../../enums/entity-enum';
import CheckBox from 'devextreme-react/check-box';
import { Condition } from '../../../enums/condition-enum';

export default function RuleConditionFields(props: {
  nestedIndex: number;
  setGroupValue: any;
  removeGroup: any;
  entities: IMasterEntity[];
  attributes: IEntityAttribute[];
  conditions: IOptionvalue[];
  canUserEnterOptions: IOptionvalue[];
  relations: IOptionvalue[];
  buId: number;
  getAttributes?: (entityId: number) => Promise<void>;
  getConditions?: (optionCode: string) => Promise<void>;
}) {
  const {
    nestedIndex,
    setGroupValue,
    removeGroup,
    entities,
    attributes,
    conditions,
    relations,
    buId,
  } = props;

  const { t } = useTranslation();

  const appendValues: IRuleConditionFields = {
    ruleGroupId: 0,
    ruleConditionId: 0,
    entityId: null,
    attributeId: null,
    condition: null,
    value1: '',
    value2: '',
    canUserEnter: false,
    description: '',
    dispalyOrder: 0,
    conditionRelation: null,
    attributeType: '',
    isListValue: false,
    isSelectable: false,
    selectList: '',
    selectListDisplay: '',
    selectListValue: '',
    entityName: '',
    isEntityValue: false,
    valueEntityId: null,
    valueAttributeId: null,
    canValueUserEnter: false,
    isEditable: false,
    attributeName: '',
  };
  const { control, setValue, getValues } = useFormContext();
  const [showRelationPopover, setShowRelationPopover] = useState(-1);
  const [forceRender, setForceRender] = useState(false);
  const attributeRef = useRef<SelectBox[]>([]);
  const conditionRef = useRef<SelectBox[]>([]);
  const entityRef = useRef<SelectBox[]>([]);
  const relationRef = useRef<any>(null);

  const ruleConditionFormArray = useFieldArray({
    control: control,
    name: `groups[${nestedIndex}].ruleConditions`,
  });
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (relationRef.current && !relationRef.current.contains(event.target)) {
        setShowRelationPopover(-1);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [relationRef]);

  useEffect(() => {
    attributeRef.current?.map((ref, index) => {
      ref?.instance
        .getDataSource()
        .filter([
          'masterEntityId',
          '=',
          getValues().groups[nestedIndex].ruleConditions[index].entityId,
        ]);
      ref?.instance.getDataSource()?.load();
    });
  }, [attributes, forceRender]);

  useEffect(() => {
    conditionRef.current?.map((ref, index) => {
      if (
        getValues()?.groups[nestedIndex]?.ruleConditions[index]?.entityName ===
        Entity.Port
      ) {
        ref?.instance
          .getDataSource()
          .filter([
            [
              'optionTypeCode',
              '=',
              getValues().groups[nestedIndex].ruleConditions[index]
                .attributeType,
            ],
            'and',
            [
              ['optionValueCode', '=', 'IN'],
              'or',
              ['optionValueCode', '=', 'NOTIN'],
              'or',
              ['optionValueCode', '=', '!='],
            ],
          ]);
      } else {
        ref?.instance
          .getDataSource()
          .filter([
            'optionTypeCode',
            '=',
            getValues().groups[nestedIndex].ruleConditions[index].attributeType,
          ]);
      }
      ref?.instance.getDataSource()?.load();
    });
  }, [conditions, forceRender]);

  const valueChanged = (e: any, field: any) => {
    field.onChange(e.value);
  };

  const checkIncidentExist = useCallback((selectedItem: any, index: number) => {
    let isIncident = false;
    getValues().groups.map((group: any, groupIndex: number) => {
      if (!isIncident) {
        isIncident =
          !!group.ruleConditions.find(
            (item: any, conditionIndex: number) =>
              item.entityName === Entity.Incident &&
              (conditionIndex !== index || nestedIndex !== groupIndex)
          ) &&
          (selectedItem?.entityName === Entity.PSC ||
            selectedItem?.entityName === Entity.ManagementHistory ||
            selectedItem?.entityName === Entity.Management);
      }
    });
    if (isIncident) {
      toast.error({
        title: t('toast.error'),
        message: t('toast.cannotAddBothPSCAndIncident'),
      });
    }
    return isIncident;
  }, []);

  const checkPSCtExist = useCallback((selectedItem: any, index: number) => {
    let isPSC = false;
    getValues().groups.map((group: any, groupIndex: number) => {
      if (!isPSC) {
        isPSC =
          !!group.ruleConditions.find(
            (item: any, conditionIndex: number) =>
              item.entityName === Entity.PSC &&
              (conditionIndex !== index || nestedIndex !== groupIndex)
          ) &&
          (selectedItem?.entityName === Entity.Incident ||
            selectedItem?.entityName === Entity.IncidentHistory ||
            selectedItem?.entityName === Entity.ManagementHistory ||
            selectedItem?.entityName === Entity.Management);
      }
    });
    if (isPSC) {
      toast.error({
        title: t('toast.error'),
        message: t('toast.cannotAddBothPSCAndIncident'),
      });
    }
    return isPSC;
  }, []);

  const checkManagementHistoryExist = useCallback(
    (selectedItem: any, index: number) => {
      let isManagement = false;
      getValues().groups.map((group: any, groupIndex: number) => {
        if (!isManagement) {
          isManagement =
            !!group.ruleConditions.find(
              (item: any, conditionIndex: number) =>
                item.entityName === Entity.ManagementHistory &&
                (conditionIndex !== index || nestedIndex !== groupIndex)
            ) &&
            (selectedItem?.entityName === Entity.PSC ||
              selectedItem?.entityName === Entity.Incident ||
              selectedItem?.entityName === Entity.IncidentHistory ||
              selectedItem?.entityName === Entity.Management);
        }
      });
      if (isManagement) {
        toast.error({
          title: t('toast.error'),
          message: t('toast.cannotAddBothPSCAndIncident'),
        });
      }
      return isManagement;
    },
    []
  );

  const checkManagementExist = useCallback(
    (selectedItem: any, index: number) => {
      let isManagement = false;
      getValues().groups.map((group: any, groupIndex: number) => {
        if (!isManagement) {
          isManagement =
            !!group.ruleConditions.find(
              (item: any, conditionIndex: number) =>
                item.entityName === Entity.Management &&
                (conditionIndex !== index || nestedIndex !== groupIndex)
            ) &&
            (selectedItem?.entityName === Entity.PSC ||
              selectedItem?.entityName === Entity.Incident ||
              selectedItem?.entityName === Entity.IncidentHistory ||
              selectedItem?.entityName === Entity.ManagementHistory);
        }
      });
      if (isManagement) {
        toast.error({
          title: t('toast.error'),
          message: t('toast.cannotAddBothPSCAndIncident'),
        });
      }
      return isManagement;
    },
    []
  );

  const onChangeEntity = async (e: any, field: any, index: number) => {
    const selectedItem =
      entityRef.current[index]?.instance.option('selectedItem');
    if (
      checkIncidentExist(selectedItem, index) ||
      checkPSCtExist(selectedItem, index) ||
      checkManagementHistoryExist(selectedItem, index) ||
      checkManagementExist(selectedItem, index)
    ) {
      toast.error({
        title: t('toast.error'),
        message: t('toast.cannotAddBothPSCAndIncident'),
      });
      return;
    }
    valueChanged(e, field);
    if (e.event) {
      setValue(
        `groups[${nestedIndex}].ruleConditions[${index}].attributeId`,
        null
      );
      setValue(
        `groups[${nestedIndex}].ruleConditions[${index}].entityName`,
        selectedItem ? selectedItem.entityName : ''
      );
      attributeRef.current[index]?.instance
        .getDataSource()
        .filter(['masterEntityId', '=', e.value]);
      attributeRef.current[index]?.instance.getDataSource()?.load();
      setForceRender(!forceRender);
    }
  };

  const onChangeAttribute = async (e: any, field: any, index: number) => {
    const selectedItem =
      attributeRef.current[index]?.instance.option('selectedItem');
    valueChanged(e, field);
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].attributeType`,
      selectedItem ? selectedItem.attributeType : ''
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].attributeName`,
      selectedItem ? selectedItem.attributeName : ''
    );
    // setValue(
    //   `groups[${nestedIndex}].ruleConditions[${index}].isEditable`,
    //   selectedItem ? selectedItem.isEditable : false
    // );
    // setValue(
    //   `groups[${nestedIndex}].ruleConditions[${index}].canUserEnter`,
    //   false
    // );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].isSelectable`,
      selectedItem ? selectedItem.isSelectable : false
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].selectList`,
      selectedItem ? selectedItem.selectList : ''
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].selectListValue`,
      selectedItem ? selectedItem.selectListValue : ''
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].selectListDisplay`,
      selectedItem ? selectedItem.selectListDisplay : ''
    );
    setValue(`groups[${nestedIndex}].ruleConditions[${index}].condition`, null);
    if (
      getValues().groups[nestedIndex].ruleConditions[index].entityName ===
      Entity.Port
    ) {
      conditionRef.current[index]?.instance
        .getDataSource()
        .filter([
          [
            'optionTypeCode',
            '=',
            selectedItem ? selectedItem.attributeType : '',
          ],
          'and',
          [
            ['optionValueCode', '=', 'IN'],
            'or',
            ['optionValueCode', '=', 'NOTIN'],
            'or',
            ['optionValueCode', '=', '!='],
          ],
        ]);
    } else {
      conditionRef.current[index]?.instance
        .getDataSource()
        .filter([
          'optionTypeCode',
          '=',
          selectedItem ? selectedItem.attributeType : '',
        ]);
    }

    conditionRef.current[index]?.instance.getDataSource()?.load();
    setForceRender(!forceRender);
  };

  const onChangeCondition = (e: any, field: any, index: number) => {
    valueChanged(e, field);
    if ([Condition.In, Condition.NotIn].includes(e.value)) {
      setValue(
        `groups[${nestedIndex}].ruleConditions[${index}].isEntityValue`,
        false
      );
    }
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].isListValue`,
      false
    );
    setValue(`groups[${nestedIndex}].ruleConditions[${index}].value1`, '');
    setValue(`groups[${nestedIndex}].ruleConditions[${index}].value2`, '');
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].valueEntityId`,
      null
    );
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].valueAttributeId`,
      null
    );
    setForceRender(!forceRender);
  };

  const selectRelation = (relation: string | undefined, index: number) => {
    if (relation === Relation.Group) {
      setGroupValue(nestedIndex);
      setShowRelationPopover(-1);
      return;
    }
    setValue(
      `groups[${nestedIndex}].ruleConditions[${index}].conditionRelation`,
      relation
    );
    setShowRelationPopover(-1);
    ruleConditionFormArray.append(appendValues);
    setForceRender(!forceRender);
  };

  const removeRelation = (index: number) => {
    if (
      getValues().groups.length === 1 &&
      getValues().groups[0].ruleConditions.length === 1
    ) {
      return;
    }
    if (
      index - 1 >= 0 &&
      !getValues().groups[nestedIndex].ruleConditions[index + 1]
    ) {
      setValue(
        `groups[${nestedIndex}].ruleConditions[${index - 1}].conditionRelation`,
        null
      );
    }
    ruleConditionFormArray.remove(index);
    setForceRender(!forceRender);
    if (getValues().groups[nestedIndex].ruleConditions.length === 0) {
      removeGroup(nestedIndex);
    }
  };

  const customRequiredValidation = (params: any, index: any) => {
    const rowData = getValues().groups[nestedIndex].ruleConditions[index];
    if (
      !params.value &&
      (rowData.value1 ||
        rowData.description ||
        rowData.canUserEnter ||
        rowData.attributeId ||
        rowData.entityId ||
        rowData.condition ||
        rowData.isEntityValue)
    ) {
      return false;
    }
    return true;
  };

  const tooltipContentRender = (item: IOptionvalue) => {
    return (
      <>
        {item.optionValueCode === Relation.Or && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.orConditionTooltip')}
          </div>
        )}
        {item.optionValueCode === Relation.And && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.andConditionTooltip')}
          </div>
        )}
        {item.optionValueCode === Relation.Group && (
          <div className="m-l-tooltip-wrapper">
            {t('tooltips.groupConditionTooltip')}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {ruleConditionFormArray.fields.map((item: any, index: number) => {
        return (
          <div key={item.id}>
            <div className="row m-l-rule-details-row u-mb0 m-l-custom-sixteen-space-row">
              <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col">
                <div className="row m-l-custom-sixteen-space-row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                    <div className="mb-btm-space m-c-form-group">
                      <label className="m-l-input-label">
                        {t('labels.entity')}
                      </label>
                      <Controller
                        name={`groups[${nestedIndex}].ruleConditions[${index}].entityId`}
                        control={control}
                        render={({ field }) => (
                          <SelectBox
                            ref={(element: any) =>
                              (entityRef.current[index] = element)
                            }
                            name="entityId"
                            searchEnabled={true}
                            valueExpr="masterEntityId"
                            displayExpr="displayName"
                            value={field.value}
                            elementAttr={{ 'data-testid': 'entity' }}
                            dataSource={entities}
                            className="m-c-input-control m-c-select-box"
                            onValueChanged={(e) =>
                              onChangeEntity(e, field, index)
                            }
                            validationMessageMode="always"
                          >
                            <Validator elementAttr={{ id: 'entity' }}>
                              <CustomRule
                                reevaluate={true}
                                message={t('errors.required')}
                                validationCallback={(params: any) =>
                                  customRequiredValidation(params, index)
                                }
                              />
                            </Validator>
                          </SelectBox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                    <div className="mb-btm-space m-c-form-group">
                      <div className="m-l-default-checkbox-unit">
                        <label className="m-l-input-label">
                          {t('labels.attribute')}
                        </label>
                        <div className="m-c-checkbox">
                          {' '}
                          <Controller
                            name={`groups[${nestedIndex}].ruleConditions[${index}].canUserEnter`}
                            control={control}
                            render={({ field }) => (
                              <>
                                <CheckBox
                                  elementAttr={{
                                    'data-testid': 'canUserEnter',
                                  }}
                                  name="canUserEnter"
                                  value={field.value}
                                  // disabled={
                                  //   !getValues().groups[nestedIndex]
                                  //     .ruleConditions[index].isEditable
                                  // }
                                  onValueChanged={(e) => valueChanged(e, field)}
                                />
                                <span className="m-l-checkbox--small-label">
                                  {t('labels.canUserEnter')}
                                </span>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {/* <label className="m-l-input-label">
                        {t('labels.attribute')}
                      </label> */}
                      <Controller
                        name={`groups[${nestedIndex}].ruleConditions[${index}].attributeId`}
                        control={control}
                        render={({ field }) => (
                          <SelectBox
                            ref={(element: any) =>
                              (attributeRef.current[index] = element)
                            }
                            name="attributeId"
                            searchEnabled={true}
                            dropDownOptions={{
                              resizeEnabled: true,
                              width: '500px',
                            }}
                            displayExpr="displayName"
                            valueExpr="masterAttributeId"
                            value={field.value}
                            elementAttr={{ 'data-testid': 'attribute' }}
                            dataSource={attributes}
                            className="m-c-input-control m-c-select-box"
                            onValueChanged={(e) =>
                              onChangeAttribute(e, field, index)
                            }
                            validationMessageMode="always"
                          >
                            <Validator elementAttr={{ id: 'entity' }}>
                              <CustomRule
                                reevaluate={true}
                                message={t('errors.required')}
                                validationCallback={(params: any) =>
                                  customRequiredValidation(params, index)
                                }
                              />
                            </Validator>
                          </SelectBox>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col">
                <div className="row m-l-custom-sixteen-space-row">
                  <div className="col-md-6 col-sm-6 col-lg-2 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
                    <div className="mb-btm-space m-c-form-group">
                      <label className="m-l-input-label">
                        {t('labels.condition')}
                      </label>
                      <Controller
                        name={`groups[${nestedIndex}].ruleConditions[${index}].condition`}
                        control={control}
                        render={({ field }) => (
                          <SelectBox
                            ref={(element: any) =>
                              (conditionRef.current[index] = element)
                            }
                            name="condition"
                            searchEnabled={true}
                            displayExpr="optionValueText"
                            valueExpr="optionValueCode"
                            value={field.value}
                            elementAttr={{ 'data-testid': 'condition' }}
                            dataSource={conditions}
                            className="m-c-input-control m-c-select-box"
                            onValueChanged={(e) =>
                              onChangeCondition(e, field, index)
                            }
                            validationMessageMode="always"
                          >
                            <Validator elementAttr={{ id: 'entity' }}>
                              <CustomRule
                                reevaluate={true}
                                message={t('errors.required')}
                                validationCallback={(params: any) =>
                                  customRequiredValidation(params, index)
                                }
                              />
                            </Validator>
                          </SelectBox>
                        )}
                      />
                    </div>
                  </div>
                  <RuleConditionValueField
                    buId={buId}
                    index={index}
                    nestedIndex={nestedIndex}
                    entities={entities}
                    attributes={attributes}
                  />
                  <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12 m-l-row-custom-column m-l-custom-sixteen-space-col">
                    <div className="mb-btm-space m-c-form-group">
                      <label className="m-l-input-label">
                        {t('labels.description')}
                      </label>
                      <Controller
                        name={`groups[${nestedIndex}].ruleConditions[${index}].description`}
                        control={control}
                        render={({ field }) => (
                          <TextBox
                            name="description"
                            inputAttr={{ 'data-testid': 'description' }}
                            className="m-c-input-control"
                            value={field.value}
                            onValueChanged={(e) => valueChanged(e, field)}
                            validationMessageMode="always"
                          ></TextBox>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
                    <div className="mb-btm-space m-c-form-group m-l-button-vertical-center-align m-l-fm-rmcl-btn">
                      <Button
                        className="app-c-btn app-c-btn--remove"
                        elementAttr={{ 'data-testid': 'conditionRemoveButton' }}
                        onClick={() => {
                          removeRelation(index);
                        }}
                        disabled={
                          getValues().groups.length === 1 &&
                          getValues().groups[0].ruleConditions.length === 1
                        }
                      >
                        <div className="m-c-icon-inside-btn">
                          <icons.RemoveIcon />
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-l-category-dropdown-wrap u-m0">
              <div className="app-c-add-btn-wrap">
                {!getValues().groups[nestedIndex].ruleConditions[index]
                  .conditionRelation && (
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                    onClick={() => setShowRelationPopover(index)}
                    elementAttr={{ 'data-testid': 'conditionAddButton' }}
                  >
                    <div className="m-c-icon-inside-btn">
                      <icons.AddIcon />
                    </div>
                  </Button>
                )}
                {showRelationPopover === index && (
                  <ul
                    className="m-l-category-list-block"
                    ref={relationRef}
                    data-testid="relationList"
                  >
                    {relations.map((item) => {
                      if (
                        item.optionValueCode !== Relation.Group ||
                        !getValues().groups[nestedIndex].relation
                      )
                        return (
                          <li
                            id={`${item.optionValueCode}${index}${nestedIndex}`}
                            key={item.optionValueCode}
                            onClick={() =>
                              selectRelation(item.optionValueCode, index)
                            }
                          >
                            <span>{item.optionValueText}</span>
                            <Tooltip
                              target={`#${item.optionValueCode}${index}${nestedIndex}`}
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              position="right"
                              contentRender={() => tooltipContentRender(item)}
                            />
                          </li>
                        );
                    })}
                  </ul>
                )}
              </div>
              {getValues().groups[nestedIndex].ruleConditions[index]
                .conditionRelation && (
                <div className="row m-l-category-dropdown-wrap u-mt0 u-ml0 u-mr0">
                  <div className="m-l-category__dropdown-block">
                    <Controller
                      name={`groups[${nestedIndex}].ruleConditions[${index}].conditionRelation`}
                      control={control}
                      render={({ field }) => (
                        <SelectBox
                          name="conditionRelation"
                          value={field.value}
                          elementAttr={{ 'data-testid': 'relation' }}
                          displayExpr="optionValueText"
                          valueExpr="optionValueCode"
                          dataSource={relations}
                          className="m-c-input-control m-c-select-box"
                          onValueChanged={(e) => valueChanged(e, field)}
                          itemRender={(data) => {
                            return (
                              <div
                                style={{ width: '100%' }}
                                id={`${data.optionValueCode}${index}${nestedIndex}`}
                              >
                                {data.optionValueText}
                                <Tooltip
                                  target={`#${data.optionValueCode}${index}${nestedIndex}`}
                                  showEvent="dxhoverstart"
                                  hideEvent="dxhoverend"
                                  position="right"
                                  contentRender={() =>
                                    tooltipContentRender(data)
                                  }
                                />
                              </div>
                            );
                          }}
                        ></SelectBox>
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}
