import Switch from 'devextreme-react/switch';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import icons from '../../common/icons/icons';
import RfiTemplate from '../../common/rfi-template/RfiTemplate';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import toast from '../../../utils/toast';

export default function RFIDetails(props: {
  isRFIToClientTemplateDefined?: boolean;
  isRFIToVesselOwnerTemplateDefined?: boolean;
  getRuleById: any;
  isRFIEditable: boolean;
  ruleId?: string;
  enableRfi?: any;
  buId?: any;
}) {
  const {
    isRFIToClientTemplateDefined,
    isRFIToVesselOwnerTemplateDefined,
    getRuleById,
    isRFIEditable,
    ruleId,
    enableRfi,
    buId,
  } = props;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [showRFIClientTemplate, setShowRFIClientTemplate] = useState(false);
  const [showRFIVesselOwnerTemplate, setShowRFIVesselOwnerTemplate] =
    useState(false);
  const [showRFITemplate, setShowRFITemplate] = useState(false);
  const [isRFIClient, setIsRFIClient] = useState(false);
  const [ruleRFITemplateTypes, setRuleRFITemplateTypes] = useState();

  const valueChanged = (e: any, field: any) => {
    if (e.event) {
      field.onChange(e.value);
    }
  };

  const onChangeRFIClient = (e: any, field: any) => {
    valueChanged(e, field);
    setShowRFIClientTemplate(e.value);
  };

  const onChangeRFIVesselOwner = (e: any, field: any) => {
    valueChanged(e, field);
    setShowRFIVesselOwnerTemplate(e.value);
  };

  const getRuleRFITemplateTypes = async () => {
    await api
      .get({
        url: apiConfig.ruleRFITemplateTypes,
      })
      .then((res) => {
        if (isRFIClient) {
          setRuleRFITemplateTypes(res[0]);
        } else {
          setRuleRFITemplateTypes(res[1]);
        }
      });
  };

  useEffect(() => {
    getRuleRFITemplateTypes();
  }, [isRFIClient]);

  return (
    <div className="m-l-rl-flx-rw-right">
      <ul className="m-l-rule-details-wrap">
        <li>
          <div className="m-l-rule-list-column1">
            <h4 className="m-c-message-text">{t('labels.rfiToRequestor')}</h4>
          </div>
          <div className="m-l-toggle-switch">
            <Controller
              name="rfiToClient"
              control={control}
              render={({ field }) => (
                <Switch
                  elementAttr={{ 'data-testid': 'rfiToClient' }}
                  name="rfiToClient"
                  value={field.value}
                  onValueChanged={(e) => onChangeRFIClient(e, field)}
                />
              )}
            />
          </div>
          <div className="m-l-rule-list-column2 u-text-right">
            <h4 className="m-c-message-text">{t('labels.rfiTemplate')}</h4>
          </div>
          <div
            className="m-l-message-icon-wrap"
            onClick={() => {
              if (showRFIClientTemplate) {
                if (enableRfi?.rfiToClient) {
                  setShowRFITemplate(true);
                  setIsRFIClient(true);
                } else {
                  toast.warning({
                    title: 'Warning',
                    message: t('toast.saveRuleBeForeRfi'),
                  });
                }
              }
            }}
          >
            {showRFIClientTemplate ? (
              <icons.mailIconSuccess />
            ) : (
              <icons.mailIcon />
            )}
          </div>
        </li>
        <li>
          <div className="m-l-rule-list-column1">
            <h4 className="m-c-message-text">{t('labels.rfiToVesselOwner')}</h4>
          </div>
          <div className="m-l-toggle-switch">
            <Controller
              name="rfiToVesselOwner"
              control={control}
              render={({ field }) => (
                <Switch
                  elementAttr={{ 'data-testid': 'rfiToVesselOwner' }}
                  name="rfiToVesselOwner"
                  value={field.value}
                  onValueChanged={(e) => onChangeRFIVesselOwner(e, field)}
                />
              )}
            />
          </div>
          <div className="m-l-rule-list-column2 u-text-right">
            <h4 className="m-c-message-text">{t('labels.rfiTemplate')}</h4>
          </div>
          <div
            className="m-l-message-icon-wrap icon-selected"
            onClick={() => {
              if (showRFIVesselOwnerTemplate) {
                if (enableRfi?.rfiToVesselOwner) {
                  setShowRFITemplate(true);
                  setIsRFIClient(false);
                } else {
                  toast.warning({
                    title: 'Warning',
                    message: t('toast.saveRuleBeForeRfi'),
                  });
                }
              }
            }}
          >
            {showRFIVesselOwnerTemplate ? (
              <icons.mailIconSuccess />
            ) : (
              <icons.mailIcon />
            )}
          </div>
        </li>
        <li>
          <div className="m-l-rule-list-column1">
            <h4 className="m-c-message-text">{t('labels.canOverride')}</h4>
          </div>
          <div className="m-l-toggle-switch">
            <Controller
              name="canOverride"
              control={control}
              render={({ field }) => (
                <Switch
                  elementAttr={{ 'data-testid': 'canOverride' }}
                  name="canOverride"
                  value={field.value}
                  onValueChanged={(e) => valueChanged(e, field)}
                />
              )}
            />
          </div>
        </li>
      </ul>
      {showRFITemplate && (
        <RfiTemplate
          isVisible={showRFITemplate}
          setIsVisible={setShowRFITemplate}
          ruleRFITemplateTypes={ruleRFITemplateTypes}
          isRFIClient={isRFIClient}
          isRFIToClientTemplateDefined={isRFIToClientTemplateDefined}
          isRFIToVesselOwnerTemplateDefined={isRFIToVesselOwnerTemplateDefined}
          ruleId={ruleId}
          getRuleById={getRuleById}
          isRFIEditable={isRFIEditable}
          buId={buId}
        />
      )}
    </div>
  );
}
