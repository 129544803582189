import React, { useEffect, useMemo, useState } from 'react';
import VetOutcomeReport from '../report/vetOutcome/VetOutcomeReport';
import BerthfitReport from '../report/berthfit/BerthfitReport';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { MarsApiService as api } from '../../api/mars-api-service';
import * as html2pdf from 'html2pdf.js';
import { useDateFormat } from '../../hooks/useDateFormat';
import { ReportType } from '../../enums/report-type-enum';
import ComplianceReport from '../../components/compliance-check/compliance-report/ComplianceReport';
import { IComplianceReport } from '../../types/compliance-report';
import SanctionCheckReport from '../../components/sanctions-check/sanction-check-report/SanctionCheckReport';
import { useTranslation } from 'react-i18next';

export default function ReportToPdf(
  props: Readonly<{
    report?: any;
    setReport?: any;
    reportDetails?: any;
    generateReport?: any;
    setGenerateReport?: any;
    // isBerthFitReport?: any;
    berthReportDetails?: any;
    complianceReportDetails?: IComplianceReport;
    sanctionReportDetails?: any;
    pdfFile?: any[];
    setPdfFile?: any;
    isAttachPdf?: boolean;
    setIsAttachPdf?: any;
    attachCallBack?: any;
    isBerthFitReportVessel?: any;
    isNotRecommended?: any;
    reviewComment?: any;
    berthFitComment?: any;
    isRecommend?: any;
    setIsRecommend?: any;
    reportGeneratedDate?: any;
    reportType: string;
  }>
) {
  const {
    report,
    setReport,
    reportDetails,
    // isBerthFitReport,
    berthReportDetails,
    pdfFile,
    setPdfFile,
    isAttachPdf,
    setIsAttachPdf,
    attachCallBack,
    isBerthFitReportVessel,
    isNotRecommended,
    reviewComment,
    berthFitComment,
    isRecommend,
    setIsRecommend,
    reportGeneratedDate,
    reportType,
    complianceReportDetails,
    sanctionReportDetails,
  } = props;
  const [showPdf, setShowPdf] = useState(false);
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const [logo, setLogo] = useState<any>();
  const { formatDate } = useDateFormat();
  const { t } = useTranslation();

  useEffect(() => {
    if (report) {
      initialLoad();
    }
  }, [report]);

  const initialLoad = async () => {
    if (report) {
      if (reportType === ReportType.BerthFit) {
        await getLogoImageData(berthReportDetails?.attachmentId);
        setShowPdf(true);
      } else if (reportType === ReportType.VetReport) {
        await getLogoImageData(reportDetails?.attachmentId);
        setShowPdf(true);
      } else if (reportType === ReportType.ComplianceReport) {
        getLogoImageData(complianceReportDetails?.attachmentId);
        setShowPdf(true);
      } else if (reportType === ReportType.SanctionCheckReport) {
        getLogoImageData(sanctionReportDetails?.attachmentId);
        setShowPdf(true);
      } else {
        setShowPdf(true);
      }
    }
  };

  const loaderMessage = useMemo(() => {
    if (isAttachPdf) {
      switch (reportType) {
        case ReportType.VetReport:
          return t('toast.generatingVetReport');
        case ReportType.BerthFit:
          return t('toast.generatingBerthFitReport');
        case ReportType.ComplianceReport:
          return t('toast.generatingComplianceReport');
        case ReportType.SanctionCheckReport:
          return t('toast.generatingSanctionReport');
        default:
          return t('toast.loading');
      }
    } else {
      return t('toast.loading');
    }
  }, [reportType]);

  useEffect(() => {
    if (showPdf) {
      generatePdf();
    }
  }, [showPdf]);

  const getLogoImageData = async (attachmentId: any) => {
    if (attachmentId) {
      await api
        .get({
          url: apiConfig.deleteEditAttachment + attachmentId,
          responseType: 'blob',
        })
        .then((res) => {
          const urlCreator = window.URL || window.webkitURL;
          const imageUrl = urlCreator.createObjectURL(new Blob([res]));
          setLogo(imageUrl);
        });
    }
  };

  const generatePdf = () => {
    setLoaderMessage?.(loaderMessage);
    setIsLoading(true);
    setTimeout(async () => {
      // setShowPdf(true);
      const divToPrint = document.querySelectorAll('#element-to-print') as any;

      const options = {
        jsPDF: {
          format: 'a4',
          compressPdf: true,
        },
        // html2canvas:  { letterRendering: true, useCORS: true,     logging: true},
        margin: [5, 5, 5, 5],
        html2canvas: { scale: 2.5 },
        image: { type: 'jpeg', quality: 0.5 },
        pagebreak: { mode: 'avoid-all' },
      };
      let worker = html2pdf().set(options).from(divToPrint[0]).toPdf();
      divToPrint.forEach((page: any, index: number) => {
        if (index !== 0) {
          worker = worker
            .get('pdf')
            .then((pdf: any) => {
              pdf.addPage();
            })
            .from(page)
            .toContainer()
            .toCanvas()
            .toPdf();
        }
      });
      if (isAttachPdf) {
        worker = worker.outputPdf('blob').then((res: any) => {
          const pdfs = new File(
            [res],
            reportType === ReportType.BerthFit
              ? berthReportDetails?.vetId + '_BerthFitReport.pdf'
              : reportType === ReportType.VetReport
              ? reportDetails?.vetId + '_VetReport.pdf'
              : reportType === ReportType.ComplianceReport
              ? complianceReportDetails?.complianceCheckId +
                '_ComplianceReport.pdf'
              : reportType === ReportType.SanctionCheckReport
              ? sanctionReportDetails?.sanctionCheckId +
                '_SanctionCheckReport.pdf'
              : 'report.pdf'
          );
          setPdfFile([...(pdfFile as any[]), pdfs]);
          setIsAttachPdf(false);
          if (setIsRecommend) {
            setIsRecommend(false);
          }
          setShowPdf(false);
          setLoaderMessage?.();
          setIsLoading(false);
          setReport(false);
          if (isAttachPdf && attachCallBack) {
            attachCallBack(reportType);
          }
        });
      } else {
        worker = worker
          .save(
            reportType === ReportType.BerthFit
              ? isBerthFitReportVessel
                ? berthReportDetails?.berthfitId + 'BerthFitReport.pdf'
                : berthReportDetails?.vetId + '_BerthFitReport.pdf'
              : reportType === ReportType.VetReport
              ? reportDetails?.vetId + '_VetReport.pdf'
              : reportType === ReportType.ComplianceReport
              ? complianceReportDetails?.complianceCheckId +
                '_ComplianceReport.pdf'
              : reportType === ReportType.SanctionCheckReport
              ? sanctionReportDetails?.sanctionCheckId +
                '_SanctionCheckReport.pdf'
              : 'report.pdf'
          )
          .then(() => {
            setShowPdf(false);
            setLoaderMessage?.();
            setIsLoading(false);
            setReport(false);
          });
      }
    }, 1000);
  };
  const currentDate = new Date().toISOString();
  const reportDate =
    reportType !== ReportType.BerthFit && reportGeneratedDate
      ? formatDate(reportGeneratedDate)
      : formatDate(currentDate);

  const HtmlContent = () => {
    switch (reportType) {
      case ReportType.BerthFit:
        return (
          <BerthfitReport
            reportDetails={berthReportDetails}
            isAttachPdf={isAttachPdf}
            reviewComment={berthFitComment}
            logo={logo}
          />
        );
      case ReportType.VetReport:
        return (
          <VetOutcomeReport
            reportDetails={reportDetails}
            isNotRecommended={isNotRecommended}
            isAttachPdf={isAttachPdf}
            reviewComment={reviewComment}
            isRecommend={isRecommend}
            logo={logo}
            reportDate={reportDate}
          />
        );
      case ReportType.ComplianceReport:
        return (
          <div style={{ display: 'none' }}>
            <ComplianceReport
              reportDetails={complianceReportDetails}
              logo={logo}
            />
          </div>
        );
      case ReportType.SanctionCheckReport:
        return (
          <div style={{ display: 'none' }}>
            <SanctionCheckReport
              sanctionReportDetails={sanctionReportDetails}
              logo={logo}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return <>{showPdf && <HtmlContent />}</>;
}
