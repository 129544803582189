import React, { useMemo, useRef } from 'react';
import { useMap } from 'react-leaflet';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import { IModalConfig } from '../../../../../types/modal';
import Modal from '../../../modal/Modal';
import icons from '../../../icons/icons';
import { FormProvider, useForm } from 'react-hook-form';
import DrawForm from '../draw-form/DrawForm';
import ValidationGroup from 'devextreme-react/validation-group';

export default function CustomCircleControl({
  onCircleDraw,
  onClose,
}: Readonly<{
  onCircleDraw?: (
    lat: number,
    lng: number,
    radius: number,
    areaName: string
  ) => void;
  onClose?: (e: boolean) => void;
}>) {
  const map = useMap();
  const validationGroup: any = useRef();
  const { t } = useTranslation();

  const modalConfig: IModalConfig = {
    width: 100,
    height: 430,
    visible: true,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal__fileupload',
    };
  }, []);

  const customDrawForm = useForm();

  const handleDrawCircle = () => {
    if (validationGroup.current.instance.validate().isValid) {
      const formValues = customDrawForm.getValues();
      if (onClose) onClose(false);
      if (formValues) {
        const areaName = formValues.areaName;
        const latitude = formValues.latitude;
        const longitude = formValues.longitude;
        let radius = formValues.circleRadius;
        radius = radius * 1852;
        if (onCircleDraw) onCircleDraw(latitude, longitude, radius, areaName);
        map.setView([latitude, longitude], map.getZoom());
      }
    }
  };

  return (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={() => {
              onClose && onClose(false);
            }}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <ValidationGroup ref={validationGroup}>
          <FormProvider {...customDrawForm}>
            <DrawForm />
          </FormProvider>
        </ValidationGroup>
      </div>
      <div className="m-l-modal__footer">
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width"
          onClick={handleDrawCircle}
        >
          {t('buttons.draw')}
        </Button>
      </div>
    </Modal>
  );
}
