import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import icons from '../../common/icons/icons';
import VetByStatusGadget from '../vets-by-status-gadget/VetByStatusGadget';
import VetsByCompanyGadget from '../vets-by-company-gadget/VetsByCompanyGadget';
import TurnaroundByCompanyGadget from '../turnaround-company-gadget/TurnaroundByCompanyGadget';
import RfiMailGadget from '../rfi-mail-gadget/RfiMailGadget';
import VetsByRequestorGadget from '../vets-by-requestor-gadget/VetsByRequestorGadget';
import VetStatusAvgTimeGadget from '../vet-status-avg-time-gadget/VetStatusAvgTimeGadget';
import TopReviewersGadget from '../top-reviewers-gadget/TopReviewersGadget';
import VetTurnAroundTimeTrendGadget from '../vet-turnaround-time-trend/VetTurnAroundTimeTrendGadget';
import BerthFitUtilityGadget from '../berth-fit-tool-gadget/BerthFitUtilityGadget';
import MyRecentVetRequestGadget from '../recent-vet-requests-gadget/MyRecentVetRequestGadget';
import RecentVesselsGadget from '../recent-vessels-gadget/RecentVesselsGadget';
import { DashbaordFeature } from '../../../types/dashboard';
import RecentComplianceCheck from '../recent-compliance-chek/RecentComplianceCheck';
import RecentSanctionsCheck from '../recent-sanctions-check/RecentSanctionsCheck';

export default React.memo(function SortableItem(props: {
  value: DashbaordFeature;
  id: string;
  onClickCloseIcon: (e: any, id: any) => Promise<void>;
  overlayWidth?: { width: number | undefined };
}) {
  const { t } = useTranslation();
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  return (
    <div
      className="grabbable"
      ref={setNodeRef}
      style={{
        opacity: isDragging ? '0.5' : '1',
        transformOrigin: '50% 50%',
        cursor: isDragging ? 'grabbing' : 'grab',
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
        ...props.overlayWidth,
      }}
      {...props}
      {...attributes}
      {...listeners}
    >
      {props?.value?.featureName === 'Dashboard.VetsByStatus' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.VetsbyStatus')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetByStatusGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetsByCompany' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetsbyCompany')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetsByCompanyGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.TurnaroundByCompany' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.TurnaroundbyCompany')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <TurnaroundByCompanyGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.RFI' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.RFI')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            {/* <div className="m-l-us-databox-body">
          <div className="m-l-das-graph-block"> */}
            <RfiMailGadget />
            {/* </div>
        </div> */}
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetsByRequestor' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetsbyRequestor')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetsByRequestorGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetStatusAvgTime' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.vetStatusAvgTime')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <VetStatusAvgTimeGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.TopReviewers' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body m-l-fxdhd-box">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">{t('labels.TopReviewers')}</div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <div className="m-l-us-databox-body">
              <div className="m-l-das-graph-block">
                <TopReviewersGadget />
              </div>
            </div>
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.VetTurnaroundTimeTrend' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.VetTurnaroundTimeTrend')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <VetTurnAroundTimeTrendGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.BerthFitToolUtilization' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.BerthFitToolUtilization')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <BerthFitUtilityGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentVetRequests' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.MyRecentVetRequests')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <MyRecentVetRequestGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentVessels' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.MyRecentVessels')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <RecentVesselsGadget />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentComplianceChecks' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.myRecentComplianceChecks')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <RecentComplianceCheck />
          </div>
        </div>
      )}
      {props?.value.featureName === 'Dashboard.MyRecentSanctionChecks' && (
        <div className="m-l-usdata-box">
          <div className="m-l-inner-page-body">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.myRecentSanctionsChecks')}
              </div>
              <Button
                className="m-l-us-close-btn"
                onClick={(e) =>
                  props.onClickCloseIcon(e, props?.value.featureId)
                }
              >
                <icons.MinusIcon />
              </Button>
            </div>
            <RecentSanctionsCheck />
          </div>
        </div>
      )}
    </div>
  );
});
