import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { usePermission } from '../../../../../contexts/PermissionContext';

export default function STSConfigForm(
  props: Readonly<{
    buId: string;
  }>
) {
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { control } = useFormContext();
  const { buId } = props;
  const stsFormConfig = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'proximityPeriod',
      label: t('labels.proximityPeriod'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      id: 'proximityPeriod',
      showHelpIcon: true,
      allowDecimal: true,
      helpText: t('toast.proximityPeriodDesc'),
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditSTSConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditSTSConfigurationSettings'),
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'thresholdPeriod',
      label: t('labels.thresholdPeriod'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      id: 'thresholdPeriod',
      showHelpIcon: true,
      helpText: t('toast.thresholdPeriodDesc'),
      maxLength: 100,
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditSTSConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditSTSConfigurationSettings'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'maximumSpeedDuringSTS',
      label: t('labels.maximumSpeedDuringSTS'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      showHelpIcon: true,
      allowDecimal: true,
      id: 'maximumSpeedDuringSTS',
      helpText: t('toast.maximumSpeedDuringSTSDesc'),
      maxLength: 100,
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditSTSConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditSTSConfigurationSettings'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'vesselContactDistanceThreshold',
      label: t('labels.vesselContactDistanceThreshold'),
      control: control,
      fieldType: 'number',
      id: 'vesselContactDistanceThreshold',
      isRequired: true,
      showHelpIcon: true,
      helpText: t('toast.vesselContactDistanceThresholdDesc'),
      maxLength: 100,
      disabled: buId
        ? !findFeature('BUCompanyConfiguration.EditSTSConfigurationSettings')
        : !findFeature('CompanyConfiguration.EditSTSConfigurationSettings'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];
  return (
    <div className="row">
      <FormBuilder formConfig={stsFormConfig} />
    </div>
  );
}
