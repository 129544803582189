import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { FormProvider, useForm } from 'react-hook-form';
import CompanyInfoForm from '../../../components/requestor-company/company-info-form/CompanyInfoForm';
import { Button } from 'devextreme-react/button';
import ContractInformation from '../../../components/requestor-company/contract-information/ContractInformation';
import icons from '../../../components/common/icons/icons';
import {
  IAddress,
  ICompany,
  ICompanyInfoForm,
  IContact,
} from '../../../types/requestor-company';
import BusinessUnitGrid from '../../../components/requestor-company/business-unit/business-unit-grid/BusinessUnitGrid';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import { useNavigate } from 'react-router-dom';
import toast from '../../../utils/toast';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { ICountry, IOptionvalue } from '../../../types/master-data';
import { useTranslation } from 'react-i18next';
import RoleGrid from '../../../components/requestor-company/role-grid/RoleGrid';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { ICompanyRolesList, IRole } from '../../../types/company-roles';
import UsersGrid from '../../../components/requestor-company/users/users-grid/UsersGrid';
import DataSource from 'devextreme/data/data_source';
import { useLocation } from 'react-router-dom';
import { IUserGridData, IUserList } from '../../../types/user';
import UserApprove from '../../../components/requestor-company/users/user-approve/UserApprove';
import { CompanyStatus } from '../../../enums/status-enum';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import AddContract from '../../../components/requestor-company/contract-information/add-contract-information/AddContract';
import { getAPIRoute } from '../../../utils/api-route';
import {
  getCompanyId,
  getCompanyType,
  getRememberMe,
} from '../../../utils/jwt-decode';
import CompanyConfigurations from '../../../components/requestor-company/company-configurations/Company-Configurations';

export default function EditRequestorCompany() {
  const defaultData: ICompanyRolesList[] = [];
  const index = Number(new URLSearchParams(useLocation().search).get('index'));
  const { t } = useTranslation();
  const [buIncoTerms, setBuIncoTerms] = useState<IOptionvalue[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [sendEmailConfirmDialog, setSendEmailConfirmDialog] = useState(false);
  const [companyStatusList, setCompanyStatusList] = useState<IOptionvalue[]>(
    []
  );
  const [personNameTitles, setPersonNameTitles] = useState<IOptionvalue[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [marsAccountManagers, setMarsAccountManagers] = useState<IUserList[]>(
    []
  );
  const [activeRoles, setActiveRoles] = useState<IRole[]>([]);
  const [companyRoles, setCompanyRoles] = useState<ICompanyRolesList[]>([]);
  const [companyDetails, setCompanyDetails] = useState<ICompany>();
  const { setIsLoading } = useLoaderContext();
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [roleDataSource, setRoleDataSource] = useState<any>(
    new DataSource(defaultData)
  );
  const [isRolesEdited, setIsRolesEdited] = useState(false);
  const [deletedRoleIds, setDeletedRoleIds] = useState<string[]>([]);
  const roleGridRef = useRef<any>();
  const [usersDataSource, setUsersDataSource] = useState<any>([]);
  const [userApproveList, setUserApproveList] = useState<any>([]);
  const [userSendForApprovalList, setUserSendForApprovalList] = useState<any>(
    []
  );
  const [showTabChangeConfirm, setShowTabChangeConfirm] = useState(false);
  const [isFromMenu, setIsFromMenu] = useState(false);
  const userGridRef = useRef<any>();
  const tabRef = useRef<TabPanel>(null);
  const [companyId, setCompanyId] = useState<number>();
  const [fromPage, setFromPage] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const [addContract, setAddContract] = useState(false);
  const [confirmAddContract, setConfirmAddContract] = useState(false);
  const [cancelAddContract, setCancelAddContract] = useState(false);
  const [contractId, setContractId] = useState<number>();
  const [contractInformation, setContractInformation] = useState<any>();
  const [contractIsEdit, setContractIsEdit] = useState(true);
  const [isMarsUser, setIsMarsUser] = useState<boolean>();
  const companyType = getCompanyType();
  const loginCompanyId = getCompanyId();
  const [saveConfig, setSaveConfig] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<number>();

  const IsCompanyTypeMars = () => {
    if (companyType === 'M') {
      setIsMarsUser(true);
    } else {
      setIsMarsUser(false);
    }
  };

  const editConfig = useMemo(() => {
    if (selectedConfig === 1) {
      return 'CompanyConfiguration.EditGeneralSettings';
    } else if (selectedConfig === 2) {
      return 'CompanyConfiguration.EditPasswordSettings';
    } else if (selectedConfig === 3) {
      return 'CompanyConfiguration.EditRestrictionsApprovals';
    } else if (selectedConfig === 4) {
      return 'CompanyConfiguration.EditEmailSettings';
    } else if (selectedConfig === 5) {
      return 'CompanyConfiguration.EditVetReportSettings';
    } else if (selectedConfig === 6) {
      return 'CompanyConfiguration.EditBerthFitReportSettings';
    } else if (selectedConfig === 9) {
      return 'CompanyConfiguration.EditSanctionCheckReportSettings';
    } else if (selectedConfig === 8) {
      return 'CompanyConfiguration.EditComplianceReportSettings';
    } else if (selectedConfig === 7) {
      return 'CompanyConfiguration.EditComplianceCheckPeriodSettings';
    }
  }, [selectedConfig]);

  const getBuIncoTerms = async () => {
    await api
      .get({ url: apiConfig.buIncoTerms })
      .then((data: IOptionvalue[]) => {
        setBuIncoTerms(data);
      });
  };
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveCompanyInfo();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setConfirmBackDialog(false);
      navigate('/company');
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };
  const sendEmailDialogConfig: IDialogConfig = {
    id: 'sentEmailConfirm',
    content: t('toast.sentEmailConfirm', {
      companyEmail: companyDetails?.companyEmail,
    }),
    handleSubmit: () => {
      sendEmail();
    },
    handleClose: () => {
      setSendEmailConfirmDialog(false);
    },
  };
  const tabChangeConfirm: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmTabChange'),
    handleSubmit: () => {
      setShowTabChangeConfirm(false);
    },
    handleClose: () => {
      setShowTabChangeConfirm(false);
    },
  };

  const [tabChangeConfrimConfig, setTabChangeConfirmConfig] =
    useState(tabChangeConfirm);

  const sendEmail = () => {
    setSendEmailConfirmDialog(false);
    const inviteJson = {
      companyId: Number(companyId),
      isResendInvite: companyDetails?.isResendInvite,
    };
    api
      .post(
        { url: apiConfig.companyInvitation, data: inviteJson },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          getCompanyDetails();
          toast.success({
            title: t('toast.success'),
            message: t('toast.invitationSentSuccess', {
              companyEmail: companyDetails?.companyEmail,
            }),
          });
        }
      });
  };

  const AddnewBusinessUnit = () => {
    isFromMenu
      ? navigate('/company/business-unit/add', {
          state: { companyId: companyId, isFromMenu: 'isFromMenu' },
        })
      : navigate('/company/business-unit/add', {
          state: { companyId: companyId },
        });
  };

  const AddnewContract = () => {
    // setContractId(0)
    setContractInformation(null);
    setAddContract(!addContract);
    setContractIsEdit(false);
  };

  const CancelAddContract = () => {
    if (addContract) {
      setCancelAddContract(true);
    } else {
      return (
        tabRef.current?.instance.option('selectedIndex', 0), setSelectedTab(0)
      );
    }
  };

  const SaveNewContract = () => {
    setConfirmAddContract(true);
  };

  const getContractById = async () => {
    await api
      .get({
        url: getAPIRoute('contractById', [companyId, contractId]),
      })
      .then((res) => {
        setContractInformation(res);
      });
  };

  const defaultValues: ICompanyInfoForm = {
    companyName: '',
    abbreviation: '',
    companyEmail: '',
    callingCode: '',
    legalEntityNumber: '',
    addressLine1: '',
    addressLine2: '',
    countryId: null,
    state: '',
    zipCode: '',
    companyStatus: CompanyStatus.draft,
    marsAccountManager: null,
    titleId: null,
    email: '',
    phone: null,
    phoneNumber: null,
    contactCallingCode: null,
    name: '',
    companyId: 0,
    contactId: 0,
    addressId: 0,
    companyType: false,
  };
  const companyForm = useForm({
    defaultValues: defaultValues,
  });

  const { isDirty } = companyForm.formState;

  const { getValues } = companyForm;

  const draftVisible = useMemo(() => {
    return companyDetails &&
      companyDetails.companyStatus === CompanyStatus.draft
      ? true
      : false;
  }, [companyDetails]);

  const { findFeature, featureList } = usePermission();

  const items = useMemo(() => {
    return [
      {
        title: 'Company Info',
        visible: findFeature('Company.ViewDetail'),
      },
      {
        title: 'Contract Information',
        visible: findFeature('CompanyContract.Listing') && !!companyId,
      },
      {
        title: 'Business Unit',
        visible: findFeature('CompanyBU.Listing') && !!companyId,
      },
      {
        title: 'Roles',
        visible: findFeature('CompanyRoles.Listing') && !!companyId,
      },
      {
        title: 'Users',
        visible: findFeature('CompanyUsers.Listing') && !!companyId,
      },
      {
        title: 'Configuration',
        visible: !!companyId,
      },
    ];
  }, [featureList, companyId]);

  const getStatus = async () => {
    await api
      .get({ url: apiConfig.companyStatus }, setIsLoading)
      .then((data) => {
        data.map((item: IOptionvalue) => {
          item.visible =
            item.optionValueCode === CompanyStatus.draft ? draftVisible : true;
        });
        setCompanyStatusList(data);
      });
  };

  const getTitleName = async () => {
    await api
      .get({ url: apiConfig.personNameTitle }, setIsLoading)
      .then((data: IOptionvalue[]) => {
        setPersonNameTitles(data);
      });
  };

  const getCountry = async () => {
    await api
      .get({ url: apiConfig.country }, setIsLoading)
      .then((data: ICountry[]) => {
        setCountries(data);
      });
  };

  const getMarsAccountManagers = async () => {
    await api
      .get({ url: apiConfig.marsAccountManager }, setIsLoading)
      .then((res: IUserList[]) => {
        setMarsAccountManagers(res);
      });
  };

  const getCompanyDetails = async (setIsLoading?: any) => {
    await api
      .get({ url: apiConfig.company + companyId }, setIsLoading)
      .then((data: ICompany) => {
        setCompanyDetails(data);
        if (loginCompanyId == data.companyId) {
          const storage: Storage =
            getRememberMe() === 'true' ? localStorage : sessionStorage;
          storage.setItem('companyName', data.companyName);
        }
      });
  };

  const getRoles = () => {
    api.get({ url: apiConfig.roles }).then((res: IRole[]) => {
      setActiveRoles(res.filter((item) => item.isActive));
    });
  };

  const getCompanyRoles = async () => {
    await api
      .get({ url: apiConfig.getCompanyRoles + companyId })
      .then((res: ICompanyRolesList[]) => {
        setCompanyRoles(res);
      });
  };

  const getCompanyUsers = async () => {
    await api.get({ url: apiConfig.employeeList + companyId }).then((res) => {
      const user: IUserGridData[] = [];
      res.map((item: IUserList) => {
        const roleNames: string[] = [];
        item.employeeBUList?.map((bu) => {
          bu.employeeBURoles?.map((role) => {
            roleNames.push(role.roleName);
          });
        });
        const data: IUserGridData = {
          employeeId: item.employeeId,
          employeeEmail: item.employeeEmail,
          employeeStatus: item.employeeStatus,
          companyId: item.companyId,
          employeeName: item.employeeName,
          buNames: item.employeeBUList
            ? item.employeeBUList?.map((bu) => bu.buName)
            : [],
          isReviewer: item.isReviewer,
          roleNames: roleNames.filter((v, i, a) => a.indexOf(v) === i),
        };
        user.push(data);
      });
      setUsersDataSource(user);
    });
  };

  const setTabValue = () => {
    switch (fromPage) {
      case 'businessUnitGrid':
        return (
          tabRef.current?.instance.option('selectedIndex', 2), setSelectedTab(2)
        );
      case 'usersGrid':
        return (
          tabRef.current?.instance.option('selectedIndex', 4), setSelectedTab(4)
        );
      default:
        if (index) {
          return (
            setSelectedTab(index),
            tabRef.current?.instance.option('selectedIndex', index)
          );
        } else {
          return (
            setSelectedTab(0),
            tabRef.current?.instance.option('selectedIndex', 0)
          );
        }
    }
  };

  const setNavigationFromMenu = () => {
    if (
      location.pathname &&
      location.pathname.split('/')[1] &&
      location.pathname.split('/')[1].toLowerCase() === 'company-details'
    ) {
      setIsFromMenu(true);
    }
  };

  useEffect(() => {
    setNavigationFromMenu();
  }, [location]);

  useEffect(() => {
    if (location.state) {
      const { companyId, fromPage }: any = location.state;
      setFromPage(fromPage);
      setCompanyId(companyId ? Number(companyId) : undefined);
    }
  }, [location.state]);

  useEffect(() => {
    getStatus();
    getCountry();
    getTitleName();
    getBuIncoTerms();
    getMarsAccountManagers();
    IsCompanyTypeMars();
  }, []);

  useEffect(() => {
    if (contractId) {
      getContractById();
    }
  }, [contractId]);

  useEffect(() => {
    setTabValue();
  }, [fromPage]);

  useEffect(() => {
    if (companyId) {
      getCompanyRoles();
      getCompanyDetails(setIsLoading);
      getRoles();
      getCompanyUsers();
    }
  }, [companyId]);

  useEffect(() => {
    const defaultTitle = personNameTitles.find(
      (title) => title.isDefault && title.visible
    );
    if (companyDetails) {
      companyForm.reset();
      companyForm.reset(
        {
          companyId: companyDetails?.companyId,
          companyName: companyDetails?.companyName,
          abbreviation: companyDetails?.abbreviation,
          companyEmail: companyDetails?.companyEmail,
          addressId:
            companyDetails?.addresses && companyDetails?.addresses[0]?.addressId
              ? companyDetails?.addresses[0]?.addressId
              : 0,
          callingCode:
            companyDetails?.addresses &&
            companyDetails?.addresses[0]?.callingCode
              ? companyDetails?.addresses[0]?.callingCode
              : null,
          legalEntityNumber: companyDetails?.legalEntityNumber,
          addressLine1:
            companyDetails?.addresses &&
            companyDetails?.addresses[0]?.addressLine1
              ? companyDetails?.addresses[0]?.addressLine1
              : '',
          addressLine2:
            companyDetails?.addresses &&
            companyDetails?.addresses[0]?.addressLine2
              ? companyDetails?.addresses[0]?.addressLine2
              : '',
          countryId:
            companyDetails?.addresses && companyDetails?.addresses[0]?.countryId
              ? companyDetails?.addresses[0]?.countryId
              : null,
          state:
            companyDetails?.addresses && companyDetails?.addresses[0]?.state
              ? companyDetails?.addresses[0]?.state
              : '',
          zipCode:
            companyDetails?.addresses && companyDetails?.addresses[0]?.zipCode
              ? companyDetails?.addresses[0]?.zipCode
              : '',
          companyStatus: companyDetails?.companyStatus,
          marsAccountManager: companyDetails.marsAccountManager
            ? companyDetails.marsAccountManager
            : null,
          titleId:
            companyDetails?.contacts &&
            companyDetails?.contacts[0]?.personNameTitle
              ? companyDetails?.contacts[0]?.personNameTitle
              : defaultTitle
              ? defaultTitle?.optionValueCode
              : null,
          email:
            companyDetails?.contacts &&
            companyDetails?.contacts[0]?.contactEmail
              ? companyDetails?.contacts[0]?.contactEmail
              : '',
          phone:
            companyDetails?.contacts && companyDetails?.contacts[0]?.phoneNumber
              ? parseInt(companyDetails?.contacts[0]?.phoneNumber)
              : null,
          phoneNumber:
            companyDetails?.addresses &&
            companyDetails?.addresses[0]?.phoneNumber
              ? parseInt(companyDetails?.addresses[0]?.phoneNumber)
              : null,
          contactCallingCode:
            companyDetails?.contacts && companyDetails?.contacts[0]?.callingCode
              ? companyDetails?.contacts[0]?.callingCode
              : null,
          name:
            companyDetails?.contacts && companyDetails?.contacts[0]?.contactName
              ? companyDetails?.contacts[0]?.contactName
              : '',
          contactId:
            companyDetails?.contacts && companyDetails?.contacts[0]?.contactId
              ? companyDetails?.contacts[0]?.contactId
              : 0,
          companyType: companyDetails.companyType === 'H' ? true : false,
        },
        { keepDirty: true }
      );
    } else {
      if (defaultTitle) {
        companyForm.resetField('titleId', {
          defaultValue: defaultTitle.optionValueCode,
        });
      }
    }
  }, [personNameTitles, companyDetails]);

  useEffect(() => {
    setRoleDataSource(new DataSource(JSON.parse(JSON.stringify(companyRoles))));
  }, [companyRoles]);

  const disableRoleSaveButton = useMemo(() => {
    return (
      (companyRoles.length === 0 &&
        roleDataSource.store()._array.length === 0) ||
      (companyRoles.length !== 0 && !isRolesEdited)
    );
  }, [roleDataSource, companyRoles, isRolesEdited]);

  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
    return;
  };

  const saveCompanyInfo = () => {
    const companyFormValue: ICompanyInfoForm = getValues();
    let addressJson: IAddress[] | undefined;
    let contactJson: IContact[] | undefined;
    if (
      companyFormValue.countryId ||
      companyFormValue.phoneNumber ||
      companyFormValue.addressLine1 ||
      companyFormValue.addressLine2 ||
      companyFormValue.state ||
      companyFormValue.state ||
      companyFormValue.addressId ||
      companyFormValue.callingCode ||
      companyFormValue.zipCode
    ) {
      addressJson = [
        {
          addressId: companyFormValue.addressId,
          companyId: companyFormValue.companyId,
          callingCode: companyFormValue.callingCode,
          addressType: '1',
          phoneNumber: companyFormValue.phoneNumber?.toString(),
          addressLine1: companyFormValue.addressLine1,
          addressLine2: companyFormValue.addressLine2,
          countryId: companyFormValue.countryId,
          state: companyFormValue.state,
          zipCode: companyFormValue.zipCode,
        },
      ];
    }
    if (
      companyFormValue.name ||
      companyFormValue.email ||
      companyFormValue.phone ||
      companyFormValue.contactId ||
      companyFormValue.callingCode
    ) {
      contactJson = [
        {
          contactId: companyFormValue.contactId,
          companyId: companyFormValue.companyId,
          personNameTitle: companyFormValue.name
            ? companyFormValue.titleId
            : null,
          contactName: companyFormValue.name,
          contactEmail: companyFormValue.email,
          callingCode: companyFormValue.contactCallingCode,
          phoneNumber: companyFormValue.phone?.toString(),
        },
      ];
    }
    const companyJson: ICompany = {
      companyId: companyFormValue.companyId,
      companyName: companyFormValue.companyName,
      abbreviation: companyFormValue.abbreviation,
      companyEmail: companyFormValue.companyEmail,
      legalEntityNumber: companyFormValue.legalEntityNumber,
      companyStatus: companyFormValue.companyStatus,
      companyType:
        !companyDetails || companyDetails.companyType !== 'M'
          ? companyFormValue.companyType
            ? 'H'
            : 'R'
          : 'M',
      marsAccountManager: companyFormValue.marsAccountManager,
      addresses: addressJson,
      contacts: contactJson,
    };
    setIsConfirmModalOpen(false);
    if (!companyId) {
      api
        .post({ url: apiConfig.company, data: companyJson }, setIsLoading)
        .then((res) => {
          toast.custom({
            title: t('toast.updatedSuccessfully'),
            message: t('toast.companyDetailsUpdated'),
          });
          navigate('/company/edit', { state: { companyId: res.companyId } });
        });
    } else {
      api
        .put({ url: apiConfig.company, data: companyJson }, setIsLoading)
        .then(() => {
          toast.custom({
            title: t('toast.updatedSuccessfully'),
            message: t('toast.companyDetailsUpdated'),
          });
          getCompanyDetails();
        });
    }
  };

  const handleBackClick = () => {
    isDirty ? setConfirmBackDialog(true) : navigate('/company');
  };
  const onBackClick = () => {
    navigate('/company');
  };
  const onTabSelectionChanged = (e: any) => {
    if (
      e.removedItems[0].title === 'Company Info' &&
      isDirty &&
      e.addedItems[0].title !== 'Company Info'
    ) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          companyForm.reset(undefined, { keepDirty: true });
          setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
          setShowTabChangeConfirm(false);
        },
        handleClose: () => {
          tabRef.current?.instance.option('selectedIndex', 0);
          setShowTabChangeConfirm(false);
        },
      };
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
      setShowTabChangeConfirm(true);
    } else if (e.removedItems[0].title === 'Roles' && !disableRoleSaveButton) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          setRoleDataSource(
            new DataSource(JSON.parse(JSON.stringify(companyRoles)))
          );
          setIsRolesEdited(false);
          setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
          setShowTabChangeConfirm(false);
        },
        handleClose: () => {
          tabRef.current?.instance.option('selectedIndex', 3);
          setShowTabChangeConfirm(false);
        },
      };
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
      setShowTabChangeConfirm(true);
    } else {
      setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    }
  };

  const approvalListCallBack = useCallback((data: any) => {
    setUserApproveList(data);
  }, []);

  const sendForApprovalListCallBack = useCallback((data: any) => {
    setUserSendForApprovalList(data);
  }, []);

  const navigateToAddUser = () => {
    isFromMenu
      ? navigate('/company/user/add', {
          state: { isFromMenu: 'isFromMenu', companyId: companyId },
        })
      : navigate('/company/user/add', { state: { companyId: companyId } });
  };

  const navigateToEditUser = (companyId: number, employeeId: number) => {
    isFromMenu
      ? navigate(`/company/user/edit`, {
          state: {
            isFromMenu: 'isFromMenu',
            companyId: companyId,
            userId: employeeId,
          },
        })
      : navigate(`/company/user/edit`, {
          state: { userId: employeeId, companyId: companyId },
        });
  };

  const saveConfigClick = (e: any) => {
    setSaveConfig(true);
  };

  const itemRender = (e: any) => {
    if (e.title === items[0].title) {
      return (
        <FormProvider {...companyForm}>
          <CompanyInfoForm
            companyStatusList={companyStatusList}
            personNameTitles={personNameTitles}
            countries={countries}
            companyDetails={companyDetails}
            marsAccountManagers={marsAccountManagers}
            getCompanyDetails={getCompanyDetails}
          />
        </FormProvider>
      );
    } else if (e.title === items[1].title) {
      return addContract ? (
        <AddContract
          setAddContract={setAddContract}
          confirmAddContract={confirmAddContract}
          companyId={companyId}
          setConfirmAddContract={setConfirmAddContract}
          cancelAddContract={cancelAddContract}
          setCancelAddContract={setCancelAddContract}
          contractInformation={contractInformation}
          setContractIsEdit={setContractIsEdit}
          contractIsEdit={contractIsEdit}
          getContractById={getContractById}
          isMarsUser={isMarsUser}
        />
      ) : (
        <ContractInformation
          companyId={companyId}
          setAddContract={setAddContract}
          setContractId={setContractId}
        />
      );
    } else if (e.title === items[2].title) {
      return (
        <BusinessUnitGrid
          companyId={companyId}
          personNameTitles={personNameTitles}
          buIncoterms={buIncoTerms}
          isFromMenu={isFromMenu}
        />
      );
    } else if (e.title === items[3].title) {
      return (
        <RoleGrid
          companyId={companyId}
          activeRoles={activeRoles}
          companyRoles={companyRoles}
          ref={roleGridRef}
          setCompanyRoles={setCompanyRoles}
          setDataSource={setRoleDataSource}
          dataSource={roleDataSource}
          isEdited={isRolesEdited}
          setIsEdited={setIsRolesEdited}
          navigate={() => navigate('/company')}
          deletedRoleIds={deletedRoleIds}
          setDeletedRoleIds={setDeletedRoleIds}
          getCompanyDetails={getCompanyDetails}
          getCompanyUsers={getCompanyUsers}
        />
      );
    } else if (e.title === items[4].title) {
      return (
        <UsersGrid
          setUserApproveList={approvalListCallBack}
          setUserSendForApprovalList={sendForApprovalListCallBack}
          userList={usersDataSource}
          ref={userGridRef}
          companyDetails={companyDetails}
          getCompanyUsers={getCompanyUsers}
          navigate={(companyId: number, employeeId: number) => {
            navigateToEditUser(companyId, employeeId);
          }}
        />
      );
    } else if (e.title === items[5].title) {
      return (
        <CompanyConfigurations
          companyType={companyDetails?.companyType}
          companyId={companyId}
          setSaveConfig={setSaveConfig}
          saveConfig={saveConfig}
          selectedConfig={selectedConfig}
          setSelectedConfig={setSelectedConfig}
        />
      );
    }
  };

  const onClickSentEmail = () => {
    isDirty
      ? toast.error({
          title: 'Error',
          message: 'Please Save the changes before Sending Invite.',
        })
      : setSendEmailConfirmDialog(true);
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>Home</a>
        </li>
        <li>
          <a className="active">Create Company</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          {companyId && companyDetails && (
            <>
              <div className="m-l-page-main-heading">
                {companyDetails?.companyName}
              </div>
              <div className="m-l-sub-title-details-block">
                <div className="m-l-page-sub-heading">
                  {t('labels.companyId')}: {companyDetails.companyCode}
                </div>
                {companyDetails?.isBUOrRolesEmpty && (
                  <div className="m-l-page-create-company-info">
                    {t('labels.sendinviteWarning')}
                  </div>
                )}
              </div>
            </>
          )}
          {!companyId && (
            <div className="m-l-page-main-heading">{t('headers.company')}</div>
          )}
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            ref={tabRef}
            // height={'600px'}
            items={items}
            className="m-c-tab-block m-c-tab--half-width"
            elementAttr={{ 'data-testid': 'companyTab' }}
            itemRender={itemRender}
            onSelectionChanged={onTabSelectionChanged}
            showNavButtons={true}
          />
          {selectedTab === 0 && (
            <div className="m-l-tab-panel-right-block">
              {!isFromMenu && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={handleBackClick}
                >
                  {/* <div className="m-c-icon-inside-btn u-pr-lg">
              <icons.AngleLeft />
            </div> */}
                  {t('buttons.back')}
                </Button>
              )}
              {companyDetails && companyDetails.isSendInvite && (
                <Restricted permission="Company.SendInvite">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={onClickSentEmail}
                    elementAttr={{ 'data-testid': 'sendInvite' }}
                  >
                    {t('buttons.sendInvite')}
                  </Button>
                </Restricted>
              )}
              {companyDetails && companyDetails.isResendInvite && (
                <Restricted permission="Company.SendInvite">
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={onClickSentEmail}
                    elementAttr={{ 'data-testid': 'resendInvite' }}
                  >
                    {t('buttons.resendInvite')}
                  </Button>
                </Restricted>
              )}
              <Restricted permission="Company.Edit">
                {companyId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
              <Restricted permission="Company.Add">
                {!companyId && (
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    elementAttr={{ 'data-testid': 'saveButton' }}
                    onClick={handleSaveClick}
                  >
                    {t('buttons.save')}
                  </Button>
                )}
              </Restricted>
            </div>
          )}
          {selectedTab === 2 && companyId !== undefined && (
            <div className="m-l-tab-panel-right-block">
              {!isFromMenu && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={onBackClick}
                >
                  {t('buttons.back')}
                </Button>
              )}
              <Restricted permission="CompanyBU.Add">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'addNewButton' }}
                  onClick={AddnewBusinessUnit}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            </div>
          )}
          {selectedTab === 1 && companyId !== undefined && (
            <div className="m-l-tab-panel-right-block">
              {(!isFromMenu || addContract) && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={addContract ? CancelAddContract : onBackClick}
                >
                  {t('buttons.back')}
                </Button>
              )}
              {addContract ? (
                contractIsEdit ? (
                  <Restricted permission="CompanyContract.Edit">
                    {isMarsUser ? (
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'addNewButton' }}
                        onClick={SaveNewContract}
                      >
                        {t('buttons.save')}
                      </Button>
                    ) : null}{' '}
                  </Restricted>
                ) : (
                  <Restricted permission="CompanyContract.Add">
                    {' '}
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'addNewButton' }}
                      onClick={SaveNewContract}
                    >
                      {t('buttons.save')}
                    </Button>
                  </Restricted>
                )
              ) : (
                <Restricted permission="CompanyContract.Add">
                  {isMarsUser ? (
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'addNewButton' }}
                      onClick={AddnewContract}
                    >
                      {t('buttons.addNew')}
                    </Button>
                  ) : null}
                </Restricted>
              )}
            </div>
          )}

          {selectedTab === 3 && (
            <div className="m-l-tab-panel-right-block">
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                elementAttr={{ 'data-testid': 'exportButton' }}
                onClick={() => roleGridRef.current.exportRolesGrid()}
                disabled={roleDataSource.store()._array.length === 0}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              {!isFromMenu && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={() => roleGridRef.current.showCancelConfirmModal()}
                >
                  {t('buttons.back')}
                </Button>
              )}
              <Restricted permission="CompanyRoles.Add">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'saveButton' }}
                  onClick={() => roleGridRef.current.showConfirmModal()}
                  disabled={disableRoleSaveButton}
                >
                  {t('buttons.save')}
                </Button>
              </Restricted>
            </div>
          )}
          {selectedTab === 4 && (
            <div className="m-l-tab-panel-right-block">
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                elementAttr={{ 'data-testid': 'exportButton' }}
                onClick={() => {
                  userGridRef.current.exportUsersGrid();
                }}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              {!isFromMenu && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={() => {
                    navigate('/company');
                  }}
                >
                  {t('buttons.back')}
                </Button>
              )}
              <UserApprove
                isFromUserList={true}
                getUserDetails={getCompanyUsers}
                userApproveList={
                  userApproveList.length > 0
                    ? userApproveList.map((item: any) => item.employeeId)
                    : []
                }
                userSendForApprovalList={
                  userSendForApprovalList.length > 0
                    ? userSendForApprovalList.map(
                        (item: any) => item.employeeId
                      )
                    : []
                }
                apiUrl={
                  userApproveList.length > 0
                    ? apiConfig.employeeApprove
                    : userSendForApprovalList.length > 0
                    ? apiConfig.employeeSendForApprove
                    : ''
                }
              />
              <Restricted permission="CompanyUsers.Add">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'saveButton' }}
                  onClick={() => navigateToAddUser()}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            </div>
          )}
          {selectedTab === 5 && (
            <div className="m-l-tab-panel-right-block">
              {!isFromMenu && (
                <Button
                  className="app-c-btn app-c-btn--secondary"
                  elementAttr={{ 'data-testid': 'backButton' }}
                  onClick={() => {
                    navigate('/company');
                  }}
                >
                  {t('buttons.back')}
                </Button>
              )}
              <Restricted permission={editConfig}>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  elementAttr={{ 'data-testid': 'addNewButton' }}
                  onClick={saveConfigClick}
                >
                  {t('buttons.save')}
                </Button>
              </Restricted>
            </div>
          )}
        </div>

        {/* Approve button for user tab */}
        {/* <div className="m-l-panel-right-btn">
          <Button className="app-c-btn app-c-btn--secondary min-btn-width">
            Approve
          </Button>
        </div> */}
      </div>
      {/* <div className="m-l-page-footer-btn-wrapper">
        <Button className="app-c-btn app-c-btn--secondary min-btn-width">
          Re-Send Invite
        </Button>
      </div> */}
      {/* Buttons in mobile view //////////////////////////*/}
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {selectedTab === 0 && (
                <>
                  {!isFromMenu && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={handleBackClick}
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  {companyDetails && companyDetails.isSendInvite && (
                    <Restricted permission="Company.SendInvite">
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width"
                        onClick={onClickSentEmail}
                        elementAttr={{ 'data-testid': 'sendInvite' }}
                      >
                        {t('buttons.sendInvite')}
                      </Button>
                    </Restricted>
                  )}
                  {companyDetails && companyDetails.isResendInvite && (
                    <Restricted permission="Company.SendInvite">
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width"
                        onClick={onClickSentEmail}
                        elementAttr={{ 'data-testid': 'resendInvite' }}
                      >
                        {t('buttons.resendInvite')}
                      </Button>
                    </Restricted>
                  )}
                  <Restricted permission="Company.Edit">
                    {companyId && (
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'saveButton' }}
                        onClick={handleSaveClick}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Restricted>
                  <Restricted permission="Company.Add">
                    {!companyId && (
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'saveButton' }}
                        onClick={handleSaveClick}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Restricted>
                </>
              )}
              {selectedTab === 1 && companyId !== undefined && (
                <>
                  {(!isFromMenu || addContract) && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={addContract ? CancelAddContract : onBackClick}
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  {addContract ? (
                    contractIsEdit ? (
                      <Restricted permission="CompanyContract.Edit">
                        {isMarsUser ? (
                          <Button
                            className="app-c-btn app-c-btn--primary min-btn-width"
                            elementAttr={{ 'data-testid': 'addNewButton' }}
                            onClick={SaveNewContract}
                          >
                            {t('buttons.save')}
                          </Button>
                        ) : null}{' '}
                      </Restricted>
                    ) : (
                      <Restricted permission="CompanyContract.Add">
                        {' '}
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          elementAttr={{ 'data-testid': 'addNewButton' }}
                          onClick={SaveNewContract}
                        >
                          {t('buttons.save')}
                        </Button>
                      </Restricted>
                    )
                  ) : (
                    <Restricted permission="CompanyContract.Add">
                      {isMarsUser ? (
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          elementAttr={{ 'data-testid': 'addNewButton' }}
                          onClick={AddnewContract}
                        >
                          {t('buttons.addNew')}
                        </Button>
                      ) : null}
                    </Restricted>
                  )}
                </>
              )}
              {selectedTab === 2 && companyId !== undefined && (
                <>
                  {!isFromMenu && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={onBackClick}
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  <Restricted permission="CompanyBU.Add">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'addNewButton' }}
                      onClick={AddnewBusinessUnit}
                    >
                      {t('buttons.addNew')}
                    </Button>
                  </Restricted>
                </>
              )}
              {selectedTab === 3 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                    onClick={() => roleGridRef.current.exportRolesGrid()}
                    disabled={roleDataSource.store()._array.length === 0}
                  >
                    {t('labels.download')}
                  </Button>
                  {!isFromMenu && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={() =>
                        roleGridRef.current.showCancelConfirmModal()
                      }
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  <Restricted permission="CompanyRoles.Add">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'saveButton' }}
                      onClick={() => roleGridRef.current.showConfirmModal()}
                      disabled={disableRoleSaveButton}
                    >
                      {t('buttons.save')}
                    </Button>
                  </Restricted>
                </>
              )}
              {selectedTab === 4 && (
                <>
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                    onClick={() => {
                      userGridRef.current.exportUsersGrid();
                    }}
                  >
                    {t('labels.download')}
                  </Button>
                  {!isFromMenu && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={() => {
                        navigate('/company');
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  <UserApprove
                    isFromUserList={true}
                    getUserDetails={getCompanyUsers}
                    userApproveList={
                      userApproveList.length > 0
                        ? userApproveList.map((item: any) => item.employeeId)
                        : []
                    }
                    userSendForApprovalList={
                      userSendForApprovalList.length > 0
                        ? userSendForApprovalList.map(
                            (item: any) => item.employeeId
                          )
                        : []
                    }
                    apiUrl={
                      userApproveList.length > 0
                        ? apiConfig.employeeApprove
                        : userSendForApprovalList.length > 0
                        ? apiConfig.employeeSendForApprove
                        : ''
                    }
                  />
                  <Restricted permission="CompanyUsers.Add">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'saveButton' }}
                      onClick={() => navigateToAddUser()}
                    >
                      {t('buttons.addNew')}
                    </Button>
                  </Restricted>
                </>
              )}
              {selectedTab === 5 && (
                <>
                  {!isFromMenu && (
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      elementAttr={{ 'data-testid': 'backButton' }}
                      onClick={() => {
                        navigate('/company');
                      }}
                    >
                      {t('buttons.back')}
                    </Button>
                  )}
                  <Restricted permission={editConfig}>
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'addNewButton' }}
                      onClick={saveConfigClick}
                    >
                      {t('buttons.save')}
                    </Button>
                  </Restricted>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={sendEmailDialogConfig}
        isOpen={sendEmailConfirmDialog}
      />
      <ConfirmDialog
        dialogConfig={tabChangeConfrimConfig}
        isOpen={showTabChangeConfirm}
      />
    </div>
  );
}
