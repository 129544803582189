import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../../../types/dialog';
import { IGridConfig } from '../../../../../../types/grid';
import ConfirmDialog from '../../../../../common/confirm/ConfirmDialog';
import Grid from '../../../../../common/grid/Grid';
import toast from '../../../../../../utils/toast';
import STSConfigMapView from '../sts-map-view/StsConfigMapView';
import { CircleConfig, CirclePayload } from '../../../../../../types/map';
import { convertDecimalToMinutes } from '../../../../../../utils/coordinates';
import { LatLng } from 'leaflet';
import { MarsApiService } from '../../../../../../api/mars-api-service';
import { MarsApiConfig } from '../../../../../../api/mars-api-config';
import { useLoaderContext } from '../../../../../../contexts/LoaderContext';
import CheckBox from 'devextreme-react/check-box';
import Switch from 'devextreme-react/switch';
import { HighRiskAreaFilters } from '../../../../../../types/compliance-config';
import { CompanyType } from '../../../../../../enums/company-type-enum';
import { usePermission } from '../../../../../../contexts/PermissionContext';

export default function StsProneAreaGrid(
  props: Readonly<{
    companyId?: string;
    buId: string;
    companyTypeFlag?: string;
  }>
) {
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { companyId, buId, companyTypeFlag } = props;
  const deleteConfirmDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.deleteStsProneArea'),
    handleSubmit: () => {
      setDeleteConfirmDialog(false);
    },
    handleClose: () => {
      setDeleteConfirmDialog(false);
    },
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.stsMapViewClose'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      setShowMap(false);
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };
  const { setIsLoading } = useLoaderContext();
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);
  const [deleteDialogConfig, setDeleteDialogConfig] = useState(
    deleteConfirmDialogConfig
  );
  const [dataSource, setDataSource] = useState<CircleConfig[]>([]);
  const [showMap, setShowMap] = useState(false);
  const [circleConfig, setCircleConfig] = useState<CircleConfig[]>([]);
  const [selection, setSelection] = useState<HighRiskAreaFilters>(
    companyTypeFlag === 'M'
      ? {
          all: true,
          marsSTSProneArea: true,
          buSTSProneArea: true,
          active: true,
          inactive: true,
        }
      : {
          all: false,
          marsSTSProneArea: true,
          buSTSProneArea: true,
          active: true,
          inactive: false,
        }
  );
  const [activeConfirmDialog, setActiveConfirmDialog] = useState(false);

  const confirmStatusDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      setActiveConfirmDialog(false);
    },
    handleClose: () => {
      setActiveConfirmDialog(false);
    },
  };

  const [activeDialogConfig, setActiveDialogConfig] = useState(
    confirmStatusDialogConfig
  );

  const handleAddClick = () => {
    setCircleConfig([]);
    setShowMap(true);
  };

  useEffect(() => {
    getStsProneAreaData();
  }, [selection]);

  const getStsProneAreaData = async () => {
    await MarsApiService.post(
      {
        url: MarsApiConfig.stsRiskProneAreaList,
        params: {
          CompanyId: companyId,
          BuId: buId,
        },
        data: selection,
      },
      setIsLoading
    ).then((data: CircleConfig[]) => {
      const formattedData = formatCircleData(data);
      setDataSource(formattedData);
    });
  };

  const formatCircleData = (data: CircleConfig[]) => {
    return data.map((item: CircleConfig) => ({
      areaName: item.areaName,
      coordinates: new LatLng(item.lat ?? 0, item.lng ?? 0),
      radius: item.radius,
      stsHighProneAreaId: item.stsHighProneAreaId,
      status: item.status,
      companyType: item.companyType,
      stsIdentifiedTillDate: item.stsIdentifiedTillDate,
    }));
  };

  const setSTSProneArea = async (data: CirclePayload) => {
    await MarsApiService.put(
      {
        url: MarsApiConfig.stsRiskProneAreaList,
        data: data,
      },
      setIsLoading
    ).then((e) => {
      triggerSchedulerApi(
        e.stsHighProneAreaId,
        data.stsHighProneAreaId ? true : false
      );
      toast.success({
        title: t('toast.success'),
        message: t('toast.stsProneAreaAddedSuccess', {
          boundaryName: e.areaName,
        }),
      });
      getStsProneAreaData();
    });
  };

  const triggerSchedulerApi = async (
    stsHighProneAreaId: number,
    isEdit: boolean
  ) => {
    await MarsApiService.schedulerPost({
      url: MarsApiConfig.stsProneAreaScheduler + stsHighProneAreaId,
      params: { isEdit },
    }).then();
  };

  const onClickDelete = (data: CircleConfig) => {
    if (data) {
      setDeleteConfirmDialog(true);
      setDeleteDialogConfig({
        id: 'confirmDelete',
        content: t('toast.deleteStsProneArea'),
        handleSubmit: () => {
          deleteLayout(data);
        },
        handleClose: () => {
          setDeleteConfirmDialog(false);
        },
      });
    }
  };

  const deleteLayout = async (data: CircleConfig) => {
    await MarsApiService.delete(
      {
        url: MarsApiConfig.deleteStsHighRiskArea + data.stsHighProneAreaId,
      },
      setIsLoading
    ).then((res) => {
      if (res) {
        setDeleteConfirmDialog(false);
        getStsProneAreaData();
        toast.success({
          title: t('toast.deleteStsProneAreaSuccess'),
        });
      }
    });
  };

  const calculateCoordinatesCellValue = useCallback(
    (data: CircleConfig) =>
      `${convertDecimalToMinutes(
        data?.coordinates?.lat ?? 0,
        true
      )} / ${convertDecimalToMinutes(data?.coordinates?.lng ?? 0)}`,
    []
  );

  const convertRadiusInNm = useCallback((data: number) => data / 1852, []);

  const yesNoDataSource = [
    {
      name: t('labels.active'),
      value: true,
    },
    {
      name: t('labels.inActive'),
      value: false,
    },
  ];

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-c-righticon-grid m-c-grid__role',
    testId: 'roleGrid',
    dataSource: dataSource,
    scrollMode: 'virtual',
    defaultColumns: [
      {
        caption: t('labels.stsProneAreaName'),
        dataField: 'areaName',
        dataType: 'string',
        minWidth: 150,
        sortOrder: 'asc',
      },
      {
        caption: t('labels.stsCoordinates'),
        dataField: 'coordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: CircleConfig) =>
          calculateCoordinatesCellValue(data),
      },
      {
        caption: t('labels.radius'),
        dataField: 'radius',
        dataType: 'number',
        alignment: 'left',
        minWidth: 150,
        calculateCellValue: (data: CircleConfig) =>
          convertRadiusInNm(data.radius),
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
      },
      {
        caption: t('labels.stsIdentifiedTillDate'),
        dataField: 'stsIdentifiedTillDate',
        alignment: 'left',
        minWidth: 150,
        dataType: 'date',
        format: 'dd MMM yyyy',
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        alignment: 'center',
        dataType: 'string',
        minWidth: 100,
        cellTemplate: 'statusTemplate',
        allowFiltering: true,
        allowSorting: true,
        lookup: {
          dataSource: yesNoDataSource,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
      {
        caption: t('labels.action'),
        dataField: 'action',
        dataType: 'string',
        visible: buId
          ? findFeature('BUCompanyConfiguration.EditSTSProneArea')
          : findFeature('CompanyConfiguration.EditSTSProneArea'),
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        allowFiltering: false,
        allowSorting: false,
        minWidth: 100,
        width: 100,
      },
    ],
    showHeader: true,
    showAddButton: buId
      ? findFeature('BUCompanyConfiguration.CreateSTSProneAreaList')
      : findFeature('CompanyConfiguration.CreateSTSProneAreaList'),
    onClickAddButton: handleAddClick,
    hidePagination: true,
    enableEditing: true,
    editMode: 'row',
    height: 350,
  };

  const findIndexInData = (
    updatedData: CircleConfig[],
    newItem: CircleConfig
  ) => {
    return updatedData.findIndex(
      (item) => item.stsHighProneAreaId === newItem.stsHighProneAreaId
    );
  };

  const updateOrAddItem = (
    updatedData: CircleConfig[],
    newItem: CircleConfig
  ) => {
    const index = findIndexInData(updatedData, newItem);
    const payload = {
      stsHighProneAreaId:
        index !== -1 ? updatedData[index].stsHighProneAreaId : 0,
      areaName: newItem.areaName,
      lat: newItem.coordinates?.lat,
      lng: newItem.coordinates?.lng,
      radius: newItem.radius,
      companyId: companyId,
      buId: buId,
    };
    setSTSProneArea(payload);
  };

  const setCircleData = (isDirty: boolean) => {
    if (isDirty) {
      const transformedData = circleConfig.map((circle) => ({
        areaName: circle.areaName,
        coordinates: circle.coordinates,
        radius: circle.radius,
        stsHighProneAreaId: circle.stsHighProneAreaId,
      }));

      transformedData.forEach((newItem) => {
        updateOrAddItem(circleConfig, newItem);
      });
      setShowMap(false);
    } else {
      toast.error({
        title: 'Error',
        message: t('toast.stsProneAreaEmptyError'),
      });
    }
  };

  const onClickEdit = (data: CircleConfig) => {
    setShowMap(true);
    setCircleConfig([data]);
  };

  const actionCellTemplate = (data: { data: CircleConfig }) => {
    return (
      <div>
        <i
          className={`dx-icon-edit dx-custom-icon ${
            data.data.companyType === companyTypeFlag ? '' : 'disabled'
          }`}
          onClick={() => onClickEdit(data.data)}
        ></i>
        <i
          className={`dx-icon-trash dx-custom-icon ${
            data.data.companyType === companyTypeFlag ? '' : 'disabled'
          }`}
          onClick={() => onClickDelete(data.data)}
        ></i>
      </div>
    );
  };

  const handleAllChange = (value: boolean) => {
    setSelection((prevFilters: HighRiskAreaFilters) => ({
      ...prevFilters,
      all: value,
      ...(value && {
        marsSTSProneArea: true,
        buSTSProneArea: true,
        active: true,
        inactive: true,
      }),
    }));
  };

  const handleFilterChange = (filterKey: string, value: boolean) => {
    setSelection((prevFilters: HighRiskAreaFilters) => {
      const updatedFilters = { ...prevFilters, [filterKey]: value };

      const allChecked =
        updatedFilters.marsSTSProneArea &&
        updatedFilters.buSTSProneArea &&
        updatedFilters.active &&
        updatedFilters.inactive;

      return { ...updatedFilters, all: allChecked };
    });
  };

  const cellRender = (data: { data: CircleConfig; value: boolean }) => {
    return (
      <div className="m-l-field-wrap">
        <div className="m-l-toggle-switch">
          <Switch
            elementAttr={{ 'data-testid': 'statusToggleswitch' }}
            defaultValue={data.value}
            onValueChanged={(e) => {
              handleActiveInactiveChange(e, data.data);
            }}
          />
        </div>
      </div>
    );
  };

  const activateRule = async (
    isActive: boolean,
    stsHighProneAreaId?: string
  ) => {
    const ruleStatusJson = {
      stsHighProneAreaId: stsHighProneAreaId,
      status: isActive,
      buId: buId,
      companyId: companyId,
    };
    MarsApiService.put(
      { url: MarsApiConfig.updateStsStatus, data: ruleStatusJson },
      setIsLoading
    ).then(() => {
      getStsProneAreaData();
    });
  };

  const handleActiveInactiveChange = async (e: any, stsData: CircleConfig) => {
    if (e.event) {
      const dialogConfig = {
        title: t('toast.confirmation'),
        content: e.value
          ? t('toast.activateStsProneArea')
          : t('toast.inActivateStsProneArea'),
        handleSubmit: () => {
          activateRule(e.value, stsData?.stsHighProneAreaId);
          setActiveConfirmDialog(false);
        },
      };
      setActiveDialogConfig({
        ...confirmStatusDialogConfig,
        ...dialogConfig,
      });
      setActiveConfirmDialog(true);
    }
  };

  return (
    <>
      {companyTypeFlag !== CompanyType.Mars ? (
        <div className="m-l-grid-top-header__section u-bb0 adjust-margin">
          <div className="left-section">
            <div className="m-l-grid-top-header-list-row">
              <div className="row">
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 col-12 m-l-grid-top-header-list-column  justify-content-start  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'allStsAreaToggle' }}
                      onValueChanged={(e) => handleAllChange(e.value)}
                      value={selection.all}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.All')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'marsStsAreaToggle' }}
                      onValueChanged={(e) =>
                        handleFilterChange('marsSTSProneArea', e.value)
                      }
                      value={selection.marsSTSProneArea}
                    />
                  </div>
                  <h2 className="checkbox-label">
                    {t('labels.marsStsProneArea')}
                  </h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'buStsAreaToggle' }}
                      onValueChanged={(e) =>
                        handleFilterChange('buSTSProneArea', e.value)
                      }
                      value={selection.buSTSProneArea}
                    />
                  </div>
                  <h2 className="checkbox-label">
                    {t('labels.buStsProneArea')}
                  </h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'activeStsAreaToggle' }}
                      onValueChanged={(e) =>
                        handleFilterChange('active', e.value)
                      }
                      value={selection.active}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.active')}</h2>
                </div>
                <div className="col-xl-auto col-lg-auto col-md-4 col-sm-6 m-l-grid-top-header-list-column  justify-content-start">
                  <div className="m-c-checkbox m-c-checkbox-dark">
                    <CheckBox
                      elementAttr={{ 'data-testid': 'inActiveStsAreaToggle' }}
                      onValueChanged={(e) =>
                        handleFilterChange('inactive', e.value)
                      }
                      value={selection.inactive}
                    />
                  </div>
                  <h2 className="checkbox-label">{t('labels.inActive')}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Grid
        gridConfig={gridConfig}
        actionTemplate={actionCellTemplate}
        statusTemplate={cellRender}
        showScroller={true}
      />
      <ConfirmDialog
        dialogConfig={deleteDialogConfig}
        isOpen={deleteConfirmDialog}
      />
      <ConfirmDialog
        dialogConfig={activeDialogConfig}
        isOpen={activeConfirmDialog}
      />
      {showMap && (
        <STSConfigMapView
          isVisible={showMap}
          zoom={8}
          isDeleteVisible={false}
          setIsVisible={(value: boolean) => setShowMap(value)}
          circleConfig={circleConfig}
          setCircleConfig={setCircleConfig}
          setCircleData={setCircleData}
          setShowConfirmBack={setShowConfirmBack}
        />
      )}
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={showConfirmBack}
      />
    </>
  );
}
