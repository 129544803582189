import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { IGridConfig } from '../../../../types/grid';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { PortCallsGridData } from '../../../../types/port-calls';
import Grid from '../../../common/grid/Grid';
import {
  getBuList,
  getCompanyId,
  getLoginBUName,
} from '../../../../utils/jwt-decode';
import { usePermission } from '../../../../contexts/PermissionContext';
import toast from '../../../../utils/toast';

export default function PortCalls(
  props: Readonly<{
    imoNumber: string;
    isMovementRisk?: boolean;
    complianceCheckId?: string;
    isDownloadVisible?: boolean;
    refreshPortCalls?: boolean;
  }>
) {
  const { t } = useTranslation();
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const { imoNumber, isMovementRisk, complianceCheckId, isDownloadVisible } =
    props;
  const { setIsLoading } = useLoaderContext();
  const { findFeature } = usePermission();
  const companyId = getCompanyId();
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.find((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;

  const [portCalls, setPortCalls] = useState<PortCallsGridData[]>([]);

  useEffect(() => {
    if (imoNumber) {
      getPortCalls();
    }
  }, [imoNumber]);

  const getPortCalls = async () => {
    let params;
    let url;
    if (isMovementRisk) {
      url = apiConfig.complianceMovementrisk + complianceCheckId;
    } else {
      url = apiConfig.portCalls;
      params = {
        imo: imoNumber,
        voyageDistanceRequired: findFeature('Ship.ViewDistanceInPortCalls'),
        carbonEmissionRequired: findFeature('Ship.ViewCarbonEmission'),
      };
    }
    await api
      .get(
        {
          url: url,
          params,
        },
        setIsLoading
      )
      .then((res) => {
        setPortCalls(res);
        setIsApiLoaded(true);
      })
      .catch((error) => {
        setIsApiLoaded(true);
        setIsApiError(true);

        if (error?.response?.data?.Message === 'AISDataNotFound') {
          toast.error({
            title: t('toast.liveTrackingNoDataTxt'),
          });
        }
      });
  };

  const onGridRefresh = async () => {
    const url = apiConfig.recalculatePortCall + imoNumber;
    await api.get(
      {
        url: url,
      },
      setIsLoading
    );
    getPortCalls();
  };

  const getNoDataText = useCallback(() => {
    if (isMovementRisk) {
      return isApiLoaded
        ? isApiError
          ? t('toast.liveTrackingNoDataTxt')
          : t('labels.movementRiskNoDataTxt')
        : t('labels.fetchingData');
    } else {
      return isApiLoaded
        ? isApiError
          ? t('toast.liveTrackingNoDataTxt')
          : t('labels.portCallsNoDataTxt')
        : t('labels.fetchingData');
    }
  }, [isApiLoaded, isApiError]);

  const gridConfig: IGridConfig = {
    dataSource: portCalls,
    remoteOperations: false,
    showExport: isDownloadVisible,
    showHeader: isDownloadVisible,
    excelFileName: 'PortCalls.xlsx',
    showRefreshButton: findFeature('Ship.RefreshPortCalls'),
    onRefreshClick: () => {
      onGridRefresh();
    },
    noDataText: getNoDataText(),
    defaultColumns: [
      {
        caption: t('labels.portName'),
        dataField: 'portName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.arrivalDate'),
        dataField: 'arrivalDate',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.departureDate'),
        dataField: 'departureDate',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.duration'),
        dataField: 'voyageDuration',
        dataType: 'string',
        alignment: 'left',
        minWidth: 150,
      },
      {
        caption: t('labels.distance'),
        dataField: 'voyageDistance',
        dataType: 'string',
        alignment: 'left',
        minWidth: 150,
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
        visible: !isMovementRisk && findFeature('Ship.ViewDistanceInPortCalls'),
      },
      {
        caption: t('labels.estimatedCarbonEmission'),
        headerCellTemplate: (headerElement: any) => {
          headerElement.innerHTML = `${t(
            'labels.estimatedCarbonEmission'
          )} (gCO&#8322;/ton)`;
        },
        dataField: 'carbonEmission',
        dataType: 'string',
        alignment: 'left',
        minWidth: 150,
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
        visible: !isMovementRisk && findFeature('Ship.ViewCarbonEmission'),
      },
    ],
  };
  return (
    <div className={'m-c-grid m-c-block-grid-list'}>
      <Grid gridConfig={gridConfig} />
    </div>
  );
}
