import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { usePermission } from '../../../../../contexts/PermissionContext';

export default function ComplianceForm(
  props: Readonly<{
    buId: string;
  }>
) {
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { control } = useFormContext();
  const { buId } = props;
  const maxValueValidation = (e: any) => {
    return e.value <= 24;
  };

  const complianceFormConfig = [
    {
      class:
        'col-lg-4 col-md-4 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'compliancePeriod',
      label: t('labels.compliancePeriod'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      showHelpIcon: true,
      id: 'compliancePeriod',
      helpText: t('toast.complianceCheckPeriodDesc'),
      maxLength: 100,
      disabled: buId
        ? !findFeature(
            'BUCompanyConfiguration.EditComplianceCheckPeriodSettings'
          )
        : !findFeature(
            'CompanyConfiguration.EditComplianceCheckPeriodSettings'
          ),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'custom',
          validationCallback: maxValueValidation,
          message: t('errors.maxTwentyFour'),
        },
      ],
    },
  ];
  return (
    <div className="row">
      <FormBuilder formConfig={complianceFormConfig} />
    </div>
  );
}
