import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { getAPIRoute } from '../../../../../utils/api-route';
import {
  SanctionAttribute,
  SanctionEntity,
} from '../../../../../enums/config-enum';
import { CompanyType } from '../../../../../enums/company-type-enum';

export default function SanctionForm(props: {
  buId?: any;
  companyId?: any;
  companyType?: any;
  selectedCompanyType?: any;
  companyTypeFlag?: any;
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { buId, companyId, selectedCompanyType } = props;

  const [managementCompanyList, setManagementCompanyList] = useState<any>([]);
  const [vesselList, setVesselList] = useState<any>([]);
  const [portList, setPortList] = useState<any>([]);
  const [noEntityList, setNoEntityList] = useState<any>([]);
  const [terminalList, setTerminalList] = useState<any>([]);
  const [berthList, setBerthList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);

  useEffect(() => {
    if (companyId) {
      getCountryList();
      getPortList();
      getVesselList();
      getManagementCompanyList();
      getBerthList();
      getTerminalList();
      if (selectedCompanyType !== CompanyType.Mars) {
        getNoEntityList();
      }
    }
  }, [companyId]);

  const getManagementCompanyList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Companies,
          SanctionAttribute.CompanyName,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setManagementCompanyList(res);
      });
  };

  const getVesselList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Ship,
          SanctionAttribute.IMODisplayName,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setVesselList(res);
      });
  };

  const getPortList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Port,
          SanctionAttribute.PortCode,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setPortList(res);
      });
  };

  const getNoEntityList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.None,
          SanctionAttribute.None,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setNoEntityList(res);
      });
  };

  const getTerminalList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Terminal,
          SanctionAttribute.TerminalName,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setTerminalList(res);
      });
  };

  const getBerthList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Berth,
          SanctionAttribute.BerthName,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setBerthList(res);
      });
  };

  const getCountryList = async () => {
    api
      .get({
        url: getAPIRoute('sanctionsList', [
          SanctionEntity.Countries,
          SanctionAttribute.CountryCode,
        ]),
        params: { companyId: companyId, buId: buId },
      })
      .then((res) => {
        setCountryList(res);
      });
  };

  const generalFormConfig = [
    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedCountryList',
      label:
        selectedCompanyType === 'M'
          ? t('labels.sanctionedCountryList')
          : t('labels.restrictedCountryList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: countryList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'sanctionedPortList',
      label:
        selectedCompanyType === 'M'
          ? t('labels.sanctionedPortList')
          : t('labels.restrictedPortList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: portList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedVesselList',
      label:
        selectedCompanyType === 'M'
          ? t('labels.sanctionedVesselList')
          : t('labels.restrictedTradingEntity'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource:
        selectedCompanyType === CompanyType.Mars ? vesselList : noEntityList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'restrictedVesselList',
      label: t('labels.restrictedVesselList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: vesselList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'sanctionedBareboatOwner',
      label: t('labels.restrictedBareboatOwner'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedDOCCompany',
      label: t('labels.restrictedDOCCompany'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedGroupOwner',
      label: t('labels.restrictedGroupOwner'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'sanctionedOperator',
      label: t('labels.restrictedOperator'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedRegisteredOwner',
      label: t('labels.restrictedRegisteredOwner'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'sanctionedShipManager',
      label: t('labels.restrictedShipManager'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'sanctionedTechnicalManager',
      label: t('labels.restrictedTechnicalManager'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: managementCompanyList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'approvedPortList',
      label: t('labels.approvedPortList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: portList,
    },

    {
      class:
        'col-lg-4 col-md-6 col-sm-12 col-xs-12 m-l-custom-sixteen-space-col',
      name: 'approvedTerminalList',
      label: t('labels.approvedTerminalList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: terminalList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'approvedBerthList',
      label: t('labels.approvedBerthList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: berthList,
    },

    {
      class: 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
      name: 'highRiskPortList',
      label: t('labels.highRiskPortList'),
      control: control,
      fieldType: 'select',
      displayExpr: 'listName',
      valueExpr: 'listId',
      dataSource: portList,
    },
  ];

  return (
    <div className="row">
      <FormBuilder formConfig={generalFormConfig} />
    </div>
  );
}
