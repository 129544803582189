import React, { useEffect, useMemo, useRef, useState } from 'react';
import List from 'devextreme-react/list';
import { useLocation, useNavigate } from 'react-router-dom';
import images from '../../../assets/images';
import icons from '../icons/icons';
import DropDownButton from 'devextreme-react/drop-down-button';
import { usePermission } from '../../../contexts/PermissionContext';
import { getCompanyId } from '../../../utils/jwt-decode';
import Tooltip from 'devextreme-react/tooltip';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';

const dropDownOptions = {
  width: 188,
  onShown: function (args: any) {
    args.component
      .content()
      .parentElement.parentElement.classList.add(
        'm-l-settings-dropdown-options-wrap'
      );
  },
};

export default function sidebarList() {
  const { findFeature, featureList } = usePermission();

  const navigate = useNavigate();

  const location = useLocation();

  const listRef = useRef<List>(null);

  const dropDownButtonRef = useRef<DropDownButton>(null);

  const [selectedSubMenu, setSelectedSubMenu] = useState<number | undefined>();

  const [reportHeaders, setReportHeaders] = useState<any[]>([]);

  const [reportId, setReportId] = useState();

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.split('/')[1];
      const selectedItem = actions.find((item) => {
        return path === item.path;
      });
      if (selectedItem) {
        const index = navigation.findIndex((item) => item.id === 8);
        listRef.current?.instance.selectItem(index);
      }
      let index = navigation.findIndex((item) => path === item.path);

      index =
        path === 'company-details'
          ? navigation.findIndex((item) => item.id === 7)
          : index;
      listRef.current?.instance.selectItem(index);
      setSelectedSubMenu(selectedItem?.id);
    }
  }, [location, featureList]);
  const isVetReportVisible = findFeature('Reports.VetReport');
  const isBerthFitReportVisible = findFeature('Reports.BerthFitReport');
  const getReportHeaders = async () => {
    await api
      .get({
        url: apiConfig.reportHeaders,
      })
      .then((response: any) => {
        response.filter((item: any) => item.reportCode == 'vr')[0].visible =
          isVetReportVisible;
        response.filter((item: any) => item.reportCode == 'bfr')[0].visible =
          isBerthFitReportVisible;
        setReportHeaders(response);
      });
  };

  useEffect(() => {
    getReportHeaders();
  }, [isVetReportVisible, isBerthFitReportVisible]);

  const navigation = useMemo(() => {
    return [
      { id: 1, icon: images.home, path: '', text: 'Home', key: 'home' },
      {
        id: 3,
        icon: images.vetRequestIcon,
        text: 'List Vet Requests',
        key: 'listVetRequests',
        path: 'list-vet-requests',
        visible: findFeature('VetRequest.Listing'),
      },
      {
        id: 9,
        icon: images.complianceList,
        text: 'Compliance Check',
        key: 'complianceCheckList',
        path: 'compliance-check-list',
        visible: findFeature('ComplianceCheck.Listing'),
      },
      {
        id: 10,
        icon: images.sanctionsCheck,
        text: 'Sanctions Check',
        key: 'sanctionsCheckList',
        path: 'sanctions-check-list',
        visible: findFeature('SanctionCheck.Listing'),
      },
      {
        id: 4,
        icon: images.EmailIcon,
        text: 'Email',
        key: 'email',
        path: 'email',
        visible:
          findFeature('Email.ViewInbox') || findFeature('Email.ViewOutbox'),
      },
      {
        id: 5,
        icon: images.ReportsIcon,
        text: 'Reports',
        key: 'report',
        // path: 'list',
        visible:
          findFeature('Reports.VetReport') ||
          findFeature('Reports.BerthFitReport'),
      },
      {
        id: 7,
        icon: images.dashboard,
        text: 'Company Details',
        key: 'companyDetails',
        path: 'company-details',
        visible: findFeature('Company.ViewDetail'),
        state: { companyId: getCompanyId() },
      },
      {
        id: 8,
        icon: images.settings,
        text: 'Settings',
        key: 'settings',
        path: 'list',
        visible:
          findFeature('Company.Listing') ||
          findFeature('GlobalRules.Listing') ||
          findFeature('RoleManagement.Listing') ||
          findFeature('GlobalList.Listing') ||
          findFeature('OptionType.ViewOption') ||
          // findFeature('GlobalBerthFit.View') ||
          findFeature('PortManagement.ViewPortManagement'),
      },
    ];
  }, [featureList]);

  const actions = useMemo(() => {
    return [
      {
        id: 4,
        text: 'Roles',
        path: 'roles',
        visible: findFeature('RoleManagement.Listing'),
      },
      {
        id: 3,
        text: 'Rules',
        path: 'rules',
        visible: findFeature('GlobalRules.Listing'),
      },
      {
        id: 2,
        text: 'Companies',
        path: 'company',
        visible: findFeature('Company.Listing'),
      },
      {
        id: 5,
        text: 'Lists',
        path: 'list',
        visible: findFeature('GlobalList.Listing'),
      },
      {
        id: 7,
        text: 'Berth Fit',
        path: 'berth-fit',
        visible:
          findFeature('GlobalBerthFit.ViewBerthFitParameters') ||
          findFeature('GlobalBerthFit.SaveBerthFitParameters') ||
          findFeature('GlobalBerthFit.ViewBerthFitInformation') ||
          findFeature('GlobalBerthFit.SaveBerthFitInformation'),
      },
      {
        id: 8,
        text: 'Port',
        path: 'port',
        visible: findFeature('PortManagement.ViewPortManagement'),
      },
      {
        id: 9,
        text: 'External Interfaces',
        path: 'external-interfaces',
        visible: findFeature('ExternalInterfaces.Listing'),
      },
      {
        id: 6,
        text: 'Option Value',
        path: 'option-value',
        visible: findFeature('OptionType.ViewOption'),
      },
    ];
  }, [featureList, location, selectedSubMenu]);

  const loadView = (e: any) => {
    if (e.itemData.id !== 8) {
      navigate(e.itemData.path, { state: e.itemData.state });
    }
  };

  const onClickSubmenu = (e: any) => {
    navigate(e.itemData.path);
  };

  const onClickSubmenuReports = (e: any) => {
    navigate('report', {
      state: {
        reportHeaderId: e.itemData.reportHeaderId,
        reportCode: e.itemData.reportCode,
        reportName: e.itemData.reportName,
      },
    });
  };

  const renderContent = (data: any) => {
    return <div className="m-l-sidebar-tooltip">{data.text}</div>;
  };

  const ListItem = (data: any) => {
    return (
      <div className="m-l-sidebar-item-wrapper" id={data.key}>
        <img
          src={data.icon}
          className="img-responsive m-l-sidebar-icon"
          alt="Logo"
        />
        <Tooltip
          target={`#${data.key}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          contentRender={() => renderContent(data)}
          position="right"
          // className='m-l-sidemenu-tooltip-wraper'
        />
        {data.id == 8 && (
          <DropDownButton
            ref={dropDownButtonRef}
            items={actions}
            keyExpr="id"
            displayExpr="text"
            dropDownOptions={dropDownOptions}
            className="m-l-settings-dropdown"
            onItemClick={onClickSubmenu}
            selectedItemKey={selectedSubMenu}
            useSelectMode={true}
          ></DropDownButton>
        )}

        {data.id == 5 && (
          <DropDownButton
            ref={dropDownButtonRef}
            items={reportHeaders}
            keyExpr="reportHeaderId"
            displayExpr="reportName"
            dropDownOptions={dropDownOptions}
            className="m-l-settings-dropdown"
            onItemClick={onClickSubmenuReports}
            selectedItemKey={selectedSubMenu}
            useSelectMode={true}
          ></DropDownButton>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="m-c-sidebar">
        <div className="m-l-sidebar-logo-block">
          <icons.MarsLogo />
        </div>

        <List
          ref={listRef}
          items={navigation}
          width={72}
          selectionMode="single"
          onItemClick={loadView}
          itemRender={ListItem}
          className="m-l-sidebar-icons-wrap"
        ></List>
      </div>
    </>
  );
}
