export enum ReportType {
  BerthFit = 'BerthFit',
  VetReport = 'VetReport',
  ComplianceReport = 'ComplianceReport',
  SanctionCheckReport = 'SanctionCheckReport',
}

export enum ReportTypeIndexEnum {
  VetReport = 0,
  BerthFit = 1,
  ComplianceReport = 2,
  SanctionCheck = 3,
}
