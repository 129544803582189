import React, { useEffect, useState } from 'react';
import { useMap, useMapEvents } from 'react-leaflet';
import L, { ControlPosition } from 'leaflet';
import { convertDecimalToMinutes } from '../../../../utils/coordinates';
import { createRoot } from 'react-dom/client';

const CoordinatesControl = ({
  position = 'bottomright',
}: {
  position?: ControlPosition;
}) => {
  const map = useMap();
  const [coordinates, setCoordinates] = useState({ lat: '--', lng: '--' });

  useMapEvents({
    mousemove: (e) => {
      setCoordinates({
        lat: e.latlng ? convertDecimalToMinutes(e.latlng?.lat, true) : '--',
        lng: e.latlng ? convertDecimalToMinutes(e.latlng?.lng) : '--',
      });
    },
  });

  useEffect(() => {
    const customControl = L.Control.extend({
      onAdd: function () {
        const div = L.DomUtil.create('div', 'custom-control');
        L.DomEvent.disableClickPropagation(div);
        const root = createRoot(div);
        root.render(coordinateContent(coordinates));

        return div;
      },
    });

    const controlInstance = new customControl({ position });
    map.addControl(controlInstance);

    return () => {
      map.removeControl(controlInstance);
    };
  }, [map, position, coordinates]);

  return null;
};

export default CoordinatesControl;

const coordinateContent = (coordinates: { lat: string; lng: string }) => {
  return (
    <div className="coordinates-control-wrapper">
      <div className="row">
        <div className="col-2">lat:</div>
        <div className="col-10">{coordinates.lat}</div>
      </div>
      <div className="row">
        <div className="col-1">lng:</div>
        <div className="col-10">{coordinates.lng}</div>
      </div>
    </div>
  );
};
