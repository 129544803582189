import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L, { ControlPosition } from 'leaflet';
import { createRoot } from 'react-dom/client';
import images from '../../../../assets/images';
import { Legend, mapColors } from '../../../../types/map';

const LegendsControl = ({
  position = 'topright',
  legendList,
}: {
  legendList: Legend[];
  position?: ControlPosition;
}) => {
  const map = useMap();

  useEffect(() => {
    const customControl = L.Control.extend({
      onAdd: function () {
        const div = L.DomUtil.create('div', 'custom-control');
        L.DomEvent.disableClickPropagation(div);
        const root = createRoot(div);
        root.render(legendsContent(legendList));

        return div;
      },
    });

    const controlInstance = new customControl({ position });
    map.addControl(controlInstance);

    return () => {
      map.removeControl(controlInstance);
    };
  }, [map, position, legendList]);

  return null;
};

export default LegendsControl;

const legendsContent = (legendsData: Legend[]) => {
  return (
    <div className="legends-control-wrapper">
      {legendsData?.map((item) => {
        return (
          <div className="legends-list" key={item.label}>
            {item.iconUrl && (
              <img className="image-block" src={item.iconUrl} alt="" />
            )}
            {item.isLine && (
              <div className="image-block">
                <div
                  className="line-block"
                  style={{
                    border: item.isDashed
                      ? `2px dashed ${item.color}`
                      : `2px solid ${item.color}`,
                  }}
                ></div>
              </div>
            )}
            {item.isMultiline && (
              <div className="image-block">
                <div
                  className="line-block u-mb-sm"
                  style={{
                    border: `2px dashed ${mapColors.blue}`,
                  }}
                ></div>
                <div
                  className="line-block"
                  style={{
                    border: `2px dashed ${mapColors.red}`,
                  }}
                ></div>
              </div>
            )}
            <div> {item.label}</div>
          </div>
        );
      })}
    </div>
  );
};
