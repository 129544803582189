import React, { useEffect, useMemo, useState } from 'react';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import countryList from '../../../components/Country.json';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { Button } from 'devextreme-react/button';
import icons from '../../common/icons/icons';
import { Popover } from 'devextreme-react/popover';
import { useResize } from '../../../hooks/useResize';

export default React.memo(function RecentVesselsGadget() {
  const { setIsLoading } = useLoaderContext();
  const [recentVessels, setRecentVessels] = useState<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [gridInstance, setGridInstance] = useState<any>();
  const [showExportPopover, setShowExportPopover] = useState(false);
  const exportPopupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-dashboard__chart-export',
      'data-testid': 'rolePopover',
    };
  }, []);

  const onRowClick = (e: any) => {
    navigate('/vessel-info', {
      state: { imoNumber: e.key.imoNumber },
    });
  };

  useEffect(() => {
    getRecentVesselsGadget();
  }, []);

  const getRecentVesselsGadget = async () => {
    await api
      .get({ url: apiConfig.recentVessels }, setIsLoading)
      .then((data: any) => {
        setRecentVessels(data.recentVessels);
      });
  };

  const onGridInitialized = (e: any) => {
    setGridInstance(e.component);
  };

  const gridConfig: IGridConfig = {
    dataSource: recentVessels,
    defaultColumns: [
      {
        name: t('labels.vesselName'),
        dataField: 'vesselName',
        dataType: 'string',
        minWidth: 50,
        caption: t('labels.vesselName'),
      },
      {
        name: t('labels.vesselType'),
        dataField: 'vesselType',
        dataType: 'string',
        minWidth: 50,
        caption: t('labels.vesselType'),
      },
      {
        caption: t('labels.flag'),
        dataField: 'flagName',
        dataType: 'string',
        minWidth: 100,
        cellTemplate: 'cellTemplate',
      },
    ],
    rowClick: onRowClick,
    hideFilterRow: true,
    hidePagination: true,
    initialized: onGridInitialized,
  };

  const fileFormats = [
    {
      id: 1,
      name: 'Excel File',
      value: 'xlsx',
    },
    {
      id: 2,
      name: 'PDF File',
      value: 'pdf',
    },
  ];

  const exportRolesGrid = (format: any) => {
    if (format === 'xlsx') {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');

      exportDataGrid({
        component: gridInstance,
        worksheet: worksheet,
      }).then(function () {
        workbook.xlsx.writeBuffer().then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `RecentVessels.${format}`
          );
        });
      });
    } else if (format === 'pdf') {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: gridInstance,
      }).then(() => {
        doc.save('RecentVessels.pdf');
      });
    }
  };

  const { isMobileResolution } = useResize();
  const [position, setPosition] = useState<any>('bottom');
  useEffect(() => {
    if (isMobileResolution) {
      setPosition('left');
    } else {
      setPosition('bottom');
    }
  }, [isMobileResolution]);

  return (
    <div className="m-c-grid m-c-block-grid-list">
      <div className="m-c-form-group u-mb0">
        <div className="row">
          <div className="col-12 d-flex pe-4 ps-4">
            <div className="ml-dashboard-export">
              <Button
                id="recentVessel"
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                onClick={() => setShowExportPopover(true)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              <Popover
                target="#recentVessel"
                position={position}
                width={132}
                visible={showExportPopover}
                wrapperAttr={exportPopupAttributes}
                closeOnOutsideClick={true}
                onHiding={() => setShowExportPopover(false)}
              >
                <ul className="ml-expport-list">
                  {/* <li>Print</li> */}
                  {fileFormats.map((role: any, index: any) => {
                    return (
                      <li
                        key={index}
                        onClick={() => exportRolesGrid(role.value)}
                      >
                        {role.name}
                      </li>
                    );
                  })}
                </ul>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <Grid gridConfig={gridConfig} cellTemplate={flagCellTemplate} />
    </div>
  );
});

const flagCellTemplate = (data: any) => {
  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == data.data?.iso3
  );

  return (
    <div>
      <img
        style={{ width: '33px', borderRadius: '5px' }}
        src={filteredCountryList?.flag}
        alt="flag"
      />
      <span className="flag-name">{data.data?.flagName}</span>
    </div>
  );
};
