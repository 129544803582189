import {
  contentReady,
  setConfig,
  ToastComponent,
} from '../components/common/toast-component/ToastComponent';
import notify from 'devextreme/ui/notify';
export default {
  success(toastConfig: IToastConfig) {
    setConfig({
      title: toastConfig.title,
      message: toastConfig.message,
      type: 'success',
      contentReady: contentReady,
    });
    notify(
      {
        ...defaultConfig,
        contentTemplate: ToastComponent,
        ...toastConfig,
        onContentReady: contentReady,
      },
      'custom'
    );
  },
  custom(toastConfig: IToastConfig) {
    setConfig({
      title: toastConfig.title,
      message: toastConfig.message,
      type: 'success',
      contentReady: contentReady,
    });
    notify(
      {
        ...defaultConfig,
        contentTemplate: ToastComponent,
        ...toastConfig,
        onContentReady: contentReady,
      },
      'custom'
    );
  },
  error(toastConfig: IToastConfig) {
    setConfig({
      title: toastConfig.title,
      message: toastConfig.message,
      type: 'error',
      contentReady: contentReady,
    });
    notify(
      {
        ...defaultConfig,
        contentTemplate: ToastComponent,
        ...toastConfig,
        onContentReady: contentReady,
      },
      'custom'
    );
  },
  info(toastConfig: IToastConfig) {
    notify({ ...defaultConfig, ...toastConfig }, 'info');
  },
  warning(toastConfig: IToastConfig) {
    setConfig({
      title: toastConfig.title,
      message: toastConfig.message,
      type: 'warning',
      contentReady: contentReady,
    });
    notify(
      {
        ...defaultConfig,
        contentTemplate: ToastComponent,
        ...toastConfig,
        onContentReady: contentReady,
      },
      'custom'
    );
  },
};

export interface IToastConfig {
  title?: string;
  message?: string;
  type?: string;
  contentTemplate?: any;
  position?: string;
  displayTime?: number;
  animation?: any;
}

const defaultConfig: IToastConfig = {
  title: '',
  message: '',
  type: '',
  position: 'bottom right',
  displayTime: 5000,
  animation: null,
};
