import React from 'react';
import { IGridConfig } from '../../../types/grid';
import Grid from '../../common/grid/Grid';
import { useTranslation } from 'react-i18next';
import { Events } from '../../../types/compliance-check';
import { ComplianceTabType } from '../../../enums/compliance-check-enum';
import { convertDecimalToMinutes } from '../../../utils/coordinates';

export default function EventGrid(
  props: Readonly<{ complianceType: number; eventData: Events[] }>
) {
  const { t } = useTranslation();

  const { complianceType, eventData } = props;

  const portCellTemplate = (data: { data: Events }) => {
    return (
      <>
        {data.data.nearByPort?.map((item) => {
          return (
            <div className="port-template">
              <i className="dx-icon-warning custom-icon"></i>
              {item.portName}
            </div>
          );
        })}
      </>
    );
  };

  const actionTemplate = (data: { data: Events }) => {
    if (!data.data.STSProneArea) return null;

    return (
      <div className="port-template">
        <i className="dx-icon-warning custom-icon"></i>
        {data.data.STSProneArea}
      </div>
    );
  };

  const gridConfig: IGridConfig = {
    dataSource: eventData,
    remoteOperations: false,
    showSelection: false,
    hidePagination: true,
    hideFilterRow: true,
    defaultColumns: [
      {
        caption: t('labels.event'),
        dataType: 'string',
        dataField: 'event',
        minWidth: 150,
      },
      {
        caption: t('labels.proximityVessel'),
        dataType: 'string',
        dataField: 'vesselInContact',
        minWidth: 150,
        visible: complianceType === ComplianceTabType.STS,
      },
      {
        caption: t('labels.stsProneArea'),
        dataType: 'string',
        dataField: 'STSProneArea',
        cellTemplate: 'actionTemplate',
        minWidth: 150,
        visible: complianceType === ComplianceTabType.STS,
      },
      {
        caption: t('labels.utcDataTime'),
        dataField: 'dateTime',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.coordinates'),
        dataField: 'coordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: Events) => {
          return data.coordinates?.lat
            ? `${convertDecimalToMinutes(
                data.coordinates.lat,
                true
              )} / ${convertDecimalToMinutes(data.coordinates.lon)}`
            : '';
        },
      },
      {
        caption: t('labels.nearByPorts'),
        dataField: 'nearbyPorts',
        dataType: 'string',
        cellTemplate: 'cellTemplate',
        minWidth: 150,
        visible: complianceType === ComplianceTabType.AISGap,
      },
      {
        caption: t('labels.destination'),
        dataType: 'string',
        dataField: 'destination',
        minWidth: 150,
        visible: complianceType === ComplianceTabType.Loitering,
      },
    ],
  };
  return (
    <Grid
      gridConfig={gridConfig}
      cellTemplate={portCellTemplate}
      actionTemplate={actionTemplate}
    />
  );
}
