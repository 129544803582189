import React, { useRef, useState } from 'react';
import icons from '../../../common/icons/icons';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import {
  VesselLiveTrackingReduceAISPointsValues,
  VesselLiveTrackingVesselNavigationValues,
} from '../../../../enums/VesselLiveTrackingVesselNavigationValue';

export default function LiveFilterIcon(
  props: Readonly<{
    selectedAIS?: string;
    selectedNavigation?: string;
    setSelectedAIS?: (value: string) => void;
    setSelectedNavigation?: (value: string) => void;
    getLiveTrackingData?: (e: boolean) => void;
    liveFilters?: (
      startDate: string,
      endDate: string,
      VesselNavigation: number,
      ReduceAISPoint: number
    ) => void;
    portCallDuration?: number;
  }>
) {
  const { liveFilters, portCallDuration = 2 } = props;

  const [showPopover, setShowPopover] = useState(false);
  const [aisReduction, setAisReduction] = useState<number>(0);
  const [vesselNavigation, setVesselNavigation] = useState<number>(0);
  const [showCustomFields, setShowCustomFields] = useState(false);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [validationError, setValidationError] = useState<string | null>(null);
  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const { t } = useTranslation();

  const maxToDate = fromDate
    ? new Date(
        new Date(fromDate).getTime() +
          (portCallDuration ?? 2) * 365 * 24 * 60 * 60 * 1000
      )
        .toISOString()
        .split('T')[0]
    : '';

  const currentDate = new Date();
  const minAllowedDate = new Date(
    currentDate.getFullYear() - portCallDuration,
    currentDate.getMonth(),
    currentDate.getDate()
  )
    .toISOString()
    .split('T')[0];

  const maxAllowedDate = currentDate.toISOString().split('T')[0];

  const handleSave = async () => {
    if (vesselNavigation === 10) {
      const earliestSelectableDate = new Date(minAllowedDate);
      const latestSelectableDate = new Date(maxAllowedDate);
      const selectedFromDate = new Date(fromDate);
      const selectedToDate = new Date(toDate);

      if (!fromDate && !toDate) {
        setValidationError('Both From Date and To Date are required.');
        return;
      }
      if (!fromDate) {
        setValidationError('From Date is required.');
        return;
      }
      if (!toDate) {
        setValidationError('To Date is required.');
        return;
      }
      if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
        setValidationError('From Date cannot be greater than To Date.');
        return;
      }
      if (
        selectedFromDate < earliestSelectableDate ||
        selectedFromDate > latestSelectableDate
      ) {
        setValidationError(
          `Date range must be within the last ${portCallDuration} Years.`
        );
        return;
      }
      if (
        selectedToDate < selectedFromDate ||
        selectedToDate > latestSelectableDate
      ) {
        setValidationError(
          `Date range must be within the last ${portCallDuration} Years.`
        );
        return;
      }
    }

    setValidationError(null);

    if (liveFilters) {
      liveFilters(fromDate, toDate, vesselNavigation, aisReduction);
    }
  };

  const handleToDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedToDate = e.target.value;
    if (fromDate && new Date(selectedToDate) > new Date(maxToDate)) {
      setValidationError(
        `The date range should not exceed more than ${portCallDuration} years.`
      );
    } else {
      setValidationError(null);
    }
    setToDate(selectedToDate);
  };

  const handleClosePopover = () => {
    setShowPopover(false);
  };

  return (
    <div className="live-filter-wrapper">
      <button
        ref={filterButtonRef}
        className="live-filter-button"
        onClick={() => setShowPopover((prev) => !prev)}
      >
        <icons.liveFilter />
      </button>

      {showPopover && (
        <div className="live-filter-dropdown">
          {/* <label>AIS Points Reduction Filter: </label>
          <select
            value={aisReduction}
            onChange={(e) => {
              setAisReduction(Number(e.target.value));
            }}
          >
            {VesselLiveTrackingReduceAISPointsValues.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select> */}

          <div className="m-l-confirm-dialogbox-header">
            <Button
              icon="close"
              onClick={handleClosePopover}
              className="close-button"
            />
          </div>

          <label>Navigation History For:</label>
          <select
            value={vesselNavigation}
            onChange={(e) => {
              setVesselNavigation(Number(e.target.value));
              setShowCustomFields(e.target.value === '10');
            }}
          >
            {VesselLiveTrackingVesselNavigationValues.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>

          {showCustomFields && (
            <div className="custom-date-fields">
              <label>
                From Date:
                <input
                  type="date"
                  placeholder="Enter start date"
                  value={fromDate}
                  min={minAllowedDate}
                  max={maxAllowedDate}
                  onChange={(e) => setFromDate(e?.target?.value)}
                  required
                />
              </label>
              <label>
                To Date:
                <input
                  type="date"
                  placeholder="Enter end date"
                  value={toDate}
                  min={fromDate || minAllowedDate}
                  max={maxAllowedDate}
                  onChange={handleToDateChange}
                  required
                />
              </label>
              {validationError && (
                <div className="validation-error">{validationError}</div>
              )}
            </div>
          )}

          <br />

          <Button className="app-c-btn app-c-btn--primary" onClick={handleSave}>
            {t('labels.apply')}
          </Button>
        </div>
      )}
    </div>
  );
}
