import React, { useEffect, useMemo, useState } from 'react';
import { VesselDetails } from '../../../types/vessel-general';
import { IModalConfig } from '../../../types/modal';
import { useTranslation } from 'react-i18next';
import Modal from '../../common/modal/Modal';
import Button from 'devextreme-react/button';
import icons from '../../common/icons/icons';
import MarsMap from '../../common/map-components/map/Map';
import { Markers, PolylineConfig } from '../../../types/map';
import LiveTrackingInfoWindow from '../live-tracking-info-window/LiveTrackingInfoWindow';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { VesselPositionLive } from '../../../types/live-tracking';
import { LatLngExpression } from 'leaflet';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { getCompliance } from '../live-tracking/LiveTrackingComplianceConfiguration';
import { IComplianceConfig } from '../../../types/compliance-config';
import { mapInfoWindow } from '../../compliance-check/map-info-window/MapInfoWindow';

export default function LiveTrackingModal(
  props: Readonly<{
    isVisible: boolean;
    vesselDetails: VesselDetails;
    flagUrl: string;
    imoNumber: string;
    setIsVisible: (value: boolean) => void;
    companyId?: string;
    buId?: string;
  }>
) {
  const {
    isVisible,
    vesselDetails,
    flagUrl,
    imoNumber,
    setIsVisible,
    companyId,
    buId,
  } = props;

  const modalConfig: IModalConfig = {
    width: 1500,
    height: 800,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const [markers, setMarkers] = useState<Markers[]>([]);
  const [polylineConfig, setPolylineConfig] = useState<PolylineConfig[]>([]);
  const [liveTrackingData, setLiveTrackingData] =
    useState<VesselPositionLive>();
  const [showNoDataFound, setShowNoDataFound] = useState<boolean>(false);
  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const [complianceData, setComplianceData] = useState<IComplianceConfig[]>([]);
  const [portCallDuration, setPortCallDuration] = useState<number>(0);

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);

  useEffect(() => {
    const fetchComplianceData = async () => {
      const data = await getCompliance(companyId, buId, setIsLoading);
      setComplianceData(data);

      const portCallDurationSetting = data.find(
        (item) => item.settingType == 'PortCallDuration'
      );
      if (portCallDurationSetting) {
        setPortCallDuration(Number(portCallDurationSetting.settingValue));
      }
    };
    fetchComplianceData();
  }, [companyId, buId, setIsLoading]);

  useEffect(() => {
    setTimeout(() => {
      getLiveTrackingData();
    }, 400);
    const interval = setInterval(() => {
      getLiveTrackingData();
    }, 40 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setMapData();
  }, [liveTrackingData]);

  const getLiveTrackingData = async (
    fromDate?: string,
    toDate?: string,
    VesselLiveTrackingVesselNavigationValues?: number,
    VesselLiveTrackingReduceAISPointsValues?: number
  ) => {
    const params: Record<string, any> = { imo: imoNumber };
    if (fromDate) params.startDate = fromDate;
    if (toDate) params.endDate = toDate;

    if (VesselLiveTrackingVesselNavigationValues !== undefined) {
      params.vesselNavigation = VesselLiveTrackingVesselNavigationValues;
    }
    if (VesselLiveTrackingReduceAISPointsValues !== undefined) {
      params.reduceAISPoints = VesselLiveTrackingReduceAISPointsValues;
    }

    await api
      .get({ url: apiConfig.liveTracking, params }, setIsLoading)
      .then((res) => {
        if (res) {
          setLiveTrackingData(res);
        } else {
          setShowNoDataFound(true);
        }
      });
  };

  const setMapData = () => {
    if (liveTrackingData) {
      const lastPriorPosition =
        liveTrackingData.priorPositions[
          liveTrackingData.priorPositions.length - 1
        ];
      const isSameDate =
        lastPriorPosition &&
        new Date(lastPriorPosition.lastPositionUTC).toDateString() ===
          new Date(liveTrackingData.lastPositionUtc).toDateString();

      const mainMarker: Markers = {
        iconName: 'arrowSymbol',
        iconRotation: liveTrackingData.heading,
        coordinates: {
          lat: liveTrackingData.lat,
          lng: liveTrackingData.lon,
        },
        infoWindowContent: LiveTrackingInfoWindow(
          liveTrackingData,
          vesselDetails,
          flagUrl,
          t
        ),
      };

      const portCallMarkers: Markers[] = liveTrackingData.priorPositions
        .filter((position) => position.hasPortCall)
        .map((position) => ({
          iconName: 'portCallSymbol',
          iconRotation: position.heading,
          coordinates: {
            lat: position.lat,
            lng: position.lon,
          },
          infoWindowContent: mapInfoWindow(
            position,
            vesselDetails?.vesselName || '',
            vesselDetails,
            t
          ),
        }));

      setMarkers([mainMarker, ...portCallMarkers]);

      const polylineSegments: PolylineConfig[] = [];
      let currentSegment: LatLngExpression[] = [];
      let currentIsDashed =
        liveTrackingData.priorPositions.length > 0
          ? liveTrackingData.priorPositions[0].hasAISGap
          : false;

      for (let i = 0; i < liveTrackingData.priorPositions.length; i++) {
        const { lat, lon, hasAISGap } = liveTrackingData.priorPositions[i];

        if (hasAISGap !== currentIsDashed && currentSegment.length > 0) {
          polylineSegments.push({
            position: [...currentSegment],
            isDashedLine: currentIsDashed,
          });
          currentSegment = [currentSegment[currentSegment.length - 1]];
        }

        currentSegment.push({ lat: lat, lng: lon });
        currentIsDashed = hasAISGap;
      }

      if (isSameDate) {
        currentSegment.push({
          lat: liveTrackingData.lat,
          lng: liveTrackingData.lon,
        });
      }

      if (currentSegment.length > 0) {
        polylineSegments.push({
          position: [...currentSegment],
          isDashedLine: currentIsDashed,
        });
      }
      setPolylineConfig(polylineSegments);
    }
  };

  return (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <h2 className="modal-title">{t('headers.liveTracking')}</h2>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={() => setIsVisible(false)}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>

      <div className="m-l-modal__body">
        {!!markers.length && (
          <MarsMap
            markers={markers}
            closePopupOnOutsideClick={true}
            polylineConfig={polylineConfig}
            liveFilter={getLiveTrackingData}
            isFilterVisible={true}
            zoom={6}
            complianceSetting={complianceData}
            portCallDuration={portCallDuration}
          />
        )}
        {showNoDataFound && (
          <div className="live-tracking-no-data-text">
            {t('toast.liveTrackingNoDataTxt')}
          </div>
        )}
      </div>
      <div className="m-l-modal__footer">
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width"
          onClick={() => setIsVisible(false)}
        >
          {t('buttons.close')}
        </Button>
      </div>
    </Modal>
  );
}
