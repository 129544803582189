export enum VesselInfoTabIndex {
  VesselDetails = 0,
  BerthFitHistory = 1,
  VetRequestDetail = 2,
  VetRequestHistory = 3,
  Rules = 4,
  BerthFit = 5,
  ComplianceCheck = 6,
  SanctionsCheck = 7,
  Email = 8,
  ComplianceHistory = 9,
  SanctionsCheckHistory = 10,
  Attachments = 11,
  Recommendation = 12,
  BerthFitCheck = 13,
}
