export const VesselLiveTrackingVesselNavigationValues = [
  { label: 'Default', value: 0 },
  { label: 'Last Week', value: 7 },
  { label: 'Last Two Weeks', value: 14 },
  { label: 'Last Month', value: 30 },
  { label: 'Last Two Months', value: 60 },
  { label: 'Last Three Months', value: 90 },
  { label: 'Last Four Months', value: 120 },
  { label: 'Last Five Months', value: 150 },
  { label: 'Last Six Months', value: 180 },
  { label: 'Last One Year', value: 1 },
  { label: 'Custom', value: 10 },
];

export const VesselLiveTrackingReduceAISPointsValues = [
  { label: 'Default', value: 0 },
  { label: '30 Mins', value: 30 },
  { label: '1 Hour', value: 60 },
  { label: '2 Hours', value: 120 },
  { label: '3 Hours', value: 180 },
  { label: '4 Hours', value: 240 },
  { label: '5 Hours', value: 300 },
];
