import L, {
  DivIcon,
  Icon,
  IconOptions,
  LatLng,
  LatLngExpression,
  LeafletEvent,
} from 'leaflet';
import { IComplianceConfig } from './compliance-config';

export type Markers = {
  coordinates: LatLngExpression;
  iconName?: MarkerIcon;
  iconRotation?: number;
  markerColor?: string;
  label?: string;
  polygonName?: string;
  infoWindowContent?: JSX.Element;
};

export type MapProps = {
  markers?: Markers[] | undefined;
  isDrawVisible?: boolean;
  isDeleteVisible?: boolean;
  zoom?: number;
  center?: LatLngExpression;
  polygonConfig?: PolygonConfig[];
  circleConfig?: CircleConfig[];
  setCircleConfig?: (e: any) => void;
  showPolygonSaveModal?: boolean;
  showCircleSaveModal?: boolean;
  polylineConfig?: PolylineConfig[];
  legendsList?: Legend[];
  drawEventCallBack?: (e: L.DrawEvents.Created) => void;
  setShowPolygonSaveModal?: (e: boolean) => void;
  setShowCircleSaveModal?: (e: boolean) => void;
  handleSaveClick?: (boundaryName: string, shape: string) => void;
  handleEditClick?: (e: any) => void;
  handleDeleteClick?: (e: any) => void;
  handlePolygonRemove?: (e: LeafletEvent) => void;
  isCustomIconVisible?: boolean;
  closePopupOnOutsideClick?: boolean;
  handleCircleDraw?: (
    lat: number,
    lng: number,
    radius: number,
    areaName: string
  ) => void;
  onEditStart?: (e: boolean) => void;
  liveFilter?: (
    startDate: string,
    endDate: string,
    VesselNavigation: number,
    ReduceAISPoint: number
  ) => void;
  isFilterVisible?: boolean;
  complianceSetting?: IComplianceConfig[];
  portCallDuration?: number;
};

export type MapDrawProps = {
  isVisible?: boolean;
  isDeleteVisible?: boolean;
  polygonConfig?: PolygonConfig[];
  circleConfig?: CircleConfig[];
  showPolygonSaveModal?: boolean;
  showCircleSaveModal?: boolean;
  drawEventCallBack?: (e: L.DrawEvents.Created) => void;
  setShowPolygonSaveModal?: (e: boolean) => void;
  setShowCircleSaveModal?: (e: boolean) => void;
  handleSaveClick?: (boundaryName: string, shape: string) => void;
  handleEditClick?: (e: any) => void;
  handleDeleteClick?: (e: any) => void;
  handlePolygonRemove?: (e: LeafletEvent) => void;
  isCustomIconVisible?: boolean;
  handleCircleDraw?: (
    lat: number,
    lng: number,
    radius: number,
    areaName: string
  ) => void;
  onEditStart?: (e: boolean) => void;
};

export type MarkerProps = {
  position: LatLngExpression;
  icon?: DivIcon | Icon<IconOptions>;
  infoWindowContent?: JSX.Element | null;
};

export type PolygonConfig = {
  polygonCoordinates: [number, number][];
  polygonName: string;
};

export type CircleConfig = {
  stsHighProneAreaId?: string;
  coordinates?: LatLng;
  lat?: number;
  lng?: number;
  radius: number;
  areaName: string;
  status?: boolean;
  companyType?: string;
  stsIdentifiedTillDate?: string;
};

export type CirclePayload = {
  stsHighProneAreaId?: string | number;
  lat?: number;
  lng?: number;
  radius: number;
  areaName: string;
  buId: string;
  companyId?: string;
};

export type PolylineConfig = {
  position: LatLngExpression[];
  strokeColor?: string;
  isDashedLine?: boolean;
};

export type Legend = {
  iconUrl?: string;
  label: string;
  isDashed?: boolean;
  isLine?: boolean;
  color?: string;
  isMultiline?: boolean;
};

export type MarkerIcon =
  | 'arrowSymbol'
  | 'aisGapStart'
  | 'aisGapEnd'
  | 'anchorageStart'
  | 'anchorageEnd'
  | 'label'
  | 'arrowSymbolBlue'
  | 'loiteringEnd'
  | 'loiteringStart'
  | 'stsStart'
  | 'stsEnd'
  | 'portCallSymbol';

export const mapColors = {
  blue: '#007BFF',
  red: '#da0b1d',
};
