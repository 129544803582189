import Button from 'devextreme-react/button';
import FileUploader from 'devextreme-react/file-uploader';
import ProgressBar from 'devextreme-react/progress-bar';
import ScrollView from 'devextreme-react/scroll-view';
import TextBox from 'devextreme-react/text-box';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IModalConfig } from '../../../types/modal';
import toast from '../../../utils/toast';
import icons from '../icons/icons';
import Modal from '../modal/Modal';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../confirm/ConfirmDialog';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { getAPIRoute } from '../../../utils/api-route';
import { getCompanyId } from '../../../utils/jwt-decode';

export default function FileUploaderModal(props: {
  isVisible: boolean;
  handleCancelClick: any;
  attachmentType?: any;
  attachmentTypeVet?: any;
  getUploadedFiles?: any;
  vetRequestId?: any;
  listId?: any;
  setShowUploadError?: any;
  setUploadedFilesError?: any;
  getListById?: any;
  imoNumberFromCreateVet?: any;
  attachmentTypeBerth?: any;
  berthFitId?: any;
  setAttachmentsAdded?: any;
  attachmentsAdded?: any;
  isFromCompanyInfo?: any;
  getCompanyDetails?: any;
  isFromVetCreation?: boolean;
  tempVetId?: string;
  setTempVetId?: any;
  companyIdLogo?: any;
  isFromPort?: boolean;
  portCode?: any;
  terminalId?: any;
  isFromSanctionsConfig?: boolean;
  handleSanctionCallBack?: () => void;
}) {
  const {
    handleCancelClick,
    attachmentType,
    isVisible,
    attachmentTypeVet,
    getUploadedFiles,
    vetRequestId,
    listId,
    setShowUploadError,
    setUploadedFilesError,
    getListById,
    imoNumberFromCreateVet,
    attachmentTypeBerth,
    berthFitId,
    setAttachmentsAdded,
    attachmentsAdded,
    isFromCompanyInfo,
    getCompanyDetails,
    isFromVetCreation,
    tempVetId,
    setTempVetId,
    companyIdLogo,
    isFromPort,
    portCode,
    terminalId,
    isFromSanctionsConfig,
    handleSanctionCallBack,
  } = props;

  const modalConfig: IModalConfig = {
    width: 418,
    height: 495,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const modalConfig2: IModalConfig = {
    width: 1011,
    height: 455,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const { t } = useTranslation();
  const [imoNumber, setImoNumber] = useState<string>();
  const location = useLocation();
  const [isDropZoneActive, SetisDropZoneActive] = React.useState(false);
  const [progressVisible, SetprogressVisible] = React.useState(false);
  const [progressValue, SetprogressValue] = React.useState(0);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const fileUploaderRef = useRef<FileUploader>(null);
  const [attachmentFileLengthConfig, setAttachmentFileLengthConfig] =
    useState<any>([]);
  const [attachmentFileTypesConfig, setAttachmentFileTypesConfig] =
    useState<any>([]);
  const [sizeLimit, setSizeLimit] = useState<number>();
  const companyId = getCompanyId();

  useEffect(() => {
    if (location.state) {
      const { imoNumber }: any = location.state;
      setImoNumber(imoNumber);
    }
  }, [location.state]);
  useEffect(() => {
    getAttachmentConfig();
  }, []);
  const onDropZoneEnter = (e: any) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      SetisDropZoneActive(true);
    }
  };

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      onClickAttach();
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: listId
      ? t('toast.cancelUpload')
      : t('toast.cancelWithoutSavingAttachment'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      handleCancelClick(false);
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };

  const getAttachmentConfig = async () => {
    await api
      .get({
        url: apiConfig.attachmentsSettings,
        params: { settingArea: 'Global' },
      })
      .then((data) => {
        const maxLengthConfig = data.find(
          (item: any) => item.settingType === 'FileMaxLength'
        );
        const fileType = data.find(
          (item: any) => item.settingType === 'AllowedFileTypes'
        );
        setAttachmentFileLengthConfig(maxLengthConfig);
        setAttachmentFileTypesConfig(fileType);
      });
  };

  const onClickSave = (e: any) => {
    if (getUploadedFiles) {
      onClickAttach();
    } else {
      setShowConfirmSave(true);
    }
  };

  const fileRename = (e: any, index: any) => {
    const fileRename = new File([uploadedFiles[index]], e.value, {
      type: uploadedFiles[index].type,
    });
    const tempFiles = uploadedFiles.slice();
    tempFiles.splice(index, 1, fileRename);
    setUploadedFiles(tempFiles);
  };

  const onClickBack = () => {
    uploadedFiles.length ? setShowConfirmBack(true) : handleCancelClick();
  };

  const allowedFileExtensions = [
    'pdf',
    'jpg',
    'jpeg',
    'png',
    'doc',
    'xls',
    'xlsx',
    'docx',
  ];

  const allowedFileExtensions2 = ['xls', 'xlsx'];

  const allowedFileExtensionsLogo = ['jpg', 'jpeg', 'png'];

  const uploadUrl =
    process.env.REACT_APP_BASE_URL + 'attachments?attachmentType=1';

  const onDropZoneLeave = (e: any) => {
    if (e.dropZoneElement.id === 'dropzone-external') {
      SetisDropZoneActive(false);
    }
  };

  const onValueChanged = (e: any) => {
    setUploadedFiles(e.value);
  };

  const onUploaded = (e: any) => {
    const { file } = e;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      SetisDropZoneActive(false);
    };
    fileReader.readAsDataURL(file);
    SetprogressVisible(false);
    SetprogressValue(0);
  };

  const onProgress = (e: any) => {
    SetprogressValue((e.bytesLoaded / e.bytesTotal) * 100);
  };

  const onUploadStarted = () => {
    SetprogressVisible(true);
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-modal__fileupload',
    };
  }, []);

  const removeFile = (index: number) => {
    const tempFiles = uploadedFiles.slice();
    tempFiles.splice(index, 1);
    setUploadedFiles(tempFiles);
  };

  const onClickAttach = () => {
    let invalidType = false;
    let invalidTypeForList = false;
    let invalidsize = false;
    let isFileAttached = false;

    let allowedExtensions = attachmentFileTypesConfig?.settingValue.split(',');
    allowedExtensions = allowedExtensions?.map((x: any) => {
      return x.toLowerCase().replace('.', '');
    });

    uploadedFiles.map((file: any) => {
      const extension = file.name.split('.').pop().toLowerCase();
      const maxSize = attachmentFileLengthConfig?.settingValue * 1000000;
      setSizeLimit(attachmentFileLengthConfig?.settingValue);

      if (uploadedFiles.length > 0) {
        isFileAttached = true;
      }
      if (!allowedExtensions?.includes(extension)) {
        isFromCompanyInfo || listId
          ? (invalidType = false)
          : (invalidType = true);
      }
      if (listId && !allowedFileExtensions2.includes(extension)) {
        invalidTypeForList = true;
      }
      if (isFromCompanyInfo && !allowedFileExtensionsLogo.includes(extension)) {
        invalidTypeForList = true;
      }
      if (
        isFromSanctionsConfig &&
        !allowedFileExtensions2.includes(extension)
      ) {
        invalidTypeForList = true;
      }
      if (file.size > maxSize) {
        invalidsize = true;
      }
    });
    if (invalidTypeForList) {
      toast.error({
        title: 'Error',
        message: t('toast.invalidAttachmentType2'),
      });
    } else if (invalidType) {
      toast.error({
        title: 'Error',
        message: t('toast.invalidAttachmentType', {
          types: allowedExtensions,
        }),
      });
    } else if (invalidsize) {
      toast.error({
        title: 'Error',
        message: t('toast.attachmentSizeExceeds', {
          size: attachmentFileLengthConfig?.settingValue,
        }),
      });
    } else if (!isFileAttached) {
      toast.error({
        title: 'Error',
        message: t('toast.filesNotEmpty'),
      });
    } else {
      if (getUploadedFiles) {
        getUploadedFiles(uploadedFiles);
        handleCancelClick();
        setShowConfirmSave(false);
        setUploadedFiles([]);
      } else {
        upload();
      }
    }
  };

  const upload = () => {
    const data = new FormData();
    if (listId) {
      for (const item in uploadedFiles) {
        data.append('file', uploadedFiles[item]);
      }
    } else if (isFromCompanyInfo) {
      for (const item in uploadedFiles) {
        data.append('file', uploadedFiles[item]);
      }
    } else if (isFromSanctionsConfig) {
      for (const item in uploadedFiles) {
        data.append('sanctionSourceDetails', uploadedFiles[item]);
      }
    } else {
      for (const item in uploadedFiles) {
        data.append('files', uploadedFiles[item]);
      }
    }
    if (listId) {
      api
        .post(
          {
            url: getAPIRoute('excelUploadToList', [listId]),
            data: data,
          },
          setIsLoading
        )
        .then((res) => {
          setUploadedFilesError(res.listEntryErrorDetails);
          if (res.responseStatus) {
            toast.success({
              title: t('toast.fileUploadSuccess'),
            });
            getListById();
            handleCancelClick();
          } else {
            setShowUploadError(true);
          }
        });
    } else if (isFromCompanyInfo) {
      api
        .put(
          {
            url: getAPIRoute('companyLogo', [companyIdLogo]),
            data: data,
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
            setAttachmentsAdded &&
              setAttachmentsAdded([...attachmentsAdded, ...res]);
          }
          handleCancelClick();
          getCompanyDetails();
        });
    } else if (berthFitId) {
      api
        .post(
          {
            url: apiConfig.attachments,
            data: data,
            params: {
              imoNumber: imoNumberFromCreateVet
                ? imoNumberFromCreateVet
                : imoNumber?.toString(),
              attachmentType: berthFitId ? attachmentTypeBerth : attachmentType,
              BerthFitId: berthFitId,
              vetRequestId: vetRequestId,
            },
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
            setAttachmentsAdded &&
              setAttachmentsAdded([...attachmentsAdded, ...res]);
          }
          handleCancelClick();
        });
    } else if (vetRequestId || isFromVetCreation) {
      api
        .post(
          {
            url: apiConfig.attachments,
            data: data,
            params: {
              imoNumber: imoNumberFromCreateVet
                ? imoNumberFromCreateVet
                : imoNumber?.toString(),
              attachmentType: attachmentTypeVet,
              vetRequestId: vetRequestId ? vetRequestId : undefined,
              tempVetId: !vetRequestId ? tempVetId : undefined,
            },
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
            setAttachmentsAdded &&
              setAttachmentsAdded([...attachmentsAdded, ...res]);
            if (res && res.length > 0 && setTempVetId) {
              setTempVetId(res[0].tempVetId);
            }
          }
          handleCancelClick();
        });
    } else if (isFromPort && portCode) {
      api
        .post(
          {
            url: apiConfig.portAttachment,
            data: data,
            params: {
              portCode: portCode,
            },
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
            setAttachmentsAdded &&
              setAttachmentsAdded([...attachmentsAdded, ...res]);
          }
          handleCancelClick();
        });
    } else if (isFromPort && terminalId) {
      api
        .post(
          {
            url: apiConfig.terminalAttachment,
            data: data,
            params: {
              terminalId: terminalId,
            },
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
            setAttachmentsAdded &&
              setAttachmentsAdded([...attachmentsAdded, ...res]);
          }
          handleCancelClick();
        });
    } else if (isFromSanctionsConfig) {
      api
        .post(
          {
            url: apiConfig.uploadSanctionOverViewList,
            data: data,
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
          }
          handleCancelClick();
          if (handleSanctionCallBack) handleSanctionCallBack();
        });
    } else {
      api
        .post(
          {
            url: apiConfig.attachments,
            data: data,
            params: {
              imoNumber: imoNumber?.toString(),
              attachmentType: berthFitId ? attachmentTypeBerth : attachmentType,
            },
          },
          setIsLoading
        )
        .then((res) => {
          if (res) {
            toast.success({
              title: t('toast.attachedSuccessfully'),
            });
          }
          handleCancelClick();
        });
    }
  };

  return (
    <div className="m-l-model-popup__wrap">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-file-upload-popup">
          <div className="m-l-modal__header">
            <div className="m-l-modal-close-button-block">
              <Button
                className="app-c-btn app-c-icon-only-fl"
                onClick={onClickBack}
              >
                <icons.IconCross />
              </Button>
            </div>
          </div>
          <div className="m-l-modal__body additional-data-capturing-body-block">
            <ScrollView width="100%" height="100%">
              <div className="m-l-file-upload-modal-body">
                <div className="m-l-fl-title">
                  {isFromCompanyInfo
                    ? t('labels.uploadCompanyLogo')
                    : t('labels.uploadFilesToAttach')}
                </div>
                <div className="m-l-fl-upl-btn">
                  <div className="widget-container m-l-file-upload-custom-flex-box">
                    <div
                      id="dropzone-external"
                      className={`m-l-file-upload-custom-flex-box m-l-file-cntr-box ${
                        isDropZoneActive
                          ? 'dx-theme-accent-as-border-color dropzone-active'
                          : 'dx-theme-border-color'
                      }`}
                    >
                      <div className="m-l-drob-box-progressbar-wrapper">
                        <div
                          id="dropzone-text"
                          className="m-l-file-upload-custom-flex-box"
                        >
                          <span>{t('labels.dragAndDropFiles')}</span>
                          <span>
                            <a className="m-file-browse" href="#">
                              {t('labels.orBrowse')}
                            </a>
                          </span>
                        </div>

                        <ProgressBar
                          id="upload-progress"
                          min={0}
                          max={100}
                          width="30%"
                          showStatus={false}
                          visible={progressVisible}
                          value={progressValue}
                        ></ProgressBar>
                      </div>
                    </div>
                    <FileUploader
                      ref={fileUploaderRef}
                      id="file-uploader"
                      dialogTrigger="#dropzone-external"
                      dropZone="#dropzone-external"
                      multiple={listId || isFromCompanyInfo ? false : true}
                      uploadMode="useButtons"
                      uploadUrl={uploadUrl}
                      visible={true}
                      onDropZoneEnter={onDropZoneEnter}
                      onDropZoneLeave={onDropZoneLeave}
                      onUploaded={onUploaded}
                      onProgress={onProgress}
                      onUploadStarted={onUploadStarted}
                      onValueChanged={onValueChanged}
                      value={uploadedFiles}
                    ></FileUploader>
                  </div>
                </div>

                <div className="m-l-slcted-file-wrapper">
                  <div className="m-l-fl-title">
                    {isFromCompanyInfo
                      ? t('labels.selectFile')
                      : t('labels.selectFiles')}
                  </div>
                  <div
                    className={
                      'm-l-file-data-container ' +
                      (uploadedFiles.length ? 'm-l-hide-note' : '')
                    }
                  >
                    <div className="m-l-fl-icon">
                      <icons.IconFile />
                    </div>
                    <div className="m-l-uploaded-file-wrapper"></div>
                    <div className="m-l-slct-fl-note">
                      {t('labels.uploadedFilesWillBeHere')}
                    </div>
                  </div>
                  <div
                    className={
                      'm-l-uploaded-file-datas ' +
                      (!uploadedFiles.length ? 'm-l-hide-note' : '')
                    }
                  >
                    {uploadedFiles.map((file: any, index: number) => {
                      return (
                        <div className="mb-3 m-c-form-group" key={index}>
                          <label className="m-l-input-label">
                            {t('labels.fileName')}
                          </label>
                          <div className="m-l-input-fileicon-wrap">
                            {file.name && (
                              <TextBox
                                onValueChanged={(e: any) => {
                                  fileRename(e, index);
                                }}
                                value={file.name}
                              />
                            )}

                            <div className="m-l-fl-wrapper m-l-badge-in-input-box">
                              <span
                                className="m-l-fl-close"
                                onClick={() => removeFile(index)}
                              >
                                {' '}
                                <icons.Close />
                              </span>
                              {file.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </ScrollView>
          </div>
          <div className="m-l-modal__footer">
            <Button
              className="app-c-btn app-c-btn--secondary  min-btn-width"
              onClick={onClickBack}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onClickAttach}
            >
              {listId || isFromCompanyInfo
                ? t('buttons.upload')
                : t('buttons.attach')}
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={showConfirmBack}
      />
    </div>
  );
}
