import React from 'react';
import { MarkerProps } from '../../../../types/map';
import { Marker, Popup } from 'react-leaflet';

export default function MarkerWithInfoWindow(props: MarkerProps) {
  const { position, icon, infoWindowContent } = props;
  return icon ? (
    <Marker position={position} icon={icon}>
      {infoWindowContent && (
        <Popup autoClose={false}>{infoWindowContent}</Popup>
      )}
    </Marker>
  ) : (
    <></>
  );
}
