import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { IComplianceConfig } from '../../../../../types/compliance-config';
import { MarsApiService } from '../../../../../api/mars-api-service';
import { MarsApiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import {
  AisType,
  ComplianceType,
  LoiteringType,
  StsType,
} from '../../../../../enums/config-enum';
import { FormProvider, useForm } from 'react-hook-form';
import {
  IAisConfigForm,
  IComplianceCheckForm,
  ILoiteringConfigForm,
  IStsConfigForm,
} from '../../../../../types/complianceCheck';
import { IDialogConfig } from '../../../../../types/dialog';
import AisConfig from '../ais-config/AISConfig';
import LoiteringConfig from '../loitering-config/LoiteringConfig';
import StsConfig from '../sts-config/StsConfig';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import ValidationGroup from 'devextreme-react/validation-group';
import ComplianceForm from './ComplianceForm';
import Accordion from 'devextreme-react/accordion';
import StsProneAreaGrid from '../sts-config/sts-prone-area/StsProneAreaGrid';
import { CompanyType } from '../../../../../enums/company-type-enum';
import {
  Restricted,
  usePermission,
} from '../../../../../contexts/PermissionContext';
export default function ComplianceCheckSettings(
  props: Readonly<{
    companyId: string;
    buId: string;
    saveConfig?: boolean;
    companyTypeFlag?: string;
    companyType?: string;
    setSaveConfig: (e: boolean) => void;
  }>
) {
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { setIsLoading } = useLoaderContext();
  const {
    companyId,
    saveConfig,
    buId,
    companyTypeFlag,
    companyType,
    setSaveConfig,
  } = props;
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [complianceCheck, setComplianceCheck] = useState<IComplianceConfig[]>(
    []
  );
  const [loiteringConfig, setLoiteringConfig] = useState<IComplianceConfig[]>(
    []
  );
  const [complianceData, setComplianceData] = useState<IComplianceConfig[]>([]);
  const [stsConfig, setSTSConfig] = useState<IComplianceConfig[]>([]);
  const [aisConfig, setAisConfig] = useState<IComplianceConfig[]>([]);
  const validationGroup: any = useRef();

  useEffect(() => {
    getCompliance();
  }, []);

  const accordionItems = useMemo(() => {
    return [
      {
        title: t('labels.aisGapConfiguration'),
        visible: buId
          ? findFeature(
              'BUCompanyConfiguration.ViewAISGapConfigurationSettings'
            )
          : findFeature('CompanyConfiguration.ViewAISGapConfigurationSettings'),
      },
      {
        title: t('labels.loiteringConfigurations'),
        visible: buId
          ? findFeature(
              'BUCompanyConfiguration.ViewLoiteringConfigurationSettings'
            )
          : findFeature(
              'CompanyConfiguration.ViewLoiteringConfigurationSettings'
            ),
      },
      {
        title: t('labels.stsConfiguration'),
        visible: buId
          ? findFeature('BUCompanyConfiguration.ViewSTSConfigurationSettings')
          : findFeature('CompanyConfiguration.ViewSTSConfigurationSettings'),
      },
    ];
  }, []);

  const accordionItemRender = (e: any) => {
    switch (e.title) {
      case accordionItems[0].title: {
        return (
          <AisConfig aisConfigForm={aisConfigForm} buId={buId}></AisConfig>
        );
      }
      case accordionItems[1].title: {
        return (
          <LoiteringConfig
            loiteringForm={loiteringForm}
            buId={buId}
          ></LoiteringConfig>
        );
      }
      case accordionItems[2].title: {
        return (
          <>
            {(companyTypeFlag ?? companyType) === CompanyType.Mars ? (
              <StsConfig stsConfigForm={stsConfigForm} buId={buId}></StsConfig>
            ) : null}
            <Restricted
              permission={
                buId
                  ? 'BUCompanyConfiguration.ViewSTSProneAreaList'
                  : 'CompanyConfiguration.ViewSTSProneAreaList'
              }
            >
              <div className="m-l-inner-page-body sts-prone-area-padding">
                <StsProneAreaGrid
                  companyTypeFlag={companyTypeFlag ?? companyType}
                  companyId={companyId}
                  buId={buId}
                ></StsProneAreaGrid>
              </div>
            </Restricted>
          </>
        );
      }
    }
  };

  const selectionChanged = (e: any) => {
    let newItems: any = [...selectedItems];
    e.removedItems.map((item: any) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  useEffect(() => {
    getComplianceData(complianceData);
  }, [complianceData]);

  const getCompliance = async () => {
    if (buId) {
      await MarsApiService.get(
        {
          url: MarsApiConfig.configurations,
          params: {
            settingArea: 'Compliance',
            companyId: companyId,
            buId: buId,
          },
        },
        setIsLoading
      ).then((data: IComplianceConfig[]) => {
        if (data?.length > 0) {
          setComplianceData(data);
        }
      });
    } else {
      await MarsApiService.get(
        {
          url: MarsApiConfig.configurations,
          params: { settingArea: 'Compliance', companyId: companyId },
        },
        setIsLoading
      ).then((data: IComplianceConfig[]) => {
        if (data?.length > 0) {
          setComplianceData(data);
        }
      });
    }
  };

  const saveCompliance = async () => {
    if (buId) {
      await MarsApiService.put(
        {
          url: MarsApiConfig.configurations,
          params: {
            configArea: 'Compliance',
            buId: buId,
            companyId: companyId,
          },
          data: complianceDataSet(),
        },
        setIsLoading
      ).then(() => {
        toast.success({
          title: t('toast.changesSavedSuccessfully'),
        });
        getCompliance();
      });
    } else {
      await MarsApiService.put(
        {
          url: MarsApiConfig.configurations,
          params: {
            configArea: 'Compliance',
            companyId: companyId,
          },
          data: complianceDataSet(),
        },
        setIsLoading
      ).then(() => {
        toast.success({
          title: t('toast.changesSavedSuccessfully'),
        });
        getCompliance();
      });
    }
  };

  const complianceDataSet = () => {
    const data = [
      ...setStsData(),
      ...setComplianceConfigData(),
      ...setLoiteringData(),
      ...setAisConfigData(),
    ];
    return data;
  };

  const getComplianceData = (data: IComplianceConfig[]) => {
    if (data?.length > 0) {
      const complianceData = data.filter(
        (setting) =>
          setting.settingArea === ComplianceType.Compliance &&
          setting.settingType == ComplianceType.CompliancePeriod
      );
      setComplianceCheck(complianceData);

      const loiteringConfigData = data.filter(
        (setting: any) =>
          setting.settingArea === ComplianceType.Compliance &&
          [
            LoiteringType.MaximumSpeed,
            LoiteringType.AisDataPointsForLoitering,
            LoiteringType.MinimumDurationForLoitering,
            LoiteringType.VesselClusterPointsDistance,
          ].includes(setting.settingType)
      );
      setLoiteringConfig(loiteringConfigData);

      const aisConfigData = data.filter(
        (setting: any) =>
          setting.settingArea === ComplianceType.Compliance &&
          [AisType.SpeedCalculationPercentage, AisType.AisGapDuration].includes(
            setting.settingType
          )
      );
      setAisConfig(aisConfigData);

      const stsConfigData = data.filter(
        (setting: any) =>
          setting.settingArea === ComplianceType.Compliance &&
          [
            StsType.ProximityPeriod,
            StsType.ThresholdPeriod,
            StsType.MaximumSpeedDuringSTS,
            StsType.VesselContactDistanceThreshold,
          ].includes(setting.settingType)
      );
      setSTSConfig(stsConfigData);
    }
  };

  useEffect(() => {
    if (saveConfig) {
      handleSaveClick();
    }
  }, [saveConfig]);

  const defaultValues: IComplianceCheckForm = {
    compliancePeriod: null,
  };

  const complianceForm = useForm({
    defaultValues: defaultValues,
  });

  const defaultLoiteringValues: ILoiteringConfigForm = {
    maximumSpeed: null,
    aisDataPointsLoitering: null,
    minimumDurationLoitering: null,
    vesselClusterPointsDistance: null,
  };

  const loiteringForm = useForm({
    defaultValues: defaultLoiteringValues,
  });

  const defaultAisValues: IAisConfigForm = {
    aisGapDuration: null,
    speedCalculationPercentage: null,
  };

  const aisConfigForm = useForm({
    defaultValues: defaultAisValues,
  });

  const defaultSTSValues: IStsConfigForm = {
    proximityPeriod: null,
    thresholdPeriod: null,
    maximumSpeedDuringSTS: null,
    vesselContactDistanceThreshold: null,
  };

  const stsConfigForm = useForm({
    defaultValues: defaultSTSValues,
  });

  useEffect(() => {
    if (complianceCheck?.length > 0) {
      complianceForm.reset({
        compliancePeriod: Number(complianceCheck[0]?.settingValue),
      });
    }
  }, [complianceCheck]);

  useEffect(() => {
    if (loiteringConfig?.length > 0) {
      const AisDataPointsForLoiteringIndex = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === LoiteringType.AisDataPointsForLoitering
      );
      const MaximumSpeedIndex = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === LoiteringType.MaximumSpeed
      );
      const MinimumDurationForLoiteringIndex = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === LoiteringType.MinimumDurationForLoitering
      );
      const VesselClusterPointsDistanceIndex = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === LoiteringType.VesselClusterPointsDistance
      );

      loiteringForm.reset({
        aisDataPointsLoitering: Number(
          loiteringConfig[AisDataPointsForLoiteringIndex]?.settingValue
        ),
        maximumSpeed: Number(loiteringConfig[MaximumSpeedIndex]?.settingValue),
        minimumDurationLoitering: Number(
          loiteringConfig[MinimumDurationForLoiteringIndex]?.settingValue
        ),
        vesselClusterPointsDistance: Number(
          loiteringConfig[VesselClusterPointsDistanceIndex]?.settingValue
        ),
      });
    }
  }, [loiteringConfig]);

  useEffect(() => {
    if (aisConfig?.length > 0) {
      const aisGapDurationIndex = aisConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === AisType.AisGapDuration
      );
      const speedCalculationPercentageIndex = aisConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType === AisType.SpeedCalculationPercentage
      );

      aisConfigForm.reset({
        speedCalculationPercentage: Number(
          aisConfig[speedCalculationPercentageIndex]?.settingValue
        ),
        aisGapDuration: Number(aisConfig[aisGapDurationIndex]?.settingValue),
      });
    }
  }, [aisConfig]);

  useEffect(() => {
    if (stsConfig?.length > 0) {
      const ProximityPeriodIndex = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.ProximityPeriod
      );
      const ThresholdPeriodIndex = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.ThresholdPeriod
      );
      const MaximumSpeedDuringSTSIndex = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.MaximumSpeedDuringSTS
      );
      const VesselContactDistanceThresholdIndex = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.VesselContactDistanceThreshold
      );

      stsConfigForm.reset({
        proximityPeriod: Number(stsConfig[ProximityPeriodIndex]?.settingValue),
        thresholdPeriod: Number(stsConfig[ThresholdPeriodIndex]?.settingValue),
        maximumSpeedDuringSTS: Number(
          stsConfig[MaximumSpeedDuringSTSIndex]?.settingValue
        ),
        vesselContactDistanceThreshold: Number(
          stsConfig[VesselContactDistanceThresholdIndex]?.settingValue
        ),
      });
    }
  }, [stsConfig]);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      saveCompliance();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const setStsData = () => {
    if (stsConfig?.length > 0) {
      const stsTempData = stsConfig;
      let index = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.ProximityPeriod
      );
      stsTempData[index].settingValue = stsConfigForm.getValues()
        ?.proximityPeriod
        ? String(stsConfigForm.getValues()?.proximityPeriod)
        : '';
      index = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.ThresholdPeriod
      );
      stsTempData[index].settingValue = stsConfigForm.getValues()
        .thresholdPeriod
        ? String(stsConfigForm.getValues().thresholdPeriod)
        : '';
      index = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.MaximumSpeedDuringSTS
      );
      stsTempData[index].settingValue = stsConfigForm.getValues()
        .maximumSpeedDuringSTS
        ? String(stsConfigForm.getValues().maximumSpeedDuringSTS)
        : '';
      index = stsConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == StsType.VesselContactDistanceThreshold
      );
      stsTempData[index].settingValue = stsConfigForm.getValues()
        .vesselContactDistanceThreshold
        ? String(stsConfigForm.getValues().vesselContactDistanceThreshold)
        : '';
      return stsTempData;
    }
    return [];
  };
  const setAisConfigData = () => {
    if (aisConfig?.length > 0) {
      const aisConfigTempData = aisConfig;
      let index = aisConfig?.findIndex(
        (item: IComplianceConfig) => item?.settingType == AisType.AisGapDuration
      );
      aisConfig[index].settingValue = aisConfigForm.getValues().aisGapDuration
        ? String(aisConfigForm.getValues()?.aisGapDuration)
        : '';
      index = aisConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == AisType.SpeedCalculationPercentage
      );
      aisConfig[index].settingValue = aisConfigForm.getValues()
        .speedCalculationPercentage
        ? String(aisConfigForm.getValues().speedCalculationPercentage)
        : '';
      return aisConfigTempData;
    }
    return [];
  };
  const setLoiteringData = () => {
    if (loiteringConfig?.length > 0) {
      const loiteringConfigTempData = loiteringConfig;
      let index = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == LoiteringType.AisDataPointsForLoitering
      );
      loiteringConfigTempData[index].settingValue = loiteringForm.getValues()
        ?.aisDataPointsLoitering
        ? String(loiteringForm.getValues()?.aisDataPointsLoitering)
        : '';
      index = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == LoiteringType.MaximumSpeed
      );
      loiteringConfigTempData[index].settingValue = loiteringForm.getValues()
        .maximumSpeed
        ? String(loiteringForm.getValues().maximumSpeed)
        : '';
      index = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == LoiteringType.MinimumDurationForLoitering
      );
      loiteringConfigTempData[index].settingValue = loiteringForm.getValues()
        .minimumDurationLoitering
        ? String(loiteringForm.getValues().minimumDurationLoitering)
        : '';
      index = loiteringConfig?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == LoiteringType.VesselClusterPointsDistance
      );
      loiteringConfigTempData[index].settingValue = loiteringForm.getValues()
        .vesselClusterPointsDistance
        ? String(loiteringForm.getValues().vesselClusterPointsDistance)
        : '';
      return loiteringConfigTempData;
    }
    return [];
  };
  const setComplianceConfigData = () => {
    if (complianceCheck?.length > 0) {
      const complianceTempData = complianceCheck;
      complianceCheck[0].settingValue = String(
        complianceForm.getValues()?.compliancePeriod
      );
      return complianceTempData;
    }
    return [];
  };

  const handleSaveClick = async () => {
    if (validationGroup.current.instance.validate().isValid) {
      setShowConfirmSave(true);
    }
    setSaveConfig(false);
  };

  return (
    <ValidationGroup ref={validationGroup}>
      <div className="m-l-form-panel-block">
        <div className="m-l-form-panel-header-block">
          <div className="m-l-form-panel-header-left-block">
            <div className="m-l-page-small-heading">
              {t('labels.complianceCheckSettings')}
            </div>
          </div>
        </div>

        <Restricted
          permission={
            buId
              ? 'BUCompanyConfiguration.ViewComplianceCheckPeriodSettings'
              : 'CompanyConfiguration.ViewComplianceCheckPeriodSettings'
          }
        >
          <div className="m-l-inner-page-body-wrap">
            <div className="m-l-inner-page-body border-margin">
              <div className="m-l-form-panel-block">
                <div className="m-l-form-panel-body-block ">
                  <div className="m-l-us-databox-header">
                    <div className="m-l-us-dbox-ttle">
                      {t('labels.complianceConfiguration')}
                    </div>
                  </div>
                  <FormProvider {...complianceForm}>
                    <ComplianceForm buId={buId} />
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </Restricted>

        <div className="m-l-accordion-panel-body-block">
          <div className="m-l-accordion__main-section">
            <div className="m-c-accordion">
              <Accordion
                collapsible={true}
                multiple={true}
                items={accordionItems}
                itemRender={accordionItemRender}
                selectedItems={selectedItems}
                onSelectionChanged={selectionChanged}
              />
            </div>
          </div>
        </div>

        <ConfirmDialog
          dialogConfig={confirmSaveDialogConfig}
          isOpen={showConfirmSave}
        />
      </div>
    </ValidationGroup>
  );
}
