import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { MarsApiService as api } from '../../api/mars-api-service';
import Tooltip from 'devextreme-react/tooltip';
import icons from '../../components/common/icons/icons';
import countryList from '../../components/Country.json';
import CopyToClipboard from '../../components/common/copy-to-clipboard/CopyToClipboard';
import Button from 'devextreme-react/button';
import VesselInfoBanner from '../vessel-info/VesselInfoBanner';
import ComplianceTab from '../../components/compliance-check/compliance-Tab/ComplianceTab';
import { VesselDetails } from '../../types/vessel-general';
import { ComplianceCheckContext } from '../../contexts/ComplianceCheckContext';
import SanctionsCheckTab from '../../components/sanctions-check/sanctions-check-tab/SanctionsCheckTab';

export default function ComplianceCheck() {
  const [imoNumber, setImoNumber] = useState<string>();
  const [complianceCheckId, setComplianceCheck] = useState<string>();
  const [sanctionsCheckId, setSanctionsCheckId] = useState<string>();
  const [vetRequestId, setVetRequestId] = useState<string>();
  const [bannerInfoDetails, setBannerInfoDetails] = useState<VesselDetails>();
  const [warning, setWarning] = useState<any>();
  const [showWarningTooltip, setShowWarningTooltip] = useState(false);
  const location = useLocation();
  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state) {
      const { imoNumber, complianceCheckId, sanctionsCheckId, vetRequestId } =
        location.state;
      setImoNumber(imoNumber);
      setComplianceCheck(complianceCheckId);
      setSanctionsCheckId(sanctionsCheckId);
      setVetRequestId(vetRequestId);
    }
  }, [location.state]);

  useEffect(() => {
    if (imoNumber) {
      vesselBannerDetails();
      getVesselWarning();
    }
  }, [imoNumber]);

  const copyText = useMemo(() => {
    if (bannerInfoDetails) {
      const text = bannerInfoDetails.imoDisplayName
        ? `${bannerInfoDetails.vesselName} (IMO: ${bannerInfoDetails.imoDisplayName})`
        : `${bannerInfoDetails.vesselName} (VIN: ${bannerInfoDetails.vinNumber})`;
      return text;
    }
  }, [bannerInfoDetails]);

  const complianceContextValue = useMemo(
    () => ({
      complianceCheckId: complianceCheckId ?? '',
      sanctionsCheckId: sanctionsCheckId ?? '',
    }),
    [complianceCheckId, sanctionsCheckId]
  );

  const vesselBannerDetails = async () => {
    await api
      .get(
        {
          url: apiConfig.vessels + imoNumber,
          params: { isFromVesselInfo: true },
        },
        setIsLoading
      )
      .then((data) => {
        setBannerInfoDetails(data);
      });
  };

  const getVesselWarning = async () => {
    await api
      .get({
        url: apiConfig.vesselsWarning + imoNumber,
      })
      .then((response: any) => {
        setWarning(response);
      });
  };

  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == bannerInfoDetails?.iso3
  );

  return (
    <div className="inner-wrapper u-pl0 u-pr0">
      <div>
        <ul aria-label="breadcrumb" className="m-c-bredcrumb">
          <li>
            <a className="active">{t('labels.vesselInfo')}</a>
          </li>
        </ul>
        <div className="m-l-inner-page-header m-l-vsl-page-hd m-l-form-panel--details-header d-flex">
          <div className="m-l-inner-page-header-left-block">
            <div className="m-l-ttl-status-wrap">
              <div className="m-l-br-ttl-hldr">
                <div className="m-l-title-block">
                  <div className="m-l-page-main-heading page-navigation-link">
                    <Link
                      className="m-l-page-main-heading"
                      to={'/vessel-info'}
                      state={{ imoNumber: imoNumber }}
                      onContextMenu={() =>
                        localStorage.setItem('imo', imoNumber as string)
                      }
                    >
                      {bannerInfoDetails?.vesselName}
                    </Link>
                  </div>

                  {warning?.warning && (
                    <>
                      <div
                        className="m-l-page-icon"
                        id="warning"
                        onMouseEnter={() => setShowWarningTooltip(true)}
                        onMouseLeave={() => setShowWarningTooltip(false)}
                      >
                        <icons.vesselWarning />
                      </div>
                      <Tooltip
                        target={'#warning'}
                        position="right"
                        visible={showWarningTooltip}
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                              <span>{warning.sanctionedMessage}</span>
                              <span>{warning.restrictedMessage}</span>
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                  <CopyToClipboard text={copyText ?? ''} />
                </div>
                <div className="m-l-sub-title-details-block">
                  <div className="m-l-flag-wrap">
                    <span className="ml-ads-tbl-flag">
                      <img
                        src={filteredCountryList?.flag}
                        className="img-responsive"
                        alt="flag"
                        height={20}
                        width={20}
                      />
                    </span>
                    <span className="m-l-page-id-text">
                      {bannerInfoDetails?.flagName}
                    </span>
                  </div>
                  <div className="app-l-vertical-seperator"></div>
                  {bannerInfoDetails && bannerInfoDetails.imoDisplayName ? (
                    <div className="m-l-page-sub-heading">
                      {t('labels.IMO')}: {bannerInfoDetails?.imoDisplayName}
                    </div>
                  ) : (
                    <div className="m-l-page-sub-heading">
                      {t('labels.vin')}: {bannerInfoDetails?.vinNumber}
                    </div>
                  )}
                  <div className="app-l-vertical-seperator"></div>
                  {complianceCheckId && (
                    <div className="m-l-page-sub-heading">
                      {t('labels.complianceCheckId')}: {complianceCheckId}
                    </div>
                  )}
                  {sanctionsCheckId && (
                    <div className="m-l-page-sub-heading">
                      {t('labels.sanctionsCheckId')}: {sanctionsCheckId}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="m-l-inner-page-header-right-block d-flex">
            <Button
              className="app-c-btn app-c-btn--secondary u-ml d-none d-md-block"
              onClick={() => navigate(-1)}
            >
              {t('buttons.back')}
            </Button>
          </div>
        </div>
        <div className="m-l-inner-page-body-wrap m-l-srv-pdng">
          <div className="m-l-form-panel--details-block">
            {bannerInfoDetails && (
              <VesselInfoBanner
                bannerInfoDetails={bannerInfoDetails}
                isSanction={warning?.warning}
              ></VesselInfoBanner>
            )}
            <div className="m-l-inner-page-footer">
              <div className="row m-l-custom-eight-space-row">
                <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                  <div className="m-l-group-button-btms">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      // onClick={goBack}
                    >
                      {t('buttons.back')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {bannerInfoDetails && complianceCheckId && (
            <ComplianceCheckContext.Provider value={complianceContextValue}>
              <ComplianceTab
                imoNumber={imoNumber ?? ''}
                vesselDetails={bannerInfoDetails}
                vetRequestId={vetRequestId}
              />
            </ComplianceCheckContext.Provider>
          )}
          {bannerInfoDetails && sanctionsCheckId && !complianceCheckId && (
            <ComplianceCheckContext.Provider value={complianceContextValue}>
              <SanctionsCheckTab
                imoNumber={imoNumber ?? ''}
                vesselDetails={bannerInfoDetails}
                vetRequestId={vetRequestId}
              />
            </ComplianceCheckContext.Provider>
          )}
        </div>
      </div>
    </div>
  );
}
