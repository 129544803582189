import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CountryList from '../../Country.json';
import { useTranslation } from 'react-i18next';
import { useDateFormat } from '../../../hooks/useDateFormat';
import Button from 'devextreme-react/button';
import { Restricted } from '../../../contexts/PermissionContext';
import { useNavigate } from 'react-router-dom';
import { VetRequestStatusCode } from '../../../enums/vet-request-status-enum';
import {
  getCompanyId,
  getCompanyType,
  getEmployeeId,
} from '../../../utils/jwt-decode';
import Icons from '../../common/icons/icons';
import CancelVetRequestCommentModal from './cancel-vet-request-modal/CancelVetRequestCommentModal';
import { IOptionvalue } from '../../../types/master-data';
import Tooltip from 'devextreme-react/tooltip';
import icons from '../../common/icons/icons';
import { getAPIRoute } from '../../../utils/api-route';
import { MarsApiService as api } from '../../../api/mars-api-service';
export default function VetPreview(props: {
  incoTerms?: any;
  vetRequestDetails?: any;
  imoNumber?: string;
  vetRequestId?: number;
  getVetRequestDetails?: any;
  reviewerId?: number;
  loginUserId?: number;
  cargoList: IOptionvalue[];
}) {
  const {
    incoTerms,
    vetRequestDetails,
    imoNumber,
    vetRequestId,
    getVetRequestDetails,
    cargoList,
  } = props;
  const [loadPorts, setLoadPorts] = useState<any>([]);
  const [dischargePorts, setDischargePorts] = useState<any>([]);
  const [incoTerm, setIncoTerm] = useState<any>('');
  const { t } = useTranslation();
  const { formatDate } = useDateFormat();
  const navigate = useNavigate();
  const [cancelVetRequestModalVisible, setCancelVetRequestModalVisible] =
    useState(false);
  const companyId = getCompanyId();
  const getCargoName = useCallback(
    (cargo: string) => {
      return cargoList.find((item) => item.optionValueCode === cargo)
        ?.optionValueText;
    },
    [cargoList]
  );

  const contructLoadPorts = async () => {
    await Promise.all(
      vetRequestDetails.vetRequestLoadPorts?.map(async (loadPort: any) => {
        loadPort.loadPortName = loadPort.loadPort.portName;
        await api
          .get({
            url: getAPIRoute('portWarning', [loadPort?.loadPortId]),
          })
          .then((response: any) => {
            loadPort.portWarning = response;
          });
        loadPort.loadPortTerminals = [];
        const portFlag = CountryList.find(
          (country: any) => country.iso_3 == loadPort.loadPort.countryCode
        );
        if (portFlag) {
          loadPort.portFlag = portFlag.flag;
        } else {
          loadPort.portFlag = '';
        }
        // if (terminals && terminals.length > 0) {
        loadPort.vetRequestLoadPortTerminals.map((loadPortTerminal: any) => {
          // const filteredLoadPortTerminal = terminals.find(
          //   (terminal: any) =>
          //     terminal.terminalId == loadPortTerminal.terminalId
          // );
          if (loadPortTerminal?.terminal) {
            loadPort.loadPortTerminals?.push(
              loadPortTerminal?.terminal?.terminalName
            );
          }
        });
        // }
      })
    );
    setLoadPorts(
      vetRequestDetails.vetRequestLoadPorts
        ? vetRequestDetails.vetRequestLoadPorts
        : []
    );
  };

  const constructDischargePorts = async () => {
    await Promise.all(
      vetRequestDetails.vetRequestDischargePorts.map(
        async (dischargePort: any) => {
          dischargePort.dischargePortName =
            dischargePort.dischargePort.portName;
          await api
            .get({
              url: getAPIRoute('portWarning', [dischargePort?.dischargePortId]),
            })
            .then((response: any) => {
              dischargePort.portWarning = response;
            });

          dischargePort.dischargePortTerminals = [];
          const portFlag = CountryList.find(
            (country: any) =>
              country.iso_3 == dischargePort.dischargePort.countryCode
          );
          if (portFlag) {
            dischargePort.portFlag = portFlag.flag;
          } else {
            dischargePort.portFlag = '';
          }
          // if (terminals && terminals.length > 0) {
          dischargePort.vetRequestDischargePortTerminals.map(
            (dischargePortTerminal: any) => {
              // const filteredDischargePortTerminal = terminals.find(
              //   (terminal: any) =>
              //     terminal.terminalId == dischargePortTerminal.terminalId
              // );
              if (dischargePortTerminal?.terminal) {
                dischargePort.dischargePortTerminals?.push(
                  dischargePortTerminal?.terminal?.terminalName
                );
              }
            }
          );
          // }
        }
      )
    );
    setDischargePorts(vetRequestDetails.vetRequestDischargePorts);
  };

  const constructIncoTerm = () => {
    if (incoTerms && incoTerms.length > 0) {
      const incoTerm = incoTerms.find(
        (option: any) => option.optionValueCode == vetRequestDetails.incoTerm
      );
      if (incoTerm) {
        setIncoTerm(incoTerm.optionValueText);
      }
    }
  };

  useEffect(() => {
    constructIncoTerm();
  }, [incoTerms]);

  useEffect(() => {
    if (vetRequestDetails) {
      contructLoadPorts();
      constructDischargePorts();
    }
  }, [vetRequestDetails]);

  const employeeId = Number(getEmployeeId());

  return (
    <div>
      {vetRequestDetails && loadPorts && dischargePorts && (
        <div className="m-l-accordion-panel-body-block">
          <div className="m-l-accordion__main-section">
            <div className="m-l-form-panel-block m-l-card-form">
              <div className="m-l-form-panel-header-block d-flex">
                <div className="m-l-form-panel-header-right-block d-flex">
                  {/* <Restricted permission={'VetRequest.Cancel'}>
                    {vetRequestDetails?.status ==
                      VetRequestStatusCode.requested &&
                      companyType == 'R' && (
                        <Button
                          className="app-c-btn app-c-btn--secondary"
                          onClick={onClickCancel}
                        >
                          {t('buttons.cancel')}
                        </Button>
                      )}
                    {[
                      VetRequestStatusCode.assigned,
                      VetRequestStatusCode.requested,
                      VetRequestStatusCode.processing,
                      VetRequestStatusCode.unassigned,
                      VetRequestStatusCode.waitingforExternalInfo,
                      VetRequestStatusCode.waitingforRequestorinfo,
                      VetRequestStatusCode.infoReceived,
                    ].includes(vetRequestDetails?.status) &&
                      companyType == 'H' && (
                        <Button
                          className="app-c-btn app-c-btn--secondary"
                          onClick={onClickCancel}
                        >
                          {t('buttons.cancel')}
                        </Button>
                      )}
                    {loginUserId &&
                      reviewerId &&
                      [
                        VetRequestStatusCode.assigned,
                        VetRequestStatusCode.processing,
                      ].includes(vetRequestDetails?.status) &&
                      companyType == 'M' &&
                      loginUserId == reviewerId && (
                        <Button
                          className="app-c-btn app-c-btn--secondary"
                          onClick={onClickCancel}
                        >
                          {t('buttons.cancel')}
                        </Button>
                      )}
                  </Restricted> */}
                  {vetRequestDetails?.status ==
                  VetRequestStatusCode.requested ? (
                    vetRequestDetails?.requestorUser?.company?.companyId ==
                    companyId ? (
                      <Restricted permission="VetRequest.Edit">
                        <Button
                          className="app-c-btn app-c-icon-only-btn"
                          onClick={() =>
                            navigate('/vet-request/edit/', {
                              state: {
                                imoNumber: imoNumber,
                                vetRequestId: vetRequestId,
                              },
                            })
                          }
                        >
                          <Icons.IconPencil />
                        </Button>
                      </Restricted>
                    ) : null
                  ) : (
                    vetRequestDetails?.reviewer?.employeeId == employeeId &&
                    (vetRequestDetails?.status ==
                      VetRequestStatusCode.processing ||
                      vetRequestDetails?.status ==
                        VetRequestStatusCode.assigned) && (
                      <Restricted permission="VetRequest.Edit">
                        <Button
                          className="app-c-btn app-c-icon-only-btn"
                          onClick={() =>
                            navigate('/vet-request/edit/', {
                              state: {
                                imoNumber: imoNumber,
                                vetRequestId: vetRequestId,
                              },
                            })
                          }
                        >
                          <Icons.IconPencil />
                        </Button>
                      </Restricted>
                    )
                  )}
                </div>
              </div>

              <div className="m-l-form-panel-body-block">
                <div className="row m-l-vt-rqst-prvw-row">
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="m-l-prvw-container">
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.date')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {formatDate(vetRequestDetails?.requestDate)}
                        </div>
                      </div>
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.requestorName')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails?.requestorUser.employeeName}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="m-c-form-group">
                            <label className="m-l-input-label">
                              {t('labels.laycanFromDate')}
                            </label>
                            {vetRequestDetails?.laycanFromDate != '' &&
                              vetRequestDetails?.laycanFromDate != null && (
                                <div className="m-l-input-data-preview">
                                  {formatDate(vetRequestDetails.laycanFromDate)}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                          <div className="m-c-form-group">
                            <label className="m-l-input-label">
                              {t('labels.toDate')}
                            </label>
                            {vetRequestDetails?.laycanToDate != '' &&
                              vetRequestDetails?.laycanToDate != null && (
                                <div className="m-l-input-data-preview">
                                  {formatDate(vetRequestDetails.laycanToDate)}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="m-l-prvw-container">
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.businessUnit')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails?.buName}
                        </div>
                      </div>
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.companyName')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails?.requestorUser.company.companyName}
                        </div>
                      </div>
                      <div className="m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.incoTerms')}
                        </label>
                        <div className="m-l-input-data-preview">{incoTerm}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="m-l-prvw-container">
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.voyageNumber')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails?.voyageNumber}
                        </div>
                      </div>
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.checkBerthFit')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails?.berthFitCheck
                            ? t('labels.yes')
                            : t('labels.no')}
                        </div>
                      </div>
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.cargo')}
                        </label>
                        {vetRequestDetails?.vetRequestCargos?.length != 0 &&
                          vetRequestDetails?.vetRequestCargos?.map(
                            (cargo: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  className="m-l-input-data-preview mb-8"
                                >
                                  {getCargoName(cargo.cargo)}
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <div className="m-l-prvw-container">
                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.complianceCheck')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails.complianceChecks
                            ? t('labels.yes')
                            : t('labels.no')}
                        </div>
                      </div>

                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.sanctionsCheck')}
                        </label>
                        <div className="m-l-input-data-preview">
                          {vetRequestDetails.sanctionChecks
                            ? t('labels.yes')
                            : t('labels.no')}
                        </div>
                      </div>

                      <div className="mb-btm-space-24 m-c-form-group">
                        <label className="m-l-input-label">
                          {t('labels.emailId')}
                        </label>
                        {vetRequestDetails?.emailIds?.split(',')?.length != 0 &&
                          vetRequestDetails?.emailIds
                            ?.split(',')
                            ?.map((emailId: any, index: any) => {
                              return (
                                <div
                                  key={index}
                                  className="m-l-input-data-preview mb-8"
                                >
                                  {emailId}
                                </div>
                              );
                            })}
                      </div>

                      {/* <div className="m-l-upld-fl-wrapper">
                      <div className="m-l-atch-icon-holder">
                        <Icons.Attach />
                      </div>
                      <div className="m-l-upld-fl-holder">
                        <div className="m-l-fl-wrapper">PSC.PDF</div>
                        <div className="m-l-fl-wrapper">PSC.PDF</div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="m-l-ldprt-dischrg-row">
              <div className="row m-l-custom-eight-space-row">
                {loadPorts.length > 0 && (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
                    <div className="m-l-ldprt-part">
                      <div className="m-l-form-panel-block m-l-card-form">
                        <div className="m-l-form-panel-header-block">
                          <div className="m-l-form-panel-header-left-block">
                            <div className="m-l-page-small-heading">
                              {t('labels.loadPort')}
                            </div>
                          </div>
                        </div>

                        <div className="m-l-form-panel-body-block m-l-preview-fm">
                          {loadPorts.map((loadPort: any, index: any) => {
                            return (
                              <div key={index}>
                                {index != 0 && <hr className="m-c-divider" />}
                                <div className="row">
                                  <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                    <div className="m-c-form-group">
                                      <label className="m-l-input-label">
                                        {t('labels.port')} {index + 1}
                                      </label>
                                      {loadPort?.portWarning?.warning && (
                                        <>
                                          <span
                                            className="m-l-port-warning"
                                            id={'port' + index}
                                          >
                                            <icons.PortWarning />
                                          </span>
                                          <Tooltip
                                            target={`#${'port' + index}`}
                                            showEvent="dxhoverstart"
                                            hideEvent="dxhoverend"
                                            position="right"
                                            contentRender={() => {
                                              return (
                                                <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                                  <span>
                                                    {
                                                      loadPort?.portWarning
                                                        ?.restrictedMessage
                                                    }
                                                  </span>
                                                </div>
                                              );
                                            }}
                                          />
                                        </>
                                      )}
                                      {loadPort && (
                                        <>
                                          <div className="m-l-page-sub-heading mtop-4">
                                            {loadPort.loadPortName}
                                          </div>
                                          {/* {loadPort.portFlag != '' && (
                                            <div className="m-l-flag-icon">
                                              {' '}
                                              <img
                                                src={loadPort.portFlag}
                                                className="img-responsive"
                                                alt={loadPort.loadPortName}
                                              />
                                            </div>
                                          )} */}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                    <div className="m-c-form-group">
                                      <label className="m-l-input-label">
                                        {t('labels.checkBerthFit')}
                                      </label>
                                      <div className="m-l-page-sub-heading mtop-4">
                                        {loadPort.berthFitCheck
                                          ? t('labels.yes')
                                          : t('labels.no')}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="m-c-form-group">
                                      <label className="m-l-input-label">
                                        {t('labels.terminals')}
                                      </label>
                                      {loadPort.loadPortTerminals?.length !=
                                        0 && (
                                        <div className="m-l-input-data-preview mtop-4">
                                          {loadPort.loadPortTerminals?.join(
                                            ', '
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                    <div className="m-c-form-group">
                                      <label className="m-l-input-label">
                                        {t('labels.estimatedDateOfLoad')}
                                      </label>
                                      {loadPort.estimatedDateOfLoad != '' &&
                                        loadPort.estimatedDateOfLoad !=
                                          null && (
                                          <div className="m-l-input-data-preview mtop-4">
                                            {formatDate(
                                              loadPort.estimatedDateOfLoad
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {dischargePorts.length > 0 && (
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 m-l-custom-eight-space-col">
                    <div className="m-l-ldprt-part">
                      <div className="m-l-form-panel-block m-l-card-form">
                        <div className="m-l-form-panel-header-block">
                          <div className="m-l-form-panel-header-left-block">
                            <div className="m-l-page-small-heading">
                              {t('labels.dischargePort')}
                            </div>
                          </div>
                        </div>

                        <div className="m-l-form-panel-body-block m-l-preview-fm">
                          {dischargePorts?.map(
                            (dischargePort: any, index: any) => {
                              return (
                                <div key={index}>
                                  {index != 0 && <hr className="m-c-divider" />}
                                  <div className="row">
                                    <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                      <div className="m-c-form-group">
                                        <label className="m-l-input-label">
                                          {t('labels.port')} {index + 1}
                                        </label>
                                        {dischargePort.portWarning?.warning && (
                                          <>
                                            <span
                                              className="m-l-port-warning"
                                              id={'dischargeport' + index}
                                            >
                                              <icons.PortWarning />
                                            </span>
                                            <Tooltip
                                              target={`#${
                                                'dischargeport' + index
                                              }`}
                                              showEvent="dxhoverstart"
                                              hideEvent="dxhoverend"
                                              position="right"
                                              contentRender={() => {
                                                return (
                                                  <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                                                    <span>
                                                      {
                                                        dischargePort
                                                          .portWarning
                                                          ?.restrictedMessage
                                                      }
                                                    </span>
                                                  </div>
                                                );
                                              }}
                                            />
                                          </>
                                        )}
                                        <div className="m-l-page-sub-heading mtop-4">
                                          {dischargePort.dischargePortName}
                                        </div>
                                        {/* {dischargePort.portFlag != '' && (
                                          <div className="m-l-flag-icon">
                                            {' '}
                                            <img
                                              src={dischargePort.portFlag}
                                              className="img-responsive"
                                              alt={
                                                dischargePort.dischargePortName
                                              }
                                            />
                                          </div>
                                        )} */}
                                      </div>
                                    </div>
                                    <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                      <div className="m-c-form-group">
                                        <label className="m-l-input-label">
                                          {t('labels.checkBerthFit')}
                                        </label>
                                        <div className="m-l-page-sub-heading mtop-4">
                                          {dischargePort.berthFitCheck
                                            ? t('labels.yes')
                                            : t('labels.no')}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                      <div className="m-c-form-group">
                                        <label className="m-l-input-label">
                                          {t('labels.terminals')}
                                        </label>
                                        {dischargePort.dischargePortTerminals
                                          ?.length != 0 && (
                                          <div className="m-l-input-data-preview mtop-4">
                                            {dischargePort.dischargePortTerminals?.join(
                                              ', '
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-xl col-lg col-md-6 col-sm-6 col-xs-12">
                                      <div className="m-c-form-group">
                                        <label className="m-l-input-label">
                                          {t('labels.estimatedDateOfDischarge')}
                                        </label>
                                        {dischargePort.estimatedDateOfDischarge !=
                                          '' &&
                                          dischargePort.estimatedDateOfDischarge !=
                                            null && (
                                            <div className="m-l-input-data-preview mtop-4">
                                              {formatDate(
                                                dischargePort.estimatedDateOfDischarge
                                              )}
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelVetRequestModalVisible ? (
        <CancelVetRequestCommentModal
          vetRequestDetails={vetRequestDetails}
          cancelVetRequestModalVisible={cancelVetRequestModalVisible}
          setCancelVetRequestModalVisible={setCancelVetRequestModalVisible}
          vetRequestId={vetRequestId}
          getVetRequestDetails={getVetRequestDetails}
        />
      ) : null}
    </div>
  );
}
