import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import LoiteringConfigForm from './LoiteringConfigForm';
import { ILoiteringConfigForm } from '../../../../../types/complianceCheck';
import { useTranslation } from 'react-i18next';

export default function LoiteringConfig(props: {
  loiteringForm: UseFormReturn<ILoiteringConfigForm>;
  buId: string;
}) {
  const { loiteringForm, buId } = props;
  const { t } = useTranslation();

  return (
    <div className="m-l-inner-page-body-wrap">
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-form-panel-body-block ">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.loiteringConfigurations')}
              </div>
            </div>
            <FormProvider {...loiteringForm}>
              <LoiteringConfigForm buId={buId} />
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
