import React, { useEffect, useState, useRef, useMemo } from 'react';
import TabPanel from 'devextreme-react/tab-panel';
import { FormProvider, useForm, useFormState } from 'react-hook-form';
import { Button } from 'devextreme-react/button';
import icons from '../../../components/common/icons/icons';
import { IAddress, ICompany, IContact } from '../../../types/requestor-company';
import {
  IBuIncoTerms,
  IBusinessUnitForm,
  IBusinessUnit,
  BuFields,
} from '../../../types/business-unit';
import BusinessUnitForm from '../../../components/requestor-company/business-unit/business-unit-form/BusinessUnitForm';
import { IDialogConfig } from '../../../types/dialog';
import { useNavigate } from 'react-router-dom';
import toast from '../../../utils/toast';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { ICountry, IOptionvalue } from '../../../types/master-data';
import { useTranslation } from 'react-i18next';
import VettingRules from '../../../components/requestor-company/vetting-rules/vetting-rules-grid/VettingRules';
import ConfirmDialog from '../../../components/common/confirm/ConfirmDialog';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { useLocation } from 'react-router-dom';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';
import BuListGrid from '../../../components/requestor-company/bu-list//BuListGrid';
import ViewBuBerthFit from '../../berth-fit-check/view-berth-fit-check/bu-berth-fit/View-BU-BerthFit';
import CompanyConfigurations from '../../../components/requestor-company/company-configurations/Company-Configurations';
import {
  getBuList,
  getCompanyType,
  getRememberMe,
} from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';

export default function EditBusinessunit() {
  const { t } = useTranslation();
  const [companyId, setCompanyId] = useState<string>();
  const [buId, setBuId] = useState<string>();
  const [isFromMenu, setIsFromMenu] = useState<string>('');
  const [fromPage, setFromPage] = useState<string>('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmBackDialog, setConfirmBackDialog] = useState(false);
  const [buStatusList, setBuStatusList] = useState<IOptionvalue[]>([]);
  const [personNameTitles, setPersonNameTitles] = useState<IOptionvalue[]>([]);
  const [buIncoTerms, setBuIncoTerms] = useState<IOptionvalue[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [companyDetails, setCompanyDetails] = useState<ICompany | null>(null);
  const [buDetails, setBuDetails] = useState<IBusinessUnit | null>(null);
  const { setIsLoading } = useLoaderContext();
  const [companyId1, setCompanyId1] = useState(companyId);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const tabRef = useRef<TabPanel>(null);
  const index = Number(new URLSearchParams(useLocation().search).get('index'));
  const location = useLocation();
  const [isBerthFitDirty, setIsBerthFitDirty] = useState(false);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showAddRule, setShowAddRule] = useState(false);
  const [showAddList, setShowAddList] = useState(false);
  const [disableRuleCopyBtn, setDisableRuleCopyBtn] = useState(true);
  const [disableListCopyBtn, setDisableListCopyBtn] = useState(true);
  const [showListPopover, setShowListPopver] = useState(false);
  const [showRulePopover, setShowRulePopver] = useState(false);
  const [saveConfig, setSaveConfig] = useState(false);
  const companyType = getCompanyType();
  const [selectedConfig, setSelectedConfig] = useState<number>();

  const { findFeature, featureList } = usePermission();

  const items = useMemo(() => {
    return [
      {
        title: 'Business Unit Info',
        visible: findFeature('CompanyBU.ViewDetail'),
      },
      {
        title: 'Rules',
        visible:
          findFeature('BURules.Listing') &&
          !!buId &&
          !!companyDetails &&
          companyDetails.companyType !== CompanyType.Mars,
      },
      {
        title: 'List',
        visible:
          findFeature('BULists.Listing') &&
          !!buId &&
          !!companyDetails &&
          companyDetails?.companyType !== CompanyType.Mars,
      },
      {
        title: 'Berth Fit',
        visible:
          (findFeature('BUBerthFit.ViewBerthFitParameters') ||
            findFeature('BUBerthFit.SaveBerthFitParameters') ||
            findFeature('BUBerthFit.ViewBerthFitInformation') ||
            findFeature('BUBerthFit.SaveBerthFitInformation')) &&
          !!buId &&
          !!companyDetails &&
          companyDetails?.companyType !== CompanyType.Mars,
      },
      {
        title: 'Configuration',
        visible:
          !!buId &&
          !!companyDetails &&
          companyDetails?.companyType !== CompanyType.Mars,
      },
    ];
  }, [featureList, buId, companyDetails]);

  const editConfig = useMemo(() => {
    if (selectedConfig === 3) {
      return 'BUCompanyConfiguration.EditRestrictionsApprovals';
    } else if (selectedConfig === 4) {
      return 'BUCompanyConfiguration.EditEmailSettings';
    } else if (selectedConfig === 5) {
      return 'BUCompanyConfiguration.EditVetReportSettings';
    } else if (selectedConfig === 6) {
      return 'BUCompanyConfiguration.EditBerthFitReportSettings';
    } else if (selectedConfig === 9) {
      return 'BUCompanyConfiguration.EditSanctionCheckReportSettings';
    } else if (selectedConfig === 8) {
      return 'BUCompanyConfiguration.EditComplianceReportSettings';
    } else if (selectedConfig === 7) {
      return 'BUCompanyConfiguration.EditComplianceCheckPeriodSettings';
    }
  }, [selectedConfig]);

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmChanges'),
    handleSubmit: () => {
      saveBUInfo();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmBack'),
    handleSubmit: () => {
      setIsBerthFitDirty(false);
      setConfirmBackDialog(false);
      // navigate(-1);
      navigateToCompany();
    },
    handleClose: () => {
      setConfirmBackDialog(false);
    },
  };
  const defaultValues: IBusinessUnitForm = {
    buName: '',
    buEmail: '',
    isGroupEmail: true,
    callingCode: null,
    phoneNumber: null,
    addressLine1: '',
    addressLine2: '',
    countryId: null,
    state: '',
    zipCode: '',
    incoTerms: [],
    name: '',
    emailId: '',
    personPhone: null,
    contactCallingCode: null,
    titleId: null,
    buStatus: '1',
    buId: 0,
    addressId: 0,
    contactId: 0,
    buIncoTermId: 0,
    companyId: 0,
  };

  const businessUnitForm = useForm({
    defaultValues: defaultValues,
  });
  const { dirtyFields } = useFormState({
    control: businessUnitForm.control,
    disabled: true,
  });

  const { getValues } = businessUnitForm;

  const getStatus = async () => {
    await api.get({ url: apiConfig.buStatus }).then((data) => {
      setBuStatusList(data);
    });
  };

  const getTitleName = async () => {
    await api
      .get({ url: apiConfig.buPersonNameTitle })
      .then((data: IOptionvalue[]) => {
        setPersonNameTitles(data);
      });
  };

  const getBuIncoTerms = async () => {
    await api
      .get({ url: apiConfig.buIncoTerms })
      .then((data: IOptionvalue[]) => {
        setBuIncoTerms(data);
      });
  };

  const getCountry = async () => {
    await api.get({ url: apiConfig.country }).then((data: ICountry[]) => {
      setCountries(data);
    });
  };

  const getCompanyDetails = async () => {
    await api
      .get({ url: apiConfig.company + companyId1 }, setIsLoading)
      .then((data: ICompany) => {
        setCompanyDetails(data);
      });
  };

  const getBuDetails = async () => {
    await api
      .get({ url: apiConfig.getBuDetails + buId }, setIsLoading)
      .then((data) => {
        let inco = [] as any;
        inco = data.buIncoTerms;
        const incotermsArray = [] as any;
        inco &&
          inco?.map(function (x: any) {
            incotermsArray.push(x.incoTerms);
          });
        data.buIncoTerms = incotermsArray;
        setBuDetails(data);
        if (data.companyId) {
          setCompanyId1(data?.companyId);
        }
        if (companyId1) {
          getCompanyDetails();
        }
      });
  };

  const { isDirty } = businessUnitForm.formState;
  const [showTabChangeConfirm, setShowTabChangeConfirm] = useState(false);
  const tabChangeConfirm: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmTabChange'),
    handleSubmit: () => {
      setShowTabChangeConfirm(false);
    },
    handleClose: () => {
      setShowTabChangeConfirm(false);
    },
  };

  const [tabChangeConfrimConfig, setTabChangeConfirmConfig] =
    useState(tabChangeConfirm);

  const setTabValue = () => {
    const { companyId, buId, isFromMenu, fromPage }: any = location.state;
    switch (fromPage) {
      case 'buGrid':
        return (
          tabRef.current?.instance.option('selectedIndex', 0), setSelectedTab(0)
        );
      case 'ruleTab':
        return (
          tabRef.current?.instance.option('selectedIndex', 1), setSelectedTab(1)
        );
      case 'listTab':
        return (
          tabRef.current?.instance.option('selectedIndex', 2), setSelectedTab(2)
        );
      case 'berthFitTab':
        return (
          tabRef.current?.instance.option('selectedIndex', 3), setSelectedTab(3)
        );
      case 'configurationTab':
        return (
          tabRef.current?.instance.option('selectedIndex', 4), setSelectedTab(4)
        );
      default:
        if (index) {
          return (
            setSelectedTab(index),
            tabRef.current?.instance.option('selectedIndex', index)
          );
        } else {
          return (
            setSelectedTab(0),
            tabRef.current?.instance.option('selectedIndex', 0)
          );
        }
    }
  };

  const onTabSelectionChanged = (e: any) => {
    if (e.removedItems[0].title === 'Business Unit Info' && isDirty) {
      const config: IDialogConfig = {
        handleSubmit: () => {
          businessUnitForm.reset(undefined, { keepDirty: true });
          setShowTabChangeConfirm(false);
          setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
        },
        handleClose: () => {
          setShowTabChangeConfirm(false);
          tabRef.current?.instance.option('selectedIndex', 0);
        },
      };
      setShowTabChangeConfirm(true);
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config });
    } else if (e.removedItems[0].title === 'Berth Fit' && isBerthFitDirty) {
      const config2: IDialogConfig = {
        handleSubmit: () => {
          setShowTabChangeConfirm(false);
          setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
          setIsBerthFitDirty(false);
        },
        handleClose: () => {
          setShowTabChangeConfirm(false);
          tabRef.current?.instance.option('selectedIndex', 3);
        },
      };
      setShowTabChangeConfirm(true);
      setTabChangeConfirmConfig({ ...tabChangeConfirm, ...config2 });
    } else {
      setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    }
  };

  const itemRender = (e: any) => {
    if (e.title === items[0].title) {
      return (
        <FormProvider {...businessUnitForm}>
          <BusinessUnitForm
            buStatusList={buStatusList}
            countries={countries}
            personNameTitles={personNameTitles}
            buIncoterms={buIncoTerms}
          />
        </FormProvider>
      );
    } else if (e.title === items[1].title) {
      return (
        <VettingRules
          ruleBuName={buDetails?.buName}
          buId={buId}
          setDisableCopyBtn={setDisableRuleCopyBtn}
          disableCopyBtn={disableRuleCopyBtn}
          showPopover={showRulePopover}
          setShowPopver={setShowRulePopver}
          showAdd={showAddRule}
        />
      );
    } else if (e.title === items[2].title) {
      return (
        <BuListGrid
          ruleBuName={buDetails?.buName}
          buId={buId}
          companyId={companyId1}
          setDisableCopyBtn={setDisableListCopyBtn}
          disableCopyBtn={disableListCopyBtn}
          showPopover={showListPopover}
          setShowPopver={setShowListPopver}
          showAdd={showAddList}
        />
      );
    } else if (e.title === items[3].title) {
      return (
        <ViewBuBerthFit
          buId={buId}
          setIsBerthFitDirty={setIsBerthFitDirty}
          isBerthFitDirty={isBerthFitDirty}
          showConfirmSave={showConfirmSave}
          setShowConfirmSave={setShowConfirmSave}
        />
      );
    } else if (e.title === items[4].title) {
      return (
        <CompanyConfigurations
          companyId={companyId1}
          buId={buId}
          saveConfig={saveConfig}
          setSaveConfig={setSaveConfig}
          companyType={companyType}
          companyTypeFlag={companyDetails?.companyType}
          selectedConfig={selectedConfig}
          setSelectedConfig={setSelectedConfig}
        />
      );
    }
  };

  const onClickSave = (e: any) => {
    setShowConfirmSave(true);
  };

  useEffect(() => {
    if (location.state) {
      const { companyId, buId, isFromMenu, fromPage }: any = location.state;
      setCompanyId(companyId);
      setBuId(buId);
      setIsFromMenu(isFromMenu);
      setFromPage(fromPage);
    }
  }, [location.state]);

  useEffect(() => {
    if (buId !== undefined) {
      getBuDetails();
      businessUnitForm.reset();
    }
    setTabValue();
  }, [buId, companyId]);

  useEffect(() => {
    getCountry();
    getStatus();
    getTitleName();
    getBuIncoTerms();
  }, []);

  useEffect(() => {
    if (companyId1) {
      getCompanyDetails();
    }
  }, [companyId1]);

  useEffect(() => {
    const defaultTitle = personNameTitles.find(
      (title) => title.isDefault && title.visible
    );
    if (buDetails) {
      businessUnitForm.reset(
        {
          buId: buDetails?.buId,
          buName: buDetails?.buName,
          buEmail: buDetails?.buEmail,
          isGroupEmail: buDetails?.isGroupEmail,
          addressId:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.addressId
              ? buDetails?.buAddresses[0]?.addressId
              : 0,
          callingCode:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.callingCode
              ? buDetails?.buAddresses[0]?.callingCode
              : null,
          addressLine1:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.addressLine1
              ? buDetails?.buAddresses[0]?.addressLine1
              : '',
          addressLine2:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.addressLine2
              ? buDetails?.buAddresses[0]?.addressLine2
              : '',
          countryId:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.countryId
              ? buDetails?.buAddresses[0]?.countryId
              : null,
          state:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.state
              ? buDetails?.buAddresses[0]?.state
              : '',
          zipCode:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.zipCode
              ? buDetails?.buAddresses[0]?.zipCode
              : '',
          buStatus: buDetails?.buStatus,
          titleId:
            buDetails?.buContacts && buDetails?.buContacts[0]?.personNameTitle
              ? buDetails?.buContacts[0]?.personNameTitle
              : defaultTitle
              ? defaultTitle?.optionValueCode
              : null,
          emailId:
            buDetails?.buContacts && buDetails?.buContacts[0]?.contactEmail
              ? buDetails?.buContacts[0]?.contactEmail
              : '',
          personPhone:
            buDetails?.buContacts && buDetails?.buContacts[0]?.phoneNumber
              ? parseInt(buDetails?.buContacts[0]?.phoneNumber)
              : null,
          phoneNumber:
            buDetails?.buAddresses && buDetails?.buAddresses[0]?.phoneNumber
              ? parseInt(buDetails?.buAddresses[0]?.phoneNumber)
              : null,
          contactCallingCode:
            buDetails?.buContacts && buDetails?.buContacts[0]?.callingCode
              ? buDetails?.buContacts[0]?.callingCode
              : null,
          name:
            buDetails?.buContacts && buDetails?.buContacts[0]?.contactName
              ? buDetails?.buContacts[0]?.contactName
              : '',
          contactId:
            buDetails?.buContacts && buDetails?.buContacts[0]?.contactId
              ? buDetails?.buContacts[0]?.contactId
              : 0,
          incoTerms:
            buDetails.buIncoTerms && buDetails.buIncoTerms
              ? buDetails.buIncoTerms
              : null,
        },
        { keepDirty: true }
      );
    } else {
      businessUnitForm.resetField('titleId', {
        defaultValue: defaultTitle ? defaultTitle?.optionValueCode : null,
      });
    }
  }, [personNameTitles, buDetails]);

  const handleSaveClick = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setIsConfirmModalOpen(true);
    }
    return;
  };

  const saveConfigClick = () => {
    setSaveConfig(true);
  };

  const buList: any = getBuList();
  const setIsGroupEmail = () => {
    const list = JSON.parse(buList);
    list.map((item: any) => {
      if (item.isDefault == true) {
        item.isGroupEmail = businessUnitForm.getValues().isGroupEmail;
      }
    });
    getRememberMe() === 'true'
      ? localStorage.setItem('buList', JSON.stringify(list))
      : sessionStorage.setItem('buList', JSON.stringify(list));
  };

  const saveBUInfo = () => {
    const businessUnitFormValue: IBusinessUnitForm = getValues();
    let addressJson: IAddress[] | undefined;
    let contactJson: IContact[] | undefined;
    let buIncoTerms: IBuIncoTerms[] | undefined;
    if (
      businessUnitFormValue.countryId ||
      businessUnitFormValue.phoneNumber ||
      businessUnitFormValue.addressLine1 ||
      businessUnitFormValue.addressLine2 ||
      businessUnitFormValue.state ||
      businessUnitFormValue.zipCode
    ) {
      addressJson = [
        {
          addressType: '1',
          addressLine1: businessUnitFormValue.addressLine1,
          addressLine2: businessUnitFormValue.addressLine2,
          countryId: businessUnitFormValue.countryId,
          state: businessUnitFormValue.state,
          zipCode: businessUnitFormValue.zipCode,
          callingCode: businessUnitFormValue.callingCode,
          phoneNumber: businessUnitFormValue.phoneNumber?.toString(),
        },
      ];
    }
    if (
      businessUnitFormValue.name ||
      businessUnitFormValue.emailId ||
      businessUnitFormValue.personPhone ||
      businessUnitFormValue.contactCallingCode
    ) {
      contactJson = [
        {
          personNameTitle: businessUnitFormValue.name
            ? businessUnitFormValue.titleId
            : null,
          contactName: businessUnitFormValue.name,
          contactEmail: businessUnitFormValue.emailId,
          callingCode: businessUnitFormValue.contactCallingCode,
          phoneNumber: businessUnitFormValue.personPhone?.toString(),
        },
      ];
    }
    if (businessUnitFormValue.incoTerms) {
      let obj = [] as any;
      obj = businessUnitFormValue.incoTerms;
      const array = [];
      array.push(...obj);
      const output = array.map(function (x) {
        return {
          incoTerms: x,
        };
      });
      buIncoTerms = output;
    }
    const payloadJson = {
      buName: businessUnitFormValue.buName,
      buEmail: businessUnitFormValue.buEmail,
      isGroupEmail: businessUnitFormValue.isGroupEmail,
      buStatus: businessUnitFormValue.buStatus,
      companyId: companyId ? parseInt(companyId) : undefined,
      buIncoTerms: buIncoTerms,
      buAddresses: addressJson,
      buContacts: contactJson,
      buId: 0,
    };
    setIsConfirmModalOpen(false);
    if (companyId && companyId !== undefined) {
      api
        .post(
          { url: apiConfig.createbusinessunits, data: payloadJson },
          setIsLoading
        )
        .then((data) => {
          setIsGroupEmail();
          Object.keys(businessUnitFormValue).map((item: string) => {
            const formKey = item as BuFields;
            businessUnitForm.resetField(formKey, {
              defaultValue: businessUnitFormValue[formKey],
            });
          });
          toast.custom({
            title: t('toast.savedSuccessfully'),
            message: t('toast.buCreated'),
          });
          navigate('/company/business-unit/edit', {
            state: { buId: data, isFromMenu: isFromMenu },
          });
        });
    } else {
      payloadJson.companyId = parseInt(JSON.stringify(buDetails?.companyId));
      payloadJson.buId = parseInt(JSON.stringify(buDetails?.buId));
      api
        .put(
          { url: apiConfig.createbusinessunits, data: payloadJson },
          setIsLoading
        )
        .then(() => {
          Object.keys(businessUnitFormValue).map((item: string) => {
            const formKey = item as BuFields;
            businessUnitForm.resetField(formKey, {
              defaultValue: businessUnitFormValue[formKey],
            });
          });
          setIsGroupEmail();
          toast.custom({
            title: t('toast.savedSuccessfully'),
            message: t('toast.buDetailsUpdated'),
          });
          let inco = [] as any;
          inco = payloadJson.buIncoTerms;
          const incotermsArray = [] as any;
          inco &&
            inco?.map(function (x: any) {
              incotermsArray.push(x.incoTerms);
            });
          payloadJson.buIncoTerms = incotermsArray;
          setBuDetails(payloadJson);
        });
    }
  };

  const handleBackClick = () => {
    if (selectedTab === 0) {
      Object.keys(dirtyFields).length !== 0 || isDirty
        ? setConfirmBackDialog(true)
        : navigateToCompany();
    } else if (selectedTab === 3) {
      if (isBerthFitDirty) {
        setConfirmBackDialog(true);
      } else {
        navigateToCompany();
      }
    } else {
      navigateToCompany();
    }
  };

  const navigateToCompany = () => {
    isFromMenu === 'isFromMenu'
      ? navigate('/company-details', {
          state: {
            fromPage: 'businessUnitGrid',
            companyId: companyId1 ? companyId1 : companyId,
          },
        })
      : navigate('/company/edit', {
          state: {
            fromPage: 'businessUnitGrid',
            companyId: companyId1 ? companyId1 : companyId,
          },
        });
  };

  return (
    <div className="inner-wrapper">
      <ul aria-label="breadcrumb" className="m-c-bredcrumb">
        <li>
          <a>{t('headers.companies')}</a>
        </li>
        <li>
          <a className="active">{t('headers.businessUnit')}</a>
        </li>
      </ul>
      <div className="m-l-inner-page-header">
        <div className="m-l-inner-page-header-left-block">
          {companyId && companyDetails && (
            <div className="m-l-page-main-heading">
              {companyDetails?.companyName}-{t('headers.businessUnitInfo')}
            </div>
          )}
          {buId && companyDetails && buDetails && (
            <div className="m-l-page-main-heading">
              {companyDetails?.companyName}-{buDetails.buName}
            </div>
          )}
        </div>
      </div>
      <div className="m-l-inner-page-body">
        <div className="m-l-tab-panel__wrap">
          <TabPanel
            ref={tabRef}
            items={items}
            className="m-c-tab-block"
            elementAttr={{ 'data-testid': 'buTab' }}
            onSelectionChanged={onTabSelectionChanged}
            itemRender={itemRender}
            showNavButtons={true}
          ></TabPanel>
          <div className="m-l-tab-panel-right-block">
            {selectedTab === 1 && (
              <Restricted permission="BURules.Copy">
                <Button
                  elementAttr={{ 'data-testid': 'copyIcon' }}
                  disabled={disableRuleCopyBtn}
                  id="copyButton"
                  className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                  onClick={() => {
                    setShowRulePopver(true);
                  }}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.CopyIcon />
                  </div>
                </Button>
              </Restricted>
            )}
            {selectedTab === 2 && (
              <Restricted permission="BULists.Copy">
                <Button
                  elementAttr={{ 'data-testid': 'copyIcon' }}
                  disabled={disableListCopyBtn}
                  id="copyButton"
                  className="app-c-btn app-c-btn--secondary min-btn-width-sm-pls"
                  onClick={() => {
                    setShowListPopver(true);
                  }}
                >
                  <div className="m-c-icon-inside-btn">
                    <icons.CopyIcon />
                  </div>
                </Button>
              </Restricted>
            )}
            <Button
              className="app-c-btn app-c-btn--secondary"
              elementAttr={{ 'data-testid': 'backButton' }}
              onClick={handleBackClick}
            >
              {t('buttons.back')}
            </Button>
            {selectedTab === 0 && (
              <>
                <Restricted permission="CompanyBU.Edit">
                  {buId && (
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'saveButton' }}
                      onClick={handleSaveClick}
                    >
                      {t('buttons.save')}
                    </Button>
                  )}
                </Restricted>
                <Restricted permission="CompanyBU.Add">
                  {!buId && (
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      elementAttr={{ 'data-testid': 'saveButton' }}
                      onClick={handleSaveClick}
                    >
                      {t('buttons.save')}
                    </Button>
                  )}
                </Restricted>
              </>
            )}
            {selectedTab === 1 && (
              <Restricted permission="BURules.Add">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={() => {
                    setShowAddRule(true);
                  }}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            )}
            {selectedTab === 2 && (
              <Restricted permission="BULists.Add">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={() => {
                    setShowAddList(true);
                  }}
                >
                  {t('buttons.addNew')}
                </Button>
              </Restricted>
            )}
            {selectedTab === 3 && (
              <Button
                className="app-c-btn app-c-btn--primary min-btn-width"
                onClick={onClickSave}
              >
                {t('buttons.save')}
              </Button>
            )}
            {selectedTab === 4 && (
              <Restricted permission={editConfig}>
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  aria-label=""
                  onClick={saveConfigClick}
                >
                  {t('buttons.save')}
                </Button>
              </Restricted>
            )}
          </div>
        </div>
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {selectedTab === 1 && (
                <Restricted permission="BURules.Copy">
                  <Button
                    elementAttr={{ 'data-testid': 'copyIcon' }}
                    disabled={disableRuleCopyBtn}
                    id="copyButton"
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => {
                      setShowRulePopver(true);
                    }}
                  >
                    {t('buttons.copy')}
                  </Button>
                </Restricted>
              )}
              {selectedTab === 2 && (
                <Restricted permission="BULists.Copy">
                  <Button
                    elementAttr={{ 'data-testid': 'copyIcon' }}
                    disabled={disableListCopyBtn}
                    id="copyButton"
                    className="app-c-btn app-c-btn--secondary"
                    onClick={() => {
                      setShowListPopver(true);
                    }}
                  >
                    {t('buttons.copy')}
                  </Button>
                </Restricted>
              )}
              <Button
                className="app-c-btn app-c-btn--secondary"
                elementAttr={{ 'data-testid': 'backButton' }}
                onClick={handleBackClick}
              >
                {t('buttons.back')}
              </Button>
              {selectedTab === 0 && (
                <>
                  <Restricted permission="CompanyBU.Edit">
                    {buId && (
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'saveButton' }}
                        onClick={handleSaveClick}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Restricted>
                  <Restricted permission="CompanyBU.Add">
                    {!buId && (
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        elementAttr={{ 'data-testid': 'saveButton' }}
                        onClick={handleSaveClick}
                      >
                        {t('buttons.save')}
                      </Button>
                    )}
                  </Restricted>
                </>
              )}
              {selectedTab === 1 && (
                <Restricted permission="BURules.Add">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setShowAddRule(true);
                    }}
                  >
                    {t('buttons.addNew')}
                  </Button>
                </Restricted>
              )}
              {selectedTab === 2 && (
                <Restricted permission="BULists.Add">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setShowAddList(true);
                    }}
                  >
                    {t('buttons.addNew')}
                  </Button>
                </Restricted>
              )}
              {selectedTab === 3 && (
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width"
                  onClick={onClickSave}
                >
                  {t('buttons.save')}
                </Button>
              )}
              {selectedTab === 4 && (
                <Restricted permission={editConfig}>
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    aria-label=""
                    onClick={saveConfigClick}
                  >
                    {t('buttons.save')}
                  </Button>
                </Restricted>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={confirmBackDialog}
      />
      <ConfirmDialog
        dialogConfig={tabChangeConfrimConfig}
        isOpen={showTabChangeConfirm}
      />
    </div>
  );
}
