import { AxiosRequestConfig } from 'axios';
import http from '../services/http-service';
import toast from '../utils/toast';

const event = new Event('logOut');
export const MarsApiService = {
  get: (request: AxiosRequestConfig, showLoader?: (flag: boolean) => void) => {
    showLoader ? showLoader(true) : null;
    return http
      .GET(request)
      .then(function (data) {
        showLoader ? showLoader(false) : null;
        return result(data);
      })
      .catch(function (err) {
        showLoader ? showLoader(false) : null;
        handleError(err);
      });
  },
  post: (request: AxiosRequestConfig, showLoader?: (flag: boolean) => void) => {
    showLoader ? showLoader(true) : null;
    return http
      .POST(request)
      .then(function (data) {
        showLoader ? showLoader(false) : null;
        return result(data);
      })
      .catch(function (err: any) {
        showLoader ? showLoader(false) : null;
        handleError(err);
      });
  },
  schedulerPost: (
    request: AxiosRequestConfig,
    showLoader?: (flag: boolean) => void
  ) => {
    showLoader ? showLoader(true) : null;
    return http
      .SCHEDULERPOST(request)
      .then(function (data) {
        showLoader ? showLoader(false) : null;
        return result(data);
      })
      .catch(function (err: any) {
        showLoader ? showLoader(false) : null;
        handleError(err);
      });
  },
  put: (request: AxiosRequestConfig, showLoader?: (flag: boolean) => void) => {
    showLoader ? showLoader(true) : null;
    return http
      .PUT(request)
      .then(function (data) {
        showLoader ? showLoader(false) : null;
        return result(data);
      })
      .catch(function (err) {
        showLoader ? showLoader(false) : null;
        handleError(err);
      });
  },
  delete: (
    request: AxiosRequestConfig,
    showLoader?: (flag: boolean) => void
  ) => {
    showLoader ? showLoader(true) : null;
    return http
      .DELETE(request)
      .then(function (data) {
        showLoader ? showLoader(false) : null;
        return result(data);
      })
      .catch(function (err) {
        showLoader ? showLoader(false) : null;
        handleError(err);
      });
  },
};

function result(data: any) {
  if (data.data != undefined) {
    const res = data.data;
    return typeof res != 'undefined' ? res : data.data;
  }
}

function handleError(error: any) {
  if (error.response && error.response.status === 409) {
    toast.error({ title: 'Error', message: error.response.data?.Message });
  } else if (error.response && error.response.status === 404) {
    if (error.response.data?.Message !== 'AISDataNotFound') {
      toast.error({ title: 'Error', message: error.response.data?.Message });
    }
  } else if (error.response && error.response.status === 401) {
    window.dispatchEvent(event);
    toast.error({ title: 'Error', message: error.response.data?.Message });
  } else if (error.response && error.response.data?.Message) {
    toast.error({ title: 'Error', message: error.response.data?.Message });
  } else {
    toast.error({ title: 'Error', message: '' });
  }

  throw error;
  // need to handle different errors
}
