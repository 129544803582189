import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { IGridConfig } from '../../../types/grid';
import { useTranslation } from 'react-i18next';
import Grid from '../../common/grid/Grid';
import MapViewModal from '../map-view-modal/MapViewModal';
import { VesselDetails } from '../../../types/vessel-general';
import {
  AisGapCoordinatesData,
  AisGapData,
  aisGapMapLegends,
  AisPosition,
  complianceStatusList,
  Events,
  MapConfig,
  riskRatingList,
} from '../../../types/compliance-check';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import { MarkerIcon, Markers, PolylineConfig } from '../../../types/map';
import { LatLngLiteral } from 'leaflet';
import { mapInfoWindow } from '../map-info-window/MapInfoWindow';
import { CellPreparedEvent } from 'devextreme/ui/data_grid';
import {
  ComplianceStatusEnum,
  ComplianceTabType,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import {
  getBuList,
  getCompanyType,
  getLoginBUName,
} from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { DataRowTemplateData } from 'devextreme/ui/data_grid';
import { ReportGeneratedDetails } from '../../../types/compliance-report';
import toast from '../../../utils/toast';

export default function AisGrid(
  props: Readonly<{
    imoNumber: string;
    vesselDetails: VesselDetails;
    reportDetails?: ReportGeneratedDetails;
    vetRequestId?: string;
  }>
) {
  const { imoNumber, vesselDetails, reportDetails, vetRequestId } = props;
  const complianceCheckContext = useContext(ComplianceCheckContext);
  const [aisGapData, setAisGapData] = useState<AisGapData[]>([]);
  const [selectedRow, setSelectedRow] = useState<AisGapData>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showMapModal, setShowMapModal] = useState<boolean>(false);
  const [mapConfig, setMapConfig] = useState<MapConfig>();
  const [eventData, setEventData] = useState<Events[]>([]);
  const [isApiLoaded, setIsApiLoaded] = useState<boolean>(false);
  const [isApiError, setIsApiError] = useState<boolean>(false);
  const gridRef = useRef<any>(null);
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const { t } = useTranslation();
  const companyType = getCompanyType();
  const bu: any = getBuList();
  const buList = JSON.parse(bu);
  const currentlyLoggedInBuu = getLoginBUName();
  const filteredBU = buList
    ? buList.find((bu: any) => bu.buName == currentlyLoggedInBuu)
    : 0;

  useEffect(() => {
    if (complianceCheckContext?.complianceCheckId) {
      getAisGapData();
    }
  }, [complianceCheckContext]);

  const getAisGapData = async () => {
    setLoaderMessage?.(t('toast.fetchingAISGap'));
    await api
      .get(
        {
          url: apiConfig.aisGapList,
          params: {
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            imo: imoNumber,
            buid: filteredBU.buId,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setIsApiLoaded(true);
        setAisGapData(res);
        setLoaderMessage?.();
      })
      .catch((error) => {
        setIsApiLoaded(true);
        setIsApiError(true);
        setLoaderMessage?.();
        if (error?.response?.data?.Message === 'AISDataNotFound') {
          toast.error({
            title: t('toast.liveTrackingNoDataTxt'),
          });
        }
      });
  };

  const cellPrepared = useCallback((e: CellPreparedEvent) => {
    if (e.rowType !== 'data') return;

    const statusClassMapping = {
      [ComplianceStatusEnum.VerifiedFalse]: 'status-active-wtout',
      [ComplianceStatusEnum.VerifiedTrue]: 'status-inactive-wtout',
      default: 'status-pending-wtout',
    };

    const riskClassMapping = {
      [RiskRatingEnum.Low]: 'status-active-wtout',
      [RiskRatingEnum.High]: 'status-inactive-wtout',
      default: 'status-pending-wtout',
    };

    const getClassName = (value: string, mapping: Record<string, string>) =>
      mapping[value] || mapping.default;

    const fieldClassMapping: Record<string, () => string> = {
      status: () => getClassName(e.data.status, statusClassMapping),
      riskRating: () => getClassName(e.data.riskRating, riskClassMapping),
      marsStatus: () => getClassName(e.data.marsStatus, statusClassMapping),
      marsRiskRating: () =>
        getClassName(e.data.marsRiskRating, riskClassMapping),
    };

    if (fieldClassMapping[e.column.dataField as string]) {
      e.cellElement.className =
        fieldClassMapping[e.column.dataField as string]();
    }
  }, []);

  const getNoDataText = useCallback(() => {
    return isApiLoaded
      ? isApiError
        ? t('toast.liveTrackingNoDataTxt')
        : t('labels.aisNoDataTxt')
      : t('labels.fetchingData');
  }, [isApiLoaded]);

  const gridConfig: IGridConfig = {
    dataSource: aisGapData,
    remoteOperations: true,
    ref: gridRef,
    noDataText: getNoDataText(),
    cellPrepared: cellPrepared,
    rowClick: (e) => getSelectedItemIndex(e?.data),
    defaultColumns: [
      {
        caption: t('labels.voyageStart'),
        dataField: 'voyageStart',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.voyageEnd'),
        dataField: 'voyageEnd',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.startCoordinates'),
        dataField: 'startCoordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: AisGapData) =>
          calculateCoordinatesCellValue(data.startPosition),
      },
      {
        caption: t('labels.endCoordinates'),
        dataField: 'endCoordinates',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: (data: AisGapData) =>
          calculateCoordinatesCellValue(data.endPosition),
      },
      {
        caption: t('labels.fromTime'),
        dataField: 'startPosition.lastPositionUTC',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.toTime'),
        dataField: 'endPosition.lastPositionUTC',
        dataType: 'date',
        format: 'dd MMM yyyy HH:mm:ss',
        minWidth: 150,
      },
      {
        caption: t('labels.distance'),
        dataField: 'distanceBetweenPositions',
        dataType: 'string',
        minWidth: 150,
        format: {
          type: 'fixedPoint',
          precision: 2,
        },
      },
      {
        caption: t('labels.durationOfGap'),
        dataField: 'timeTakenInHr',
        dataType: 'string',
        alignment: 'left',
        minWidth: 150,
      },
      {
        caption: t('labels.nearByPorts'),
        dataField: 'nearByPorts',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'cellTemplate',
        calculateCellValue: (data: AisGapData) => {
          return data.darkPortCalls?.map((item) => item.portName);
        },
      },
      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsRiskRating'
            : 'labels.riskRating'
        ),
        dataField: 'marsRiskRating',
        dataType: 'string',
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
      },
      {
        caption: t('labels.riskRating'),
        dataField: 'riskRating',
        dataType: 'string',
        lookup: {
          dataSource: riskRatingList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
        visible: companyType !== CompanyType.Mars,
      },
      {
        caption: t(
          companyType !== CompanyType.Mars
            ? 'labels.marsStatus'
            : 'labels.status'
        ),
        dataField: 'marsStatus',
        dataType: 'string',
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
      },
      {
        caption: t('labels.status'),
        dataField: 'status',
        dataType: 'string',
        lookup: {
          dataSource: complianceStatusList,
          valueExpr: 'optionValueCode',
          displayExpr: 'optionValueText',
        },
        minWidth: 150,
        visible: companyType !== CompanyType.Mars,
      },
      {
        caption: t('labels.action'),
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
  };

  const calculateCoordinatesCellValue = useCallback(
    (data: AisPosition) =>
      `${convertDecimalToMinutes(data.lat, true)} / ${convertDecimalToMinutes(
        data.lon
      )}`,
    []
  );

  const onClickMapIcon = async (index: number) => {
    const data = aisGapData[index];
    await api
      .get(
        {
          url: apiConfig.aisGapPositions,
          params: {
            aisGapDetailId: data.aisGapDetailId,
            voyageStartDate: data.voyageStartDate,
            voyageEndDate: data.voyageEndDate,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setSelectedRow(data);
        setSelectedIndex(index);
        formatCoordinatesData(res, data);
        setEventsGridData(data);
        setShowMapModal(true);
      });
  };

  const handleGridRefresh = () => {
    getAisGapData();
  };

  const setEventsGridData = (data: AisGapData) => {
    const events: Events[] = [
      {
        event: 'Voyage Start',
        coordinates: data.voyageStartCoordinates,
        dateTime: data.voyageStartDate,
      },
      {
        event: 'AIS Gap Start',
        coordinates: {
          lat: data.startPosition.lat,
          lon: data.startPosition.lon,
        },
        dateTime: data.startPosition.lastPositionUTC,
        nearByPort: data.darkPortCalls,
      },
      {
        event: 'AIS Gap End',
        coordinates: {
          lat: data.endPosition.lat,
          lon: data.endPosition.lon,
        },
        dateTime: data.endPosition.lastPositionUTC,
        nearByPort: data.darkPortCalls,
      },
      {
        event: 'Voyage End',
        coordinates: data.voyageEndCoordinates,
        dateTime: data.voyageEndDate,
      },
    ];
    setEventData(events);
  };

  const getSelectedItemIndex = (data: AisGapData) => {
    const itemIndex = aisGapData.findIndex(
      (item) => item.aisGapDetailId === data.aisGapDetailId
    );
    if (itemIndex != -1) {
      onClickMapIcon(itemIndex);
    }
  };

  const actionCellTemplate = (data: DataRowTemplateData) => {
    return (
      <div>
        <i
          className="dx-icon-map dx-custom-icon"
          onClick={() => getSelectedItemIndex(data.data)}
        ></i>
      </div>
    );
  };

  const getIconName = (
    coordinateData: AisPosition,
    aisGapCoordinatesData: AisGapCoordinatesData
  ) => {
    if (
      coordinateData.vesselAISPositionDetailId ===
      aisGapCoordinatesData.startPosition.vesselAISPositionDetailId
    ) {
      return 'aisGapStart';
    } else if (
      coordinateData.vesselAISPositionDetailId ===
      aisGapCoordinatesData.endPosition.vesselAISPositionDetailId
    ) {
      return 'aisGapEnd';
    }
    return 'arrowSymbol';
  };

  const getInfoWindowContent = (
    iconName: MarkerIcon,
    position: AisPosition,
    aisGapGridData: AisGapData
  ) => {
    const titles: Record<string, string> = {
      aisGapStart: t('labels.aisGapStart'),
      aisGapEnd: t('labels.aisGapEnd'),
    };

    return mapInfoWindow(
      position,
      titles[iconName] || vesselDetails?.vesselName || '',
      aisGapGridData,
      t
    );
  };

  const formatCoordinatesData = (
    aisGapCoordinatesData: AisGapCoordinatesData,
    aisGapGridData: AisGapData
  ) => {
    const polilineLocations: LatLngLiteral[] = [];
    const portCoordinates: Markers[] = [];
    const data: Markers[] =
      aisGapCoordinatesData?.vesselPositionsBeforeAndAfterGap.map(
        (item, index) => {
          const iconName =
            index === 0
              ? 'anchorageStart'
              : getIconName(item, aisGapCoordinatesData);
          const infoWindow = getInfoWindowContent(
            iconName,
            item,
            aisGapGridData
          );
          polilineLocations.push({
            lat: item.lat,
            lng: item.lon,
          });
          return {
            iconName: iconName,
            coordinates: {
              lat: item.lat,
              lng: item.lon,
            },
            iconRotation: item.heading,
            infoWindowContent: infoWindow,
          };
        }
      );
    aisGapCoordinatesData?.darkPortCalls?.forEach((item) => {
      portCoordinates.push({
        coordinates: {
          lat: item.latitude,
          lng: item.longitude,
        },
        infoWindowContent: (
          <div className="port-info-content">{item.portName}</div>
        ),
      });
    });
    setMapConfig({
      center: {
        lat: aisGapCoordinatesData.startPosition.lat,
        lng: aisGapCoordinatesData.startPosition.lon,
      },
      markers: [...data, ...portCoordinates],
      polylineConfig: getPolylineConfigList(data),
      legendsList: aisGapMapLegends,
    });
  };

  const getPolylineConfigList = (data: Markers[]) => {
    const aisStartIndex = data.findIndex(
      (item) => item.iconName === 'aisGapStart'
    );
    const aisEndIndex = data.findIndex((item) => item.iconName === 'aisGapEnd');
    const poly: PolylineConfig[] = [
      {
        position: data
          .slice(0, aisStartIndex + 1)
          .map((item) => item.coordinates),
      },
      {
        position: data
          .slice(aisStartIndex, aisEndIndex + 1)
          .map((item) => item.coordinates),
        isDashedLine: true,
      },
      {
        position: data.slice(aisEndIndex).map((item) => item.coordinates),
      },
    ];
    return poly;
  };

  const handleCloseMapModal = (isVisible: boolean, refreshData?: boolean) => {
    setShowMapModal(isVisible);
    if (refreshData) {
      getAisGapData();
    }
  };

  const portCellTemplate = (data: { data: AisGapData }) => {
    return (
      <>
        {data.data.darkPortCalls?.map((item) => {
          return (
            <div className="port-template">
              <i className="dx-icon-warning custom-icon"></i>
              {item.portName}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={'m-c-grid m-c-block-grid-list'}>
      <Grid
        gridConfig={gridConfig}
        actionTemplate={actionCellTemplate}
        cellTemplate={portCellTemplate}
      />
      {showMapModal && mapConfig && selectedRow && (
        <MapViewModal
          isReportGenerated={reportDetails?.isReportGenerated ?? false}
          eventData={eventData}
          mapConfig={mapConfig}
          vesselDetails={vesselDetails}
          isVisible={showMapModal}
          selectedIndex={selectedIndex}
          gridDataLength={aisGapData?.length}
          detailId={selectedRow.aisGapDetailId}
          complianceType={ComplianceTabType.AISGap}
          selectedRow={selectedRow}
          vetRequestId={vetRequestId}
          setIsVisible={handleCloseMapModal}
          handleNextPrev={onClickMapIcon}
          handleGridRefresh={handleGridRefresh}
        />
      )}
    </div>
  );
}
