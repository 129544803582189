import TextBox from 'devextreme-react/text-box';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../../../../contexts/PermissionContext';
import { IFormConfig } from '../../../../../types/form-builder';
import { IOptionvalue } from '../../../../../types/master-data';
import { getCompanyType } from '../../../../../utils/jwt-decode';
import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function PSCInspectionForm(props: {
  pscmou: IOptionvalue[];
  port: any;
  inspectionType: IOptionvalue[];
  inspectionStatus: IOptionvalue[];
  pscInspectionId: number;
  disableEdit: boolean;
  isMarsUser: any;
  marsStatus: any;
  isMarsOrExternal: any;
  stateOfPSCInspection: any;
  stateOfPSCInspectionVal: any;
  isMastersLoaded: boolean;
  pscInspectionDetails: any;
}) {
  const { t } = useTranslation();
  const companyType = getCompanyType();
  const {
    pscmou,
    inspectionType,
    port,
    inspectionStatus,
    pscInspectionId,
    disableEdit,
    isMarsUser,
    isMarsOrExternal,
    marsStatus,
    stateOfPSCInspection,
    stateOfPSCInspectionVal,
    isMastersLoaded,
    pscInspectionDetails,
  } = props;
  const { control, setValue, getValues } = useFormContext();
  const [forceRender, setForceRender] = useState(false);
  const { findFeature } = usePermission();
  const searchExpression = ['port', 'countryName'];

  const hideAdditionalFields = useMemo(() => {
    if (companyType === 'R') {
      return !findFeature('Ship.ViewPSCAdditionalDetails');
    } else {
      if (findFeature('Ship.EditPSC') || findFeature('Ship.AddPSC')) {
        return false;
      } else {
        return !findFeature('Ship.ViewPSCAdditionalDetails');
      }
    }
  }, [companyType]);

  const rowOneFormConfig: IFormConfig[] = useMemo(() => {
    if (
      isMastersLoaded &&
      ((pscInspectionId && pscInspectionDetails) || !pscInspectionId)
    ) {
      return [
        {
          class: 'col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
          fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
          name: 'inspectionDate',
          label: t('labels.dateOfInspection'),
          control: control,
          fieldType: 'date',
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
          disabled: disableEdit,
        },
        {
          class: 'col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
          fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
          name: 'portId',
          label: t('labels.portOfInspection'),
          control: control,
          fieldType: 'select',
          displayExpr: 'portName',
          searchExpr: searchExpression,
          valueExpr: 'portId',
          dataSource: port,
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
          disabled: disableEdit,
        },
        {
          class: 'col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
          fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
          name: 'pscmou',
          label: t('labels.authority'),
          control: control,
          fieldType: 'select',
          displayExpr: 'optionValueText',
          valueExpr: 'optionValueCode',
          dataSource: pscmou,
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
          disabled: disableEdit,
        },
        {
          class: 'col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
          fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
          name: 'pscInspectionType',
          label: t('labels.typeOfInspection'),
          control: control,
          fieldType: 'select',
          displayExpr: 'optionValueText',
          valueExpr: 'optionValueCode',
          dataSource: inspectionType,
          isRequired: true,
          rules: [
            {
              type: 'required',
              message: t('errors.required'),
            },
          ],
          disabled: disableEdit,
        },
      ];
    }
    return [];
  }, [pscInspectionDetails, isMastersLoaded]);

  const statusItemRender = (data: IOptionvalue) => {
    return (
      <div
        className={
          data?.optionValueCode === 'Open'
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === 'Closed'
            ? 'm-l-status-item status-bg-green'
            : data?.optionValueCode === 'WrongEntry'
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };

  const marsStatusRender = (marsStatus: string) => {
    return (
      <div
        className={
          marsStatus === 'Open'
            ? 'm-l-status-item status-bg-red'
            : marsStatus === 'Closed'
            ? 'm-l-status-item status-bg-green'
            : marsStatus === 'WrongEntry'
            ? 'status-wrong-entry-wtout'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {getStatusName(marsStatus)}
      </div>
    );
  };

  const statusFieldRender = (data: IOptionvalue) => {
    return (
      <TextBox
        className={
          data?.optionValueCode === 'Open'
            ? 'inactive-lbl'
            : data?.optionValueCode === 'Closed'
            ? 'active-lbl'
            : data?.optionValueCode === 'WrongEntry'
            ? 'status-wrong-entry-wtout'
            : 'draft-lbl'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  const onChangeDetention = (e: any) => {
    setForceRender(!forceRender);
    if (!e.value) {
      setValue('duration', null);
    }
  };

  const rowTwoFormConfig: IFormConfig[] = [
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20 tp-space-det',
      fieldClass: 'm-c-radio-button m-c-custom-space-radiobtn',
      name: 'isDetained',
      label: t('labels.detention'),
      control: control,
      fieldType: 'radio',
      items: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
      displayExpr: 'label',
      valueExpr: 'value',
      layout: 'horizontal',
      onChange: onChangeDetention,
      disabled: disableEdit,
    },
    {
      class: 'col-sm-6 m-l-custom-sixteen-space-col',
      name: 'duration',
      label: t('labels.durationDays'),
      control: control,
      fieldType: 'number',
      disabled: disableEdit ? disableEdit : !getValues().isDetained,
    },
  ];
  const rowThreeFormConfig: IFormConfig[] = [
    {
      class: 'col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
      name: 'noOfDeficiencies',
      label: t('labels.noOfDeficiencies'),
      control: control,
      fieldType: 'number',
      disabled: disableEdit,
      maxLength: 7,
    },
    {
      class: 'col-xl-3 col-sm-6 col-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group',
      fieldClass: 'm-c-text-area m-c-psc-text-area',
      name: 'comment',
      label: t('labels.comments'),
      control: control,
      fieldType: 'textArea',
      disabled:
        companyType === 'R'
          ? true
          : isMarsUser && isMarsOrExternal == false
          ? true
          : false,
      isHidden: hideAdditionalFields,
    },
  ];
  const rowFourFormConfig: IFormConfig[] = [
    {
      class: 'd-block',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-20',
      name: 'status',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: inspectionStatus,
      fieldClass: 'm-c-input-control m-c-select-box m-c-st-box',
      fieldRender: statusFieldRender,
      itemRender: statusItemRender,
      disabled:
        companyType === 'R'
          ? true
          : isMarsUser && isMarsOrExternal == false
          ? true
          : false,
      isHidden: hideAdditionalFields,
    },
  ];
  const rowFiveFormConfig: IFormConfig[] = [
    {
      class: 'col-xl-9 col-sm-6 col-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-24',
      fieldClass: 'm-c-text-area m-c-psc-text-area',
      name: 'deficiencyDescription',
      label: t('labels.deficiencyDescription'),
      control: control,
      fieldType: 'textArea',
      disabled:
        companyType === 'R'
          ? true
          : isMarsUser && isMarsOrExternal == false
          ? true
          : false,
      isHidden: hideAdditionalFields,
    },
  ];

  const getStatusName = useCallback(
    (statusCode?: string | null) => {
      const status = inspectionStatus.find(
        (item) => item.optionValueCode === statusCode
      );
      return status ? status.optionValueText : null;
    },
    [inspectionStatus]
  );

  const getStateOfPSCInspection = () => {
    if (stateOfPSCInspection) {
      const item = stateOfPSCInspectionVal.find(
        (item: { optionValueCode: any }) =>
          item.optionValueCode == stateOfPSCInspection
      );
      return item?.optionValueText;
    } else {
      return '';
    }
  };

  // useEffect(() => {
  //   getStateOfPSCInspection();
  // }, [stateOfPSCInspection]);

  return (
    <>
      <div className="row m-l-custom-sixteen-space-row">
        <FormBuilder formConfig={rowOneFormConfig} />
      </div>
      <div className="row m-l-custom-sixteen-space-row">
        <div className="col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
          <div className="row m-l-custom-sixteen-space-row">
            <FormBuilder formConfig={rowTwoFormConfig} />
          </div>
        </div>
        <FormBuilder formConfig={rowThreeFormConfig} />
        <div className="col-xl-3 col-sm-6 col-xs-12 m-l-custom-sixteen-space-col">
          <FormBuilder formConfig={rowFourFormConfig} />
        </div>
      </div>

      <div className="row m-l-custom-sixteen-space-row">
        <FormBuilder formConfig={rowFiveFormConfig} />

        {pscInspectionId != 0 && (
          <>
            {isMarsUser && (
              <>
                <div className="col-xl-3 col-sm-6 m-l-custom-sixteen-space-col">
                  <div className="m-c-form-group mb-btm-space-24">
                    <label className="m-l-input-label">
                      {' '}
                      {t('labels.source')}
                    </label>
                    <div className="m-l-data-label">{getValues().source}</div>
                  </div>
                </div>
                {stateOfPSCInspection && (
                  <div className="col-xl-3 col-sm-6 m-l-custom-sixteen-space-col">
                    <div className="m-c-form-group mb-btm-space-24">
                      <label className="m-l-input-label">
                        {t('labels.pscStatus')}
                      </label>
                      <div className="m-l-data-label">
                        {getStateOfPSCInspection()}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            {!isMarsUser && !hideAdditionalFields && (
              <div className="col-xl-3 col-sm-6 m-l-custom-sixteen-space-col">
                <div className="m-c-form-group mb-btm-space-24">
                  <label className="m-l-input-label">
                    {t('labels.marsStatus')}
                  </label>
                  <div className="m-l-data-label">
                    {marsStatusRender(marsStatus)}
                  </div>
                </div>
              </div>

              // <div className="col-xl col-lg col-md col-sm-4 col-6 m-l-custom-sixteen-space-col">
              //   <div className="m-c-form-group mb-btm-space-24 tp-space-source">
              //     <label className="m-l-input-label">
              //       {t('labels.marsStatus')}
              //     </label>
              //     <span className="m-l-data-label">
              //       {marsStatusRender(marsStatus)}
              //     </span>
              //   </div>
              // </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
