import TabPanel from 'devextreme-react/tab-panel';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import AisGrid from '../ais-grid/AISGrid';
import LoiteringGrid from '../loitering-grid/LoiteringGrid';
import { VesselDetails } from '../../../types/vessel-general';
import StsGrid from '../sts-grid/StsGrid';
import PortCalls from '../../vessel-info/vessel-details/port-calls/PortCalls';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import ReportToPdf from '../../../pages/vet-report/ReportToPdf';
import { ReportType } from '../../../enums/report-type-enum';
import icons from '../../common/icons/icons';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import {
  IComplianceReport,
  ReportAttachment,
  ReportGeneratedDetails,
} from '../../../types/compliance-report';
import Tooltip from 'devextreme-react/tooltip';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { getAPIRoute } from '../../../utils/api-route';
import { getCurrentBuId } from '../../../utils/jwt-decode';
import { useDateFormat } from '../../../hooks/useDateFormat';
import FileSaver from 'file-saver';
import { AttachmentUploadType } from '../../../enums/compliance-check-enum';
import ReportSummaryModal from '../../sanctions-check/add-reportSummary-modal/ReportSummaryModal';
import { Restricted, usePermission } from '../../../contexts/PermissionContext';

export default function ComplianceTab(props: {
  imoNumber: string;
  vesselDetails: VesselDetails;
  vetRequestId?: string;
  isFromVet?: boolean;
}) {
  const { imoNumber, vesselDetails, vetRequestId, isFromVet } = props;
  const tabRef = useRef<TabPanel>(null);
  const { t } = useTranslation();
  const { apiDateAndTimeFormat } = useDateFormat();
  const { findFeature, featureList } = usePermission();
  const { setIsLoading } = useLoaderContext();
  const complianceCheckContext = useContext(ComplianceCheckContext);
  const [report, setReport] = useState<boolean>(false);
  const [reportSummary, setReportSummary] = useState<string>('');
  const [onReportGenerate, setOnReportGenerate] = useState<boolean>(false);
  const [complianceReportDetails, setComplianceReportDetails] =
    useState<IComplianceReport>();
  const [pdfFile, setPdfFile] = useState<File[]>([]);
  const [compliancePdf, setCompliancePdf] = useState<ReportAttachment>();
  const [isAttachPdf, setIsAttachPdf] = useState(false);
  const [reportGeneratedDetails, setReportGeneratedDetails] =
    useState<ReportGeneratedDetails>();
  const currentBuId = getCurrentBuId();

  useEffect(() => {
    if (complianceCheckContext?.complianceCheckId) {
      checkIsReportGenerated();
      getComplianceReport();
    }
  }, []);

  const tabItems = useMemo(() => {
    if (featureList.length > 0) {
      return [
        {
          title: 'AIS Gap',
          visible: findFeature(
            vetRequestId
              ? 'VetRequestComplianceCheck.ViewAISGap'
              : 'ComplianceCheck.ViewAISGap'
          ),
        },
        {
          title: 'Loitering Risks',
          visible: findFeature(
            vetRequestId
              ? 'VetRequestComplianceCheck.ViewLoitering'
              : 'ComplianceCheck.ViewLoitering'
          ),
        },
        {
          title: 'STS',
          visible: findFeature(
            vetRequestId
              ? 'VetRequestComplianceCheck.ViewSTS'
              : 'ComplianceCheck.ViewSTS'
          ),
        },
        {
          title: 'Movement Risk',
          visible: findFeature(
            vetRequestId
              ? 'VetRequestComplianceCheck.ViewMovementRisk'
              : 'ComplianceCheck.ViewMovementRisk'
          ),
        },
      ];
    }
    return [];
  }, [featureList]);

  useEffect(() => {
    if (pdfFile?.length > 0) {
      uploadComplianceReport(pdfFile);
    }
  }, [pdfFile]);

  const uploadComplianceReport = async (file: File[]) => {
    const data = new FormData();
    if (file) {
      for (const item in file) {
        data.append('Files', file[item]);
      }
      await api
        .post(
          {
            url: apiConfig.attachments,
            data: data,
            params: {
              attachmentType: AttachmentUploadType.Common,
              imoNumber: imoNumber,
              complianceCheckId: complianceCheckContext?.complianceCheckId,
              complianceReportType: AttachmentUploadType.Compliance,
            },
          },
          setIsLoading
        )
        .then((response) => {
          getComplianceReport();
          checkIsReportGenerated();
          if (response) {
            downloadAttachment(response);
          }
        });
    }
  };

  const getComplianceReport = async () => {
    await api
      .get({
        url: apiConfig.getAttachment,
        params: {
          complianceReportType: AttachmentUploadType.Compliance,
          complianceCheckId: complianceCheckContext?.complianceCheckId,
        },
      })
      .then((Response) => {
        setCompliancePdf(Response[0]);
      });
  };

  const downloadAttachment = (attachment?: ReportAttachment[]) => {
    let attachmentId = 0;
    let attachmentName = '';
    if (attachment) {
      attachmentId = attachment[0].attachmentId;
      attachmentName = attachment[0].fileName;
    }
    api
      .get(
        {
          url:
            apiConfig.attachments +
            (compliancePdf?.attachmentId ?? attachmentId),
          responseType: 'arraybuffer',
        },
        setIsLoading
      )
      .then((res) => {
        setIsLoading(true);
        FileSaver.saveAs(
          new Blob([res]),
          compliancePdf?.fileName ?? attachmentName
        );
        setIsLoading(false);
      });
  };

  const getComplianceReportDetails = async () => {
    await api
      .get(
        {
          url: apiConfig.getComplianceReport,
          params: {
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            imo: imoNumber,
            buId: currentBuId,
            isMovementRisk: true,
          },
        },
        setIsLoading
      )
      .then((response) => {
        setComplianceReportDetails(response);
        setIsAttachPdf(true);
        setReport(true);
      });
  };

  const checkIsReportGenerated = async () => {
    await api
      .get({
        url: getAPIRoute('getGenerateComplianceReport', [
          complianceCheckContext?.complianceCheckId,
        ]),
      })
      .then((response) => {
        setReportGeneratedDetails(response);
      });
  };

  const generateComplianceReport = async () => {
    await api
      .put(
        {
          url: apiConfig.generateComplianceReport,
          data: {
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            summary: reportSummary,
          },
        },
        setIsLoading
      )
      .then(() => {
        getComplianceReportDetails();
        setOnReportGenerate(false);
      });
  };

  const tabContentRender = (e: any) => {
    if (e.title === tabItems[0].title) {
      return (
        <AisGrid
          imoNumber={imoNumber}
          vesselDetails={vesselDetails}
          reportDetails={reportGeneratedDetails}
          vetRequestId={vetRequestId}
        />
      );
    } else if (e.title === tabItems[1].title) {
      return (
        <LoiteringGrid
          imoNumber={imoNumber}
          vesselDetails={vesselDetails}
          reportDetails={reportGeneratedDetails}
          vetRequestId={vetRequestId}
        ></LoiteringGrid>
      );
    } else if (e.title === tabItems[2].title) {
      return (
        <StsGrid
          imoNumber={imoNumber}
          vesselDetails={vesselDetails}
          reportDetails={reportGeneratedDetails}
          vetRequestId={vetRequestId}
        ></StsGrid>
      );
    } else if (e.title === tabItems[3].title) {
      return (
        <PortCalls
          imoNumber={imoNumber}
          isMovementRisk={true}
          complianceCheckId={complianceCheckContext?.complianceCheckId}
        />
      );
    }
    return <></>;
  };

  const handleSetPdf = (file: File[]) => {
    setPdfFile(file);
  };

  const setComplianceReportSummary = (summary: string) => {
    setReportSummary(summary);
  };

  const closeModal = () => {
    setOnReportGenerate(false);
  };

  return (
    <>
      <div className={`m-l-inner-page-tab-section ${!!vetRequestId && 'u-p0'}`}>
        <div className="m-l-inner-page-body u-mb0 ">
          <div className="m-l-tab-panel__wrap">
            <TabPanel
              ref={tabRef}
              items={tabItems}
              className="m-c-tab-block"
              // onSelectionChanged={onTabSelectionChanged}
              itemRender={tabContentRender}
              showNavButtons={true}
            ></TabPanel>

            {!reportGeneratedDetails?.isReportGenerated && !vetRequestId && (
              <Restricted permission="ComplianceCheck.GenerateReport">
                <div className="m-l-tab-panel-right-block">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setOnReportGenerate(true);
                    }}
                  >
                    {t('buttons.generateReport')}
                  </Button>
                </div>
              </Restricted>
            )}
            <div className="m-l-tab-panel-right-block u-h-36">
              {vetRequestId && isFromVet && (
                <div className="u-pl">
                  <span>{t('labels.complianceCheckId')} :</span>
                  <span className="m-l-page-id-text">
                    {' '}
                    {complianceCheckContext?.complianceCheckId}{' '}
                  </span>
                </div>
              )}
              {reportGeneratedDetails?.isReportGenerated && (
                <>
                  {reportGeneratedDetails?.date && (
                    <div className="u-pl u-pr">
                      <span>{t('labels.generatedOn')} :</span>
                      <span className="m-l-page-id-text">
                        {' '}
                        {apiDateAndTimeFormat(
                          reportGeneratedDetails?.date
                        )}{' '}
                      </span>
                    </div>
                  )}
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequestComplianceCheck.DownloadReport'
                        : 'ComplianceCheck.DownloadReport'
                    }
                  >
                    <Button
                      className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                      aria-label="Export-btn"
                      onClick={() => downloadAttachment()}
                      id="downloadReport"
                    >
                      <div
                        className="m-c-icon-inside-btn"
                        data-testid="export-all"
                      >
                        <Tooltip
                          target={'#downloadReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.downloadComplianceReport')}
                                <span className="generated-by">
                                  Report Generated By:{' '}
                                  {reportGeneratedDetails?.userName}
                                </span>
                              </div>
                            );
                          }}
                        />
                        <icons.download />
                      </div>
                    </Button>
                  </Restricted>
                </>
              )}
            </div>
          </div>
        </div>

        {report && (
          <div>
            <ReportToPdf
              report={report}
              pdfFile={pdfFile}
              setPdfFile={handleSetPdf}
              isAttachPdf={isAttachPdf}
              setIsAttachPdf={setIsAttachPdf}
              setReport={setReport}
              reportType={ReportType.ComplianceReport}
              complianceReportDetails={complianceReportDetails}
            />
          </div>
        )}

        {onReportGenerate && (
          <ReportSummaryModal
            isModalVisible={onReportGenerate}
            reportSummary={reportSummary}
            handleSetReportSummary={setComplianceReportSummary}
            handleCancelClick={closeModal}
            generateReport={generateComplianceReport}
          />
        )}
      </div>
      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {!reportGeneratedDetails?.isReportGenerated && !vetRequestId && (
                <Restricted permission="ComplianceCheck.GenerateReport">
                  <Button
                    className="app-c-btn app-c-btn--primary min-btn-width"
                    onClick={() => {
                      setOnReportGenerate(true);
                    }}
                  >
                    {t('buttons.generateReport')}
                  </Button>
                </Restricted>
              )}

              {reportGeneratedDetails?.isReportGenerated && (
                <>
                  {reportGeneratedDetails?.date && (
                    <div className="u-pl u-pr">
                      <span>{t('labels.generatedOn')} :</span>
                      <span className="m-l-page-id-text">
                        {' '}
                        {apiDateAndTimeFormat(
                          reportGeneratedDetails?.date
                        )}{' '}
                      </span>
                    </div>
                  )}
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequestComplianceCheck.DownloadReport'
                        : 'ComplianceCheck.DownloadReport'
                    }
                  >
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      aria-label="Export-btn"
                      onClick={() => downloadAttachment()}
                      id="downloadReport"
                    >
                      {t('labels.download')}
                    </Button>
                  </Restricted>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
