import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IGridConfig } from '../../../types/grid';
import { useTranslation } from 'react-i18next';
import Grid from '../../common/grid/Grid';
import icons from '../../common/icons/icons';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { getAPIRoute } from '../../../utils/api-route';
import CustomStore from 'devextreme/data/custom_store';
import { BerthFitHistoryStatus } from '../../../enums/status-enum';
import { VesselInfoTabIndex } from '../../../enums/vessel-info-tab.enum';

export default function BerthFitHistoryGrid(props: {
  imoNumber?: string;
  setIsBerthFit?: any;
  setBerthfitId?: any;
  setBerthDetails?: any;
  tabRef?: any;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [historyData, setHistoryData] = useState<any>();
  const { imoNumber, setIsBerthFit, tabRef } = props;

  useEffect(() => {
    if (imoNumber) {
      getBirthFitHistory();
    }
  }, [imoNumber]);

  const getBirthFitHistory = async () => {
    await api
      .get({ url: getAPIRoute('berthFitHistory', [imoNumber]) })
      .then((res) => {
        setHistoryData(res);
      })
      .catch(() => {
        // console.log(e);
      });
  };

  const statusDataSource = {
    store: new CustomStore({
      key: 'optionValueCode',
      loadMode: 'raw',
      load: () => {
        return api.get({ url: apiConfig.berthFitCheckStatusOptions });
      },
    }),
  };

  const onRowClick = (e: any) => {
    setIsBerthFit(true);
    tabRef?.current?.instance.option(
      'selectedIndex',
      VesselInfoTabIndex.BerthFitCheck
    );
    navigate('/vessel-info/berth-fit-check', {
      state: {
        berthFitID: e.key.berthFitId,
        berthDetails: e.data,
        imoNumber: imoNumber,
      },
    });
  };

  const gridConfig: IGridConfig = {
    testId: 'berthFitHistoryGrid',
    dataSource: historyData,
    defaultColumns: [
      {
        caption: t('labels.berthFitID'),
        dataField: 'berthId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.berthFitFinalizedDate'),
        dataField: 'berthFitFinalizedDate',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
        sortOrder: 'desc',
      },
      {
        caption: t('labels.vetId'),
        dataField: 'vetId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.port'),
        dataField: 'portName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.attachment'),
        dataField: 'attachmentsIcon',
        dataType: 'string',
        minWidth: 150,
        cellTemplate: 'actionTemplate',
      },
      {
        caption: t('labels.status'),
        dataField: 'berthFitCheckStatus',
        dataType: 'boolean',
        minWidth: 150,
        cellTemplate: 'statusTemplate',
        lookup: {
          dataSource: statusDataSource,
          valueExpr: 'optionValueText',
          displayExpr: 'optionValueText',
        },
      },
      {
        caption: t('labels.berthFitCreatedDate'),
        dataField: 'berthFitCreatedDate',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
      },
    ],
    showHeader: true,
    showAddButton: false,
    hidePagination: false,
    noDataText: t('labels.noBerthFitFound'),
    rowClick: onRowClick,
    onClickAddButton: () => {
      navigate(-1);
    },
  };

  const cellRender = (data: any) => {
    return (
      <div className="m-l-field-wrap">
        {data?.row?.key?.berthFitCheckStatusCode ===
          BerthFitHistoryStatus.acceptable && <icons.circleTickDanger />}
        {data?.row?.key?.berthFitCheckStatusCode ===
          BerthFitHistoryStatus.notAcceptable && <icons.circleCrossDanger />}
        {data?.row?.key?.berthFitCheckStatusCode ===
          BerthFitHistoryStatus.furtherInfoNeeded && (
          <icons.circleCrossDanger />
        )}
      </div>
    );
  };

  const actionTemplate = (data: any) => {
    return (
      <div className="m-l-field-wrap">
        {data?.row?.key?.isAttachmentsAdded === true && <icons.IconAttach12 />}
      </div>
    );
  };

  return (
    <div className={'m-c-grid m-c-block-grid-list'}>
      <Grid
        gridConfig={gridConfig}
        statusTemplate={cellRender}
        actionTemplate={actionTemplate}
      />
    </div>
  );
}
