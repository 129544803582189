import React from 'react';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useTranslation } from 'react-i18next';
import Button from 'devextreme-react/button';
import icons from '../../../common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { ClickEvent } from 'devextreme/ui/button';

export default function CommentViewSection(props: {
  comments: string;
  username: string;
  commentDateTime: string;
  label: string;
  downloadTooltipText: string;
  downloadButtonId: string;
  downloadPermission: string;
  downloadButtonCallback: (e: ClickEvent) => void;
}) {
  const { t } = useTranslation();
  const { apiDateAndTimeFormat } = useDateFormat();
  const {
    comments,
    username,
    commentDateTime,
    label,
    downloadButtonId,
    downloadTooltipText,
    downloadPermission,
    downloadButtonCallback,
  } = props;
  return (
    <div className="m-l-appl-comment-wrapper">
      <div className="m-l-appl-comment-section">
        <div className="m-l-inner-page-header">
          <div className="m-l-inner-page-header-left-block">
            <h6 className="m-l-appl-title">{label}</h6>
          </div>
          <Restricted permission={downloadPermission}>
            <div className="m-l-inner-page-header-right-block">
              <Button
                className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                aria-label="Export-btn"
                elementAttr={{ 'data-testid': 'exportButton' }}
                id={downloadButtonId}
                onClick={(e) => downloadButtonCallback(e)}
              >
                <div className="m-c-icon-inside-btn" data-testid="export-all">
                  <icons.download />
                </div>
              </Button>
              <Tooltip
                target={`#${downloadButtonId}`}
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                position="bottom"
                contentRender={() => {
                  return (
                    <div className="m-l-tooltip-wrapper">
                      {downloadTooltipText}
                    </div>
                  );
                }}
              />
            </div>
          </Restricted>
        </div>
        {comments ? <div className="m-l-appl-cmnt">{comments}</div> : null}
        <div className="m-l-appl-commentor-holder">
          <div className="m-l-appl-commentor">
            <div className="m-l-appl-commentor-name">{username}</div>
            <ul className="m-l-appl-date">
              <li>
                <span className="m-l-usr-dt-item">
                  {apiDateAndTimeFormat(commentDateTime)}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="m-l-inner-page-footer">
          <div className="row m-l-custom-eight-space-row">
            <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
              <div className="m-l-group-button-btms">
                <Restricted permission={'VetRequest.DownloadVetReport'}>
                  <Button
                    className="app-c-btn app-c-btn--secondary min-btn-width"
                    onClick={(e) => downloadButtonCallback(e)}
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                  >
                    {t('labels.download')}
                  </Button>
                </Restricted>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
