import React, { useMemo, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import TextBox from 'devextreme-react/text-box';
import Modal from '../../modal/Modal';
import icons from '../../icons/icons';
import { IModalConfig } from '../../../../types/modal';
import { useTranslation } from 'react-i18next';
import Validator from 'devextreme-react/validator';
import ValidationGroup from 'devextreme-react/validation-group';

export default function BoundarySaveModal(props: {
  isVisible: boolean;
  layerType?: string;
  shape: string;
  handleCloseClick: () => void;
  handleSaveClick?: (boundaryName: string, shape: string) => void;
}) {
  const { isVisible, shape, handleCloseClick, handleSaveClick } = props;
  const { t } = useTranslation();
  const validationGroupStsProneAreaName: any = useRef();
  const modalConfigPsc: IModalConfig = {
    width: 200,
    height: 250,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);

  const [boundaryName, setBoundaryName] = useState<string>('');

  const onChange = (e: any) => {
    setBoundaryName(e?.event?.target?.value);
  };

  const onBoundaryNameSave = () => {
    if (validationGroupStsProneAreaName.current?.instance.validate().isValid) {
      if (handleSaveClick) {
        handleSaveClick(boundaryName, shape);
      }
    }
  };

  return (
    <Modal modalConfig={modalConfigPsc} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <h2 className="modal-title">{t('labels.stsProneArea')}</h2>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={handleCloseClick}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <div className="app-l-cmp-wrapper form-elements u-pt0">
          <div className="app-l-cmp-data-container">
            <div className="app-l-cmp-row">
              <div className="app-l-cmp-items">
                <div className="mb-3 m-c-form-group">
                  <label className="m-l-input-label">
                    {' '}
                    {t('labels.stsProneAreaName')}
                  </label>

                  <ValidationGroup ref={validationGroupStsProneAreaName}>
                    <TextBox
                      className="m-c-input-control  m-c-input-text"
                      placeholder=""
                      value={boundaryName}
                      onChange={onChange}
                      validationMessageMode="always"
                    >
                      <Validator
                        validationRules={[
                          {
                            type: 'required',
                            message: t('errors.required'),
                          },
                        ]}
                      />
                    </TextBox>
                  </ValidationGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-l-modal__footer">
        <div className="m-l-group-btn-wrap">
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onBoundaryNameSave}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
}
