import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

export default function GestureControl() {
  const map: any = useMap();
  useEffect(() => {
    map.addHandler('gestureHandling', GestureHandling);
    map.gestureHandling.enable();
  }, [map]);
  return <></>;
}
