import Button from 'devextreme-react/button';
import ScrollView from 'devextreme-react/scroll-view';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IFormConfig } from '../../../../../types/form-builder';
import { IModalConfig } from '../../../../../types/modal';
import {
  IVesselNameForm,
  IVesselNameJson,
  IVesselNamePostJson,
} from '../../../../../types/vessel-name-tab';
import FormBuilder from '../../../../common/form-builder/FormBuilder';
import icons from '../../../../common/icons/icons';
import Modal from '../../../../common/modal/Modal';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { getAPIRoute } from '../../../../../utils/api-route';
import { IDialogConfig } from '../../../../../types/dialog';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import toast from '../../../../../utils/toast';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function EditName(props: {
  imoNumber?: string;
  historyId: number;
  handleCancelClick: any;
  isVisible: boolean;
  getVesselName: () => void;
}) {
  const { imoNumber, historyId, handleCancelClick, getVesselName } = props;
  const { setIsLoading } = useLoaderContext();
  const { t } = useTranslation();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const { apiDateFormat } = useDateFormat();

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.vesselNameConfirmSave'),
    handleSubmit: () => {
      saveName(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.vesselNameConfirmBack'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      handleCancelClick(false);
      setTabDirtyFlag.setState(
        setTabDirtyFlag.state == 0
          ? setTabDirtyFlag.state
          : setTabDirtyFlag.state - 1
      );
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };
  const confirmNameChangeDialogConfig: IDialogConfig = {
    id: 'confirmNameChange',
    content: t('toast.vesselNameChangeConfirm'),
    handleSubmit: () => {
      saveName(true);
    },
    handleClose: () => {
      setShowConfirmNameChange(false);
    },
  };

  const [showConfirmSave, setShowConfirmSave] = useState(false);

  const [showConfirmBack, setShowConfirmBack] = useState(false);

  const [showConfirmNameChange, setShowConfirmNameChange] = useState(false);

  const [vesselNameDetails, setVesselNameDetails] = useState<IVesselNameForm>();

  const formDefaultValues: IVesselNameForm = {
    dateFrom: null,
    name: '',
  };

  const { control, reset, formState, getValues } = useForm({
    defaultValues: formDefaultValues,
  });

  const { isDirty } = formState;

  const nameFormConfig: IFormConfig[] = [
    {
      class:
        'col-md-12 col-sm-12 col-lg-12 col-xs-12 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space',
      name: 'name',
      label: t('labels.name'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class:
        'col-md-12 col-sm-12 col-lg-12 col-xs-12 col-xs-12 m-l-custom-sixteen-space-col',
      fieldWrapperClass: 'm-c-form-group mb-btm-space',
      name: 'dateFrom',
      label: t('labels.dateFrom'),
      control: control,
      fieldType: 'date',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  const modalConfig: IModalConfig = {
    width: 450,
    height: 310,
    visible: props.isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const getVesselNameDetails = async () => {
    await api
      .get({ url: getAPIRoute('vesselNameDetails', [imoNumber, historyId]) })
      .then((res) => {
        setVesselNameDetails(res);
      });
  };

  useEffect(() => {
    if (historyId && imoNumber) {
      getVesselNameDetails();
    }
  }, [historyId, imoNumber]);

  useEffect(() => {
    if (vesselNameDetails) {
      reset(vesselNameDetails, { keepDirty: true });
    }
  }, [vesselNameDetails]);

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setShowConfirmSave(true);
    }
  };

  const saveName = async (isCurrentVesselNameChange: boolean) => {
    const data: IVesselNameJson = {
      imoNumber: imoNumber,
      historyId: historyId,
      vesselName: getValues().name,
      dateFrom: apiDateFormat(getValues().dateFrom),
      isCurrentVesselNameChange: isCurrentVesselNameChange,
    };
    if (historyId) {
      await api
        .put({ url: apiConfig.updateVesselName, data: data }, setIsLoading)
        .then((res) => {
          setShowConfirmSave(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          if (res.isCurrentName) {
            setShowConfirmNameChange(true);
          } else {
            toast.success({ title: t('toast.savedSuccessfully') });
            handleCancelClick();
            getVesselName();
          }
        });
    } else {
      await api
        .post(
          {
            url: apiConfig.updateVesselName,
            data: data as IVesselNamePostJson,
          },
          setIsLoading
        )
        .then((res) => {
          setShowConfirmSave(false);
          setTabDirtyFlag.setState(
            setTabDirtyFlag.state == 0
              ? setTabDirtyFlag.state
              : setTabDirtyFlag.state - 1
          );
          if (!res?.responseStatus) {
            setShowConfirmNameChange(true);
          } else {
            toast.success({ title: t('toast.savedSuccessfully') });
            handleCancelClick();
            getVesselName();
          }
        });
    }
  };

  const onClickBack = () => {
    isDirty ? setShowConfirmBack(true) : handleCancelClick();
  };

  useEffect(() => {
    if (isDirty) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-model-popup__wrap" data-testid="editName">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {historyId !== 0 && (
            <h2 className="modal-title">{t('headers.editName')}</h2>
          )}
          {!historyId && (
            <h2 className="modal-title">{t('headers.addName')}</h2>
          )}
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={() => onClickBack()}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          <ScrollView width="100%" height="100%">
            <div className="m-l-edit-form-panel">
              <div className="row m-l-custom-sixteen-space-row">
                <FormBuilder formConfig={nameFormConfig} />
              </div>
            </div>
          </ScrollView>
        </div>
        <div className="m-l-modal__footer">
          <Button
            className="app-c-btn app-c-btn--secondary min-btn-width"
            onClick={onClickBack}
            elementAttr={{ 'data-testid': 'backButton' }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            onClick={onClickSave}
            elementAttr={{ 'data-testid': 'saveButton' }}
          >
            {t('buttons.save')}
          </Button>
        </div>
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={showConfirmBack}
      />
      <ConfirmDialog
        dialogConfig={confirmNameChangeDialogConfig}
        isOpen={showConfirmNameChange}
      />
    </div>
  );
}
