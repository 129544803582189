import { format } from 'date-fns';
import React from 'react';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import {
  AisGapData,
  AisPosition,
  LoiteringData,
  StsComplianceData,
  StsData,
} from '../../../types/compliance-check';
import { TFunction } from 'react-i18next';
import { ComplianceTabType } from '../../../enums/compliance-check-enum';
import { VesselDetails } from '../../../types/vessel-general';
import countryList from '../../Country.json';

export const mapInfoWindow = (
  position: AisPosition,
  headerText: string,
  gridData: AisGapData | LoiteringData | StsComplianceData | VesselDetails,
  t: TFunction<'translation', undefined>,
  complianceType?: number,
  vesselType?: string
) => {
  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == position.portDetails?.iso3
  );

  return (
    <div className="map-info-window-container">
      <div className="map-info-window-header">{headerText}</div>
      {position.lastPositionUTC && (
        <div className="time-block">
          {dateInGMTFormat(position.lastPositionUTC)}
        </div>
      )}
      {position.lat && (
        <p className="map-info-window-label">
          {t('labels.coordinates')}:{' '}
          <strong>
            {convertDecimalToMinutes(position.lat, true) +
              '/' +
              convertDecimalToMinutes(position.lon)}
          </strong>
        </p>
      )}
      {vesselType !== 'proximity' && (
        <>
          {gridData?.voyageStart && (
            <p className="map-info-window-label">
              {t('labels.voyageStart')}:{' '}
              <strong>{gridData?.voyageStart}</strong>
            </p>
          )}
          {gridData?.voyageEnd && (
            <p className="map-info-window-label">
              {t('labels.voyageEnd')}: <strong>{gridData?.voyageEnd}</strong>
            </p>
          )}
        </>
      )}
      {complianceType === ComplianceTabType.STS && (
        <>
          {vesselType === 'proximity' ? (
            <>
              {'proximityVesselVoyageStart' in gridData &&
                gridData?.proximityVesselVoyageStartPortName && (
                  <p className="map-info-window-label">
                    {t('labels.voyageStart')}:{' '}
                    <strong>
                      {gridData?.proximityVesselVoyageStartPortName}
                    </strong>
                  </p>
                )}

              {'proximityVesselVoyageEnd' in gridData &&
                gridData?.proximityVesselVoyageEndPortName && (
                  <p className="map-info-window-label">
                    {t('labels.voyageEnd')}:{' '}
                    <strong>
                      {gridData?.proximityVesselVoyageEndPortName}
                    </strong>
                  </p>
                )}

              {'proximityVesselDuration' in gridData &&
                gridData?.proximityVesselDuration !== null && (
                  <p className="map-info-window-label">
                    {t('labels.duration')}:{' '}
                    <strong>{gridData?.proximityVesselDuration}</strong>
                  </p>
                )}
            </>
          ) : (
            <>
              {'proximityVessel' in gridData && gridData?.proximityVessel && (
                <p className="map-info-window-label">
                  {t('labels.proximityVessel')}:{' '}
                  <strong>{gridData?.proximityVessel}</strong>
                </p>
              )}
              {'duration' in gridData && gridData?.duration !== null && (
                <p className="map-info-window-label">
                  {t('labels.duration')}: <strong>{gridData?.duration}</strong>
                </p>
              )}
            </>
          )}
        </>
      )}
      {(position.speed || position.speed === 0) && (
        <p className="map-info-window-label">
          {t('labels.speed')}:{' '}
          <strong>{`${position.speed.toFixed(2)} kn`}</strong>
        </p>
      )}
      {position.portDetails && vesselType !== 'proximity' && (
        <>
          <p className="map-info-window-label">
            {t('labels.portName')}:{' '}
            <strong>{position?.portDetails?.portName ?? 'Unknown'}</strong>
          </p>
          <p className="map-info-window-label">
            {t('labels.country')}:
            <img
              src={filteredCountryList?.flag}
              className="img-responsive"
              alt="flag"
              height={20}
              width={20}
              style={{ margin: '0 5px' }}
            />
            <strong>{position?.portDetails?.countryName ?? 'Unknown'}</strong>
          </p>
          <p className="map-info-window-label">
            {t('labels.portCoordinates')}:{' '}
            <strong>
              {position?.portDetails?.latitude !== undefined &&
              position?.portDetails?.longitude !== undefined
                ? `${convertDecimalToMinutes(
                    position?.portDetails?.latitude ?? 0,
                    true
                  )} / ${convertDecimalToMinutes(
                    position?.portDetails?.longitude ?? 0
                  )}`
                : '-'}
            </strong>
          </p>
        </>
      )}
    </div>
  );
};

const dateInGMTFormat = (date: string) => {
  const dateObj = new Date(date.replace(/Z$/, ''));
  return format(dateObj, "dd MMM yyyy HH:mm:ss 'GMT'");
};
