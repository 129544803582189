import DataGrid from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IBerthParameter } from '../../../../types/berthfit-execution';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import icons from '../../../common/icons/icons';

export default function BerthParameterGrid(props: {
  berthParameters: IBerthParameter[];
}) {
  const { berthParameters } = props;

  const gridRef = useRef<DataGrid>(null);

  const { t } = useTranslation();

  const { dimensionUnit, dimensionUnitText, meterToFeetConversion } =
    useDimensionsUnit();

  const statusCheck = [
    { name: t('labels.pass'), value: true },
    { name: t('labels.fail'), value: false },
  ];

  const cellTemplate = (data: any) => {
    if (data.value == true) {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleTickDanger />
        </div>
      );
    } else {
      return (
        <div className="m-l-icon-btn-holder">
          <icons.circleCrossDanger />
        </div>
      );
    }
  };

  const calculateParameterDisplayNameCellValue = useCallback(
    (data: any) => {
      if (data.isConversionRequired) {
        return data.berthParameterDisplayName + dimensionUnitText();
      } else {
        return data.berthParameterDisplayName;
      }
    },
    [dimensionUnit]
  );

  const calculateBerthCellValue = useCallback(
    (data: any) => {
      if (data.isConversionRequired) {
        return meterToFeetConversion(data.berthValue);
      } else {
        return data.berthValue;
      }
    },
    [dimensionUnit]
  );

  const calculateShipCellValue = useCallback(
    (data: any) => {
      if (data.isConversionRequired) {
        return meterToFeetConversion(data.shipValue);
      } else {
        return data.shipValue;
      }
    },
    [dimensionUnit]
  );

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.columnOption(
        'berthParameterDisplayName',
        'calculateCellValue',
        calculateParameterDisplayNameCellValue
      );
      gridRef.current?.instance.columnOption(
        'shipValue',
        'calculateCellValue',
        calculateShipCellValue
      );
      gridRef.current?.instance.columnOption(
        'berthValue',
        'calculateCellValue',
        calculateBerthCellValue
      );
    }
  }, [dimensionUnit]);

  const gridConfig: IGridConfig = {
    dataSource: berthParameters,
    class:
      'm-c-grid m-l-incident-grid m-c-auto-scroll-grid m-l-grid-with--top-border',
    ref: gridRef,
    defaultColumns: [
      {
        caption: t('labels.parameterName'),
        dataField: 'berthParameterDisplayName',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: calculateParameterDisplayNameCellValue,
      },
      {
        caption: t('labels.passOrFails'),
        dataField: 'isPassed',
        dataType: 'string',
        minWidth: 198,
        cellTemplate: 'cellTemplate',
        allowFiltering: true,
        lookup: {
          dataSource: statusCheck,
          valueExpr: 'value',
          displayExpr: 'name',
        },
      },
      {
        caption: t('labels.berthValue'),
        dataField: 'berthValue',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: calculateBerthCellValue,
      },
      {
        caption: t('labels.shipValue'),
        dataField: 'shipValue',
        dataType: 'string',
        minWidth: 150,
        calculateCellValue: calculateShipCellValue,
      },
    ],
    hidePagination: false,
    showSelection: false,
    hideFilterRow: false,
  };
  return <Grid gridConfig={gridConfig} cellTemplate={cellTemplate} />;
}
