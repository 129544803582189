import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import STSConfigForm from './StsConfigForm';
import { IStsConfigForm } from '../../../../../types/complianceCheck';
import { useTranslation } from 'react-i18next';

export default function StsConfig(
  props: Readonly<{
    stsConfigForm: UseFormReturn<IStsConfigForm>;
    buId: string;
  }>
) {
  const { stsConfigForm, buId } = props;
  const { t } = useTranslation();
  return (
    <div className="m-l-inner-page-body-wrap">
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-form-panel-body-block ">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.stsConfiguration')}
              </div>
            </div>
            <FormProvider {...stsConfigForm}>
              <STSConfigForm buId={buId} />
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
