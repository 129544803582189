import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import Modal from '../../common/modal/Modal';
import Button from 'devextreme-react/button';
import icons from '../../common/icons/icons';
import ScrollView from 'devextreme-react/scroll-view';
import ValidationGroup from 'devextreme-react/validation-group';
import TextArea from 'devextreme-react/text-area';
import { IModalConfig } from '../../../types/modal';
import Validator from 'devextreme-react/validator';
import { useTranslation } from 'react-i18next';

export default function ReportSummaryModal(
  props: Readonly<{
    isModalVisible: boolean;
    reportSummary: string;
    handleSetReportSummary: (summary: string) => void;
    handleCancelClick: () => void;
    generateReport: () => void;
  }>
) {
  const {
    isModalVisible,
    reportSummary,
    handleSetReportSummary,
    handleCancelClick,
    generateReport,
  } = props;
  const { t } = useTranslation();
  const reportSummaryValidationGroup: any = useRef();
  const popupAttributesChangePWD = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-edit-name-popup',
    };
  }, []);

  const modalConfigChangePWD: IModalConfig = {
    width: 550,
    height: 334,
    visible: isModalVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const onClickGenerate = () => {
    if (reportSummaryValidationGroup?.current?.instance.validate().isValid) {
      generateReport();
    }
  };

  return (
    <Modal
      modalConfig={modalConfigChangePWD}
      wrapperAttr={popupAttributesChangePWD}
    >
      <div className="m-l-modal__header report-summary-height">
        <h2 className="modal-title">Add Summary</h2>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={handleCancelClick}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <ScrollView width="100%" height="100%">
          <ValidationGroup ref={reportSummaryValidationGroup}>
            <TextArea
              className="m-c-text-area m-c-text-area-custom text-area-height"
              value={reportSummary}
              placeholder="Enter summary of the report"
              onValueChanged={(e) => {
                handleSetReportSummary(e.value);
              }}
              validationMessageMode="always"
            >
              <Validator
                validationRules={[
                  {
                    type: 'required',
                    message: t('errors.required'),
                  },
                ]}
              />
            </TextArea>
          </ValidationGroup>
        </ScrollView>
      </div>

      <div className="m-l-modal__footer">
        <Button
          className="app-c-btn app-c-btn--secondary min-btn-width"
          elementAttr={{ 'data-testid': 'backButton' }}
          onClick={handleCancelClick}
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          className="app-c-btn app-c-btn--primary min-btn-width"
          elementAttr={{ 'data-testid': 'saveButton' }}
          onClick={onClickGenerate}
        >
          {t('labels.generate')}
        </Button>
      </div>
    </Modal>
  );
}
