import DataGrid from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDimensionsUnit } from '../../../../hooks/useDimensionsUnit';
import { IGridConfig } from '../../../../types/grid';
import Grid from '../../../common/grid/Grid';
import EditVesselRestrictionsAtApproaches from './EditVesselRestrictionsAtApproaches';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';

export default function VesselRestrictionsAtApproaches(props: {
  berthCode: any;
  isFromViewInfoModal?: boolean;
}) {
  const { berthCode, isFromViewInfoModal } = props;
  const [showEditModal, setShowEditModal] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [
    berthPermissibleVesselRestrictionId,
    setBerthPermissibleVesselRestrictionId,
  ] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { dimensionUnitText, dimensionUnit, meterToFeetConversion } =
    useDimensionsUnit();
  const gridRef = useRef<DataGrid>(null);
  const onClickAdd = () => {
    setShowEditModal(true);
    setIsEdit(false);
  };

  const onRowClick = (e: any) => {
    if (!isFromViewInfoModal) {
      setBerthPermissibleVesselRestrictionId(
        e?.data?.berthPermissibleVesselRestrictionId
      );
      setShowEditModal(true);
      setIsEdit(true);
    }
  };

  useEffect(() => {
    if (!showEditModal) {
      setBerthPermissibleVesselRestrictionId(null);
    }
  }, [showEditModal]);

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.refresh();
      gridRef.current?.instance.columnOption(
        'maxDraftAtApproaches',
        'caption',
        t('labels.maxDraftAtApproaches') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'minUkcValue',
        'caption',
        t('labels.minUkcAtApproaches') + dimensionUnitText()
      );
      gridRef.current?.instance.columnOption(
        'depthAtApproaches',
        'caption',
        t('labels.depthAtApproaches') + dimensionUnitText()
      );
    }
  }, [dimensionUnit]);

  const getVesselApproaches = async () => {
    if (berthCode) {
      await api
        .get({
          url: apiConfig.berthVesselRestrictions + '/' + berthCode,
        })
        .then((res: any) => {
          setDataSource(res);
        });
    }
  };
  useEffect(() => {
    getVesselApproaches();
  }, []);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-l-grid-with--top-border m-c-righticon-grid',
    testId: 'vesselRestrictionsGrid',
    height: '350',
    dataSource: dataSource,
    rowClick: onRowClick,
    onClickAddButton: onClickAdd,
    defaultColumns: [
      {
        caption: 'Ship Type',
        dataField: 'shipType',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.airDraught'),
        dataField: 'airDraught',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.maxDraftAtApproaches') + dimensionUnitText(),
        dataField: 'maxDraftAtApproaches',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.depthAtApproaches') + dimensionUnitText(),
        dataField: 'depthAtApproaches',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minUkcAtApproaches') + dimensionUnitText(),
        dataField: 'minUkcValue',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.minUkcAtApproaches') + ' (%)',
        dataField: 'minUkcAtApproaches',
        dataType: 'string',
        minWidth: 150,
      },
    ],
    ref: gridRef,
    showHeader: true,
    showExport: false,
    showAddButton: !isFromViewInfoModal,
  };

  useEffect(() => {
    if (dimensionUnit) {
      gridRef.current?.instance.columnOption(
        'maxDraftAtApproaches',
        'calculateCellValue',
        calculateMaxDraftAtApproaches
      );
      gridRef.current?.instance.columnOption(
        'depthAtApproaches',
        'calculateCellValue',
        calculateDepthAtApproaches
      );
      gridRef.current?.instance.columnOption(
        'minUkcValue',
        'calculateCellValue',
        calculateMinUkcValue
      );
    }
  }, [dimensionUnit]);

  const calculateMaxDraftAtApproaches = useCallback(
    (data: any) => {
      return meterToFeetConversion(data.maxDraftAtApproaches);
    },
    [dimensionUnit]
  );
  const calculateDepthAtApproaches = useCallback(
    (data: any) => {
      return meterToFeetConversion(data.depthAtApproaches);
    },
    [dimensionUnit]
  );
  const calculateMinUkcValue = useCallback(
    (data: any) => {
      return meterToFeetConversion(data.minUkcValue);
    },
    [dimensionUnit]
  );

  return (
    <div className="m-l-tab-accordion-item-content-section">
      <Grid gridConfig={gridConfig} />
      {showEditModal && (
        <EditVesselRestrictionsAtApproaches
          isVisible={showEditModal}
          handleCancelClick={() => setShowEditModal(false)}
          isEdit={isEdit}
          berthCode={berthCode}
          getVesselApproaches={getVesselApproaches}
          berthPermissibleVesselRestrictionId={
            berthPermissibleVesselRestrictionId
          }
        />
      )}
    </div>
  );
}
