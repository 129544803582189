import React, { useEffect, useState } from 'react';
import ReportSettingForm from '../report-settings/report-setting-form/ReportSettingForm';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';
import CheckBox from 'devextreme-react/check-box';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../../../contexts/LoaderContext';
import { IDialogConfig } from '../../../../types/dialog';
import { SanctionCheckReportSettingsType } from '../../../../enums/config-enum';
import toast from '../../../../utils/toast';
import { IComplianceConfig } from '../../../../types/compliance-config';
import ReportSettingHtmlEditor from '../report-settings/report-setting-form/ReportSettingHtmlEditor';
import icons from '../../../common/icons/icons';
import FileUploaderModal from '../../../common/file-uploader-modal/FileUploaderModal';
import HelpIcon from '../../../common/help-icon/HelpIcon';
import { ReportAttachment } from '../../../../types/compliance-report';
import Tooltip from 'devextreme-react/tooltip';
import FileSaver from 'file-saver';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import {
  Restricted,
  usePermission,
} from '../../../../contexts/PermissionContext';

export default function SanctionCheckReportSettings(
  props: Readonly<{
    companyId: string;
    buId: string;
    saveConfig?: any;
    setSaveConfig?: any;
  }>
) {
  const { buId, saveConfig, setSaveConfig, companyId } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { setIsLoading } = useLoaderContext();
  const { apiDateAndTimeFormat } = useDateFormat();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [reportConfigurationData, setReportConfigurationData] = useState<any[]>(
    []
  );
  const [vesselDetails, setVesselDetails] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<boolean>(false);
  const [marsDisclaimer, setMarsDisclaimer] = useState<boolean>(false);
  const [marsDisclaimerText, setMarsDisclaimerText] = useState<string>('');
  const [requestorDisclaimer, setRequestorDisclaimer] =
    useState<boolean>(false);
  const [requestorDisclaimerText, setRequestorDisclaimerText] =
    useState<string>('');
  const [showMarsDisclaimer, setShowMarsDisclaimer] = useState<string>('');
  const [showReqDisclaimer, setShowReqDisclaimer] = useState<string>('');

  const [isVisible, setIsVisible] = useState(false);
  const [isFromSanctionsConfig, setIsFromSanctionsConfig] = useState(false);
  const [sanctionSourceList, setSanctionSourceList] = useState<boolean>(false);
  const [sanctionSourceOverView, setSanctionSourceOverView] =
    useState<boolean>(false);
  const [showSanctionsOverView, setShowSanctionsOverView] =
    useState<string>('');
  const [sanctionSourceOverViewText, setSanctionSourceOverViewText] =
    useState<string>('');
  const [sanctionSourceListFile, setSanctionSourceListFile] =
    useState<ReportAttachment>();
  const [disclaimerRules, setDisclaimerRules] = useState<string>('');
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      saveSanctionReportConfigs();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  const getReportData = async () => {
    if (buId && companyId) {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: SanctionCheckReportSettingsType.SanctionsCheckReport,
            companyId: companyId,
            buId: buId,
          },
        })
        .then((response) => {
          setReportConfigurationData(response);
        });
    } else {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: SanctionCheckReportSettingsType.SanctionsCheckReport,
            companyId: companyId,
          },
        })
        .then((response) => {
          setReportConfigurationData(response);
        });
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  const saveSanctionReportConfigs = async () => {
    if (buId) {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: SanctionCheckReportSettingsType.SanctionsCheckReport,
              buId: buId,
              companyId: companyId,
            },
            data: reportConfigurationData,
          },
          setIsLoading
        )
        .then(() => {
          getReportData();
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    } else {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: SanctionCheckReportSettingsType.SanctionsCheckReport,
              companyId: companyId,
            },
            data: reportConfigurationData,
          },
          setIsLoading
        )
        .then(() => {
          getReportData();
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    }
  };

  const handleSaveClick = async () => {
    let index = reportConfigurationData?.findIndex(
      (item: any) =>
        item?.settingType == SanctionCheckReportSettingsType.MARSDisclaimer
    );
    reportConfigurationData[index].settingDescription = marsDisclaimerText;
    index = reportConfigurationData?.findIndex(
      (item: any) =>
        item?.settingType == SanctionCheckReportSettingsType.RequestorDisclaimer
    );
    reportConfigurationData[index].settingDescription = requestorDisclaimerText;
    index = reportConfigurationData?.findIndex(
      (item: any) =>
        item?.settingType ==
        SanctionCheckReportSettingsType.sanctionSourceOverView
    );
    reportConfigurationData[index].settingDescription =
      sanctionSourceOverViewText;
    setShowConfirmSave(true);
  };

  const onClickCheckBox = async (e: boolean, settingType: string) => {
    const index = reportConfigurationData?.findIndex(
      (item: any) => item?.settingType == settingType
    );
    reportConfigurationData[index].settingValue = e ? 'yes' : 'no';
  };

  useEffect(() => {
    getSanctionSourceListFile();
  }, []);

  const getSanctionSourceListFile = async () => {
    await api
      .get({
        url: apiConfig.getSanctionSourceList,
      })
      .then((response) => {
        setSanctionSourceListFile(response);
      });
  };

  useEffect(() => {
    if (saveConfig) {
      handleSaveClick();
    }
  }, [saveConfig]);

  useEffect(() => {
    if (buId) {
      setDisclaimerRules(
        'BUCompanyConfiguration.EditSanctionReportMARSDisclaimerContents'
      );
    } else {
      setDisclaimerRules(
        'CompanyConfiguration.EditSanctionReportMARSDisclaimerContents'
      );
    }
  }, []);

  useEffect(() => {
    if (!reportConfigurationData) {
      setShowEditor(false);
      return;
    }

    const getSettingValue = (type: string) => {
      const setting = reportConfigurationData.find(
        (item: IComplianceConfig) => item?.settingType === type
      );
      return setting || {};
    };

    const vesselDetailsSetting = getSettingValue(
      SanctionCheckReportSettingsType.VesselDetails
    );
    setVesselDetails(vesselDetailsSetting.settingValue === 'yes');

    const companyDetailsSetting = getSettingValue(
      SanctionCheckReportSettingsType.CompanyDetails
    );
    setCompanyDetails(companyDetailsSetting.settingValue === 'yes');

    const marsDisclaimerSetting = getSettingValue(
      SanctionCheckReportSettingsType.MARSDisclaimer
    );
    setMarsDisclaimer(marsDisclaimerSetting.settingValue === 'yes');
    setMarsDisclaimerText(marsDisclaimerSetting.settingDescription || '');
    setShowMarsDisclaimer(marsDisclaimerSetting.settingType || '');

    const requestorDisclaimerSetting = getSettingValue(
      SanctionCheckReportSettingsType.RequestorDisclaimer
    );
    setRequestorDisclaimer(requestorDisclaimerSetting.settingValue === 'yes');
    setRequestorDisclaimerText(requestorDisclaimerSetting.settingDescription);
    setShowReqDisclaimer(requestorDisclaimerSetting.settingType);

    const sanctionSourceOverviewSetting = getSettingValue(
      SanctionCheckReportSettingsType.sanctionSourceOverView
    );
    setSanctionSourceOverView(
      sanctionSourceOverviewSetting.settingValue === 'yes'
    );
    setSanctionSourceOverViewText(
      sanctionSourceOverviewSetting.settingDescription
    );
    setShowSanctionsOverView(sanctionSourceOverviewSetting.settingType);

    const sanctionSourceListSetting = getSettingValue(
      SanctionCheckReportSettingsType.sanctionSourceList
    );
    setSanctionSourceList(sanctionSourceListSetting.settingValue === 'yes');
  }, [reportConfigurationData]);

  const onAddClick = () => {
    setIsFromSanctionsConfig(true);
    setIsVisible(true);
  };

  const onClickDownload = () => {
    api
      .get(
        {
          url: apiConfig.attachments + sanctionSourceListFile?.attachmentId,
          responseType: 'arraybuffer',
        },
        setIsLoading
      )
      .then((res) => {
        FileSaver.saveAs(new Blob([res]), sanctionSourceListFile?.fileName);
      });
  };

  const handleCancelClick = () => {
    setIsVisible(false);
    setIsFromSanctionsConfig(true);
  };

  const handleFileUploadCallBack = () => {
    getSanctionSourceListFile();
  };

  const renderContent = () => {
    return (
      <div className="m-l-tooltip-wrapper m-l-info-tooltip">
        <div>
          {' '}
          {t('labels.uploadedOn')} :{' '}
          {apiDateAndTimeFormat(sanctionSourceListFile?.uploadDate)}
        </div>
      </div>
    );
  };

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-header-block">
        <div className="m-l-form-panel-header-left-block">
          <div className="m-l-page-small-heading">
            {t('labels.sanctionCheckReportSettings')}
          </div>
        </div>
      </div>
      <div className="m-l-grid-top-header__section u-bb0">
        <div className="left-section">
          <div className="m-l-grid-top-header-list-row">
            <div className="row">
              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 col-12 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'vesselDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.VesselDetails
                      );
                      setVesselDetails(e.value);
                    }}
                    value={vesselDetails}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.vesselDetails')}</h2>
              </div>

              <div className="col-xl-auto col-lg-4 col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'CompanyDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.CompanyDetails
                      );
                      setCompanyDetails(e.value);
                    }}
                    value={companyDetails}
                  />
                </div>
                <h2 className="checkbox-label">
                  {' '}
                  {t('labels.vesselCompanyDetails')}
                </h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'marsDisclaimerToggle' }}
                    disabled={
                      buId
                        ? !findFeature(
                            'BUCompanyConfiguration.EditSanctionReportMARSDisclaimerCheckbox'
                          )
                        : !findFeature(
                            'CompanyConfiguration.EditSanctionReportMARSDisclaimerCheckbox'
                          )
                    }
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.MARSDisclaimer
                      );
                      setMarsDisclaimer(e.value);
                    }}
                    value={marsDisclaimer}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.marsDisclaimer')}</h2>
              </div>
              <div className="col-xl-auto col-lg-4 col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'requestorDisclaimerToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.RequestorDisclaimer
                      );
                      setRequestorDisclaimer(e.value);
                    }}
                    value={requestorDisclaimer}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.requestorDisclaimer')}
                </h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 col-12 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{
                      'data-testid': 'sanctionSourceOverViewToggle',
                    }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.sanctionSourceOverView
                      );
                      setSanctionSourceOverView(e.value);
                    }}
                    value={sanctionSourceOverView}
                    disabled={
                      buId
                        ? !findFeature(
                            'BUCompanyConfiguration.EditSanctionSourceOverviewCheckbox'
                          )
                        : !findFeature(
                            'CompanyConfiguration.EditSanctionSourceOverviewCheckbox'
                          )
                    }
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.sanctionsSourceOverview')}
                </h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 col-12 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'sanctionSourceListToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        SanctionCheckReportSettingsType.sanctionSourceList
                      );
                      setSanctionSourceList(e.value);
                    }}
                    value={sanctionSourceList}
                    disabled={
                      buId
                        ? !findFeature(
                            'BUCompanyConfiguration.EditSanctionSourceListCheckbox'
                          )
                        : !findFeature(
                            'CompanyConfiguration.EditSanctionSourceListCheckbox'
                          )
                    }
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.sanctionsSourceList')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-form-panel-body-block m-l-form-company-details">
        <ReportSettingForm
          requestorDisclaimer={requestorDisclaimerText}
          setRequestorDisclaimer={setRequestorDisclaimerText}
          marsDisclaimer={marsDisclaimerText}
          setMarsDisclaimer={setMarsDisclaimerText}
          showEditor={showEditor}
          buId={buId}
          showMarsDisclaimer={showMarsDisclaimer}
          showReqDisclaimer={showReqDisclaimer}
          rule={disclaimerRules}
        />

        <div className="m-l-srv-btm sanction-config-over-view-editor">
          <label className="m-l-email-input-label">
            {t('labels.sanctionsSourceOverview')}
          </label>
          <ReportSettingHtmlEditor
            content={sanctionSourceOverViewText}
            setContent={setSanctionSourceOverViewText}
            showEditor={showSanctionsOverView ? true : false}
            isDisabled={
              buId
                ? !findFeature(
                    'BUCompanyConfiguration.EditSanctionSourceOverviewContent'
                  )
                : !findFeature(
                    'CompanyConfiguration.EditSanctionSourceOverviewContent'
                  )
            }
          />
          <div className="m-l-upld-fl-wrapper m-l-upld__birth-fit sanc-config-upload-dwnld">
            <Restricted
              permission={
                buId
                  ? 'BUCompanyConfiguration.SanctionSourceListUpload'
                  : 'CompanyConfiguration.SanctionSourceListUpload'
              }
            >
              <div className="m-l-atch-container sanc-upload-ui">
                <div className="m-l-atch-icon-holder sanc--attach-icon">
                  <icons.Attach />
                  <a className="m-l-add-atch-txt" onClick={onAddClick}>
                    {t('labels.uploadSourceList')}
                  </a>
                </div>
                <div className="m-l-tooltip-show-icon sanc-tooltip-icon">
                  <HelpIcon
                    helpText={t('toast.sanctionUploadInfoTooltip')}
                    id={'attachment-sanction'}
                  ></HelpIcon>
                </div>
              </div>
            </Restricted>
            {sanctionSourceListFile && (
              <Restricted
                permission={
                  buId
                    ? 'BUCompanyConfiguration.SanctionSourceListUpload'
                    : 'CompanyConfiguration.SanctionSourceListUpload'
                }
              >
                <div className="m-l-atch-icon-holder sanc-download-file">
                  <div className="sanc-dwnld-icon">
                    <icons.download />
                  </div>
                  <a
                    className="m-l-add-atch-txt"
                    onClick={onClickDownload}
                    id="info"
                  >
                    {t('labels.downloadSanctionSourceList')}
                  </a>
                </div>
              </Restricted>
            )}
            {sanctionSourceListFile?.uploadDate && (
              <Tooltip
                target="#info"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
                contentRender={renderContent}
                position="top"
              />
            )}
          </div>
        </div>
        {isVisible && (
          <FileUploaderModal
            isVisible={isVisible}
            handleCancelClick={handleCancelClick}
            isFromSanctionsConfig={isFromSanctionsConfig}
            handleSanctionCallBack={handleFileUploadCallBack}
          />
        )}
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
