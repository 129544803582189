import React, { useCallback, useEffect, useState } from 'react';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import { useDateFormat } from '../../../hooks/useDateFormat';

export default function SanctionCheckReport(
  props: Readonly<{
    sanctionReportDetails?: any;
    logo?: any;
  }>
) {
  const { sanctionReportDetails, logo } = props;

  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();
  const { formatDate } = useDateFormat();
  const getCommentBody = useCallback((comment: string) => {
    return comment?.replace(/\n/g, '<br/>');
  }, []);

  const cardStyles = {
    logoHead: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      'flex-direction': 'column',
      padding: '16px 0',
    },
    logoImage: {
      height: '120px',
      marginBottom: '30px',
    },
    logoTextWrapDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoText: {
      marginBottom: '0',
      fontSize: '18px',
      color: '#174a84',
      fontWeight: '700',
    },
    stamp: {
      marginLeft: '25px',
    },
    statusImage: {
      height: '44px',
    },
    contentHeaderWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '10px',
    },
    headerColfirst: {
      display: 'flex',
      alignItems: 'center',
    },
    headerColfirstHeading: {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '0',
    },
    headerColfirstSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '30px',
    },
    headerColSecond: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    headerColSecondSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '0px',
    },
    contentSubTextWrap: {
      display: 'block',
    },
    reportCard: {
      border: '1px solid #e5e5e5',
      padding: '16px 0',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardContainer: {
      border: '1px solid #e5e5e5',
      padding: '0px 8px 8px 8px',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardRow: {
      display: 'flex',
      padding: '8px 0',
      width: '100%',
    },
    reportCardCol: {
      borderRight: '1px solid #e5e5e5',
      padding: '0 5px 0 16px',
      margin: '0',
    },
    reportCardColWidth10: {
      width: '10%',
    },
    reportCardColWidth15: {
      width: '15%',
    },
    reportCardColWidth20: {
      width: '20%',
    },
    reportCardColMinWidth20: {
      minWidth: '20%',
    },
    reportCardColWidth25: {
      width: '25%',
    },
    reportCardColWidth30: {
      width: '30%',
    },
    reportCardColWidth40: {
      width: '40%',
    },
    reportCardColWidth50: {
      width: '50%',
    },
    reportCardColWidth100: {
      width: '100%',
    },
    reportPl0: {
      paddingLeft: '0',
    },
    reportLabel: {
      color: '#4f4f4f',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '600',
      display: 'block',
      marginBottom: '6px',
    },
    reportValue: {
      color: '#174a84',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '400',
    },
    reportValueGreen: {
      color: '#00b050',
    },
    reportValueRed: {
      color: '#df2f2f',
    },
    reportSectionHeading: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '16px 0 8px',
      backgroundColor: '#ebf3fe',
      padding: '10px 16px',
      border: '1px solid #ebf3fe',
    },
    reportEqualFlex: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    reportFlexEqCol: {
      borderRight: '1px solid #e5e5e5',
      flex: '1',
      padding: '0',
    },
    reportBlock: {
      display: 'block',
    },
    reportBorderRightNone: {
      borderRight: 'none',
    },
    reportBorderTop: {
      borderTop: '1px solid #e5e5e5',
    },
    reportWidth100: {
      width: '100%',
    },
    reportTitleH3: {
      margin: '0',
      padding: '0 16px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    reportRowWrapper: {
      overflow: 'hidden',
      padding: '0 16px',
    },
    executionWrap: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      marginBottom: '20px',
    },
    exeHeader: {
      display: 'flex',
      borderBottom: '1px solid #e5e5e5',
      alignItems: 'center',
      padding: '12px 16px',
    },
    exeHeaderText: {
      color: '#000',
      fontSize: '12px',
      lineHeight: '1',
      fontWeight: '500',
      marginBottom: '0',
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    displayFlex: {
      display: 'flex',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    exeHeaderLastItem: {
      marginLeft: '10px',
      paddingLeft: '10px',
      borderLeft: '1px solid #e5e5e5',
    },
    exeHeaderRightContent: {
      color: '#174a84',
      fontSize: '9px',
      fontWeight: '500',
      paddingBottom: '16px',
    },
    approvalInfo: {
      color: '#f2994a',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    approvalInfoApproved: {
      color: '#27ae60',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    exeBody: {
      padding: '8px',
    },
    exeTextH4: {
      color: '#828282',
      fontSize: '12px',
      fontWeight: '400',
      marginBottom: '8px',
    },
    exBgBox: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#ebf3fe',
    },
    exBgBoxPadding: {
      padding: '20px 15px 15px',
    },
    exBorderBottom: {
      borderBottom: '1px solid #e5e5e5',
    },
    exBgBoxHeaderText: {
      margin: '0px 0px 10px',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    label: {
      color: '#828282',
      fontSize: '10px',
      fontWeight: '500',
      marginBottom: '4px',
      display: 'block',
    },
    formValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
    },
    commentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '460px',
      'overflow-wrap': 'break-word',
    },
    berthCommentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '440px',
      'overflow-wrap': 'break-word',
    },
    paddinRight8: {
      paddingRight: '8px',
    },
    exBoxWhite: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#fff',
    },
    table: {
      width: '100%',
    },
    tableTH: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
    tableTD: {
      fontSize: '10px',
      fontWeight: '400',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
      WordBreak: 'break-all',
      OverflowWrap: 'break-word',
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    key: {
      padding: '10px',
    },
    rowStyle: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      paddingBottom: '10px',
      marginBottom: '10px',
    },
    rowStyleSanction: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '10px',
      marginBottom: '10px',
    },

    labelStyle: {
      flex: '1',
      fontWeight: 'bold',
    },

    separatorStyle: {
      width: '1px',
      backgroundColor: '#ccc',
      height: '100%',
      margin: '0 10px',
    },

    valueStyle: {
      flex: '3',
    },

    listStyle: {
      margin: '0',
      paddingLeft: '20px',
      listStyleType: 'disc',
    },
  };

  const docCompany = sanctionReportDetails?.vesselCompanyDetails?.find(
    (item: any) => item.roleName === 'DOC Company'
  );
  const groupOwner = sanctionReportDetails?.vesselCompanyDetails?.find(
    (item: any) => item.roleName === 'Group Owner'
  );
  const [sanctionData, setSanctionData] = useState<any[]>([]);

  useEffect(() => {
    const parsedData = JSON.parse(sanctionReportDetails?.sanctionsSourceList);
    setSanctionData(parsedData);
  }, []);

  const isValidUrl = (str: unknown): str is string => {
    if (typeof str !== 'string') return false;
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  return (
    <div className="inner-wrapper-report ps-3 pe-3">
      <div style={cardStyles.reportBlock}>
        {sanctionReportDetails && (
          <div id="element-to-print">
            <div style={cardStyles.reportBlock}>
              <div style={cardStyles.logoHead}>
                {logo && (
                  <img
                    src={logo}
                    style={cardStyles.logoImage}
                    alt="CompanyLogo"
                  />
                )}
                <div style={cardStyles.logoTextWrapDiv}>
                  <h1 style={cardStyles.logoText}>SANCTIONS CHECK REPORT</h1>
                </div>
              </div>
              <div style={cardStyles.contentHeaderWrap}>
                <div style={cardStyles.headerColfirst}>
                  <h2 style={cardStyles.headerColfirstHeading}>
                    {sanctionReportDetails?.shipName}
                  </h2>
                </div>
                <div style={cardStyles.headerColSecond}>
                  {sanctionReportDetails?.reportGeneratedDate && (
                    <span style={cardStyles.headerColSecondSubHead}>
                      Date: {sanctionReportDetails?.reportGeneratedDate}
                    </span>
                  )}
                </div>
              </div>
              <div style={cardStyles.contentSubTextWrap}>
                {sanctionReportDetails?.imoNumber && (
                  <span style={cardStyles.headerColSecondSubHead}>
                    IMO: {sanctionReportDetails?.imoNumber}
                  </span>
                )}
              </div>
              <div style={cardStyles.contentSubTextWrap}>
                {sanctionReportDetails?.sanctionCheckId && (
                  <span style={cardStyles.headerColSecondSubHead}>
                    Sanctions Check ID: {sanctionReportDetails?.sanctionCheckId}
                  </span>
                )}
              </div>
            </div>

            {sanctionReportDetails?.vesselInfo && (
              <div style={cardStyles.reportCard}>
                <div style={cardStyles.reportCardRow}>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Age</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.age}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Status</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.vesselStatus}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Vessel Type</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.vesselType}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>DOC Company</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.docCompany}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>
                      LOA {dimensionUnitText()}
                    </span>
                    <span style={cardStyles.reportValue}>
                      {meterToFeetConversion(
                        sanctionReportDetails?.vesselInfo?.lengthOverallLOA
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth15,
                      ...cardStyles.reportBorderRightNone,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Summer DWT</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.deadweight}
                    </span>
                  </div>
                </div>
                <div style={cardStyles.reportCardRow}>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Call sign</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.callSign}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Class</span>
                    <span style={cardStyles.reportValue}>
                      {
                        sanctionReportDetails?.vesselInfo
                          ?.classificationSocietyName
                      }
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>P & I Club</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.pandIClubName}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Previous Name</span>
                    <span style={cardStyles.reportValue}>
                      {sanctionReportDetails?.vesselInfo?.previousName}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>
                      Breadth {dimensionUnitText()}
                    </span>
                    <span style={cardStyles.reportValue}>
                      {meterToFeetConversion(
                        sanctionReportDetails?.vesselInfo?.breadthExtreme
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth15,
                      ...cardStyles.reportBorderRightNone,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Sanction</span>
                    <span
                      style={
                        sanctionReportDetails?.vesselInfo?.sanctionDetails ===
                        'no'
                          ? {
                              ...cardStyles.reportValue,
                              ...cardStyles.reportValueGreen,
                            }
                          : {
                              ...cardStyles.reportValue,
                              ...cardStyles.reportValueRed,
                            }
                      }
                    >
                      {sanctionReportDetails?.vesselInfo?.sanctionDetails}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {sanctionReportDetails?.reportSummary && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>Report Summary</h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                >
                  {sanctionReportDetails?.reportSummary}
                </div>
              </div>
            )}

            {sanctionReportDetails?.vesselCompanyDetails && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  Vessel Company Details
                </h3>
                <div>
                  {sanctionReportDetails?.vesselCompanyDetails?.length > 0 ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          padding: '0px 1px 0px',
                        }}
                      >
                        <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                          Role
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                          Company Number
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '14%' }}>
                          Company Name
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '16%' }}>
                          Address
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                          Country
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                          Email
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                          Phone
                        </div>
                        <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                          From Date
                        </div>
                      </div>

                      {sanctionReportDetails?.vesselCompanyDetails?.map(
                        (vesselDetails: any, index: number) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              padding: '0px 1px 0px',
                            }}
                          >
                            <div
                              style={{
                                ...cardStyles.tableTD,
                                width: '12.5%',
                              }}
                            >
                              {vesselDetails?.roleName}
                            </div>
                            <div
                              style={{
                                ...cardStyles.tableTD,
                                width: '12.5%',
                              }}
                            >
                              {vesselDetails?.companyNumber}
                            </div>
                            <div
                              style={{ ...cardStyles.tableTD, width: '14%' }}
                            >
                              {vesselDetails?.companyName}
                            </div>
                            <div
                              style={{ ...cardStyles.tableTD, width: '16%' }}
                            >
                              {vesselDetails?.address}
                            </div>
                            <div
                              style={{
                                ...cardStyles.tableTD,
                                width: '12.5%',
                              }}
                            >
                              {vesselDetails?.country}
                            </div>

                            <div
                              style={{
                                ...cardStyles.tableTD,
                                width: '12.5%',
                              }}
                            >
                              {vesselDetails?.email && (
                                <>
                                  <span>
                                    {vesselDetails.email.split('@')[0]}
                                  </span>
                                  <span style={{ display: 'block' }}>
                                    @{vesselDetails.email.split('@')[1]}
                                  </span>
                                </>
                              )}
                            </div>

                            <div
                              style={{ ...cardStyles.tableTD, width: '10%' }}
                            >
                              {vesselDetails?.phone}
                            </div>
                            <div
                              style={{ ...cardStyles.tableTD, width: '10%' }}
                            >
                              {formatDate(vesselDetails?.fromDate)}
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <p>No Vessel Company Details available.</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {sanctionReportDetails?.isAllRejected && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  Sanction not found on
                </h3>
                <div>
                  <ul>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>Vessel Name:</div>
                        <div style={cardStyles.valueStyle}>
                          {sanctionReportDetails?.shipName}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>Vessel IMO:</div>
                        <div style={cardStyles.valueStyle}>
                          {sanctionReportDetails?.imoNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>
                          DOC Company IMO:
                        </div>
                        <div style={cardStyles.valueStyle}>
                          {docCompany?.companyNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>
                          DOC Company Name:
                        </div>
                        <div style={cardStyles.valueStyle}>
                          {groupOwner?.companyName}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>
                          {' '}
                          Group Owner IMO:
                        </div>
                        <div style={cardStyles.valueStyle}>
                          {groupOwner?.companyNumber}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={cardStyles.rowStyle}>
                        <div style={cardStyles.labelStyle}>
                          {' '}
                          Group Owner Name:
                        </div>
                        <div style={cardStyles.valueStyle}>
                          {groupOwner?.companyName}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {sanctionReportDetails?.sanctionDetails?.sanctionChecks && (
              <>
                <h3
                  style={{
                    ...cardStyles.reportSectionHeading,
                    marginBottom: '8px',
                  }}
                >
                  Sanctions
                </h3>
                {sanctionReportDetails?.sanctionDetails?.sanctionChecks.map(
                  (sanction: any, index: number) => (
                    <div key={index} style={{ marginBottom: '12px' }}>
                      <h3
                        style={{
                          ...cardStyles.reportSectionHeading,
                          marginBottom: '6px',
                        }}
                      >
                        {sanction.name}
                      </h3>
                      <div style={cardStyles.key}>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Alias
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            <ul style={cardStyles.listStyle}>
                              {sanction.aliasNames?.map(
                                (item: string, index: number) => (
                                  <li key={index}>{item}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Sanction Details
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            <ul style={cardStyles.listStyle}>
                              {sanction.sanctionDetails?.map(
                                (item: string, index: number) => (
                                  <li key={index}>{item}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Links
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            <ul style={cardStyles.listStyle}>
                              {sanction.links?.map(
                                (item: string, index: number) => (
                                  <li key={index}>
                                    <a
                                      href={item}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {item}
                                    </a>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Other Information
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            <ul style={cardStyles.listStyle}>
                              {sanction.otherInformation?.map(
                                (item: string, index: number) => (
                                  <li key={index}>{item}</li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Source Country
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            {sanction.sourceCountry}
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Source Description
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            {sanction.sourceDescription}
                          </div>
                        </div>
                        <div
                          style={{
                            ...cardStyles.rowStyle,
                            marginBottom: '4px',
                          }}
                        >
                          <div
                            style={{
                              ...cardStyles.labelStyle,
                              fontWeight: '500',
                            }}
                          >
                            Source Details
                          </div>
                          <div style={cardStyles.separatorStyle}></div>
                          <div style={cardStyles.valueStyle}>
                            <a
                              href={sanction.sourceDetails}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {`${sanction.sourceIssuerName} - ${sanction.sourceName}`}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )}

            {sanctionReportDetails?.disclaimerBasicInfo?.marsDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>Mars Disclaimer</h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      sanctionReportDetails?.disclaimerBasicInfo?.marsDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}
            {sanctionReportDetails?.disclaimerBasicInfo
              ?.requestorDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  {sanctionReportDetails?.companyName} Disclaimer
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      sanctionReportDetails?.disclaimerBasicInfo
                        ?.requestorDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}

            {sanctionReportDetails?.sanctionsSourceOverview && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  Sanction Source Overview
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      sanctionReportDetails?.sanctionsSourceOverview
                    ),
                  }}
                ></div>
              </div>
            )}

            {sanctionData?.length > 0 && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  Sanction Source List
                </h3>
                <div>
                  <div style={{ display: 'flex', padding: '0px 1px 0px' }}>
                    {Object.keys(sanctionData[0])?.map((key) => (
                      <div
                        key={key}
                        style={{
                          ...cardStyles.tableTH,
                          width: `${
                            100 / Object.keys(sanctionData[0])?.length
                          }%`,
                        }}
                      >
                        {key}
                      </div>
                    ))}
                  </div>

                  <div>
                    {sanctionData?.map((sanction, index) => (
                      <div
                        key={index}
                        style={{ display: 'flex', padding: '0px 1px 0px' }}
                      >
                        {Object.entries(sanction).map(([key, value]) => (
                          <div
                            key={key}
                            style={{
                              ...cardStyles.tableTD,
                              width: `${
                                100 / Object.keys(sanctionData[0])?.length
                              }%`,
                            }}
                          >
                            {Array.isArray(value) ? (
                              <ul style={cardStyles.listStyle}>
                                {value.map((item, idx) => (
                                  <li key={idx}>{String(item)}</li>
                                ))}
                              </ul>
                            ) : isValidUrl(value) ? (
                              <a
                                href={String(value)}
                                target="_blank"
                                style={{
                                  wordBreak: 'break-word',
                                  textAlign: 'center',
                                }}
                                rel="noreferrer"
                              >
                                {String(value)}
                              </a>
                            ) : (
                              <span>{String(value)}</span>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
