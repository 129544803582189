import Button from 'devextreme-react/button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { getCurrentBuId } from '../../../utils/jwt-decode';
import { useLoaderContext } from '../../../contexts/LoaderContext';

export default function SanctionsCheckButton(props: { imoNumber: string }) {
  const { imoNumber } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsLoading } = useLoaderContext();

  const buId = getCurrentBuId();

  const onClickSanctionsCheck = async () => {
    await api
      .get(
        {
          url: apiConfig.generateSanctionsCheck,
          params: { imo: imoNumber, buid: buId },
        },
        setIsLoading
      )
      .then((res) => {
        navigate('/sanctions-check', {
          state: {
            imoNumber: imoNumber,
            sanctionsCheckId: res,
          },
        });
      });
  };
  return (
    <Button
      className="app-c-btn app-c-btn--primary min-btn-width d-none d-md-block"
      onClick={onClickSanctionsCheck}
    >
      {t('buttons.sanctionsCheck')}
    </Button>
  );
}
