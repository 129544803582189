import React, { useCallback } from 'react';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { useDimensionsUnit } from '../../../hooks/useDimensionsUnit';
import {
  ComplianceStatusEnum,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import { convertDecimalToMinutes } from '../../../utils/coordinates';
import { IComplianceReport } from '../../../types/compliance-report';

export default function ComplianceReport(
  props: Readonly<{
    reportDetails?: IComplianceReport;
    logo?: any;
  }>
) {
  const { reportDetails, logo } = props;
  const { formatDate } = useDateFormat();
  const { dimensionUnitText, meterToFeetConversion } = useDimensionsUnit();
  const cardStyles = {
    logoHead: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      'flex-direction': 'column',
      padding: '16px 0',
    },
    logoImage: {
      height: '120px',
      marginBottom: '30px',
    },
    logoTextWrapDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    logoText: {
      marginBottom: '0',
      fontSize: '18px',
      color: '#174a84',
      fontWeight: '700',
    },
    stamp: {
      marginLeft: '25px',
    },
    statusImage: {
      height: '44px',
    },
    contentHeaderWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '10px',
    },
    headerColfirst: {
      display: 'flex',
      alignItems: 'center',
    },
    headerColfirstHeading: {
      fontSize: '22px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '0',
    },
    headerColfirstSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '30px',
    },
    headerColSecond: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    headerColSecondSubHead: {
      fontSize: '12px',
      lineHeight: '14px',
      color: '#174a84',
      fontWeight: '600',
      marginLeft: '0px',
    },
    contentSubTextWrap: {
      display: 'block',
    },
    reportCard: {
      border: '1px solid #e5e5e5',
      padding: '16px 0',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardContainer: {
      border: '1px solid #e5e5e5',
      padding: '0px 8px 8px 8px',
      borderRadius: '8px',
      marginTop: '8px',
    },
    reportCardRow: {
      display: 'flex',
      padding: '8px 0',
      width: '100%',
    },
    reportCardCol: {
      borderRight: '1px solid #e5e5e5',
      padding: '0 5px 0 16px',
      margin: '0',
    },
    reportCardColWidth10: {
      width: '10%',
    },
    reportCardColWidth15: {
      width: '15%',
    },
    reportCardColWidth20: {
      width: '20%',
    },
    reportCardColMinWidth20: {
      minWidth: '20%',
    },
    reportCardColWidth25: {
      width: '25%',
    },
    reportCardColWidth30: {
      width: '30%',
    },
    reportCardColWidth40: {
      width: '40%',
    },
    reportCardColWidth50: {
      width: '50%',
    },
    reportCardColWidth100: {
      width: '100%',
    },
    reportPl0: {
      paddingLeft: '0',
    },
    reportLabel: {
      color: '#4f4f4f',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '600',
      display: 'block',
      marginBottom: '6px',
    },
    reportValue: {
      color: '#174a84',
      fontSize: '10px',
      lineHeight: '16px',
      fontWeight: '400',
    },
    reportValueGreen: {
      color: '#00b050',
    },
    reportValueRed: {
      color: '#df2f2f',
    },
    reportSectionHeading: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
      margin: '16px 0 8px',
      backgroundColor: '#ebf3fe',
      padding: '10px 16px',
      border: '1px solid #ebf3fe',
    },
    reportEqualFlex: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    reportFlexEqCol: {
      borderRight: '1px solid #e5e5e5',
      flex: '1',
      padding: '0',
    },
    reportBlock: {
      display: 'block',
    },
    reportBorderRightNone: {
      borderRight: 'none',
    },
    reportBorderTop: {
      borderTop: '1px solid #e5e5e5',
    },
    reportWidth100: {
      width: '100%',
    },
    reportTitleH3: {
      margin: '0',
      padding: '0 16px',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    reportRowWrapper: {
      overflow: 'hidden',
      padding: '0 16px',
    },
    executionWrap: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      marginBottom: '20px',
    },
    exeHeader: {
      display: 'flex',
      borderBottom: '1px solid #e5e5e5',
      alignItems: 'center',
      padding: '12px 16px',
    },
    exeHeaderText: {
      color: '#000',
      fontSize: '12px',
      lineHeight: '1',
      fontWeight: '500',
      marginBottom: '0',
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    displayFlex: {
      display: 'flex',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    exeHeaderLastItem: {
      marginLeft: '10px',
      paddingLeft: '10px',
      borderLeft: '1px solid #e5e5e5',
    },
    exeHeaderRightContent: {
      color: '#174a84',
      fontSize: '9px',
      fontWeight: '500',
      paddingBottom: '16px',
    },
    approvalInfo: {
      color: '#f2994a',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    approvalInfoApproved: {
      color: '#27ae60',
      fontSize: '9px',
      fontWeight: '500',
      marginLeft: '10px',
    },
    exeBody: {
      padding: '8px',
    },
    exeTextH4: {
      color: '#828282',
      fontSize: '12px',
      fontWeight: '400',
      marginBottom: '8px',
    },
    exBgBox: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#ebf3fe',
    },
    exBgBoxPadding: {
      padding: '20px 15px 15px',
    },
    exBorderBottom: {
      borderBottom: '1px solid #e5e5e5',
    },
    exBgBoxHeaderText: {
      margin: '0px 0px 10px',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#174a84',
      fontWeight: '600',
    },
    label: {
      color: '#828282',
      fontSize: '10px',
      fontWeight: '500',
      marginBottom: '4px',
      display: 'block',
    },
    formValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
    },
    commentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '460px',
      'overflow-wrap': 'break-word',
    },
    berthCommentValue: {
      color: '#585c64',
      padding: '6px 8px 6px 8px',
      background: '#fff',
      display: 'block',
      fontSize: '1em',
      border: '1px solid #90a1b5',
      borderRadius: '4px',
      minHeight: '32px',
      width: '440px',
      'overflow-wrap': 'break-word',
    },
    paddinRight8: {
      paddingRight: '8px',
    },
    exBoxWhite: {
      border: '1px solid #2f80ed',
      borderRadius: '8px',
      background: '#fff',
    },
    table: {
      width: '100%',
    },
    tableTH: {
      fontSize: '12px',
      fontWeight: '500',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
    },
    tableTD: {
      fontSize: '10px',
      fontWeight: '400',
      color: '#000',
      border: '1px solid #e5e5e5',
      padding: '10px 15px',
      WordBreak: 'break-all',
      OverflowWrap: 'break-word',
      whiteSpace: 'normal',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  };

  const getCommentBody = useCallback((comment: string) => {
    return comment?.replace(/\n/g, '<br/>');
  }, []);

  const getRiskRatingStyle = (data: any) => {
    let color = '#27ae60';
    if (data === RiskRatingEnum.High) {
      color = '#eb5757';
    } else if (data === RiskRatingEnum.Medium) {
      color = '#FFA500';
    }

    return {
      color: color,
    };
  };

  const getStatusStyle = (data: any) => {
    let color = '#27ae60';

    if (data === ComplianceStatusEnum.VerifiedTrue) {
      color = '#eb5757';
    } else if (data === ComplianceStatusEnum.Open) {
      color = '#FFA500';
    }
    return {
      color: color,
    };
  };

  const getRiskRating = (data: any) => {
    let riskRating = 'Low';
    if (data === RiskRatingEnum.High) {
      riskRating = 'High';
    } else if (data === RiskRatingEnum.Medium) {
      riskRating = 'Medium';
    }

    return riskRating;
  };

  const getStatus = (data: number) => {
    let status = 'Open';
    if (data === ComplianceStatusEnum.VerifiedTrue) {
      status = 'Verified True';
    } else if (data === ComplianceStatusEnum.VerifiedFalse) {
      status = 'Verified False';
    }

    return status;
  };

  return (
    <div className="inner-wrapper-report ps-3 pe-3">
      <div style={cardStyles.reportBlock}>
        {reportDetails && (
          <div id="element-to-print">
            <div style={cardStyles.reportBlock}>
              <div style={cardStyles.logoHead}>
                {logo && (
                  <img
                    src={logo}
                    style={cardStyles.logoImage}
                    alt="CompanyLogo"
                  />
                )}
                <div style={cardStyles.logoTextWrapDiv}>
                  <h1 style={cardStyles.logoText}>COMPLIANCE REPORT</h1>
                </div>
              </div>
              <div style={cardStyles.contentHeaderWrap}>
                <div style={cardStyles.headerColfirst}>
                  <h2 style={cardStyles.headerColfirstHeading}>
                    {reportDetails?.shipName}
                  </h2>
                </div>
                <div style={cardStyles.headerColSecond}>
                  {reportDetails?.reportGeneratedDate && (
                    <span style={cardStyles.headerColSecondSubHead}>
                      Date: {reportDetails?.reportGeneratedDate}
                    </span>
                  )}
                </div>
              </div>
              <div style={cardStyles.contentSubTextWrap}>
                {reportDetails?.imoNumber && (
                  <span style={cardStyles.headerColSecondSubHead}>
                    IMO: {reportDetails?.imoNumber}
                  </span>
                )}
              </div>
              <div style={cardStyles.contentSubTextWrap}>
                {reportDetails?.complianceCheckId && (
                  <span style={cardStyles.headerColSecondSubHead}>
                    Compliance Check ID: {reportDetails?.complianceCheckId}
                  </span>
                )}
              </div>
            </div>

            {reportDetails?.vesselInfo && (
              <div style={cardStyles.reportCard}>
                <div style={cardStyles.reportCardRow}>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Age</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.age}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Status</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.vesselStatus}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Vessel Type</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.vesselType}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>DOC Company</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.docCompany}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>
                      LOA {dimensionUnitText()}
                    </span>
                    <span style={cardStyles.reportValue}>
                      {meterToFeetConversion(
                        reportDetails?.vesselInfo?.lengthOverallLOA
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth15,
                      ...cardStyles.reportBorderRightNone,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Summer DWT</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.deadweight}
                    </span>
                  </div>
                </div>
                <div style={cardStyles.reportCardRow}>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Call sign</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.callSign}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Class</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.classificationSocietyName}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>P & I Club</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.pandIClubName}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth20,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Previous Name</span>
                    <span style={cardStyles.reportValue}>
                      {reportDetails?.vesselInfo?.previousName}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth10,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>
                      Breadth {dimensionUnitText()}
                    </span>
                    <span style={cardStyles.reportValue}>
                      {meterToFeetConversion(
                        reportDetails?.vesselInfo?.breadthExtreme
                      )}
                    </span>
                  </div>
                  <div
                    style={{
                      ...cardStyles.reportCardCol,
                      ...cardStyles.reportCardColWidth15,
                      ...cardStyles.reportBorderRightNone,
                    }}
                  >
                    <span style={cardStyles.reportLabel}>Sanction</span>
                    <span
                      style={
                        reportDetails?.vesselInfo?.sanctionDetails === 'no'
                          ? {
                              ...cardStyles.reportValue,
                              ...cardStyles.reportValueGreen,
                            }
                          : {
                              ...cardStyles.reportValue,
                              ...cardStyles.reportValueRed,
                            }
                      }
                    >
                      {reportDetails?.vesselInfo?.sanctionDetails}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {reportDetails?.reportSummary && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>Report Summary</h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                >
                  {reportDetails?.reportSummary}
                </div>
              </div>
            )}
            {!reportDetails?.isAISDataAvailable && (
              <>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <p>No AIS Data available.</p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '10px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '200px',
                    }}
                  >
                    <strong>Vessel Status:</strong>{' '}
                    <span>{reportDetails?.vesselInfo?.vesselStatus}</span>
                  </div>
                </div>
              </>
            )}

            {reportDetails?.aisGapDetails &&
              reportDetails?.isAISDataAvailable && (
                <div>
                  <h3 style={cardStyles.reportSectionHeading}>
                    AIS Gap Details
                  </h3>
                  <div>
                    {reportDetails.aisGapDetails.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            padding: '0px 1px 0px',
                          }}
                        >
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            Voyage Start
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            Voyage End
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            Start Coordinates
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            End Coordinates
                          </div>
                          <div
                            style={{ ...cardStyles.tableTH, width: '12.5%' }}
                          >
                            Nearby Ports
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                            Duration of Gap
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '7.5%' }}>
                            Risk Rating
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                            Status
                          </div>
                        </div>

                        {reportDetails.aisGapDetails.map(
                          (aisData: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                padding: '0px 1px 0px',
                              }}
                            >
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {aisData?.voyageStart}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {aisData?.voyageEnd}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {convertDecimalToMinutes(
                                  aisData?.startPosition?.lat,
                                  true
                                )}{' '}
                                /{' '}
                                {convertDecimalToMinutes(
                                  aisData?.startPosition?.lon
                                )}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {convertDecimalToMinutes(
                                  aisData?.endPosition?.lat,
                                  true
                                )}{' '}
                                /{' '}
                                {convertDecimalToMinutes(
                                  aisData?.endPosition?.lon
                                )}
                              </div>

                              <div
                                style={{
                                  ...cardStyles.tableTD,
                                  width: '12.5%',
                                }}
                              >
                                {aisData?.darkPortCalls
                                  .map((port: any) => port?.portName)
                                  .join(', ')}
                              </div>

                              <div
                                style={{ ...cardStyles.tableTD, width: '10%' }}
                              >
                                {aisData?.timeTakenInHr}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '7.5%' }}
                              >
                                <span
                                  style={getRiskRatingStyle(
                                    aisData?.riskRating
                                  )}
                                >
                                  {getRiskRating(aisData?.riskRating)}
                                </span>
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '10%' }}
                              >
                                <span style={getStatusStyle(aisData?.status)}>
                                  {getStatus(aisData?.status)}
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <p>No AIS Gap Details available.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {reportDetails?.stsDetails && reportDetails?.isAISDataAvailable && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>STS Details</h3>
                {reportDetails.stsDetails.length > 0 ? (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        padding: '0px 1px 0px',
                      }}
                    >
                      <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                        Proximity Vessel
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                        Start Coordinates
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                        End Coordinates
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                        Vessel Speed
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                        Duration
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                        Area
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                        Risk Rating
                      </div>
                      <div style={{ ...cardStyles.tableTH, width: '12.5%' }}>
                        Status
                      </div>
                    </div>

                    {reportDetails.stsDetails.map(
                      (stsData: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            padding: '0px 1px 0px',
                          }}
                        >
                          <div
                            style={{ ...cardStyles.tableTD, width: '12.5%' }}
                          >
                            {stsData?.proximityVessel}
                          </div>
                          <div style={{ ...cardStyles.tableTD, width: '15%' }}>
                            {convertDecimalToMinutes(
                              stsData?.startCoordinates?.lat,
                              true
                            )}{' '}
                            /{' '}
                            {convertDecimalToMinutes(
                              stsData?.startCoordinates?.lon
                            )}
                          </div>
                          <div style={{ ...cardStyles.tableTD, width: '15%' }}>
                            {convertDecimalToMinutes(
                              stsData?.endCoordinates?.lat,
                              true
                            )}{' '}
                            /{' '}
                            {convertDecimalToMinutes(
                              stsData?.endCoordinates?.lon
                            )}
                          </div>
                          <div
                            style={{ ...cardStyles.tableTD, width: '12.5%' }}
                          >
                            {stsData?.vesselAverageSpeed?.toFixed(2)}
                          </div>
                          <div style={{ ...cardStyles.tableTD, width: '10%' }}>
                            {stsData?.duration}
                          </div>
                          <div style={{ ...cardStyles.tableTD, width: '10%' }}>
                            {stsData?.area}
                          </div>
                          <div
                            style={{ ...cardStyles.tableTD, width: '12.5%' }}
                          >
                            <span
                              style={getRiskRatingStyle(stsData?.riskRating)}
                            >
                              {getRiskRating(stsData?.riskRating)}
                            </span>
                          </div>
                          <div
                            style={{ ...cardStyles.tableTD, width: '12.5%' }}
                          >
                            <span style={getStatusStyle(stsData?.status)}>
                              {getStatus(stsData?.status)}
                            </span>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <p>No STS Data available.</p>
                  </div>
                )}
              </div>
            )}

            {reportDetails?.loiteringDetails &&
              reportDetails?.isAISDataAvailable && (
                <div>
                  <h3 style={cardStyles.reportSectionHeading}>
                    Loitering Details
                  </h3>
                  <div>
                    {reportDetails?.loiteringDetails.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            padding: '0px 1px 0px',
                          }}
                        >
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            Start Coordinates
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '15%' }}>
                            End Coordinates
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                            Average Cluster Speed
                          </div>
                          <div
                            style={{ ...cardStyles.tableTH, width: '12.5%' }}
                          >
                            From Time
                          </div>
                          <div
                            style={{ ...cardStyles.tableTH, width: '12.5%' }}
                          >
                            To Time
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '10%' }}>
                            Duration
                          </div>
                          <div
                            style={{ ...cardStyles.tableTH, width: '12.5%' }}
                          >
                            Risk Rating
                          </div>
                          <div
                            style={{ ...cardStyles.tableTH, width: '12.5%' }}
                          >
                            Status
                          </div>
                        </div>

                        {reportDetails?.loiteringDetails.map(
                          (loiteringData: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                padding: '0px 1px 0px',
                              }}
                            >
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {convertDecimalToMinutes(
                                  loiteringData?.startCoordinate?.lat,
                                  true
                                )}{' '}
                                /{' '}
                                {convertDecimalToMinutes(
                                  loiteringData?.startCoordinate?.lon
                                )}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '15%' }}
                              >
                                {convertDecimalToMinutes(
                                  loiteringData?.endCoordinate?.lat,
                                  true
                                )}{' '}
                                /{' '}
                                {convertDecimalToMinutes(
                                  loiteringData?.endCoordinate?.lon
                                )}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '10%' }}
                              >
                                {loiteringData?.avgClusterSpeed.toFixed(2)}
                              </div>
                              <div
                                style={{
                                  ...cardStyles.tableTD,
                                  width: '12.5%',
                                }}
                              >
                                {formatDate(
                                  loiteringData?.startCoordinate
                                    ?.lastPositionUTC
                                )}
                              </div>
                              <div
                                style={{
                                  ...cardStyles.tableTD,
                                  width: '12.5%',
                                }}
                              >
                                {formatDate(
                                  loiteringData?.endCoordinate?.lastPositionUTC
                                )}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '10%' }}
                              >
                                {loiteringData?.loiteringDurationinHr}
                              </div>
                              <div
                                style={{
                                  ...cardStyles.tableTD,
                                  width: '12.5%',
                                }}
                              >
                                <span
                                  style={getRiskRatingStyle(
                                    loiteringData?.riskRating
                                  )}
                                >
                                  {getRiskRating(loiteringData?.riskRating)}
                                </span>
                              </div>
                              <div
                                style={{
                                  ...cardStyles.tableTD,
                                  width: '12.5%',
                                }}
                              >
                                <span
                                  style={getStatusStyle(loiteringData?.status)}
                                >
                                  {getStatus(loiteringData?.status)}
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <p>No Loitering Details available.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {reportDetails?.movementRiskDetails &&
              reportDetails?.isAISDataAvailable && (
                <div>
                  <h3 style={cardStyles.reportSectionHeading}>Movement Risk</h3>
                  <div>
                    {reportDetails?.movementRiskDetails.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            padding: '0px 1px 0px',
                          }}
                        >
                          <div style={{ ...cardStyles.tableTH, width: '25%' }}>
                            Port
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '25%' }}>
                            Arrival Date
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '25%' }}>
                            Departure Date
                          </div>
                          <div style={{ ...cardStyles.tableTH, width: '25%' }}>
                            Duration
                          </div>
                        </div>

                        {reportDetails?.movementRiskDetails.map(
                          (movementRiskData: any, index: number) => (
                            <div
                              key={index}
                              style={{
                                display: 'flex',
                                padding: '0px 1px 0px',
                              }}
                            >
                              <div
                                style={{ ...cardStyles.tableTD, width: '25%' }}
                              >
                                {movementRiskData?.portName}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '25%' }}
                              >
                                {formatDate(movementRiskData?.arrivalDate)}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '25%' }}
                              >
                                {formatDate(movementRiskData?.departureDate)}
                              </div>
                              <div
                                style={{ ...cardStyles.tableTD, width: '25%' }}
                              >
                                {movementRiskData?.voyageDuration}
                              </div>
                            </div>
                          )
                        )}
                      </>
                    ) : (
                      <div style={{ textAlign: 'center', padding: '20px' }}>
                        <p>No Movement Risk Details available.</p>
                      </div>
                    )}
                  </div>
                </div>
              )}

            {reportDetails?.disclaimerBasicInfo?.marsDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>Mars Disclaimer</h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      reportDetails?.disclaimerBasicInfo?.marsDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}
            {reportDetails?.disclaimerBasicInfo?.requestorDisclaimer && (
              <div>
                <h3 style={cardStyles.reportSectionHeading}>
                  {reportDetails?.companyName} Disclaimer
                </h3>
                <div
                  style={{
                    ...cardStyles.reportCard,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '50px',
                    fontSize: '12px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: getCommentBody(
                      reportDetails?.disclaimerBasicInfo?.requestorDisclaimer
                    ),
                  }}
                ></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
