import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { IGridConfig } from '../../../../types/grid';
import { IVesselManagementRoles } from '../../../../types/vessel-management-tab';
import Grid from '../../../common/grid/Grid';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { useTranslation } from 'react-i18next';
import { Button } from 'devextreme-react/button';
import ManagementEditModal from './edit-management/EditManagement';
import { getAPIRoute } from '../../../../utils/api-route';
import { Restricted } from '../../../../contexts/PermissionContext';
import { useLoaderContext } from '../../../../contexts/LoaderContext';

function ManagementF(
  props: { imoNumber?: string; getHistoryDetails?: any },
  ref: any
) {
  const { imoNumber, getHistoryDetails } = props;

  const { t } = useTranslation();

  const [vesselManagementRoles, setVesselManagementRoles] = useState<
    IVesselManagementRoles[]
  >([]);

  const [managementRow, setManagementRow] = useState<any>();
  const [isEdit, setIsEdit] = useState(false);
  const [selectedModalId, setSelectedModalId] = useState(0);

  const onRowClick = (e: any) => {
    if (['dx-cell-focus-disabled'].includes(e.event.target.className)) {
      getManagementRow(e.data.roleName, e.data.companyNumber);
      setSelectedModalId(0);
      setIsEdit(true);
    } else {
      getManagementRow(e.data.roleName, e.data.companyNumber);
      setSelectedModalId(1);
      setIsEdit(true);
    }
  };

  const handleCancelClick = () => {
    setIsEdit(!isEdit);
  };

  useImperativeHandle(ref, () => ({
    getVesselMAnagementRoles,
  }));

  const getManagementRow = async (roleName: any, roleCode: any) => {
    await api
      .get({
        url: getAPIRoute('vesselManagementRoleDetails', [
          imoNumber,
          roleName,
          roleCode,
        ]),
      })
      .then((res) => {
        setManagementRow(res);
      });
  };
  const { setIsLoading } = useLoaderContext();
  const getVesselMAnagementRoles = async () => {
    await api
      .get({ url: apiConfig.vesselManagementRoles + imoNumber }, setIsLoading)
      .then((res) => {
        setVesselManagementRoles(res);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      getVesselMAnagementRoles();
    }
  }, [imoNumber]);

  const gridConfig: IGridConfig = {
    class: 'm-c-grid m-c-grid-in-tab m-c-auto-scroll-grid no-border-top',
    testId: 'managementRolesGrid',
    dataSource: vesselManagementRoles,
    defaultColumns: [
      {
        caption: t('labels.role'),
        dataField: 'roleName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyNumberImo'),
        dataField: 'companyNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.companyName'),
        dataField: 'companyName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.address'),
        dataField: 'address',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.country'),
        dataField: 'country',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.email'),
        dataField: 'email',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.phone'),
        dataField: 'phoneNumber',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.fromDate'),
        dataField: 'dateFrom',
        dataType: 'date',
        format: 'dd MMM yyyy',
        minWidth: 150,
      },
      {
        caption: '',
        dataField: 'action',
        dataType: 'string',
        cellTemplate: 'actionTemplate',
        alignment: 'center',
        minWidth: 100,
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    showHeader: false,
    showExport: false,
    showAddButton: false,
    excelFileName: 'VesselManagementRoles.xlsx',
    rowClick: onRowClick,
  };

  const actionTemplate = () => {
    return (
      <>
        <Restricted permission="Ship.ChangeManagement">
          <Button className="app-c-btn app-c-btn--secondary">
            {t('buttons.change')}
          </Button>
        </Restricted>
      </>
    );
  };

  return (
    <React.Fragment>
      <Grid gridConfig={gridConfig} actionTemplate={actionTemplate} />
      <Restricted permission="Ship.EditManagement">
        {isEdit ? (
          <ManagementEditModal
            imoNumber={imoNumber}
            isVisible={isEdit}
            modalId={selectedModalId}
            handleCancelClick={handleCancelClick}
            managementRow={managementRow}
            getVesselMAnagementRoles={getVesselMAnagementRoles}
            getHistoryDetails={getHistoryDetails}
          />
        ) : null}
      </Restricted>
    </React.Fragment>
  );
}

const Management = forwardRef(ManagementF);
export default Management;
