import * as React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ListRequestorCompany from '../pages/requestor-company/list-requestor-company/ListRequestorCompany';
import EditRequestorCompany from '../pages/requestor-company/edit-requestor-company/EditRequestorCompany';
import UiComponent from '../pages/ui-components/ui-component-library/uiComponent';
import VesselInformation from '../pages/vessel-info/VesselInformation';
import Login from '../pages/login/Login';
import EditBusinessunit from '../pages/requestor-company/edit-business-unit/EditBusinessUnit';

import EditRole from '../pages/configuration/roles/edit-roles/EditRoles';
import DashboardUser from '../pages/dashboard/User/DashboardUser';
import ListRoles from '../pages/configuration/roles/list-roles/ListRoles';
import CreateVetRequest from '../pages/vet-request/create-vet-request/CreateVetRequest';
import EditUser from '../pages/requestor-company/edit-user/EditUser';
import EditRule from '../pages/rules/edit-rule/EditRule';
import AdvancedSearch from '../pages/advanced-search/AdvancedSearch';
import GlobalSearchResults from '../pages/global-vessal-search-results/GlobalSearchResults';
import Rules from '../pages/rules';
import ListRules from '../pages/rules/list-rules/RulesList';
import ViewList from '../pages/list/view-list/ViewList';
import EditList from '../pages/list/edit-list/EditList';
import Layout from '../layout/CommonLayout/Layout';
import LoginLayout from '../layout/LoginLayout/LoginLayout';
import List from '../pages/list';
import ViewForgotPassword from '../components/forgotpassword/ForgotPassword';
import Email from '../components/vessel-info/email/Email';
import { usePermission } from '../contexts/PermissionContext';
import { useEffect, useState } from 'react';
import CompanyLayout from '../pages/requestor-company';
import VetRquestListing from '../pages/vet-request/list-vet-request/ListVetRequest';
import MailList from '../pages/vet-request/mail-list/MailList';
import OptionValues from '../pages/option-values/Option-Types';
import NotAuthorized from '../pages/ErrorPages/NotAuthorized';
import PageNotFound from '../pages/ErrorPages/PageNotFound';
import ViewBerthfit from '../pages/berth-fit-check/view-berth-fit-check/ViewBerthfit';
import ViewBuBerthFit from '../pages/berth-fit-check/view-berth-fit-check/bu-berth-fit/View-BU-BerthFit';
import ReportDesigner from '../components/report/report-designer/ReportDesigner';
import Report from '../pages/report/Report';
import { getToken } from '../utils/jwt-decode';
import PortTerminalBerthView from '../pages/port-management/port-termianl-berth-view/PortTerminalBerthView';
import EditPort from '../pages/port-management/edit-port/EditPort';
import EditTerminal from '../pages/port-management/edit-terminal/EditTerminal';
import EditBerth from '../pages/port-management/edit-berth/EditBerth';
import ExternalInterfaces from '../pages/external-interfaces/ExternalInterfaces';
import InterfaceGrid from '../pages/external-interfaces/InterfaceGrid';
import FailureGrid from '../pages/external-interfaces/FailureGrid';
import ComplianceCheck from '../pages/compliance-check/ComplianceCheck';
import ComplianceCheckListing from '../pages/compliance-check-list/ComplianceCheckList';
import SanctionsCheckList from '../pages/sanctions-check-list/SanctionsCheckList';

function Router() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="company" element={<CompanyLayout />}>
          <Route
            path=""
            element={
              <RequireAuth permission="Company.Listing">
                <ListRequestorCompany />
              </RequireAuth>
            }
          />
          <Route
            path="add"
            element={
              <RequireAuth permission="Company.Add">
                <EditRequestorCompany />
              </RequireAuth>
            }
          />
          <Route
            path="edit"
            element={
              <RequireAuth permission="Company.ViewDetail">
                <EditRequestorCompany />
              </RequireAuth>
            }
          />
          <Route
            path="business-unit/add"
            element={
              <RequireAuth permission="CompanyBU.Add">
                <EditBusinessunit />
              </RequireAuth>
            }
          />
          <Route
            path="business-unit/edit"
            element={
              <RequireAuth permission="CompanyBU.ViewDetail">
                <EditBusinessunit />
              </RequireAuth>
            }
          />
          <Route
            path="user/add"
            element={
              <RequireAuth permission="CompanyUsers.Add">
                <EditUser />
              </RequireAuth>
            }
          />
          <Route
            path="user/edit"
            element={
              <RequireAuth permission="CompanyUsers.ViewDetail">
                <EditUser />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="ui-component" element={<UiComponent />} />
        <Route path="vessel-info" element={<VesselInformation />} />
        <Route path="vessel-info/vet-request" element={<VesselInformation />} />
        <Route
          path="vessel-info/berth-fit-check"
          element={<VesselInformation />}
        />
        <Route path="roles" element={<ListRoles />} />
        <Route path="rules" element={<Rules />}>
          <Route
            path=""
            element={
              <RequireAuth permission="GlobalRules.Listing">
                <ListRules />
              </RequireAuth>
            }
          />
          <Route
            path="add"
            element={
              <RequireAuth permission="GlobalRules.Add">
                <EditRule />
              </RequireAuth>
            }
          />
          <Route
            path="business-unit/add"
            element={
              <RequireAuth permission="BURules.Add">
                <EditRule />
              </RequireAuth>
            }
          />
          <Route
            path="edit"
            element={
              <RequireAuth permission="GlobalRules.ViewDetail">
                <EditRule />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="list" element={<List />}>
          <Route
            path=""
            element={
              <RequireAuth permission="GlobalList.Listing">
                <ViewList />
              </RequireAuth>
            }
          />
          <Route
            path="add"
            element={
              <RequireAuth permission="GlobalList.AddNewList">
                <EditList />
              </RequireAuth>
            }
          />
          <Route
            path="business-unit/add"
            element={
              <RequireAuth permission="BULists.Add">
                <EditList />
              </RequireAuth>
            }
          />
          <Route
            path="edit"
            element={
              <RequireAuth permission="GlobalList.ViewDetail">
                <EditList />
              </RequireAuth>
            }
          />
        </Route>
        <Route path="roles/add" element={<EditRole />} />
        <Route path="roles/edit" element={<EditRole />} />
        <Route path="" element={<DashboardUser />} />
        <Route path="user" element={<EditUser />} />
        <Route path="vet-request" element={<CreateVetRequest />} />
        <Route path="vet-request/edit" element={<CreateVetRequest />} />
        <Route path="global-search/" element={<GlobalSearchResults />} />
        <Route path="advanced-search" element={<AdvancedSearch />} />
        <Route path="email" element={<Email />} />
        {/* <Route path="vet-request" element={<VetRequest />} /> */}
        <Route path="list-vet-requests" element={<VetRquestListing />} />
        <Route path="option-value" element={<OptionValues />} />
        <Route
          path="compliance-check-list"
          element={<ComplianceCheckListing />}
        />
        <Route path="sanctions-check-list" element={<SanctionsCheckList />} />
        <Route path="report" element={<Report />} />
        <Route path="berth-fit" element={<ViewBuBerthFit />} />
        <Route path="mail-list" element={<MailList />} />
        <Route path="company-details" element={<EditRequestorCompany />} />
        <Route path="berth-fit-check" element={<ViewBerthfit />} />
        <Route path="compliance-check" element={<ComplianceCheck />} />
        <Route path="sanctions-check" element={<ComplianceCheck />} />
        <Route path="unauthorized" element={<NotAuthorized />} />
        <Route path="report-designer" element={<ReportDesigner />} />
        <Route
          path="external-interfaces"
          element={
            <RequireAuth permission="ExternalInterfaces.Listing">
              <ExternalInterfaces />
            </RequireAuth>
          }
        />
        <Route
          path="external-interfaces/grid"
          element={
            <RequireAuth permission="ExternalInterfaces.ViewDetail">
              <InterfaceGrid />
            </RequireAuth>
          }
        />
        <Route
          path="external-interfaces/grid/failure"
          element={<FailureGrid />}
        />
        <Route path="port" element={<PortTerminalBerthView />} />
        <Route path="port/edit" element={<EditPort />} />
        <Route path="port/terminal/edit" element={<EditTerminal />} />
        <Route path="port/terminal/berth/edit" element={<EditBerth />} />
        <Route path="*" element={<PageNotFound />} />
      </Route>
      <Route path="/login" element={<LoginLayout />}>
        <Route path="" element={<Login />} />
      </Route>
      <Route path="/forgot-password" element={<LoginLayout />}>
        <Route path="" element={<ViewForgotPassword />} />
      </Route>
    </Routes>
  );
}

function RequireAuth({
  children,
  permission,
}: {
  children: JSX.Element;
  permission?: string;
}) {
  const location = useLocation();
  const authToken = getToken();
  const { isAllowedTo, isFeatureListLoaded } = usePermission();
  const [isLoaded, setIsLoaded] = useState(false);
  const [allowed, setIsAllowed] = useState(false);
  useEffect(() => {
    setIsLoaded(!permission);
    const getIsAllowed = async () => {
      await isAllowedTo(permission).then((res) => {
        setIsAllowed(res);
        setIsLoaded(true);
      });
    };
    if (isFeatureListLoaded && permission) {
      getIsAllowed();
    }
  }, [isFeatureListLoaded]);
  if (!authToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (!isLoaded) {
    return null;
  } else if (!allowed && permission) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }
  return children;
}

export default Router;
