import React, { useCallback, useRef, useState } from 'react';
import { DashbaordFeature } from '../../../types/dashboard';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSwappingStrategy, SortableContext } from '@dnd-kit/sortable';
import SortableItem from '../sortable-item/SortableItem';

export default function DraggableRender(props: {
  dashboardListFilter: DashbaordFeature[];
  onSortEnd: (e: any) => void;
  onClickCloseIcon: (e: any, id: any) => Promise<void>;
}) {
  const { dashboardListFilter, onSortEnd, onClickCloseIcon } = props;
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: { delay: 300, tolerance: 2 },
    }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 300, tolerance: 2 },
    })
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const ulRef = useRef<HTMLUListElement | null>(null);

  const overLayWidth = useCallback(() => {
    if (ulRef?.current?.offsetWidth) return ulRef?.current?.offsetWidth / 2;
  }, []);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
    document.body.style.cursor = 'grabbing';
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      onSortEnd(event);
      setActiveId(null);
    },
    [dashboardListFilter]
  );

  if (dashboardListFilter && dashboardListFilter.length) {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        onDragStart={handleDragStart}
      >
        <ul className="m-l-usdata-box-wrapper" ref={ulRef}>
          <SortableContext
            items={dashboardListFilter.map((item) => item.featureId)}
            strategy={rectSwappingStrategy}
          >
            {dashboardListFilter?.map(
              (value: DashbaordFeature, index: number) => {
                return (
                  <SortableItem
                    id={value.featureId}
                    key={`item-${index}`}
                    value={value}
                    onClickCloseIcon={onClickCloseIcon}
                  />
                );
              }
            )}
          </SortableContext>

          <DragOverlay>
            {activeId ? (
              <SortableItem
                id={activeId.toString()}
                value={
                  dashboardListFilter[
                    dashboardListFilter.findIndex(
                      (item) => item.featureId === activeId
                    )
                  ]
                }
                onClickCloseIcon={onClickCloseIcon}
                overlayWidth={{ width: overLayWidth() }}
              />
            ) : null}
          </DragOverlay>
        </ul>
      </DndContext>
    );
  }
  return null;
}
