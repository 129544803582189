import React, { useMemo, useRef, useState } from 'react';
import { IModalConfig } from '../../../../../../types/modal';
import { Button } from 'devextreme-react/button';
import Modal from '../../../../../common/modal/Modal';
import icons from '../../../../../common/icons/icons';
import MarsMap from '../../../../../common/map-components/map/Map';
import { CircleConfig } from '../../../../../../types/map';
import { useTranslation } from 'react-i18next';
import L, { LatLng } from 'leaflet';
import toast from '../../../../../../utils/toast';

export default function STSConfigMapView(
  props: Readonly<{
    isVisible: boolean;
    isDeleteVisible?: boolean;
    zoom: number;
    circleConfig?: CircleConfig[];
    setIsVisible: (value: boolean) => void;
    setCircleConfig: (value: CircleConfig[]) => void;
    setCircleData: (value: boolean) => void;
    setShowConfirmBack: (value: boolean) => void;
  }>
) {
  const {
    isVisible,
    circleConfig,
    zoom,
    setIsVisible,
    setCircleConfig,
    setCircleData,
    isDeleteVisible,
    setShowConfirmBack,
  } = props;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const { t } = useTranslation();
  const modalConfig: IModalConfig = {
    width: 1500,
    height: 800,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };
  const [showCircleSaveModal, setShowCircleSaveModal] =
    useState<boolean>(false);
  const [circlePoints, setCirclePoints] = useState<any>();
  const mapRef = useRef<any>();
  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);

  const drawEvent = (e: any) => {
    const { layer, layerType } = e;
    if (layerType === 'circle') {
      if (layer.getRadius() / 1852 > 50) {
        toast.error({
          title: t('toast.invalidRadiusError'),
        });
        return null;
      } else {
        const newCircle = {
          coordinates: layer.getLatLng(),
          radius: layer.getRadius(),
        };
        setShowCircleSaveModal(true);
        setCirclePoints(newCircle);
      }
    }
  };

  const handleDrawSaveClick = (boundaryName: string, shape?: string) => {
    if (shape === 'Circle') {
      const data = circleConfig;
      data?.push({
        coordinates: circlePoints.coordinates,
        areaName: boundaryName,
        radius: circlePoints.radius,
      });
      if (data) {
        setCircleConfig(data);
      } else {
        setCircleConfig([]);
      }
      setIsDirty(true);
      setShowCircleSaveModal(false);
    }
  };

  const handleEditClick = (e: any) => {
    const { layer, layerType } = e;
    if (layerType === 'circle') {
      if (circleConfig) {
        if (layer.getRadius() / 1852 > 50) {
          toast.error({
            title: t('toast.invalidRadiusError'),
          });
          layer.setRadius(circleConfig[0].radius);
          return null;
        } else {
          const circleConfigList = circleConfig;
          const index = circleConfigList?.findIndex(
            (item: CircleConfig) =>
              item.stsHighProneAreaId === layer.stsHighProneAreaId
          );
          circleConfigList[index] = {
            coordinates: layer.getLatLng(),
            radius: layer.getRadius(),
            areaName: layer.name,
            stsHighProneAreaId: layer.stsHighProneAreaId,
          };
          setCircleConfig(circleConfigList);
          setIsDirty(true);
        }
      }
    }
  };

  const handleCircleDraw = (
    lat: number,
    lng: number,
    radius: number,
    areaName: string
  ) => {
    const coordinates: LatLng = L.latLng(lat, lng);
    const data = circleConfig;
    data?.push({
      coordinates: coordinates,
      areaName: areaName,
      radius: radius,
    });
    if (data) {
      setCircleConfig(data);
      setIsDirty(true);
    }
  };

  const onCancel = () => {
    if (isDirty) {
      setShowConfirmBack(true);
    } else {
      setIsVisible(false);
    }
  };

  const onEditStarted = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const onHighRiskAreaSave = () => {
    if (
      mapRef?.current?.mapDrawRef?.current?.editControlRef?.current?._toolbars
        ?.edit?._modes?.edit?.handler?._enabled
    ) {
      toast.error({
        title: 'Error',
        message: t('toast.stsProneAreaUnsavedError'),
      });
    } else {
      setCircleData(isDirty);
    }
  };
  return (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header">
        <h2 className="modal-title">
          {circleConfig?.length
            ? t('labels.editStsProneArea')
            : t('labels.addStsProneArea')}
        </h2>
        <div className="m-l-modal-close-button-block">
          <Button className="app-c-btn app-c-icon-only-btn" onClick={onCancel}>
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <div style={{ height: '100%' }}>
          <MarsMap
            onEditStart={onEditStarted}
            isDrawVisible={true}
            isDeleteVisible={isDeleteVisible}
            circleConfig={circleConfig}
            setCircleConfig={setCircleConfig}
            showCircleSaveModal={showCircleSaveModal}
            setShowCircleSaveModal={setShowCircleSaveModal}
            drawEventCallBack={drawEvent}
            handleSaveClick={handleDrawSaveClick}
            handleEditClick={handleEditClick}
            handleCircleDraw={handleCircleDraw}
            isCustomIconVisible={true}
            closePopupOnOutsideClick={true}
            zoom={zoom}
            center={
              circleConfig?.length ? circleConfig[0]?.coordinates : [0, 0]
            }
            ref={mapRef}
          />
        </div>
      </div>
      <div className="m-l-modal__footer">
        <Button className="app-c-btn app-c-btn--secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          className="app-c-btn app-c-btn--primary"
          onClick={onHighRiskAreaSave}
          disabled={!isDirty}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
}
