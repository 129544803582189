import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { MapContainer, Polyline, ScaleControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { mapColors, MapProps, Markers } from '../../../../types/map';
import L, { DivIcon, Icon, IconOptions } from 'leaflet';
import MarkerWithInfoWindow from '../marker-with-info-window/MarkerWithInfoWindow';
import GestureControl from '../gesture-control/GestureControl';
import CoordinatesControl from '../coordinates-control/CoordinatesControl';
import MapDraw from '../draw/draw';
import images from '../../../../assets/images';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import LegendsControl from '../legends-control/LegendsControl';
import LiveFilterIcon from '../live-filter-control/liveFilterIcon';
import { usePermission } from '../../../../contexts/PermissionContext';

function MarsMap(props: Readonly<MapProps>, ref?: any) {
  const {
    center,
    markers,
    isDrawVisible,
    zoom,
    polygonConfig,
    circleConfig,
    showCircleSaveModal,
    showPolygonSaveModal,
    polylineConfig,
    legendsList,
    drawEventCallBack,
    setShowPolygonSaveModal,
    handleSaveClick,
    handleEditClick,
    setShowCircleSaveModal,
    handleDeleteClick,
    handlePolygonRemove,
    isDeleteVisible,
    isCustomIconVisible,
    closePopupOnOutsideClick,
    handleCircleDraw,
    onEditStart,
    liveFilter,
    isFilterVisible,
    portCallDuration,
  } = props;

  const { findFeature } = usePermission();
  const apiKey = 'AIzaSyAWtdEsXU1l1KMdMZEzcJiPRPehPysOTVA';
  const mapDrawRef = useRef<any>();
  const getIcon = useCallback(
    (marker: Markers): DivIcon | Icon<IconOptions> | undefined => {
      switch (marker.iconName) {
        case 'arrowSymbol':
          return arrowSymbolIcon(marker.iconRotation ?? 0, mapColors.red);
        case 'arrowSymbolBlue':
          return arrowSymbolIcon(marker.iconRotation ?? 0, mapColors.blue);
        case 'aisGapStart':
          return L.icon({ iconUrl: images.aisGapStart, iconAnchor: [15, 15] });
        case 'aisGapEnd':
          return L.icon({ iconUrl: images.aisGapEnd, iconAnchor: [15, 15] });
        case 'stsStart':
          return L.icon({ iconUrl: images.stsStart, iconAnchor: [15, 15] });
        case 'stsEnd':
          return L.icon({ iconUrl: images.stsEnd, iconAnchor: [15, 15] });
        case 'anchorageStart':
          return L.icon({ iconUrl: images.departure, iconAnchor: [15, 15] });
        case 'anchorageEnd':
          return L.icon({ iconUrl: images.arrival, iconAnchor: [15, 15] });
        case 'portCallSymbol':
          return L.icon({
            iconUrl: images.portCallSymbol,
            iconAnchor: [15, 15],
          });
        case 'loiteringStart':
          return L.icon({
            iconUrl: images.loiteringStart,
            iconAnchor: [15, 15],
          });
        case 'loiteringEnd':
          return L.icon({ iconUrl: images.loiteringEnd, iconAnchor: [15, 15] });
        case 'label':
          return L.divIcon({
            html: `<div>${marker.label}</div>`,
            className: '',
            iconSize: [60, 20],
          });
        default:
          return L.icon({
            iconUrl: images.pinIconRed,
            iconSize: [40, 40],
            iconAnchor: [20, 40],
          });
      }
    },
    []
  );

  const DEFAULT_COORDINATES: [number, number] = [0, 0];

  const mapElement = useRef<L.Map>(null);
  useEffect(() => {
    setTimeout(() => {
      mapElement?.current?.invalidateSize(true);
      if (center) mapElement?.current?.setView(center);
    }, 500);
  }, [mapElement, center]);

  useImperativeHandle(ref, () => ({
    mapDrawRef,
  }));

  return (
    <div style={{ height: '100%' }}>
      <MapContainer
        id="marsMap"
        ref={mapElement}
        center={
          center ??
          (markers?.length ? markers[0]?.coordinates : DEFAULT_COORDINATES)
        }
        zoom={zoom ?? 6}
        style={{ height: '100%', width: '100%', top: '0', left: '0' }}
        maxBounds={[
          [85, -180],
          [-85, 180],
        ]}
        minZoom={2.4}
        maxZoom={21}
        attributionControl={true}
        scrollWheelZoom={false}
        closePopupOnClick={!!closePopupOnOutsideClick}
      >
        <ReactLeafletGoogleLayer
          styles={[
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{ color: '#67a1c4' }],
            },
          ]}
          apiKey={apiKey}
          type={'roadmap'}
        />
        {polylineConfig?.map((polyline, index) => {
          return (
            <Polyline
              key={`polyline-${index}`}
              dashArray={polyline.isDashedLine ? [4, 7] : undefined}
              positions={polyline.position}
              color={polyline.strokeColor ?? 'red'}
            />
          );
        })}
        <MapDraw
          isVisible={isDrawVisible}
          isDeleteVisible={isDeleteVisible}
          polygonConfig={polygonConfig}
          circleConfig={circleConfig}
          setShowPolygonSaveModal={setShowPolygonSaveModal}
          setShowCircleSaveModal={setShowCircleSaveModal}
          showCircleSaveModal={showCircleSaveModal}
          showPolygonSaveModal={showPolygonSaveModal}
          drawEventCallBack={drawEventCallBack}
          handleEditClick={handleEditClick}
          handleSaveClick={handleSaveClick}
          handleDeleteClick={handleDeleteClick}
          handlePolygonRemove={handlePolygonRemove}
          handleCircleDraw={handleCircleDraw}
          isCustomIconVisible={isCustomIconVisible}
          onEditStart={onEditStart}
          ref={mapDrawRef}
        />
        {markers?.map((marker, index) => {
          return (
            <MarkerWithInfoWindow
              key={`marker-${index}`}
              position={marker.coordinates}
              icon={getIcon(marker)}
              infoWindowContent={marker.infoWindowContent}
            />
          );
        })}
        <FullscreenControl />
        <ScaleControl />
        <GestureControl />
        <CoordinatesControl />
        {findFeature('Ship.ViewNavigationHistory') && isFilterVisible && (
          <LiveFilterIcon
            liveFilters={liveFilter}
            portCallDuration={portCallDuration}
          />
        )}
        {legendsList?.length && <LegendsControl legendList={legendsList} />}
      </MapContainer>
    </div>
  );
}

const arrowSymbolIcon = (rotation: number, color: string) => {
  return L.divIcon({
    html: `<svg 
      viewBox="0 0 24 24" 
      fill="${color}" 
      xmlns="http://www.w3.org/2000/svg" 
      style="transform-origin: 12px 4px; transform: rotate(${rotation}deg);"
      stroke="${color}">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g>
      <g id="SVGRepo_iconCarrier">
        <path opacity="0.15" d="M12 3L19 20L12 17L5 20L12 3Z" fill="${color}"></path>
        <path d="M19 20L12 3L5 20L12 17L19 20Z" stroke="${color}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
      </g>
    </svg>`,
    className: '',
    iconSize: [24, 24],
    iconAnchor: [12, 4],
  });
};
export default forwardRef(MarsMap);
