import React, { useMemo } from 'react';
import Popup from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { IDialogConfig } from '../../../types/dialog';
import icons from '../icons/icons';

export default function ConfirmDialog(props: {
  dialogConfig: IDialogConfig;
  isOpen: boolean;
}) {
  const defaultDialogConfig: IDialogConfig = {
    title: 'Confirmation',
    content: 'Are you sure you want to save the changes?',
    okButtonLabel: 'Yes',
    cancelButtonLabel: 'No',
    handleSubmit: () => {
      return;
    },
    handleClose: () => {
      return;
    },
  };

  const popupAttributes = useMemo(() => {
    return { 'data-testid': props.dialogConfig.id };
  }, []);

  const {
    title,
    content,
    okButtonLabel,
    cancelButtonLabel,
    handleSubmit,
    handleClose,
    handleCancel,
  } = { ...defaultDialogConfig, ...props.dialogConfig };

  return (
    <Popup
      visible={props.isOpen}
      showTitle={false}
      hideOnOutsideClick={false}
      wrapperAttr={popupAttributes}
    >
      <div className="m-l-confirmation-dialogbox-wrap">
        <div className="m-l-confirm-dialogbox-header">
          <Button
            icon="close"
            onClick={handleCancel ? handleCancel : handleClose}
          ></Button>
        </div>
        <br></br>
        <div className="m-l-confirm-dialogbox-body">
          <div className="m-l-confirm-dialogbox-title-block">
            <icons.Confirmation />
            <div className="m-l-confirmation-dialogbox-title">{title}</div>
          </div>
          <div className="m-l-confirmation-info">{content}</div>
        </div>
        <div className="m-l-confirm-dialogbox-footer">
          <Button
            width={120}
            text={cancelButtonLabel}
            type="default"
            stylingMode="outlined"
            onClick={handleClose}
            className="app-c-btn app-c-btn--secondary"
          />
          <Button
            width={120}
            text={okButtonLabel}
            type="default"
            stylingMode="contained"
            onClick={handleSubmit}
            className="app-c-btn app-c-btn--primary"
          />
        </div>
      </div>
    </Popup>
  );
}
