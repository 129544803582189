import L, { Layer, LeafletEvent } from 'leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Circle, FeatureGroup, Polygon, Popup, Tooltip } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import BoundarySaveModal from '../boundary-save-modal/BoundarySaveModal';
import { MapDrawProps } from '../../../../types/map';
import { convertDecimalToMinutes } from '../../../../utils/coordinates';
import { useTranslation } from 'react-i18next';
import CustomDrawIcon from '../custom-draw-control/CustomDrawIcon';

function MapDraw(props: Readonly<MapDrawProps>, ref?: any) {
  const {
    isVisible,
    polygonConfig,
    drawEventCallBack,
    handleEditClick,
    showPolygonSaveModal,
    handleSaveClick,
    setShowPolygonSaveModal,
    setShowCircleSaveModal,
    circleConfig,
    showCircleSaveModal,
    handleDeleteClick,
    handlePolygonRemove,
    isDeleteVisible,
    handleCircleDraw,
    isCustomIconVisible,
  } = props;

  const { t } = useTranslation();
  const editControlRef = useRef<any>(null);
  const popupRef = useRef<any>(null);
  const handleCreated = (e: L.DrawEvents.Created) => {
    featureRef.current?.removeLayer(e.layer as Layer);
    if (drawEventCallBack) {
      drawEventCallBack(e);
    }
  };
  const handleEdited = (e: L.DrawEvents.Edited) => {
    e.layers.eachLayer(function (layer: Layer) {
      if (layer instanceof L.Polygon) {
        if (handleEditClick) {
          handleEditClick({ layer, layerType: 'polygon' });
        }
      } else if (layer instanceof L.Circle) {
        if (handleEditClick) {
          handleEditClick({ layer, layerType: 'circle' });
        }
      } else {
        console.log('Unknown Layer Type');
      }
    });
  };

  const handleDeleted = (e: L.DrawEvents.Deleted) => {
    e.layers.eachLayer(function (layer: Layer) {
      if (layer instanceof L.Polygon) {
        if (handleDeleteClick) {
          handleDeleteClick({ layer, layerType: 'Polygon' });
        }
      } else if (layer instanceof L.Circle) {
        if (handleDeleteClick) {
          handleDeleteClick({ layer, layerType: 'Circle' });
        }
      } else {
        console.log('Unknown Layer Type');
      }
    });
  };

  const handleResize = (e: any) => {
    popupRef?.current?.remove();
    const layer = e.layer;
    if (layer instanceof L.Circle) {
      const radius = layer.getRadius();
      const tooltipElement = document.querySelector(
        '.leaflet-draw-tooltip-subtext'
      );
      if (tooltipElement) {
        tooltipElement.textContent = `Radius: ${(radius / 1852).toFixed(2)} NM`;
      }
    }
  };

  const onRemovePolygon = (e: LeafletEvent) => {
    if (handlePolygonRemove) {
      handlePolygonRemove(e.target);
    }
  };

  useImperativeHandle(ref, () => ({
    editControlRef,
  }));
  const featureRef = useRef<L.FeatureGroup>(null);
  if (!isVisible) return null;

  return (
    <FeatureGroup ref={featureRef}>
      <EditControl
        onMounted={(e: any) => (editControlRef.current = e)}
        position="topright"
        edit={{
          remove: isDeleteVisible ?? true,
          edit: circleConfig?.length ? true : false,
        }}
        draw={{
          circle: circleConfig?.length
            ? false
            : {
                nautic: true,
                metric: false,
                feet: false,
                shapeOptions: {
                  color: 'brown',
                  fillColor: 'red',
                },
              },
          polyline: false,
          rectangle: false,
          marker: false,
          polygon: false,
          circlemarker: false,
        }}
        onCreated={handleCreated}
        onDeleted={handleDeleted}
        onEdited={handleEdited}
        onEditResize={handleResize}
        onEditStart={() => {
          popupRef?.current?.remove();
        }}
        onEditMove={() => {
          popupRef?.current?.remove();
        }}
      />
      {!circleConfig?.length && isCustomIconVisible ? (
        <CustomDrawIcon handleCircleDraw={handleCircleDraw}></CustomDrawIcon>
      ) : null}
      {polygonConfig?.map((item) => (
        <Polygon
          key={Math.random()}
          positions={item.polygonCoordinates}
          fillColor="red"
          color="brown"
          eventHandlers={{
            remove: (e) => {
              onRemovePolygon(e);
            },
            mouseout: (e) => {
              const layer = e.target;
              layer.name = item.polygonName;
              layer.setStyle({ weight: 4 });
            },
          }}
        >
          <Tooltip sticky>{item.polygonName}</Tooltip>
        </Polygon>
      ))}

      {circleConfig?.map((item) => (
        <Circle
          key={Math.random()}
          center={item?.coordinates ?? [0, 0]}
          radius={item.radius}
          fillColor="red"
          color="brown"
          eventHandlers={{
            mouseout: (e) => {
              const layer = e.target;
              layer.name = item.areaName;
              layer.stsHighProneAreaId = item.stsHighProneAreaId;
              layer.setStyle({ weight: 4 });
            },
          }}
        >
          {item.areaName && (
            <Popup autoClose={false} ref={popupRef}>
              <div>
                <p>
                  <strong>{t('labels.stsProneAreaName')}: </strong>{' '}
                  {item.areaName}
                </p>
                <p>
                  <strong>{t('labels.stsCoordinates')}: </strong>
                  {`${convertDecimalToMinutes(
                    item?.coordinates?.lat ?? 0,
                    true
                  )} / ${convertDecimalToMinutes(item?.coordinates?.lng ?? 0)}`}
                </p>
                <p>
                  <strong>{t('labels.radius')}: </strong>
                  {`${(item?.radius / 1852).toFixed(2)} NM`}
                </p>
              </div>
            </Popup>
          )}
          {/* <Tooltip sticky>{item.circleName}</Tooltip> */}
        </Circle>
      ))}
      {(showPolygonSaveModal || showCircleSaveModal) && (
        <BoundarySaveModal
          isVisible={true}
          handleCloseClick={() => {
            if (setShowPolygonSaveModal) setShowPolygonSaveModal(false);
            if (setShowCircleSaveModal) setShowCircleSaveModal(false);
          }}
          handleSaveClick={handleSaveClick}
          shape={showPolygonSaveModal ? 'Polygon' : 'Circle'}
        />
      )}
    </FeatureGroup>
  );
}
export default forwardRef(MapDraw);
