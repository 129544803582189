import React, { useEffect, useMemo, useRef, useState } from 'react';
import Button from 'devextreme-react/button';
import TabPanel from 'devextreme-react/tab-panel';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MarsApiConfig as apiConfig } from '../../api/mars-api-config';
import { MarsApiService as api } from '../../api/mars-api-service';
import { useLoaderContext } from '../../contexts/LoaderContext';
import { useTranslation } from 'react-i18next';
import VesselDetailsTab from '../../components/vessel-info/vessel-details/vessel-details-tab/VesselDetailsTab';
import VesselInfoBanner from './VesselInfoBanner';
import ListVetRequest from '../../components/vessel-info/vet-request-history/ListVetRquest';
import RulesTab from '../../components/vessel-info/rules/rules-tab/RulesTab';
import RecommendationTab from '../../components/vessel-info/recommendation/RecommendationTab';
import AppealHistory from '../../components/vessel-info/appeal-history/AppealHistory';
import { Restricted, usePermission } from '../../contexts/PermissionContext';
import AttachmentGrid from '../../components/vessel-info/attachments/attachment-list/Attachments';
import icons from '../../components/common/icons/icons';
import AddAttachment from '../../components/vessel-info/attachments/add-attachment/AddAttachment';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import imageUrl from '../../assets/images';
import {
  getAdditionalInfo,
  getCompanyId,
  getCompanyType,
  getEmployeeId,
} from '../../utils/jwt-decode';
import { getAPIRoute } from '../../utils/api-route';
import { IDialogConfig } from '../../types/dialog';
import ConfirmDialog from '../../components/common/confirm/ConfirmDialog';
import toast from '../../utils/toast';
import VetRequestDetails from '../../components/vessel-info/vet-request-details/VetRequestDetails';
import { VetRequestStatusCode } from '../../enums/vet-request-status-enum';
import { IAttachments } from '../../types/vessel-attachments';
import AppealDecisionCommentModal from './appeal-decision/AppealDecisionCommentModal';
import VetComments from '../vet-request/vet-comments/Vet-Comments';
import { IChangeAppealReviewer } from '../../types/vet-appeal';
import Email from '../../components/vessel-info/email/Email';
import { IOptionvalue } from '../../types/master-data';
import { CompanyType } from '../../enums/company-type-enum';
import BerthFitHistoryGrid from '../../components/vessel-info/berth-fit-history/BerthFitHistoryGrid';
import BerthFitTab from '../../components/vessel-info/berth-fit-execution/berth-fit-tab/BerthFitTab';
import countryList from '../../components/Country.json';
import { EmployeeStatus } from '../../enums/status-enum';
import ViewBerthfit from '../berth-fit-check/view-berth-fit-check/ViewBerthfit';
import { useDateFormat } from '../../hooks/useDateFormat';
import { Tooltip } from 'devextreme-react/tooltip';
import CancelVetButton from '../../components/vessel-info/vet-request-details/cancel-vet-button/CancelVetButton';
import ReportToPdf from '../vet-report/ReportToPdf';
import AddVesselToListPopover from './add-vessel-to-list/AddVesselToList';
import CopyToClipboard from '../../components/common/copy-to-clipboard/CopyToClipboard';
import LiveTracking from '../../components/vessel-info/live-tracking/LiveTracking';
import { VesselInfoTabIndex } from '../../enums/vessel-info-tab.enum';
import ComplianceListGrid from '../../components/vessel-info/compliance-list-grid/ComplianceListGrid';
import { ReportType } from '../../enums/report-type-enum';
import ComplianceCheckButton from '../../components/vessel-info/compliance-check-button/ComplianceCheckButton';
import SanctionsCheckButton from '../../components/vessel-info/sanctions-check-button/SanctionsCheckButton';
import SanctionsCheckGrid from '../../components/vessel-info/sanctions-check-grid/SanctionsCheckGrid';
import { ComplianceCheckContext } from '../../contexts/ComplianceCheckContext';
import ComplianceTab from '../../components/compliance-check/compliance-Tab/ComplianceTab';
import SanctionsCheckTab from '../../components/sanctions-check/sanctions-check-tab/SanctionsCheckTab';

export const isTabDirtyContext = React.createContext<any>({});

export default function VesselInformation() {
  const { dateTimeWithTimeZone } = useDateFormat();
  const tabRef = useRef<TabPanel>(null);
  const [imoNumber, setImoNumber] = useState<string>();
  const [mailListTab, setMailListTab] = useState<any>();
  const [vetRequestId, setVetRequestId] = useState<string>();
  const { setIsLoading } = useLoaderContext();
  const navigate = useNavigate();
  const [bannerInfoDetails, setBannerInfoDetails] = useState([]) as any;
  const [reviewersList, setReviewersList] = useState([]) as any;
  const [appealReviewers, setAppealReviewers] = useState<any>();
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState<number | undefined>(0);
  const [isVisible, setIsVisible] = useState(false);
  const [reviewerName, setReviewerName] = useState<string>('');
  const [reviewerData, setReviewerData] = useState<any>();
  const [assignedTo, setAssignmentTo] = useState<any>({});
  const [appealReviewerName, setAppealReviewerName] = useState<string>('');
  const [appealReviewerData, setAppealReviewerData] = useState<any>();
  const [appealTo, setAppealTo] = useState<any>({});
  const { featureList, findFeature } = usePermission();
  const companyId = getCompanyId();
  const companyType = getCompanyType();
  const [vetData, setVetData] = useState<any>({});
  const [showConfirmAssign, setShowConfirmAssign] = useState(false);
  const [showConfirmAppeal, setShowConfirmAppeal] = useState(false);
  const lookUpRef = useRef<Lookup>(null);
  const [getVetData, setGetVetData] = useState(false);
  const vetRequestHistoryGridRef = useRef<any>();
  const complianceCheckHistoryGridRef = useRef<any>();
  const sanctionsCheckHistoryGridRef = useRef<any>();
  const [vesselAttachments, setVesselAttachments] = useState<IAttachments[]>(
    []
  );
  const employeeId = Number(getEmployeeId());
  const [isAppeal, setIsAppeal] = useState<boolean>(false);
  const rulesTabRef = useRef<any>(null);
  const berthCheckTabRef = useRef<any>(null);
  const [appealStatusChanged, setAppealStatusChanged] = useState<any>();

  const [isStatusblock, setStatusBlock] = useState(false);
  const [appealStatus, setAppealStatus] = useState<any>('');
  const [appealDecisionModalVisible, setAppealDecisionvModalVisible] =
    useState(false);
  const [statusColor, setStatusColor] = useState('status-bg-orange');
  const [mailCount, setMailCount] = useState<number>(0);
  const tokenFlag = getAdditionalInfo();
  const [showDpDnIcon, setShowDpDnIcon] = useState(false);
  const [showAppealHistoryBtn, setShowhowAppealHistoryBtn] = useState(false);
  const [vetRequestStatusOptions, setVetRequestStatusOptions] =
    useState<any>('');
  const [isMarsUser, setIsMarsUser] = useState<boolean>();
  const [isHostedUser, setIsHostedUser] = useState<boolean>(false);
  const [isRequestedUser, setIsRequestedUser] = useState<boolean>();
  const [downIcon, setDownIcon] = useState<boolean>(false);
  const [appealDecisionvModalTitle, setAppealDecisionvModalTitle] =
    useState<any>('');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [berthfitId, setBerthfitId] = useState<any>();
  const [isBerthFit, setIsBerthFit] = useState<boolean>(false);
  const [berthDetails, setBerthDetails] = useState<any>();
  const [warning, setWarning] = useState<any>();
  const [attachmentId, setAttachmentId] = useState<any>();
  const [recommend, setRecommend] = useState<any>(false);
  const [notRecommend, setNotRecommend] = useState<any>(false);
  const [showRecommendation, setShowRecommendation] = useState<any>(false);
  const [isconfirmBackDialog, setIsConfirmBackDialog] = useState(false);
  const [tabDirtyCount, setTabDirtyCount] = useState<number>(0);
  const [report, setReport] = useState(false);
  const [isBerthFitVessel, setIsBerthFitVessel] = useState(false);
  const [berthReportDetails, setBerthReportDetails] = useState<any>();
  const [berthReportDetailsVessel, setBerthReportDetailsVessel] =
    useState<any>();
  const [showRunOverride, setShowRunOverride] = useState(false);
  const [showAddListPopover, setShowAddListPopover] = useState(false);
  const [berthPortDetails, setBerthPortDetails] = useState<any>();
  const [berthFitDetails, setBerthFitDetails] = useState<any>();
  const [showBerthFitDownloadFromVet, setShowBerthFitDownloadFromVet] =
    useState<boolean>(false);

  const confirmAssignDialogConfig: IDialogConfig = {
    id: 'confirmAssign',
    content:
      reviewerName == ''
        ? t('toast.assignVetRequestConfirm', {
            vetId: vetData?.vetId,
            reviewerName: reviewerData?.employeeName,
          })
        : t('toast.reAssignVetRequestConfirm', {
            vetId: vetData?.vetId,
            reviewerName: reviewerData?.employeeName,
          }),
    handleSubmit: () => {
      assignReviewer();
      setShowConfirmAssign(false);
    },
    handleClose: () => {
      setShowConfirmAssign(false);
    },
  };

  const companyUser = () => {
    if (vetRequestId) {
      if (companyType === CompanyType.Mars) {
        setIsMarsUser(true);
        return false;
      } else if (companyType === CompanyType.HostedCompany) {
        setIsHostedUser(true);
        return false;
      } else {
        setIsRequestedUser(true);
        return true;
      }
    } else {
      if (companyType === CompanyType.Mars) {
        setIsMarsUser(true);
        return false;
      } else if (companyType === CompanyType.HostedCompany) {
        return true;
      } else {
        setIsRequestedUser(true);
        return true;
      }
    }
  };

  const getMailCount = async () => {
    if (vetRequestId) {
      await api
        .get({
          url: apiConfig.VetRequestById + vetRequestId,
        })
        .then((response: any) => {
          setMailCount(response.pendingRFIMailsCount);
        });
    }
  };
  const confirmAppealDialogConfig: IDialogConfig = {
    id: 'confirmAppeal',
    content: t('toast.changeAppealReviewerConfirm', {
      appealReviewerName: appealReviewerData?.employeeName,
    }),
    handleSubmit: () => {
      changeAppealToUser();
      setShowConfirmAppeal(false);
    },
    handleClose: () => {
      setShowConfirmAppeal(false);
    },
  };

  const setTabValue = () => {
    switch (location.state) {
      case 'vetRequestHistoryTab':
        return tabRef.current?.instance.option(
          'selectedIndex',
          VesselInfoTabIndex.VetRequestDetail
        );
      default:
        return tabRef.current?.instance.option(
          'selectedIndex',
          VesselInfoTabIndex.VesselDetails
        );
    }
  };

  const handleCancelClick = () => {
    setIsVisible(false);
    getAttachments();
  };

  useEffect(() => {
    if (location.state) {
      const {
        imoNumber,
        vetRequestId,
        tabValue,
        berthFitID,
        berthDetails,
        attachmentId,
      }: any = location.state;
      setVetRequestId(vetRequestId);
      setImoNumber(imoNumber);
      setMailListTab(tabValue);
      setAttachmentId(attachmentId);
      setBerthfitId(berthFitID);
      setBerthDetails(berthDetails);
      if (berthFitID) {
        onBerthFitNavigation();
      }
    } else {
      const imo = sessionStorage.getItem('imo')
        ? sessionStorage.getItem('imo')
        : localStorage.getItem('imo');
      if (imo) {
        sessionStorage.setItem('imo', imo);
        setImoNumber(imo);
      }
    }
  }, [location.state]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('imo');
      localStorage.removeItem('imo');
    };
  }, []);

  useEffect(() => {
    companyUser();
    if (imoNumber) {
      vesselBannerDetails();
      // getAttachments();
    }
    if (vetRequestId) {
      if (mailListTab || mailListTab == 0) {
        tabRef.current?.instance.option(
          'selectedIndex',
          VesselInfoTabIndex.VetRequestDetail
        );
        tabRef.current?.instance.option('selectedIndex', mailListTab);
      } else {
        tabRef.current?.instance.option(
          'selectedIndex',
          VesselInfoTabIndex.VetRequestDetail
        );
      }
    } else if (attachmentId) {
      tabRef.current?.instance.option(
        'selectedIndex',
        VesselInfoTabIndex.Attachments
      );
    } else {
      setTabValue();
    }
  }, [imoNumber, vetRequestId, mailListTab, attachmentId, berthfitId]);

  useEffect(() => {
    if (vetRequestId) {
      getReviewersList();
      getVetRequestDetails();
      getCargos();
      getIncoTerms();
      getVetRequestStatus();
    }
  }, [vetRequestId]);

  useEffect(() => {
    if (reviewersList.length > 0 && vetData?.reviewerId != null) {
      const selectedReviewer = reviewersList.find(
        (reviewer: any) => reviewer?.employeeId === vetData?.reviewerId
      );
      if (selectedReviewer) {
        setReviewerName(selectedReviewer.employeeName);
        setAssignmentTo({
          imgSrc: imageUrl.avatardrpdwn,
          employeeName: selectedReviewer.employeeName,
        });
      }
    } else {
      setReviewerName('');
      setAssignmentTo({});
    }
  }, [reviewersList, vetData]);

  useEffect(() => {
    if (appealReviewers?.length > 0 && vetData?.appealToUser != null) {
      const selectedReviewer = appealReviewers.find(
        (reviewer: any) =>
          reviewer?.employeeId === vetData?.appealToUser.employeeId
      );
      if (selectedReviewer) {
        setAppealReviewerName(selectedReviewer.employeeName);
        setAppealTo({
          imgSrc: imageUrl.avatardrpdwn,
          employeeName: selectedReviewer.employeeName,
        });
      }
    } else {
      setAppealReviewerName('');
      setAppealTo({});
    }
  }, [appealReviewers, vetData]);

  useEffect(() => {
    if (appealStatusChanged) {
      setGetVetData(true);
    }
  }, [appealStatusChanged]);

  useEffect(() => {
    if (
      vetData.status === VetRequestStatusCode.assigned ||
      vetData.status === VetRequestStatusCode.processing ||
      vetData.status === VetRequestStatusCode.infoReceived ||
      vetData.status === VetRequestStatusCode.recommended ||
      vetData.status === VetRequestStatusCode.approved ||
      vetData.status === VetRequestStatusCode.conditionallyApproved
    ) {
      setStatusColor('status-bg-green status-bg-green-border');
    } else if (
      vetData.status === VetRequestStatusCode.unassigned ||
      vetData.status === VetRequestStatusCode.rejected
    ) {
      setStatusColor('status-bg-red status-bg-red-border');
    } else if (
      vetData.status === VetRequestStatusCode.cancelled ||
      vetData.status === VetRequestStatusCode.autoCancelled
    ) {
      setStatusColor('status-bg-grey status-bg-grey-border');
    } else {
      setStatusColor('status-bg-orange status-bg-orange-border');
    }

    if (
      (vetData?.status &&
        vetData?.status === VetRequestStatusCode.appeal &&
        vetData.appealToUser?.employeeId === employeeId) ||
      ((vetData?.status === VetRequestStatusCode.assigned ||
        vetData?.status === VetRequestStatusCode.processing) &&
        employeeId === vetData?.reviewerId)
    ) {
      setShowDpDnIcon(true);
    } else {
      setShowDpDnIcon(false);
    }
    if (
      (vetData?.status && vetData?.status === VetRequestStatusCode.appeal) ||
      vetData?.status === VetRequestStatusCode.assigned ||
      vetData?.status === VetRequestStatusCode.processing
    ) {
      setStatusBlock(false);
    }
  }, [vetData.status]);

  const showBerthFit = useMemo(() => {
    return vetData.berthFitCheck;
  }, [vetData]);
  const [cargo, setCargo] = useState<IOptionvalue[]>([]);
  const [incoTerms, setIncoTerms] = useState<IOptionvalue[]>([]);

  const getIncoTerms = async () => {
    await api.get({ url: apiConfig.buIncoTerms }).then((data: any) => {
      setIncoTerms(data);
    });
  };

  const getCargos = async () => {
    await api.get({ url: apiConfig.cargo }).then((data: IOptionvalue[]) => {
      setCargo(data);
    });
  };

  const [vetRequestDetails, setVetRequestDetails] = useState<any>();
  const getVetRequestDetails = async () => {
    await api
      .get({ url: apiConfig.vetRequests + vetRequestId })
      .then((data: any) => {
        setVetRequestDetails(data);
        setMailCount(data.pendingRFIMailsCount);
        setVetData({
          vetId: data.vetId,
          statusText: data.statusText,
          status: data.status,
          reviewerId: data.reviewerId,
          isAssignedToMARS: data.isAssignedToMars,
          containFailedRules: data.containFailedRules,
          appealToUser: data.appealToUser,
          isReviewer: data.isReviewer,
          berthFitCheck: data.berthFitCheck,
          complianceChecks: data.complianceChecks,
          sanctionChecks: data.sanctionChecks,
          complianceCheckId: data.complianceCheckId,
          sanctionCheckId: data.sanctionCheckId,
          buId: data.buId,
          vetRequestId: data.vetRequestId,
          vetAttachments: data.vetAttachments,
        });
        setGetVetData(false);
      });
  };

  useEffect(() => {
    if (getVetData) {
      getVetRequestDetails();
    }
  }, [getVetData]);

  const items = useMemo(() => {
    let berthId: any;
    let vetId: any;
    if (location.state) {
      const { berthFitID, vetRequestId }: any = location.state;
      berthId = berthFitID;
      vetId = vetRequestId;
    }
    if (featureList.length > 0 && ((vetId && vetData.vetId) || !vetId)) {
      return [
        {
          title: 'Vessel Detail',
        },
        {
          title: 'Berth Fit History',
          visible: !vetId && findFeature('Ship.BerthFitHistoryView'),
        },
        {
          title: 'Vet Request Detail',
          visible: vetId ? true : false,
        },
        {
          title: 'Vet Request History',
          visible: findFeature('Ship.ViewVetRequestHistory') && !vetId,
        },
        {
          title: 'Rules',
          visible: findFeature('VetRequest.ViewRules') && !!vetId,
        },
        {
          title: 'Berth Fit',
          visible:
            // findFeature('BerthFitVet.BerthfitTab') &&
            !!vetId && showBerthFit,
        },
        {
          title: 'Compliance Check',
          visible: !!vetId && vetData.complianceChecks,
        },
        {
          title: 'Sanctions Check',
          visible: !!vetId && vetData.sanctionChecks,
        },
        {
          title: 'Email',
          visible: vetId ? true : false,
        },
        {
          title: 'Compliance Check History',
          visible:
            !berthId && !vetId && findFeature('Ship.ViewComplianceHistory'),
        },
        {
          title: 'Sanctions Check History',
          visible:
            !berthId && !vetId && findFeature('Ship.ViewSanctionHistory'),
        },
        {
          title: 'Attachments',
          visible: findFeature(
            vetId ? 'VetRequest.ViewAttachments' : 'Ship.ViewAttachments'
          ),
        },
        {
          title: 'Recommendation',
          visible: vetId ? true : false,
        },
        {
          title: 'Berth Fit Check',
          visible: !!berthId,
        },
      ];
    }
    return [];
  }, [featureList, vetData, location.state]);

  const vesselBannerDetails = async () => {
    await api
      .get(
        {
          url: apiConfig.vessels + imoNumber,
          params: { isFromVesselInfo: vetRequestId ? false : true },
        },
        setIsLoading
      )
      .then((data) => {
        setBannerInfoDetails(data);
      });
  };

  const getAttachments = async () => {
    if (vetRequestId) {
      await api
        .get({
          url: getAPIRoute('vetRequestAttachment', [vetRequestId]),
          params: { imoNumber: imoNumber },
        })
        .then((res) => {
          setVesselAttachments(res);
        });
    } else if (berthfitId) {
      await api
        .get({
          url: getAPIRoute('berthFitAttachments', [berthfitId]),
          params: { imoNumber: imoNumber },
        })
        .then((res) => {
          setVesselAttachments(res);
        });
    } else {
      await api
        .get({ url: apiConfig.vesselAttachments + imoNumber })
        .then((res) => {
          setVesselAttachments(res);
        });
    }
  };

  const getReviewersList = async () => {
    await api
      .get({ url: apiConfig.employeeList + companyId }, setIsLoading)
      .then((res) => {
        if (res) {
          const reviewers = res.filter(
            (employee: any) =>
              employee.isReviewer === true &&
              employee.employeeStatus === EmployeeStatus.active
          );
          reviewers.map(
            (reviewer: any) => (reviewer.imgSrc = imageUrl.avatardrpdwn)
          );
          const isReviewer = reviewers.find(
            (item: any) => item.employeeId === employeeId
          );
          if (isReviewer) {
            const index = reviewers.findIndex(
              (item: any) => item.employeeId === employeeId
            );
            const logginedInUser = reviewers[index];
            reviewers.splice(index, 1);
            reviewers.splice(0, 0, logginedInUser);
          }
          setReviewersList(reviewers);
        }
      });
  };

  const assignReviewer = async () => {
    setGetVetData(false);
    await api
      .put(
        {
          url: getAPIRoute('vetRequestAssign', [vetRequestId]),
          params: { reviewerId: reviewerData.employeeId },
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          setReviewerName(reviewerData.employeeName);
          setAssignmentTo({
            imgSrc: reviewerData.imgSrc,
            employeeName: reviewerData.employeeName,
          });

          toast.custom({
            title:
              reviewerName == ''
                ? t('toast.vetRequestAssignedSuccessfully')
                : t('toast.vetRequestReAssignedSuccessfully'),
          });
          setGetVetData(true);
        }
      });
  };

  const goBack = () => {
    const locationState = sessionStorage.getItem('locationState');
    if (locationState != null && locationState == 'advancedSearch') {
      navigate('/advanced-search', { state: 'vesselInfo' });
    } else if (locationState != null && locationState == 'listVetRequest') {
      navigate('/list-vet-requests');
    } else {
      navigate(-1);
    }
    sessionStorage.removeItem('locationState');
  };

  const onAddClick = () => {
    setIsVisible(true);
  };

  const vesselDetailTabRef = useRef<any>(null);

  const isRuleExecutable = useMemo(() => {
    return ![
      VetRequestStatusCode.cancelled,
      VetRequestStatusCode.autoCancelled,
      VetRequestStatusCode.recommended,
      VetRequestStatusCode.notRecommended,
      VetRequestStatusCode.approved,
      VetRequestStatusCode.appeal,
      VetRequestStatusCode.rejected,
      VetRequestStatusCode.conditionallyApproved,
    ].includes(vetData.status);
  }, [vetData]);

  const showRunButton = useMemo(() => {
    return (
      ![
        VetRequestStatusCode.cancelled,
        VetRequestStatusCode.autoCancelled,
        VetRequestStatusCode.recommended,
        VetRequestStatusCode.notRecommended,
        VetRequestStatusCode.approved,
        VetRequestStatusCode.appeal,
        VetRequestStatusCode.rejected,
        VetRequestStatusCode.conditionallyApproved,
      ].includes(vetData.status) && employeeId === vetData?.reviewerId
    );
  }, [vetData]);

  const onClickVesselDetailExpandAll = () => {
    vesselDetailTabRef.current?.expandCollapseAll(!isExpanded);
    setIsExpanded(!isExpanded);
  };

  const complianceContextValue = useMemo(
    () => ({
      complianceCheckId: vetRequestDetails?.complianceCheckId ?? '',
      sanctionsCheckId: vetRequestDetails?.sanctionCheckId ?? '',
    }),
    [vetRequestDetails]
  );

  const itemRender = (e: any) => {
    switch (e.title) {
      case items[VesselInfoTabIndex.VesselDetails]?.title:
        return (
          <isTabDirtyContext.Provider
            value={{
              state: tabDirtyCount,
              setState: setTabDirtyCount,
            }}
          >
            <VesselDetailsTab
              imoNumber={imoNumber}
              ref={vesselDetailTabRef}
              vesselBannerDetails={vesselBannerDetails}
              bannerInfoDetails={bannerInfoDetails.vesselName}
            />
          </isTabDirtyContext.Provider>
        );
      case items[VesselInfoTabIndex.BerthFitHistory]?.title:
        return selectedTab === VesselInfoTabIndex.BerthFitHistory &&
          !vetRequestId ? (
          <BerthFitHistoryGrid
            imoNumber={imoNumber}
            setBerthfitId={setBerthfitId}
            setIsBerthFit={setIsBerthFit}
            setBerthDetails={setBerthDetails}
            tabRef={tabRef}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.VetRequestDetail]?.title:
        return vetRequestId ? (
          <VetRequestDetails
            cargo={cargo}
            incoTerms={incoTerms}
            vetRequestDetails={vetRequestDetails}
            getVetRequestDetails={getVetRequestDetails}
            imoNumber={imoNumber}
            vetRequestId={vetRequestId}
            setVetData={setVetData}
            getVetData={getVetData}
            setGetVetData={setGetVetData}
            manualStatus={appealStatusChanged}
            loginUserId={employeeId}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.VetRequestHistory]?.title:
        return selectedTab === VesselInfoTabIndex.VetRequestHistory ? (
          <ListVetRequest
            imoNumber={imoNumber}
            isFromVetRequestHistoryTab={true}
            tabRef={tabRef}
            ref={vetRequestHistoryGridRef}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.Rules]?.title:
        return selectedTab === VesselInfoTabIndex.Rules ? (
          <RulesTab
            ref={rulesTabRef}
            isRuleExecutable={isRuleExecutable}
            imoNumber={imoNumber}
            vetRequestId={vetRequestId}
            getMailCount={getMailCount}
            vetData={vetData}
            setShowRunOverride={setShowRunOverride}
            showRunButton={showRunButton}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.BerthFit]?.title:
        return selectedTab === VesselInfoTabIndex.BerthFit ? (
          <BerthFitTab
            isRuleExecutable={isRuleExecutable}
            vetRequestId={vetRequestId}
            imoNumber={imoNumber}
            buId={vetData.buId}
            setShowBerthFitDownloadFromVet={setShowBerthFitDownloadFromVet}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.Email]?.title:
        return selectedTab === VesselInfoTabIndex.Email ? (
          <Email vetRequestId={vetRequestId} />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.ComplianceHistory]?.title:
        return selectedTab === VesselInfoTabIndex.ComplianceHistory ? (
          <ComplianceListGrid
            imoNumber={imoNumber ?? ''}
            isFromComplianceHistory={true}
            ref={complianceCheckHistoryGridRef}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.SanctionsCheckHistory]?.title:
        return selectedTab === VesselInfoTabIndex.SanctionsCheckHistory ? (
          <SanctionsCheckGrid
            imoNumber={imoNumber ?? ''}
            isFromSanctionsHistory={true}
            ref={sanctionsCheckHistoryGridRef}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.Attachments]?.title:
        return selectedTab === VesselInfoTabIndex.Attachments ? (
          <AttachmentGrid
            vesselAttachments={vesselAttachments}
            getAttachments={getAttachments}
            vetRequestId={vetRequestId}
            imoNumber={imoNumber}
            isMarsUser={isMarsUser}
            setIsMarsUser={setIsMarsUser}
            isHostedUser={isHostedUser}
            setIsHostedUser={setIsHostedUser}
            isRequestedUser={isRequestedUser}
            setIsRequestedUser={setIsRequestedUser}
            companyUser={companyUser}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.Recommendation]?.title:
        return vetRequestId ? (
          <RecommendationTab
            selectedTab={selectedTab}
            vetData={vetData}
            setAppealReviewers={setAppealReviewers}
            appealReviewerData={appealReviewerData}
            setIsAppeal={setIsAppeal}
            isAppeal={isAppeal}
            companyType={companyType}
            setVetData={setVetData}
            setAppealReviewerName={setAppealReviewerName}
            setAppealTo={setAppealTo}
            setGetVetData={setGetVetData}
            vetRequestId={vetRequestId}
            recommend={recommend}
            setRecommend={setRecommend}
            notRecommend={notRecommend}
            setNotRecommend={setNotRecommend}
            setShowRecommendation={setShowRecommendation}
            imoNumber={imoNumber}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.BerthFitCheck]?.title:
        return selectedTab === VesselInfoTabIndex.BerthFitCheck ? (
          <ViewBerthfit
            ref={berthCheckTabRef}
            berthfitIdProps={berthfitId}
            setBerthfitId={setBerthfitId}
            setBerthDetails={setBerthDetails}
            berthDetails={berthDetails}
            isBerthFit={isBerthFit}
            portStatus={berthPortDetails}
            setPortStatus={setBerthPortDetails}
            berthFitDetails={berthFitDetails}
            setBerthFitDetails={setBerthFitDetails}
          />
        ) : (
          <></>
        );
      case items[VesselInfoTabIndex.ComplianceCheck]?.title:
        return (
          <ComplianceCheckContext.Provider value={complianceContextValue}>
            <ComplianceTab
              imoNumber={imoNumber ?? ''}
              vesselDetails={bannerInfoDetails}
              vetRequestId={vetRequestId}
              isFromVet={true}
            />
          </ComplianceCheckContext.Provider>
        );
      case items[VesselInfoTabIndex.SanctionsCheck]?.title:
        return (
          <ComplianceCheckContext.Provider value={complianceContextValue}>
            <SanctionsCheckTab
              vetRequestId={vetRequestId}
              imoNumber={imoNumber ?? ''}
              vesselDetails={bannerInfoDetails}
              isFromVet={true}
            />
          </ComplianceCheckContext.Provider>
        );
    }
  };

  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.confirmTabChange'),
    handleSubmit: () => {
      setTabDirtyCount(0);
      setIsConfirmBackDialog(false);
    },
    handleClose: () => {
      setIsConfirmBackDialog(false);
      tabRef.current?.instance.option(
        'selectedIndex',
        VesselInfoTabIndex.VesselDetails
      );
    },
  };

  const onTabSelectionChanged = () => {
    if (
      tabDirtyCount > 0 &&
      tabRef.current?.instance.option('selectedIndex') !==
        VesselInfoTabIndex.VesselDetails
    ) {
      setIsConfirmBackDialog(true);
    }
    if (tabDirtyCount == 0) {
      setSelectedTab(tabRef.current?.instance.option('selectedIndex'));
    }
  };

  const onBerthFitNavigation = () => {
    tabRef.current?.instance.option(
      'selectedIndex',
      VesselInfoTabIndex.BerthFitCheck
    );
  };

  useEffect(() => {
    if (berthfitId) {
      onBerthFitNavigation();
      // setIsBerthFit(false);
    }
  }, [berthfitId]);

  const getBerthFitReportDetails = async () => {
    if (selectedTab === VesselInfoTabIndex.BerthFitCheck) {
      const vetid = null;
      await api
        .get({
          url: apiConfig.berthfitReport,
          params: { vetid, imoNumber, berthfitId },
        })
        .then((response: any) => {
          setBerthReportDetailsVessel(response);
          setIsLoading(true), setIsBerthFitVessel(true), setReport(true);
        });
    } else {
      await api
        .get({
          url: apiConfig.berthfitReport,
          params: { vetRequestId, imoNumber, berthfitId },
        })
        .then((response: any) => {
          setBerthReportDetails(response);
          setIsBerthFitVessel(false), setReport(true), setIsLoading(true);
        });
    }
  };

  const searchExpression = ['employeeName'];

  const onAssignClick = (e: any) => {
    if (e.itemData.employeeName != reviewerName) {
      setShowConfirmAssign(true);
      setReviewerData(e.itemData);
    }
  };

  const onAppealClick = (e: any) => {
    if (e.itemData.employeeName != appealReviewerName) {
      if (!vetData.appealToUser?.employeeId) {
        setIsAppeal(true);
      } else {
        setShowConfirmAppeal(true);
      }
      setAppealReviewerData(e.itemData);
    }
  };

  const changeAppealToUser = async () => {
    const chaneAppealReviewerJson: IChangeAppealReviewer = {
      vetRequestId: Number(vetRequestId),
      appealToUserId: appealReviewerData?.employeeId,
    };
    await api
      .put(
        {
          url: getAPIRoute('appealVetRequest', [vetRequestId]),
          data: chaneAppealReviewerJson,
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          setAppealReviewerName(appealReviewerData.employeeName);
          setAppealTo({
            imgSrc: appealReviewerData.imgSrc,
            employeeName: appealReviewerData.employeeName,
          });
          toast.custom({
            title: t('toast.appealSubmittedSuccessfully'),
          });
        }
      });
  };

  const showAppealOptions = () => {
    setDownIcon(!downIcon);
    if (
      (vetData?.status &&
        vetData?.status === VetRequestStatusCode.appeal &&
        vetData.appealToUser?.employeeId === employeeId) ||
      ((vetData?.status === VetRequestStatusCode.assigned ||
        vetData?.status === VetRequestStatusCode.processing) &&
        employeeId === vetData?.reviewerId)
    ) {
      setStatusBlock(!isStatusblock);
    }
  };

  const makeAppealDecision = (decision: string) => {
    setAppealDecisionvModalVisible(true);
    setAppealStatus(decision);
    setStatusBlock(!isStatusblock);
    setAppealDecisionvModalTitle(getvetStatusCodeText(decision));
  };

  const renderAssignTitle = () => {
    return (
      <div>
        {assignedTo?.employeeName && (
          <div className="m-l-look-up-pic-nm-wrap-heading-header">
            <div className="assign-to-text">{t('labels.assignedTo')}</div>
            <div className="m-l-look-up-pic-nm-wrap">
              {/* <img src={assignedTo?.imgSrc} className="m-l-lookup-prof-pic" /> */}
              <icons.Profile />
              <div className="m-l-lookup-drp-text">
                {assignedTo?.employeeName}
              </div>
            </div>
          </div>
        )}
        {!assignedTo.employeeName && (
          <div className="assign-to-text">{t('labels.selectReviewer')}</div>
        )}
      </div>
    );
  };

  const renderAppealTitle = () => {
    return (
      <div>
        {appealTo?.employeeName && (
          <div className="m-l-look-up-pic-nm-wrap-heading-header">
            <div className="assign-to-text">{t('labels.appealTo')}</div>
            <div className="m-l-look-up-pic-nm-wrap">
              <img src={appealTo?.imgSrc} className="m-l-lookup-prof-pic" />
              <div className="m-l-lookup-drp-text">
                {appealTo?.employeeName}
              </div>
            </div>
          </div>
        )}
        {!appealTo.employeeName && (
          <div className="assign-to-text">
            {t('labels.selectAppealReviewer')}
          </div>
        )}
      </div>
    );
  };

  const getVetRequestStatus = async () => {
    await api
      .get({ url: apiConfig.vetRequestStatus })
      .then((data: IOptionvalue[]) => {
        setVetRequestStatusOptions(data);
      });
  };

  const getvetStatusCodeText = (statusCode: any) => {
    if (vetRequestStatusOptions && vetRequestStatusOptions.length > 0) {
      const result = vetRequestStatusOptions.find((item: any) => {
        return item.optionValueCode === statusCode;
      });
      const optionValueText = result?.optionValueText || '';
      return optionValueText;
    }
    return statusCode;
  };

  const filteredCountryList = countryList.find(
    (countryItem) => countryItem.iso_3 == bannerInfoDetails?.iso3
  );

  const clickRef = useRef<any>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        setStatusBlock(false);
        setDownIcon(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [clickRef]);

  useEffect(() => {
    if (imoNumber) {
      getVesselWarning();
      triggerVesselAisPositions();
    }
  }, [imoNumber]);

  const triggerVesselAisPositions = async () => {
    await api
      .get({
        url: `${apiConfig.vesselPositions}${imoNumber}`,
        params: {
          voyageDistanceRequired: findFeature('Ship.ViewDistanceInPortCalls'),
          carbonEmissionRequired: findFeature('Ship.ViewCarbonEmission'),
        },
      })
      .then();
  };

  const getVesselWarning = async () => {
    await api
      .get({
        url: apiConfig.vesselsWarning + imoNumber,
      })
      .then((response: any) => {
        setWarning(response);
      });
  };

  const onClickAddToList = () => {
    setShowAddListPopover(!showAddListPopover);
  };

  const [showWarningTooltip, setShowWarningTooltip] = useState(false);

  const copyText = useMemo(() => {
    const text = bannerInfoDetails.imoDisplayName
      ? `${bannerInfoDetails.vesselName} (IMO: ${bannerInfoDetails.imoDisplayName})`
      : `${bannerInfoDetails.vesselName} (VIN: ${bannerInfoDetails.vinNumber})`;
    return text;
  }, [bannerInfoDetails]);

  return (
    <div className="inner-wrapper u-pl0 u-pr0">
      <div>
        <ul aria-label="breadcrumb" className="m-c-bredcrumb">
          <li>
            <a className="active">{t('labels.vesselInfo')}</a>
          </li>
        </ul>
        <div className="m-l-inner-page-header m-l-vsl-page-hd m-l-form-panel--details-header d-flex">
          <div className="m-l-inner-page-header-left-block">
            <div className="m-l-ttl-status-wrap">
              <div className="m-l-br-ttl-hldr">
                <div className="m-l-title-block">
                  <div
                    className="m-l-page-main-heading page-navigation-link"
                    // onClick={() => {
                    //   navigate('/vessel-info', {
                    //     state: { imoNumber: imoNumber },
                    //   });
                    // }}
                  >
                    <Link
                      className="m-l-page-main-heading"
                      to={'/vessel-info'}
                      state={{ imoNumber: imoNumber }}
                      onContextMenu={() =>
                        localStorage.setItem('imo', imoNumber as string)
                      }
                    >
                      {bannerInfoDetails?.vesselName}
                    </Link>
                  </div>
                  {warning?.warning && (
                    <>
                      <div
                        className="m-l-page-icon"
                        id="warning"
                        onMouseEnter={() => setShowWarningTooltip(true)}
                        onMouseLeave={() => setShowWarningTooltip(false)}
                      >
                        {/* <img
                          src={imageUrl.warningToastIcon}
                          className="img-responsive"
                          alt="success icon"
                          id="warning"
                        /> */}
                        <icons.vesselWarning />
                      </div>
                      <Tooltip
                        target={'#warning'}
                        position="right"
                        visible={showWarningTooltip}
                        contentRender={() => {
                          return (
                            <div className="m-l-tooltip-wrapper m-l-warning-icon-tooltip">
                              <span>{warning.sanctionedMessage}</span>
                              <span>{warning.restrictedMessage}</span>
                            </div>
                          );
                        }}
                      />
                    </>
                  )}
                  <CopyToClipboard text={copyText} />
                </div>
                <div className="m-l-sub-title-details-block">
                  <div className="m-l-flag-wrap">
                    <span className="ml-ads-tbl-flag">
                      <img
                        src={filteredCountryList?.flag}
                        className="img-responsive"
                        alt="flag"
                        height={20}
                        width={20}
                      />
                    </span>
                    <span className="m-l-page-id-text">
                      {bannerInfoDetails.flagName}
                    </span>
                  </div>
                  <div className="app-l-vertical-seperator"></div>
                  {bannerInfoDetails && bannerInfoDetails.imoDisplayName ? (
                    <div className="m-l-page-sub-heading">
                      {t('labels.IMO')}: {bannerInfoDetails?.imoDisplayName}
                    </div>
                  ) : (
                    <div className="m-l-page-sub-heading">
                      {t('labels.vin')}: {bannerInfoDetails?.vinNumber}
                    </div>
                  )}
                  {!vetRequestId && !berthfitId && imoNumber && (
                    <Restricted permission="Ship.ViewLiveTracking">
                      <LiveTracking
                        vesselDetails={bannerInfoDetails}
                        flagUrl={filteredCountryList?.flag ?? ''}
                        imoNumber={imoNumber}
                      />
                    </Restricted>
                  )}
                  {berthDetails && (
                    <>
                      <div className="app-l-vertical-seperator"></div>
                      <span className="m-l-page-id-text">
                        {t('labels.berthFitID')} :
                        <span> {berthDetails?.berthId}</span>
                      </span>
                      {/* <div className="app-l-vertical-seperator"></div> */}

                      {/* <span className="m-l-page-id-text">
                        {t('labels.berthFitDate')} :
                        <span> {formatDate(berthDetails?.berthFitDate)}</span>
                      </span> */}
                    </>
                  )}
                  {vetRequestId && (
                    <>
                      <div className="app-l-vertical-seperator"></div>
                      <span className="m-l-page-id-text">
                        {t('labels.VETID')}: {vetData?.vetId}
                      </span>
                      <div className="app-l-vertical-seperator"></div>
                    </>
                  )}
                  {vetRequestId && (
                    <>
                      <div
                        className={
                          downIcon
                            ? 'm-l-status-block open'
                            : 'm-l-status-block'
                        }
                      >
                        <div className="m-c-form-group">
                          <label className="m-l-input-label">
                            <span className="m-l-page-id-text">
                              {t('labels.status')}
                            </span>
                          </label>
                          <div className="m-l-status-block-dropdown">
                            <Button
                              // className="m-l-status-btn m-l-status-btn-no-icon status-bg-orange"
                              className={`m-l-status-btn m-l-status-btn-no-icon ${statusColor}`}
                              onClick={() => {
                                if (findFeature('VetRequest.ChangeVetStatus')) {
                                  showAppealOptions();
                                }
                              }}
                            >
                              {vetData.statusText}
                              {showDpDnIcon && (
                                <div className="m-c-status-icon-btn">
                                  <icons.downArrow />
                                </div>
                              )}
                            </Button>
                            <div
                              ref={clickRef}
                              className={
                                'm-l-status-dropdown-box ' +
                                (isStatusblock ? '' : 'u-display-none')
                              }
                            >
                              <ul className="m-l-status-items">
                                {vetData?.status ===
                                  VetRequestStatusCode.assigned && (
                                  <li
                                    className="m-l-status-item status-bg-green"
                                    onClick={() =>
                                      makeAppealDecision(
                                        VetRequestStatusCode.processing
                                      )
                                    }
                                  >
                                    {getvetStatusCodeText(
                                      VetRequestStatusCode.processing
                                    )}
                                  </li>
                                )}
                                {vetData?.status ===
                                  VetRequestStatusCode.appeal && (
                                  <>
                                    <li
                                      className="m-l-status-item status-bg-green"
                                      onClick={() =>
                                        makeAppealDecision(
                                          VetRequestStatusCode.approved
                                        )
                                      }
                                    >
                                      {getvetStatusCodeText(
                                        VetRequestStatusCode.approved
                                      )}
                                    </li>
                                    <li
                                      className="m-l-status-item status-bg-red"
                                      onClick={() =>
                                        makeAppealDecision(
                                          VetRequestStatusCode.rejected
                                        )
                                      }
                                    >
                                      {getvetStatusCodeText(
                                        VetRequestStatusCode.rejected
                                      )}
                                    </li>
                                    <li
                                      className="m-l-status-item status-bg-green"
                                      onClick={() =>
                                        makeAppealDecision(
                                          VetRequestStatusCode.conditionallyApproved
                                        )
                                      }
                                    >
                                      {getvetStatusCodeText(
                                        VetRequestStatusCode.conditionallyApproved
                                      )}
                                    </li>
                                  </>
                                )}
                                {vetData?.status ===
                                VetRequestStatusCode.processing ? (
                                  <>
                                    <li
                                      className="m-l-status-item status-bg-orange"
                                      onClick={() => {
                                        makeAppealDecision(
                                          VetRequestStatusCode.waitingforExternalInfo
                                        );
                                      }}
                                    >
                                      {getvetStatusCodeText(
                                        VetRequestStatusCode.waitingforExternalInfo
                                      )}
                                    </li>
                                    <li
                                      className="m-l-status-item status-bg-orange"
                                      onClick={() =>
                                        makeAppealDecision(
                                          VetRequestStatusCode.waitingforRequestorinfo
                                        )
                                      }
                                    >
                                      {getvetStatusCodeText(
                                        VetRequestStatusCode.waitingforRequestorinfo
                                      )}
                                    </li>
                                  </>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {vetRequestId && (
                    <CancelVetButton
                      loginUserId={employeeId}
                      vetRequestDetails={vetRequestDetails}
                      getVetRequestDetails={getVetRequestDetails}
                      imoNumber={imoNumber}
                      vetRequestId={vetRequestId}
                      reviewerId={vetRequestDetails?.reviewerId}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="m-l-inner-page-header-right-block d-flex">
            <Restricted permission={'VetRequest.ViewMaillist'}>
              {vetRequestId && companyType != CompanyType.RequestorCompany && (
                <div
                  className="m-l-mail-notifier-block"
                  onClick={() => {
                    const bannerObj: any = {};
                    bannerObj.vetRequestId = vetRequestId;
                    bannerObj.bannerInfoDetails = bannerInfoDetails;
                    bannerObj.vetId = vetData.vetId;
                    bannerObj.selectedTab = selectedTab;
                    navigate('/mail-list/', { state: { bannerObj } });
                  }}
                >
                  {mailCount > 0 && (
                    <div className="m-l-mail-count-holder">
                      <span className="m-l-mail-count">{mailCount}</span>
                    </div>
                  )}
                  <icons.Mail />
                </div>
              )}
            </Restricted>
            <Button
              className="app-c-btn app-c-btn--secondary u-ml d-none d-md-block"
              onClick={goBack}
            >
              {t('buttons.back')}
            </Button>
            {!vetRequestId &&
              selectedTab !== VesselInfoTabIndex.BerthFitCheck && (
                <Restricted permission="Ship.AddtoList">
                  <Button
                    className="app-c-btn app-c-btn--secondary u-ml d-none d-md-block"
                    onClick={onClickAddToList}
                    id="addToList"
                  >
                    {t('buttons.addToList')}
                  </Button>
                </Restricted>
              )}

            {!vetRequestId && !berthfitId && (
              <Restricted permission="SanctionCheck.SanctionCheck">
                <SanctionsCheckButton imoNumber={imoNumber ?? ''} />
              </Restricted>
            )}

            {!vetRequestId && !berthfitId && (
              <Restricted permission="ComplianceCheck.ComplianceCheck">
                <ComplianceCheckButton imoNumber={imoNumber ?? ''} />
              </Restricted>
            )}

            {!vetRequestId && (
              <Restricted permission="BerthFitVessel.BerthfitCheck">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width d-none d-md-block"
                  onClick={() =>
                    navigate('/berth-fit-check', {
                      state: { imoNumber: imoNumber },
                    })
                  }
                >
                  {t('buttons.berthFitCheck')}
                </Button>
              </Restricted>
            )}

            {/* </Restricted> */}
            {!vetRequestId && (
              <Restricted permission="VetRequest.Create">
                <Button
                  className="app-c-btn app-c-btn--primary min-btn-width d-none d-md-block"
                  onClick={() =>
                    navigate('/vet-request', {
                      state: { imoNumber: imoNumber },
                    })
                  }
                >
                  {t('buttons.createRequest')}
                </Button>
              </Restricted>
            )}
          </div>
          <AddVesselToListPopover
            showPopover={showAddListPopover}
            setShowPopover={setShowAddListPopover}
            target="#addToList"
            imoNumber={bannerInfoDetails.imoDisplayName}
            vinNumber={bannerInfoDetails?.vinNumber}
            vesselName={bannerInfoDetails?.vesselName}
          />
        </div>

        <div className="m-l-inner-page-body-wrap m-l-srv-pdng">
          <div className="m-l-form-panel--details-block">
            <VesselInfoBanner
              bannerInfoDetails={bannerInfoDetails}
              isSanction={warning?.warning}
            ></VesselInfoBanner>
            {/* mobile view buttons */}
            <div className="m-l-inner-page-footer">
              <div className="row m-l-custom-eight-space-row">
                <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                  <div className="m-l-group-button-btms">
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      onClick={goBack}
                    >
                      {t('buttons.back')}
                    </Button>
                    {!vetRequestId &&
                      selectedTab !== VesselInfoTabIndex.BerthFitCheck && (
                        <Restricted permission="Ship.AddtoList">
                          <Button
                            className="app-c-btn app-c-btn--secondary"
                            onClick={onClickAddToList}
                            id="addToList"
                          >
                            {t('buttons.addToList')}
                          </Button>
                        </Restricted>
                      )}
                    {!vetRequestId && !berthfitId && (
                      <Restricted permission="SanctionCheck.SanctionCheck">
                        <SanctionsCheckButton imoNumber={imoNumber ?? ''} />
                      </Restricted>
                    )}

                    {!vetRequestId && !berthfitId && (
                      <Restricted permission="ComplianceCheck.ComplianceCheck">
                        <ComplianceCheckButton imoNumber={imoNumber ?? ''} />
                      </Restricted>
                    )}
                    {!vetRequestId && (
                      <Restricted permission="BerthFitVessel.BerthfitCheck">
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          onClick={() =>
                            navigate('/berth-fit-check', {
                              state: { imoNumber: imoNumber },
                            })
                          }
                        >
                          {t('buttons.berthFitCheck')}
                        </Button>
                      </Restricted>
                    )}
                    {!vetRequestId && (
                      <Restricted permission="VetRequest.Create">
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          onClick={() =>
                            navigate('/vet-request', {
                              state: { imoNumber: imoNumber },
                            })
                          }
                        >
                          {t('buttons.createRequest')}
                        </Button>
                      </Restricted>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* mobile view buttons */}
          </div>
          <div className="m-l-inner-page-tab-section">
            <div className="m-l-inner-page-body u-mb0 ">
              <div className="m-l-tab-panel__wrap">
                <TabPanel
                  ref={tabRef}
                  items={items}
                  className="m-c-tab-block"
                  onSelectionChanged={onTabSelectionChanged}
                  itemRender={itemRender}
                  showNavButtons={true}
                ></TabPanel>
                {isVisible ? (
                  <AddAttachment
                    vetRequestId={vetRequestId}
                    isVisible={isVisible}
                    handleCancelClick={handleCancelClick}
                  />
                ) : null}
                {selectedTab === VesselInfoTabIndex.VetRequestDetail &&
                  vetRequestId &&
                  ((companyType == 'H' && !vetData?.isAssignedToMARS) ||
                    companyType == 'M') && (
                    <div className="m-l-reviewer-right-block">
                      {reviewerName &&
                        reviewerName != '' &&
                        (vetData?.status == VetRequestStatusCode.assigned ||
                          vetData?.status == VetRequestStatusCode.processing ||
                          vetData?.status ==
                            VetRequestStatusCode.recommended) && (
                          <div className="m-l-reviewer-block">
                            <h5>{t('labels.reviewer')} :</h5>
                            <div className="m-l-reviewer-nmae-block">
                              <span>{reviewerName}</span>
                            </div>
                          </div>
                        )}
                      <div className="m-l-apl-btn-wrap">
                        <Restricted permission={'VetRequest.Assign'}>
                          {reviewerName === '' &&
                            (vetData?.status ==
                              VetRequestStatusCode.requested ||
                              vetData?.status ==
                                VetRequestStatusCode.unassigned ||
                              vetData?.status ==
                                VetRequestStatusCode.waitingforExternalInfo ||
                              vetData?.status ==
                                VetRequestStatusCode.waitingforRequestorinfo ||
                              vetData?.status ==
                                VetRequestStatusCode.infoReceived ||
                              vetData?.status ==
                                VetRequestStatusCode.autoCancelled) && (
                              <Button className="app-c-btn app-c-btn--primary min-btn-width">
                                {t('buttons.assign')}
                              </Button>
                            )}
                        </Restricted>
                        <Restricted permission={'VetRequest.ReAssign'}>
                          {reviewerName &&
                            reviewerName != '' &&
                            (vetData?.status == VetRequestStatusCode.assigned ||
                              vetData?.status ==
                                VetRequestStatusCode.processing) && (
                              <Button
                                className="app-c-btn app-c-btn--primary min-btn-width"
                                //onClick={() => setShowLookUp(true)}
                              >
                                {t('buttons.reAssign')}
                              </Button>
                            )}
                        </Restricted>
                        <Lookup
                          ref={lookUpRef}
                          className="field-customization m-l-appeal-drp-dwn"
                          items={reviewersList}
                          searchExpr={searchExpression}
                          valueExpr="employeeId"
                          itemRender={renderItem}
                          onItemClick={onAssignClick}
                          cleanSearchOnOpening={true}
                        >
                          <DropDownOptions
                            title="Assigned to"
                            titleRender={renderAssignTitle}
                            closeOnOutsideClick={true}
                          />
                        </Lookup>
                      </div>
                    </div>
                  )}
                {selectedTab === VesselInfoTabIndex.VetRequestHistory && (
                  <div className="m-l-tab-panel-right-block">
                    <Button
                      className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                      aria-label="Export-btn"
                      elementAttr={{ 'data-testid': 'exportButton' }}
                      onClick={() => {
                        vetRequestHistoryGridRef.current.exportVetRequestsGrid();
                      }}
                    >
                      <div
                        className="m-c-icon-inside-btn"
                        data-testid="export-all"
                      >
                        <icons.download />
                      </div>
                    </Button>
                  </div>
                )}
                {selectedTab === VesselInfoTabIndex.ComplianceHistory && (
                  <div className="m-l-tab-panel-right-block">
                    <Button
                      className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                      aria-label="Export-btn"
                      elementAttr={{ 'data-testid': 'exportButton' }}
                      onClick={() => {
                        complianceCheckHistoryGridRef.current.exportComplianceHistoryGrid();
                      }}
                    >
                      <div
                        className="m-c-icon-inside-btn"
                        data-testid="export-all"
                      >
                        <icons.download />
                      </div>
                    </Button>
                  </div>
                )}
                {selectedTab === VesselInfoTabIndex.SanctionsCheckHistory && (
                  <div className="m-l-tab-panel-right-block">
                    <Button
                      className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                      aria-label="Export-btn"
                      elementAttr={{ 'data-testid': 'exportButton' }}
                      onClick={() => {
                        sanctionsCheckHistoryGridRef.current.exportSanctionsHistoryGrid();
                      }}
                    >
                      <div
                        className="m-c-icon-inside-btn"
                        data-testid="export-all"
                      >
                        <icons.download />
                      </div>
                    </Button>
                  </div>
                )}
                {selectedTab === VesselInfoTabIndex.Rules && (
                  <div className="m-l-tab-panel-right-block">
                    <Restricted permission="VetRequest.ExecuteRules">
                      {showRunButton && showRunOverride && (
                        <Restricted permission="VetRequest.ReRunRules">
                          <Button
                            className="app-c-btn app-c-btn--secondary min-btn-width"
                            onClick={() =>
                              rulesTabRef.current?.showConfirmRuleExecute(true)
                            }
                            id="reRunOverriddenRule"
                          >
                            <>{t('buttons.reRun')}</>
                            <Tooltip
                              target={'#reRunOverriddenRule'}
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              position="bottom"
                              contentRender={() => {
                                return (
                                  <div className="m-l-tooltip-wrapper">
                                    {t('labels.reRunOverriddenRule')}
                                  </div>
                                );
                              }}
                            />
                          </Button>
                        </Restricted>
                      )}
                      {showRunButton && (
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          onClick={() =>
                            rulesTabRef.current?.showConfirmRuleExecute()
                          }
                        >
                          Run
                        </Button>
                      )}
                    </Restricted>
                  </div>
                )}
                {selectedTab === VesselInfoTabIndex.VesselDetails && (
                  <div className="m-l-tab-panel-right-block">
                    {!isExpanded && (
                      <div className="m-l-expand-btn-wrap">
                        <Button
                          elementAttr={{ 'data-testid': 'expandButton' }}
                          onClick={() => onClickVesselDetailExpandAll()}
                          className="app-c-btn app-c-icon-only-btn"
                          id="expand"
                        >
                          <icons.ExpandIcon />
                          <Tooltip
                            target={'#expand'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="bottom"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper">
                                  {t('labels.expand')}
                                </div>
                              );
                            }}
                          />
                        </Button>
                      </div>
                    )}
                    {isExpanded && (
                      <div className="m-l-expand-btn-wrap">
                        <Button
                          elementAttr={{ 'data-testid': 'expandButton' }}
                          onClick={() => onClickVesselDetailExpandAll()}
                          className="app-c-btn app-c-icon-only-btn"
                          id="expand"
                        >
                          <icons.CollapseIcon />
                          <Tooltip
                            target={'#expand'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="bottom"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper">
                                  {t('labels.collapse')}
                                </div>
                              );
                            }}
                          />
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {selectedTab === VesselInfoTabIndex.VetRequestHistory && (
                <div className="m-l-tab-panel-right-block">
                  <Button
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                    onClick={() => {
                      vetRequestHistoryGridRef.current.exportVetRequestsGrid();
                    }}
                  >
                    <div
                      className="m-c-icon-inside-btn"
                      data-testid="export-all"
                    >
                      <icons.download />
                    </div>
                  </Button>
                </div>
              )}
              {selectedTab === VesselInfoTabIndex.ComplianceHistory && (
                <div className="m-l-tab-panel-right-block">
                  <Button
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                    onClick={() => {
                      complianceCheckHistoryGridRef.current.exportComplianceHistoryGrid();
                    }}
                  >
                    <div
                      className="m-c-icon-inside-btn"
                      data-testid="export-all"
                    >
                      <icons.download />
                    </div>
                  </Button>
                </div>
              )}
              {selectedTab === VesselInfoTabIndex.SanctionsCheckHistory && (
                <div className="m-l-tab-panel-right-block">
                  <Button
                    className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                    aria-label="Export-btn"
                    elementAttr={{ 'data-testid': 'exportButton' }}
                    onClick={() => {
                      sanctionsCheckHistoryGridRef.current.exportSanctionsHistoryGrid();
                    }}
                  >
                    <div
                      className="m-c-icon-inside-btn"
                      data-testid="export-all"
                    >
                      <icons.download />
                    </div>
                  </Button>
                </div>
              )}
              {selectedTab === VesselInfoTabIndex.Attachments &&
                !(!vetRequestId && isRequestedUser) && (
                  <div className="m-l-tab-panel-right-block">
                    <div className="m-l-atch-icon-holder">
                      <Restricted
                        permission={
                          vetRequestId
                            ? 'VetRequest.AddAttachments'
                            : 'Ship.AddAttachments'
                        }
                      >
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          onClick={onAddClick}
                          id="attach"
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.AttachBlueIcon />
                          </div>
                          <Tooltip
                            target={'#attach'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="bottom"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper">
                                  {t('labels.addAnAttachment')}
                                </div>
                              );
                            }}
                          />
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                )}
              {selectedTab === VesselInfoTabIndex.BerthFit &&
                (vetData?.status == VetRequestStatusCode.appeal ||
                  vetData?.status == VetRequestStatusCode.recommended ||
                  vetData?.status == VetRequestStatusCode.notRecommended ||
                  vetData?.status == VetRequestStatusCode.approved ||
                  vetData?.status == VetRequestStatusCode.rejected ||
                  vetData?.status ==
                    VetRequestStatusCode.conditionallyApproved) &&
                showBerthFitDownloadFromVet && (
                  <div className="m-l-tab-panel-right-block">
                    <div className="m-l-atch-icon-holder">
                      <Restricted
                        permission={'BerthFitVet.DownloadBerthFitReport'}
                      >
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          onClick={() => getBerthFitReportDetails()}
                          id="downloadreport"
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                          <Tooltip
                            target={'#downloadreport'}
                            showEvent="dxhoverstart"
                            hideEvent="dxhoverend"
                            position="bottom"
                            contentRender={() => {
                              return (
                                <div className="m-l-tooltip-wrapper">
                                  {t('labels.exportBerthReport')}
                                </div>
                              );
                            }}
                          />
                        </Button>
                      </Restricted>
                    </div>
                  </div>
                )}
              {selectedTab === VesselInfoTabIndex.BerthFitCheck && (
                <div className="m-l-tab-panel-right-block">
                  {/* <div className="m-l-atch-icon-holder"> */}

                  {berthPortDetails &&
                    berthPortDetails.berthFitFinalizedDate && (
                      <>
                        <div className="m-l-tab-panel-berth-date">
                          {t('labels.berthFitDate')} :{' '}
                          {dateTimeWithTimeZone(
                            berthPortDetails.berthFitFinalizedDate + 'z'
                          )}
                        </div>
                        <Restricted
                          permission={'BerthFitVessel.DownloadBerthFitReport'}
                        >
                          <Button
                            className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                            onClick={() => getBerthFitReportDetails()}
                            id="download"
                          >
                            <div
                              className="m-c-icon-inside-btn"
                              data-testid="export-all"
                            >
                              <icons.download />
                            </div>
                            <Tooltip
                              target={'#download'}
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              position="bottom"
                              contentRender={() => {
                                return (
                                  <div className="m-l-tooltip-wrapper">
                                    {t('labels.exportBerthReport')}
                                  </div>
                                );
                              }}
                            />
                          </Button>
                        </Restricted>
                      </>
                    )}
                  {berthPortDetails &&
                    !berthPortDetails.berthFitFinalizedDate &&
                    !berthFitDetails?.vetRequestId && (
                      <Restricted permission={'BerthFitVessel.Finalize'}>
                        <Button
                          className="app-c-btn app-c-btn--primary"
                          onClick={() =>
                            berthCheckTabRef?.current?.onClickFinalize()
                          }
                        >
                          {t('buttons.finalize')}
                        </Button>
                      </Restricted>
                    )}
                </div>
                // </div>
              )}
              {selectedTab === VesselInfoTabIndex.Recommendation &&
                vetRequestId &&
                (companyType == 'R' || companyType == 'H') && (
                  <div className="m-l-reviewer-right-block">
                    {appealReviewerName &&
                      appealReviewerName != '' &&
                      (vetData?.status == VetRequestStatusCode.appeal ||
                        vetData?.status == VetRequestStatusCode.approved ||
                        vetData?.status == VetRequestStatusCode.rejected ||
                        vetData?.status ==
                          VetRequestStatusCode.conditionallyApproved) && (
                        <div className="m-l-reviewer-block">
                          <h5>{t('labels.appealTo')} :</h5>
                          <div className="m-l-reviewer-nmae-block">
                            <span>{appealReviewerName}</span>
                          </div>
                        </div>
                      )}
                    <div className="m-l-apl-btn-wrap">
                      <Restricted permission={'VetRequest.Appeal'}>
                        {appealReviewerName === '' &&
                          vetData?.status ==
                            VetRequestStatusCode.notRecommended && (
                            <Button className="app-c-btn app-c-btn--primary min-btn-width">
                              {t('buttons.appeal')}
                            </Button>
                          )}
                      </Restricted>
                      <Restricted permission={'VetRequest.Appeal'}>
                        {appealReviewerName &&
                          appealReviewerName != '' &&
                          vetData?.status == VetRequestStatusCode.appeal && (
                            <Button className="app-c-btn app-c-btn--primary min-btn-width">
                              {t('buttons.change')}
                            </Button>
                          )}
                      </Restricted>
                      <Lookup
                        ref={lookUpRef}
                        className="field-customization m-l-appeal-drp-dwn"
                        items={appealReviewers}
                        searchExpr={searchExpression}
                        valueExpr="employeeId"
                        itemRender={renderItem}
                        onItemClick={onAppealClick}
                        cleanSearchOnOpening={true}
                      >
                        <DropDownOptions
                          title="Appeal to"
                          titleRender={renderAppealTitle}
                          closeOnOutsideClick={true}
                        />
                      </Lookup>
                    </div>
                  </div>
                )}
              {selectedTab === VesselInfoTabIndex.Recommendation &&
                showRecommendation && (
                  <div className="m-l-reviewer-right-block">
                    {/* <div className="m-c-recmnd-btn-wrap"> */}
                    <Restricted permission={'VetRequest.NotRecommend'}>
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width"
                        onClick={() => {
                          setNotRecommend(true);
                        }}
                        elementAttr={{ 'data-testid': 'notRecommBtn' }}
                      >
                        {t('buttons.notRecommend')}
                      </Button>
                    </Restricted>
                    <Restricted permission={'VetRequest.Recommend'}>
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        onClick={() => {
                          setRecommend(true);
                        }}
                        elementAttr={{ 'data-testid': 'recommBtn' }}
                      >
                        {t('buttons.recommend')}
                      </Button>
                    </Restricted>
                    {/* </div> */}
                  </div>
                )}
            </div>
            {/* mobile resolution buttons */}
            <div className="m-l-inner-page-footer">
              <div className="row m-l-custom-eight-space-row">
                <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
                  <div className="m-l-group-button-btms">
                    {selectedTab === VesselInfoTabIndex.VetRequestHistory && (
                      <Button
                        className="app-c-btn app-c-btn--secondary"
                        aria-label="Export-btn"
                        elementAttr={{ 'data-testid': 'exportButton' }}
                        onClick={() => {
                          vetRequestHistoryGridRef.current.exportVetRequestsGrid();
                        }}
                      >
                        {t('labels.download')}
                      </Button>
                    )}
                    {selectedTab === VesselInfoTabIndex.ComplianceHistory && (
                      <div className="m-l-tab-panel-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          onClick={() => {
                            complianceCheckHistoryGridRef.current.exportComplianceHistoryGrid();
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                      </div>
                    )}
                    {selectedTab ===
                      VesselInfoTabIndex.SanctionsCheckHistory && (
                      <div className="m-l-tab-panel-right-block">
                        <Button
                          className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                          aria-label="Export-btn"
                          elementAttr={{ 'data-testid': 'exportButton' }}
                          onClick={() => {
                            sanctionsCheckHistoryGridRef.current.exportSanctionsHistoryGrid();
                          }}
                        >
                          <div
                            className="m-c-icon-inside-btn"
                            data-testid="export-all"
                          >
                            <icons.download />
                          </div>
                        </Button>
                      </div>
                    )}
                    {selectedTab === VesselInfoTabIndex.Rules && (
                      <Restricted permission="VetRequest.ExecuteRules">
                        {showRunButton && showRunOverride && (
                          <Restricted permission="VetRequest.ReRunRules">
                            <Button
                              className="app-c-btn app-c-btn--secondary"
                              onClick={() =>
                                rulesTabRef.current?.showConfirmRuleExecute(
                                  true
                                )
                              }
                              id="reRunOverriddenRule"
                            >
                              <>{t('buttons.reRun')}</>
                              <Tooltip
                                target={'#reRunOverriddenRule'}
                                showEvent="dxhoverstart"
                                hideEvent="dxhoverend"
                                position="bottom"
                                contentRender={() => {
                                  return (
                                    <div className="m-l-tooltip-wrapper">
                                      {t('labels.reRunOverriddenRule')}
                                    </div>
                                  );
                                }}
                              />
                            </Button>
                          </Restricted>
                        )}
                        {showRunButton && (
                          <Button
                            className="app-c-btn app-c-btn--primary"
                            onClick={() =>
                              rulesTabRef.current?.showConfirmRuleExecute()
                            }
                          >
                            {t('buttons.run')}
                          </Button>
                        )}
                      </Restricted>
                    )}
                    {selectedTab === VesselInfoTabIndex.BerthFit &&
                      (vetData?.status == VetRequestStatusCode.appeal ||
                        vetData?.status == VetRequestStatusCode.recommended ||
                        vetData?.status ==
                          VetRequestStatusCode.notRecommended ||
                        vetData?.status == VetRequestStatusCode.approved ||
                        vetData?.status == VetRequestStatusCode.rejected ||
                        vetData?.status ==
                          VetRequestStatusCode.conditionallyApproved) &&
                      showBerthFitDownloadFromVet && (
                        <Restricted
                          permission={'BerthFitVet.DownloadBerthFitReport'}
                        >
                          <Button
                            className="app-c-btn app-c-btn--secondary "
                            onClick={() => getBerthFitReportDetails()}
                            id="downloadReport"
                          >
                            {t('labels.download')}
                          </Button>
                        </Restricted>
                      )}
                    {selectedTab === VesselInfoTabIndex.BerthFitCheck && (
                      <>
                        {berthPortDetails &&
                          berthPortDetails.berthFitFinalizedDate && (
                            <>
                              <div className="m-l-tab-panel-berth-date">
                                {t('labels.berthFitDate')} :{' '}
                                {dateTimeWithTimeZone(
                                  berthPortDetails.berthFitFinalizedDate + 'z'
                                )}
                              </div>
                              <Restricted
                                permission={
                                  'BerthFitVessel.DownloadBerthFitReport'
                                }
                              >
                                <Button
                                  className="app-c-btn app-c-btn--secondary"
                                  onClick={() => getBerthFitReportDetails()}
                                  id="downloadReport"
                                >
                                  {t('labels.download')}
                                </Button>
                              </Restricted>
                            </>
                          )}
                        {berthPortDetails &&
                          !berthPortDetails.berthFitFinalizedDate &&
                          !berthFitDetails.vetRequestId && (
                            <Restricted permission={'BerthFitVessel.Finalize'}>
                              <Button
                                className="app-c-btn app-c-btn--primary"
                                onClick={() =>
                                  berthCheckTabRef?.current?.onClickFinalize()
                                }
                              >
                                {t('buttons.finalize')}
                              </Button>
                            </Restricted>
                          )}
                      </>
                    )}
                    {selectedTab === VesselInfoTabIndex.Attachments &&
                      !(!vetRequestId && isRequestedUser) && (
                        <Restricted
                          permission={
                            vetRequestId
                              ? 'VetRequest.AddAttachments'
                              : 'Ship.AddAttachments'
                          }
                        >
                          <Button
                            className="app-c-btn app-c-btn--secondary"
                            onClick={onAddClick}
                          >
                            {t('buttons.attach')}
                          </Button>
                        </Restricted>
                      )}
                    {selectedTab === VesselInfoTabIndex.Recommendation &&
                      showRecommendation && (
                        <>
                          <Restricted permission={'VetRequest.NotRecommend'}>
                            <Button
                              className="app-c-btn app-c-btn--secondary min-btn-width"
                              onClick={() => {
                                setNotRecommend(true);
                              }}
                              elementAttr={{ 'data-testid': 'notRecommBtn' }}
                            >
                              {t('buttons.notRecommend')}
                            </Button>
                          </Restricted>
                          <Restricted permission={'VetRequest.Recommend'}>
                            <Button
                              className="app-c-btn app-c-btn--primary min-btn-width"
                              onClick={() => {
                                setRecommend(true);
                              }}
                              elementAttr={{ 'data-testid': 'recommBtn' }}
                            >
                              {t('buttons.recommend')}
                            </Button>
                          </Restricted>
                        </>
                      )}
                    {selectedTab === VesselInfoTabIndex.Recommendation &&
                      vetRequestId &&
                      (companyType == 'R' || companyType == 'H') && (
                        <div className="appeal-footer">
                          <div className="m-l-reviewer-right-block m-l-reviewer-footer-block">
                            {appealReviewerName &&
                              appealReviewerName != '' &&
                              (vetData?.status == VetRequestStatusCode.appeal ||
                                vetData?.status ==
                                  VetRequestStatusCode.approved ||
                                vetData?.status ==
                                  VetRequestStatusCode.rejected ||
                                vetData?.status ==
                                  VetRequestStatusCode.conditionallyApproved) && (
                                <div className="m-l-reviewer-block">
                                  <h5>{t('labels.appealTo')} :</h5>
                                  <div className="m-l-reviewer-nmae-block">
                                    <span>{appealReviewerName}</span>
                                  </div>
                                </div>
                              )}
                          </div>
                          <>
                            <Restricted permission={'VetRequest.Appeal'}>
                              {appealReviewerName === '' &&
                                vetData?.status ==
                                  VetRequestStatusCode.notRecommended && (
                                  <Button className="app-c-btn app-c-btn--primary min-btn-width">
                                    {t('buttons.appeal')}
                                  </Button>
                                )}
                            </Restricted>
                            <Restricted permission={'VetRequest.Appeal'}>
                              {appealReviewerName &&
                                appealReviewerName != '' &&
                                vetData?.status ==
                                  VetRequestStatusCode.appeal && (
                                  <Button className="app-c-btn app-c-btn--primary min-btn-width">
                                    {t('buttons.change')}
                                  </Button>
                                )}
                            </Restricted>
                            <Lookup
                              ref={lookUpRef}
                              className="field-customization m-l-appeal-drp-dwn"
                              items={appealReviewers}
                              searchExpr={searchExpression}
                              valueExpr="employeeId"
                              itemRender={renderItem}
                              onItemClick={onAppealClick}
                              cleanSearchOnOpening={true}
                            >
                              <DropDownOptions
                                title="Appeal to"
                                titleRender={renderAppealTitle}
                                closeOnOutsideClick={true}
                              />
                            </Lookup>
                          </>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* mobile resolution buttons */}
          </div>
          {selectedTab === VesselInfoTabIndex.VetRequestDetail &&
            vetRequestId &&
            ((companyType == 'H' && !vetData?.isAssignedToMARS) ||
              companyType == 'M') && (
              <div className="m-l-reviewer-right-block m-l-reviewer-footer-block top-8 px-3">
                {reviewerName &&
                  reviewerName != '' &&
                  (vetData?.status == VetRequestStatusCode.assigned ||
                    vetData?.status == VetRequestStatusCode.processing ||
                    vetData?.status == VetRequestStatusCode.recommended) && (
                    <div className="m-l-reviewer-block">
                      <h5>{t('labels.reviewer')} :</h5>
                      <div className="m-l-reviewer-nmae-block">
                        <span>{reviewerName}</span>
                      </div>
                    </div>
                  )}
                <div className="m-l-apl-btn-wrap">
                  <Restricted permission={'VetRequest.Assign'}>
                    {reviewerName === '' &&
                      (vetData?.status == VetRequestStatusCode.requested ||
                        vetData?.status == VetRequestStatusCode.unassigned ||
                        vetData?.status ==
                          VetRequestStatusCode.waitingforExternalInfo ||
                        vetData?.status ==
                          VetRequestStatusCode.waitingforRequestorinfo ||
                        vetData?.status ==
                          VetRequestStatusCode.infoReceived) && (
                        <Button className="app-c-btn app-c-btn--primary min-btn-width">
                          {t('buttons.assign')}
                        </Button>
                      )}
                  </Restricted>
                  <Restricted permission={'VetRequest.ReAssign'}>
                    {reviewerName &&
                      reviewerName != '' &&
                      (vetData?.status == VetRequestStatusCode.assigned ||
                        vetData?.status == VetRequestStatusCode.processing) && (
                        <Button
                          className="app-c-btn app-c-btn--primary min-btn-width"
                          //onClick={() => setShowLookUp(true)}
                        >
                          {t('buttons.reAssign')}
                        </Button>
                      )}
                  </Restricted>
                  <Lookup
                    ref={lookUpRef}
                    className="field-customization m-l-appeal-drp-dwn"
                    items={reviewersList}
                    searchExpr={searchExpression}
                    valueExpr="employeeId"
                    itemRender={renderItem}
                    onItemClick={onAssignClick}
                    cleanSearchOnOpening={true}
                  >
                    <DropDownOptions
                      title="Assigned to"
                      titleRender={renderAssignTitle}
                      closeOnOutsideClick={true}
                    />
                  </Lookup>
                </div>
              </div>
            )}
          {/* {selectedTab === 8 &&
            vetRequestId &&
            (companyType == 'R' || companyType == 'H') && (
              <div className="m-l-reviewer-right-block m-l-reviewer-footer-block top-8">
                {appealReviewerName &&
                  appealReviewerName != '' &&
                  (vetData?.status == VetRequestStatusCode.appeal ||
                    vetData?.status == VetRequestStatusCode.approved ||
                    vetData?.status == VetRequestStatusCode.rejected ||
                    vetData?.status ==
                      VetRequestStatusCode.conditionallyApproved) && (
                    <div className="m-l-reviewer-block">
                      <h5>{t('labels.appealTo')} :</h5>
                      <div className="m-l-reviewer-nmae-block">
                        <span>{appealReviewerName}</span>
                      </div>
                    </div>
                  )}
                <div className="m-l-apl-btn-wrap">
                  <Restricted permission={'VetRequest.Appeal'}>
                    {appealReviewerName === '' &&
                      vetData?.status ==
                        VetRequestStatusCode.notRecommended && (
                        <Button className="app-c-btn app-c-btn--primary min-btn-width">
                          {t('buttons.appeal')}
                        </Button>
                      )}
                  </Restricted>
                  <Restricted permission={'VetRequest.Appeal'}>
                    {appealReviewerName &&
                      appealReviewerName != '' &&
                      vetData?.status == VetRequestStatusCode.appeal && (
                        <Button className="app-c-btn app-c-btn--primary min-btn-width">
                          {t('buttons.change')}
                        </Button>
                      )}
                  </Restricted>
                  <Lookup
                    ref={lookUpRef}
                    className="field-customization m-l-appeal-drp-dwn"
                    items={appealReviewers}
                    searchExpr={searchExpression}
                    valueExpr="employeeId"
                    itemRender={renderItem}
                    onItemClick={onAppealClick}
                    cleanSearchOnOpening={true}
                  >
                    <DropDownOptions
                      title="Appeal to"
                      titleRender={renderAppealTitle}
                      closeOnOutsideClick={true}
                    />
                  </Lookup>
                </div>
              </div>
            )} */}
          {vetRequestId &&
            !(
              showAppealHistoryBtn &&
              selectedTab === VesselInfoTabIndex.Recommendation
            ) && (
              <VetComments
                vetId={vetData.vetId}
                tokenFlag={tokenFlag}
                processingManualStatus={vetData.status}
              />
            )}
        </div>
      </div>
      {selectedTab === VesselInfoTabIndex.Recommendation && (
        <Restricted permission={'VetRequest.ViewAppealHistory'}>
          <AppealHistory
            vetRequestId={vetRequestId}
            imoNumber={imoNumber}
            showAppealHistoryBtn={showAppealHistoryBtn}
            setShowhowAppealHistoryBtn={setShowhowAppealHistoryBtn}
          />
        </Restricted>
      )}
      <ConfirmDialog
        dialogConfig={confirmAssignDialogConfig}
        isOpen={showConfirmAssign}
      />
      <ConfirmDialog
        dialogConfig={confirmAppealDialogConfig}
        isOpen={showConfirmAppeal}
      />
      {appealDecisionModalVisible ? (
        <AppealDecisionCommentModal
          appealDecisionModalVisible={appealDecisionModalVisible}
          setAppealDecisionvModalVisible={setAppealDecisionvModalVisible}
          vetRequestId={vetRequestId}
          appealStatus={appealStatus}
          setAppealStatusChanged={setAppealStatusChanged}
          appealDecisionvModalTitle={appealDecisionvModalTitle}
        />
      ) : null}
      {report && (berthReportDetails || berthReportDetailsVessel) && (
        <ReportToPdf
          report={report}
          setReport={setReport}
          berthReportDetails={
            isBerthFitVessel ? berthReportDetailsVessel : berthReportDetails
          }
          isBerthFitReportVessel={isBerthFitVessel}
          reportType={ReportType.BerthFit}
        />
      )}
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={isconfirmBackDialog}
      />
    </div>
  );
}

const renderItem = (data: any) => {
  if (data) {
    return (
      <div className="m-l-look-up-pic-nm-wrap">
        {/* <img src={data.imgSrc} className="m-l-lookup-prof-pic" /> */}
        <icons.Profile />
        <div className="m-l-lookup-drp-text">{data.employeeName}</div>
      </div>
    );
  }
};
