import Button from 'devextreme-react/button';
import React, { useEffect, useState } from 'react';
import icons from '../../../../common/icons/icons';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import { IEngineDetails } from '../../../../../types/vessel-engine-tab';
import { useTranslation } from 'react-i18next';
import EngineEdit from '../edit-engine/EngineEdit';
import { Restricted } from '../../../../../contexts/PermissionContext';

export default function Engine(props: { imoNumber?: string }) {
  const [isEdit, setIsEdit] = useState(false);
  const [engineView, setEngineView] = useState(true);
  const [engineDetails, setEngineDetails] = useState<IEngineDetails>();
  const { imoNumber } = props;
  const { t } = useTranslation();

  const GetEngineDetails = async () => {
    await api
      .get({
        url: apiConfig.engineTab + imoNumber,
      })
      .then((response: any) => {
        setEngineDetails(response);
      });
  };

  useEffect(() => {
    if (imoNumber) {
      GetEngineDetails();
    }
  }, []);

  return (
    <div data-testid="engine">
      {engineView ? (
        <div className="m-l-inner-page-body">
          <div className="m-l-expand-btn-wrap m-l-edit-view-mode m-l-edit-view-engine-tab-mode">
            <Restricted permission="Ship.EditEngine">
              <Button
                className="app-c-btn app-c-icon-only-btn"
                onClick={() => {
                  setEngineView(!engineView);
                  setIsEdit(!isEdit);
                }}
              >
                <icons.IconPencil />
              </Button>
            </Restricted>
          </div>
          <div className="m-l-form-panel-block m-l-form-panel-block--tab-mode">
            <div className="m-l-tbl-square-wrapper u-pt0">
              <div className="m-l-ads-table-block">
                <div className="m-l-ads-table-right-part">
                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.mainEngine')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engineType')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineType}
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engineBuilder')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineBuilder}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engineBuilderCode')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineBuilderCode}
                      </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engineModel')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineModel}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.enginePower')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.enginePower}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engTotalBhp')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.powerkwmax}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.engineBore')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineBore}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.maximumSpeed')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.speedMax}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.consumptionInBallast')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineConsumptionInBallast}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.speedInBallast')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineSpeedInBallast}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.consumptionInLoaded')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineConsumptionInloaded}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.speedInLoaded')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.mainEngineSpeedInLoaded}
                      </div>
                    </div>
                  </div>
                  <div className="row m-l-ads-table-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb0 m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-small-heading">
                        {t('labels.auxillaryEngine')}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.generator')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.numberOfAuxiliaryEngines}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.generatorDescription')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.auxiliaryGeneratorsDescriptiveNarrative}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">{t('labels.rpm')}</div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.rpm}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">{t('labels.kw')}</div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.kw}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">{t('labels.bhp')}</div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.bhp}
                      </div>
                    </div>
                  </div>

                  {/* <div className="row m-l-custom-eight-space-row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-l-ads-table-items u-mb-xl m-l-custom-sixteen-space-col">
                      <div className="m-l-details-page-sepeartor"></div>
                    </div>
                  </div> */}
                  <div className="row m-l-ads-table-row">
                    <div className="row m-l-custom-eight-space-row u-mb-xl">
                      <div className="m-l-details-page-sepeartor"></div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.eedi')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.eedi}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.eexi')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.eexi}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">{t('labels.eiv')}</div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.eiv}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">
                        {t('labels.eeoi')}
                      </div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.eeoi}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-6 m-l-ads-table-items m-l-custom-sixteen-space-col">
                      <div className="m-l-ads-tbl-title">{t('labels.cii')}</div>
                      <div className="m-l-ads-tbl-desc">
                        {engineDetails?.cii}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isEdit ? (
        <EngineEdit
          imoNumber={imoNumber}
          setIsEdit={setIsEdit}
          setEngineView={setEngineView}
          engineDetails={engineDetails}
          getEngineDetails={GetEngineDetails}
        />
      ) : null}
    </div>
  );
}
