import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import AISConfigForm from './AisConfigForm';
import { IAisConfigForm } from '../../../../../types/complianceCheck';
import { useTranslation } from 'react-i18next';

export default function AisConfig(props: {
  aisConfigForm: UseFormReturn<IAisConfigForm>;
  buId: string;
}) {
  const { aisConfigForm, buId } = props;
  const { t } = useTranslation();

  return (
    <div className="m-l-inner-page-body-wrap">
      <div className="m-l-inner-page-body">
        <div className="m-l-form-panel-block">
          <div className="m-l-form-panel-body-block ">
            <div className="m-l-us-databox-header">
              <div className="m-l-us-dbox-ttle">
                {t('labels.aisGapConfiguration')}
              </div>
            </div>
            <FormProvider {...aisConfigForm}>
              <AISConfigForm buId={buId} />
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
