import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IModalConfig } from '../../../types/modal';
import TabPanel from 'devextreme-react/tab-panel';
import Modal from '../../common/modal/Modal';
import { Link } from 'react-router-dom';
import { VesselDetails } from '../../../types/vessel-general';
import icons from '../../common/icons/icons';
import { useTranslation } from 'react-i18next';
import countryList from '../../../components/Country.json';
import Button from 'devextreme-react/button';
import CommentSection from '../comment-section/CommentSection';
import { FormProvider, useForm } from 'react-hook-form';
import ScrollView from 'devextreme-react/scroll-view';
import EventGrid from '../event-grid/EventGrid';
import {
  AisGapData,
  LoiteringData,
  MapConfig,
  StsComplianceData,
} from '../../../types/compliance-check';
import MarsMap from '../../common/map-components/map/Map';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import { getCompanyType } from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { IDialogConfig } from '../../../types/dialog';
import toast from '../../../utils/toast';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import {
  ComplianceStatusEnum,
  ComplianceTabType,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import { ClickEvent } from 'devextreme/ui/button';
import Tooltip from 'devextreme-react/tooltip';
import { Restricted } from '../../../contexts/PermissionContext';

export default function MapViewModal(
  props: Readonly<{
    isVisible: boolean;
    vesselDetails: VesselDetails;
    selectedIndex: number;
    gridDataLength: number;
    complianceType: number;
    detailId: number;
    isReportGenerated?: boolean;
    selectedRow: AisGapData | LoiteringData | StsComplianceData;
    eventData: any[];
    mapConfig?: MapConfig;
    vetRequestId?: string;
    setIsVisible: (value: boolean, refreshData?: boolean) => void;
    handleNextPrev?: (index: number) => void;
    handleGridRefresh?: () => void;
  }>
) {
  const {
    mapConfig,
    isVisible,
    vesselDetails,
    complianceType,
    detailId,
    selectedIndex,
    gridDataLength,
    selectedRow,
    isReportGenerated,
    eventData,
    vetRequestId,
    setIsVisible,
    handleNextPrev,
    handleGridRefresh,
  } = props;

  const modalConfig: IModalConfig = {
    width: '98vw',
    height: 800,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
    minHeight: '95vh',
  };

  const { t } = useTranslation();
  const { setIsLoading } = useLoaderContext();
  const complianceCheckContext = useContext(ComplianceCheckContext);
  const companyType = getCompanyType();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [showNextPrevModalOpen, setShowNextPrevModalOpen] = useState(false);

  const [isAttachmentDirty, setIsAttachmentDirty] = useState(false);
  const [isVisibleAttach, setIsVisibleAttach] = useState(false);
  const [attachmentsAdded, setAttachmentsAdded] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const confirmPrevNextConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.unSavedWillLost'),
    handleSubmit: () => {
      saveComments();
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };
  const [nextPrevConfig, setNextPrevConfig] = useState(confirmPrevNextConfig);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    handleSubmit: () => {
      saveComments();
      setIsAttachmentDirty(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  useEffect(() => {
    getAttachments();
  }, [detailId]);

  const defaultValues: {
    status: number | null;
    riskRating: number | null;
    comment: string;
  } = {
    status: null,
    riskRating: null,
    comment: '',
  };

  const commentForm = useForm({
    defaultValues: defaultValues,
  });

  const { isDirty } = commentForm.formState;

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-psc-modal',
    };
  }, []);
  const tabRef = useRef<TabPanel>(null);

  const tabItems = useMemo(() => {
    return [
      {
        title: t('labels.map'),
      },
      {
        title: t('labels.event'),
      },
    ];
  }, []);

  const tabContentRender = (e: any) => {
    if (e.title === tabItems[0].title) {
      return (
        <div className="m-l-tab-panel-map-view">
          <MarsMap
            zoom={6}
            center={mapConfig?.center ?? mapConfig?.markers[0].coordinates}
            markers={mapConfig?.markers}
            polylineConfig={mapConfig?.polylineConfig}
            legendsList={mapConfig?.legendsList}
            closePopupOnOutsideClick={true}
          />
        </div>
      );
    } else if (e.title === tabItems[1].title) {
      return (
        <div className="m-l-tab-panel-map-view">
          {eventData && (
            <EventGrid eventData={eventData} complianceType={complianceType} />
          )}
        </div>
      );
    }
    return <></>;
  };

  const getCountryFlag = (countryCode: string) => {
    const filteredCountryList = countryList.find(
      (countryItem) => countryItem.iso_3 == countryCode
    );
    return filteredCountryList?.flag;
  };

  const flag = useMemo(
    () => getCountryFlag(vesselDetails?.iso3),
    [vesselDetails]
  );

  useEffect(() => {
    if (selectedRow) {
      commentForm.reset({
        status:
          companyType === CompanyType.Mars
            ? selectedRow.marsStatus
            : selectedRow.status ?? ComplianceStatusEnum.Open,
        riskRating:
          companyType === CompanyType.Mars
            ? selectedRow.marsRiskRating
            : selectedRow.riskRating ?? RiskRatingEnum.Low,
        comment:
          companyType === CompanyType.Mars
            ? selectedRow.marsComment
            : selectedRow.comment ?? '',
      });
    }
  }, [selectedRow]);

  const saveComments = async () => {
    const formValue = commentForm.getValues();
    const saveJson = new FormData();
    saveJson.append(
      'detailId',
      detailId ? JSON.parse(JSON.stringify(detailId)) : ''
    );
    saveJson.append(
      'complianceCheckId',
      complianceCheckContext?.complianceCheckId
        ? JSON.parse(JSON.stringify(complianceCheckContext?.complianceCheckId))
        : ''
    );
    saveJson.append(
      'complianceType',
      complianceType ? JSON.parse(JSON.stringify(complianceType)) : ''
    );
    saveJson.append(
      'riskRating',
      formValue.riskRating
        ? JSON.parse(JSON.stringify(formValue.riskRating))
        : ''
    );
    saveJson.append(
      'status',
      formValue.status ? JSON.parse(JSON.stringify(formValue.status)) : ''
    );
    saveJson.append(
      'comment',
      formValue.comment ? JSON.parse(JSON.stringify(formValue.comment)) : ''
    );
    saveJson.append(
      'imo',
      vesselDetails?.imoDisplayName
        ? JSON.parse(JSON.stringify(vesselDetails?.imoDisplayName))
        : ''
    );
    if (isAttachmentDirty) {
      for (const item in uploadedFiles) {
        saveJson.append('AttachedFiles', uploadedFiles[item]);
      }
    }

    attachmentsAdded?.forEach((element: any) => {
      if (element?.attachmentId) {
        saveJson.append('AttachmentIds', element?.attachmentId);
      }
    });

    await api
      .put(
        { url: apiConfig.saveComplianceCheckComment, data: saveJson },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          toast.custom({
            title: t('toast.savedSuccessfully'),
          });
          commentForm.reset(formValue);
          setIsConfirmModalOpen(false);
          setUploadedFiles([]);
          getAttachments();
          if (handleGridRefresh) handleGridRefresh();
        }
      });
  };

  const onSaveClick = (e: ClickEvent) => {
    if (e?.validationGroup?.validate()?.isValid) {
      setIsConfirmModalOpen(true);
    }
  };

  const getAttachments = async () => {
    let complianceReportType = 'A';
    if (complianceType === ComplianceTabType.Loitering) {
      complianceReportType = 'L';
    } else if (complianceType === ComplianceTabType.STS) {
      complianceReportType = 'S';
    }

    await api
      .get(
        {
          url: apiConfig.getAttachment,
          params: {
            complianceReportType: complianceReportType,
            complianceCheckId: complianceCheckContext?.complianceCheckId,
            complianceTypeId: detailId,
          },
        },
        setIsLoading
      )
      .then((res) => {
        setAttachmentsAdded(res);
      });
  };

  const handleUploadFiles = (file: File[]) => {
    setUploadedFiles(file);
  };

  const handleFiles = (file: File[]) => {
    setAttachmentsAdded(file);
  };

  const handleAttachmentsVisible = (visible: boolean) => {
    setIsVisibleAttach(visible);
  };
  const handleAttachmentDirty = (isDirty: boolean) => {
    setIsAttachmentDirty(isDirty);
  };

  const onNextPrevButtonClick = (index: number) => {
    if (handleNextPrev) {
      const result = !(isDirty || isAttachmentDirty);
      if (!result) {
        setShowNextPrevModalOpen(true);
        setNextPrevConfig({
          id: 'confirmBack',
          content: t('toast.unSavedWillLost'),
          handleSubmit: () => {
            handleNextPrev(index);
            setUploadedFiles([]);
            setShowNextPrevModalOpen(false);
          },
          handleClose: () => {
            setShowNextPrevModalOpen(false);
          },
        });
      } else {
        handleNextPrev(index);
      }
    }
  };

  const checkIsDirty = useCallback(() => {
    const result = !(isDirty || isAttachmentDirty);
    return result;
  }, [isDirty, isAttachmentDirty]);

  const editPermission = useMemo(() => {
    switch (complianceType) {
      case ComplianceTabType.AISGap:
        return vetRequestId
          ? 'VetRequestComplianceCheck.EditAISGapInstance'
          : 'ComplianceCheck.EditAISGapInstance';
      case ComplianceTabType.Loitering:
        return vetRequestId
          ? 'VetRequestComplianceCheck.EditLoiteringInstance'
          : 'ComplianceCheck.EditLoiteringInstance';
      case ComplianceTabType.STS:
        return vetRequestId
          ? 'VetRequestComplianceCheck.EditSTSInstance'
          : 'ComplianceCheck.EditSTSInstance';
      default:
        return '';
    }
  }, [complianceType]);

  return (
    <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
      <div className="m-l-modal__header u-pt-15">
        <div className="m-l-inner-page-header-left-block">
          <div className="m-l-ttl-status-wrap">
            <div className="m-l-br-ttl-hldr">
              <div className="m-l-title-block">
                <div className="m-l-page-main-heading page-navigation-link">
                  <Link
                    className="m-l-page-main-heading"
                    to={'/vessel-info'}
                    state={{ imoNumber: vesselDetails.imoDisplayName }}
                    onContextMenu={() =>
                      localStorage.setItem(
                        'imo',
                        vesselDetails.imoNumber as string
                      )
                    }
                  >
                    {vesselDetails?.vesselName}
                  </Link>
                </div>
                {vesselDetails?.isSanction && (
                  <div className="m-l-page-icon" id="warning">
                    <icons.vesselWarning />
                  </div>
                )}
              </div>

              <div className="m-l-sub-title-details-block">
                <div className="m-l-flag-wrap">
                  <span className="ml-ads-tbl-flag">
                    <img
                      src={flag}
                      className="img-responsive"
                      alt="flag"
                      height={20}
                      width={20}
                    />
                  </span>
                  <span className="m-l-page-id-text">
                    {vesselDetails?.flagName}
                  </span>
                </div>
                <div className="app-l-vertical-seperator"></div>
                {vesselDetails && vesselDetails.imoDisplayName ? (
                  <div className="m-l-page-sub-heading">
                    {t('labels.IMO')}: {vesselDetails?.imoDisplayName}
                  </div>
                ) : (
                  <div className="m-l-page-sub-heading">
                    {t('labels.vin')}: {vesselDetails?.vinNumber}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="next-button-container">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={() => onNextPrevButtonClick(selectedIndex - 1)}
            disabled={selectedIndex === 0}
            id="arrowLeft"
          >
            <icons.ArrowBoldLeft />
            <Tooltip
              target={'#arrowLeft'}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              position="bottom"
              contentRender={() => {
                return (
                  <div className="m-l-tooltip-wrapper">
                    {t('labels.previous')}
                  </div>
                );
              }}
            />
          </Button>
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={() => onNextPrevButtonClick(selectedIndex + 1)}
            disabled={selectedIndex === gridDataLength - 1}
            id="arrowRight"
          >
            <icons.ArrowBoldRight />
            <Tooltip
              target={'#arrowRight'}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
              position="bottom"
              contentRender={() => {
                return (
                  <div className="m-l-tooltip-wrapper">{t('labels.next')}</div>
                );
              }}
            />
          </Button>
        </div>
        <div className="m-l-modal-close-button-block">
          <Button
            className="app-c-btn app-c-icon-only-btn"
            onClick={() => setIsVisible(false, true)}
          >
            <icons.IconCross />
          </Button>
        </div>
      </div>
      <div className="m-l-modal__body">
        <ScrollView width="100%" height="100%">
          <div className="row">
            <div className="col-sm-12 col-lg-8 col-xs-12">
              <div className="m-l-inner-page-tab-section u-p0">
                <div className="m-l-inner-page-body u-mb0 ">
                  <div className="m-l-tab-panel__wrap">
                    <TabPanel
                      ref={tabRef}
                      items={tabItems}
                      className="m-c-tab-block"
                      itemRender={tabContentRender}
                      showNavButtons={true}
                    ></TabPanel>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-lg-4 col-xs-12">
              <FormProvider {...commentForm}>
                <CommentSection
                  selectedRow={selectedRow}
                  isReportGenerated={isReportGenerated}
                  handleAttachmentDirty={handleAttachmentDirty}
                  handleAttachmentsVisible={handleAttachmentsVisible}
                  handleFiles={handleFiles}
                  handleUploadFiles={handleUploadFiles}
                  isVisibleAttach={isVisibleAttach}
                  attachmentsAdded={attachmentsAdded}
                  uploadedFiles={uploadedFiles}
                  complianceType={complianceType}
                  editPermission={editPermission}
                />
              </FormProvider>
            </div>
          </div>
        </ScrollView>
      </div>

      <div className="m-l-modal__footer u-pb-15 u-pt-15">
        <Restricted permission={editPermission}>
          <Button
            className="app-c-btn app-c-btn--primary min-btn-width"
            disabled={checkIsDirty()}
            onClick={onSaveClick}
          >
            {t('buttons.save')}
          </Button>
        </Restricted>
      </div>

      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />

      <ConfirmDialog
        dialogConfig={nextPrevConfig}
        isOpen={showNextPrevModalOpen}
      />
    </Modal>
  );
}
