import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { VesselDetails } from '../../../types/vessel-general';
import TabPanel from 'devextreme-react/tab-panel';
import { IVesselManagementRoles } from '../../../types/vessel-management-tab';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import SanctionCheck from '../sanction-check/SanctionCheck';
import Button from 'devextreme-react/button';
import { useTranslation } from 'react-i18next';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';
import { paramNames } from '../../../types/sanctions-check';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import Tooltip from 'devextreme-react/tooltip';
import icons from '../../common/icons/icons';
import ReportToPdf from '../../../pages/vet-report/ReportToPdf';
import { ReportType } from '../../../enums/report-type-enum';
import { useDateFormat } from '../../../hooks/useDateFormat';
import {
  ReportAttachment,
  ReportGeneratedDetails,
} from '../../../types/compliance-report';
import { getAPIRoute } from '../../../utils/api-route';
import { AttachmentUploadType } from '../../../enums/compliance-check-enum';
import FileSaver from 'file-saver';
import ReportSummaryModal from '../add-reportSummary-modal/ReportSummaryModal';
import { Restricted } from '../../../contexts/PermissionContext';

export default function SanctionsCheckTab(
  props: Readonly<{
    imoNumber: string;
    vesselDetails: VesselDetails;
    vetRequestId?: string;
    isFromVet?: boolean;
  }>
) {
  const { imoNumber, vesselDetails, vetRequestId, isFromVet } = props;

  const { setIsLoading } = useLoaderContext();
  const sanctionsCheckContext = useContext(ComplianceCheckContext);
  const { apiDateAndTimeFormat } = useDateFormat();
  const { t } = useTranslation();

  const [paramList, setParamList] = useState<
    Record<string, string | undefined>[]
  >([]);
  const [isApproved, setIsApproved] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [vesselManagementRoles, setVesselManagementRoles] = useState<
    IVesselManagementRoles[]
  >([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectConfirmMessage, setRejectConfirmMessage] = useState<string>();

  const [sanctionReportDetails, setSanctionReportDetails] = useState<any>();
  const [report, setReport] = useState<boolean>(false);
  const [pdfFile, setPdfFile] = useState<File[]>([]);
  const [isAttachPdf, setIsAttachPdf] = useState(false);
  const [onReportGenerate, setOnReportGenerate] = useState<boolean>(false);
  const [reportSummary, setReportSummary] = useState<string>('');
  const [reportGeneratedDetails, setReportGeneratedDetails] =
    useState<ReportGeneratedDetails>();
  const [sanctionsPdf, setSanctionsPdf] = useState<ReportAttachment>();

  const confirmRejectDialogConfig = useMemo(
    () => ({
      id: 'confirmReject',
      content: rejectConfirmMessage,
      handleSubmit: () => {
        rejectSanctions();
      },
      handleClose: () => {
        setIsRejectModalOpen(false);
      },
    }),
    [rejectConfirmMessage]
  );

  const sanctionChekRef = useRef<any>(null);

  useEffect(() => {
    if (imoNumber) {
      getVesselMAnagementRoles();
    }
  }, [imoNumber]);

  useEffect(() => {
    if (sanctionsCheckContext?.sanctionsCheckId) {
      checkIsReportGenerated();
      getSanctionsAttachment();
    }
  }, []);

  const getVesselMAnagementRoles = async () => {
    await api
      .get({ url: apiConfig.vesselManagementRoles + imoNumber }, setIsLoading)
      .then((res) => {
        setVesselManagementRoles(res);
      });
  };

  useEffect(() => {
    if (vesselManagementRoles.length && vesselDetails) {
      const docCompany = vesselManagementRoles.find(
        (item) => item.roleName === 'DOC Company'
      );
      const groupOwnerCompany = vesselManagementRoles.find(
        (item) => item.roleName === 'Group Owner'
      );
      setParamList([
        { VesselIMO: imoNumber },
        { VesselName: vesselDetails.vesselName },
        { DOCCompanyIMO: docCompany?.companyNumber },
        {
          DOCCompanyName:
            docCompany?.companyName?.trim()?.toLowerCase() === 'unknown'
              ? ''
              : docCompany?.companyName,
        },
        { GroupOwnerIMO: groupOwnerCompany?.companyNumber },
        {
          GroupOwnerName:
            groupOwnerCompany?.companyName?.trim()?.toLowerCase() === 'unknown'
              ? ''
              : groupOwnerCompany?.companyName,
        },
      ]);
    }
  }, [vesselManagementRoles, vesselDetails]);
  const tabRef = useRef<TabPanel>(null);
  const tabItems = useMemo(() => {
    return [
      {
        title: 'Sanctions Check',
      },
    ];
  }, []);

  const handleCurrentIndexCallback = useCallback((value: number) => {
    setCurrentIndex(value);
  }, []);

  const handleIsApproved = useCallback((value: boolean) => {
    setIsApproved(value);
  }, []);

  const handleRejectButtonClick = () => {
    if (paramNames[currentIndex + 1]) {
      setRejectConfirmMessage(
        t('toast.sanctionsCheckRejectConfirm', {
          currentValue: paramNames[currentIndex],
          nextValue: paramNames[currentIndex + 1],
        })
      );
    } else {
      setRejectConfirmMessage(
        t('toast.sanctionsCheckRejectConfirmWithoutParam', {
          currentValue: paramNames[currentIndex],
        })
      );
    }
    setIsRejectModalOpen(true);
  };

  const rejectSanctions = async () => {
    await api
      .put({
        url: apiConfig.rejectSanctionsCheck,
        params: {
          sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
          rejectedStep: currentIndex + 1,
        },
      })
      .then((res) => {
        if (res) {
          setCurrentIndex(currentIndex + 1);
          setIsRejectModalOpen(false);
        }
      });
  };

  const tabContentRender = (e: any) => {
    if (e.title === tabItems[0].title) {
      return (
        <>
          {paramList.length > 0 && (
            <SanctionCheck
              ref={sanctionChekRef}
              imoNumber={imoNumber ?? ''}
              sanctionsCheckParams={paramList}
              currentIndex={currentIndex}
              isApproved={isApproved}
              rejectSanctions={rejectSanctions}
              setIsApproved={handleIsApproved}
              setCurrentIndex={handleCurrentIndexCallback}
            />
          )}
        </>
      );
    }
    return <></>;
  };

  const handleSetPdf = (file: File[]) => {
    setPdfFile(file);
  };

  useEffect(() => {
    if (pdfFile?.length > 0) {
      uploadSanctionsReport(pdfFile);
    }
  }, [pdfFile]);

  const uploadSanctionsReport = async (file: File[]) => {
    const data = new FormData();
    if (file) {
      for (const item in file) {
        data.append('Files', file[item]);
      }
      await api
        .post(
          {
            url: apiConfig.attachments,
            data: data,
            params: {
              attachmentType: AttachmentUploadType.Sanctions,
              imoNumber: imoNumber,
              sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
            },
          },
          setIsLoading
        )
        .then((response) => {
          getSanctionsAttachment();
          checkIsReportGenerated();
          if (response) {
            downloadAttachment(response);
          }
        });
    }
  };

  const generateReport = () => {
    setOnReportGenerate(true);
  };

  const checkIsReportGenerated = async () => {
    await api
      .get(
        {
          url: getAPIRoute('isSanctionsReportGenerated', [
            sanctionsCheckContext?.sanctionsCheckId,
          ]),
        },
        setIsLoading
      )
      .then((response) => {
        setReportGeneratedDetails(response);
      });
  };

  const getSanctionsAttachment = async () => {
    await api
      .get(
        {
          url: apiConfig.getSanctionAttachment,
          params: {
            sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
          },
        },
        setIsLoading
      )
      .then((Response) => {
        setSanctionsPdf(Response[0]);
      });
  };

  const getSanctionsReportData = async () => {
    await api
      .get(
        {
          url: apiConfig.getSanctionReport,
          params: {
            sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
            imo: imoNumber,
          },
        },
        setIsLoading
      )
      .then((response) => {
        setSanctionReportDetails(response);
        setIsAttachPdf(true);
        setReport(true);
      });
  };

  const generateSanctionReport = async () => {
    await api
      .put(
        {
          url: apiConfig.generateSanctionsReport,
          data: {
            sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
            summary: reportSummary,
          },
        },
        setIsLoading
      )
      .then(() => {
        getSanctionsReportData();
        setOnReportGenerate(false);
      });
  };

  const handleCancelClick = () => {
    setOnReportGenerate(false);
  };

  const setSanctionsReportSummary = (summary: string) => {
    setReportSummary(summary);
  };

  const downloadAttachment = (attachment?: ReportAttachment[]) => {
    let attachmentId = 0;
    let attachmentName = '';
    if (attachment) {
      attachmentId = attachment[0].attachmentId;
      attachmentName = attachment[0].fileName;
    }
    api
      .get(
        {
          url:
            apiConfig.attachments +
            (sanctionsPdf?.attachmentId ?? attachmentId),
          responseType: 'arraybuffer',
        },
        setIsLoading
      )
      .then((res) => {
        setIsLoading(true);
        FileSaver.saveAs(
          new Blob([res]),
          sanctionsPdf?.fileName ?? attachmentName
        );
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className={`m-l-inner-page-tab-section ${!!vetRequestId && 'u-p0'}`}>
        <div className="m-l-inner-page-body u-mb0 ">
          <div className="m-l-tab-panel__wrap">
            <TabPanel
              ref={tabRef}
              items={tabItems}
              className="m-c-tab-block"
              itemRender={tabContentRender}
              showNavButtons={true}
            ></TabPanel>

            <div className={`m-l-tab-panel-right-block u-h-36`}>
              {vetRequestId && isFromVet && (
                <div className="u-pl u-pr">
                  <span>{t('labels.sanctionsCheckId')} :</span>
                  <span className="m-l-page-id-text">
                    {' '}
                    {sanctionsCheckContext?.sanctionsCheckId}{' '}
                  </span>
                </div>
              )}
              {!reportGeneratedDetails?.isReportGenerated &&
                !isApproved &&
                currentIndex < paramList.length && (
                  <>
                    <Restricted
                      permission={
                        vetRequestId
                          ? 'VetRequestSanctionCheck.Approve'
                          : 'SanctionCheck.Approve'
                      }
                    >
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        onClick={() =>
                          sanctionChekRef?.current?.handleApproveButtonClick()
                        }
                      >
                        {t('buttons.select')}
                      </Button>
                    </Restricted>
                    <Restricted
                      permission={
                        vetRequestId
                          ? 'VetRequestSanctionCheck.RejectAll'
                          : 'SanctionCheck.RejectAll'
                      }
                    >
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width"
                        onClick={handleRejectButtonClick}
                      >
                        {t('buttons.rejectAll')}
                      </Button>
                    </Restricted>
                  </>
                )}
              {(isApproved || currentIndex === paramList.length) &&
                !vetRequestId &&
                !reportGeneratedDetails?.isReportGenerated && (
                  <Restricted permission="SanctionCheck.GenerateReport">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      onClick={() => {
                        generateReport();
                      }}
                    >
                      {t('buttons.generateReport')}
                    </Button>
                  </Restricted>
                )}
              {reportGeneratedDetails?.isReportGenerated && (
                <>
                  {reportGeneratedDetails?.date && (
                    <div className="u-pl u-pr">
                      <span>{t('labels.generatedOn')} :</span>
                      <span className="m-l-page-id-text">
                        {' '}
                        {apiDateAndTimeFormat(
                          reportGeneratedDetails?.date
                        )}{' '}
                      </span>
                    </div>
                  )}
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequestSanctionCheck.DownloadReport'
                        : 'SanctionCheck.DownloadReport'
                    }
                  >
                    <Button
                      className="app-c-btn app-c-btn--secondary m-c-icon-inside-secondary-button"
                      aria-label="Export-btn"
                      onClick={() => downloadAttachment()}
                      id="downloadSanctionsReport"
                    >
                      <div
                        className="m-c-icon-inside-btn"
                        data-testid="export-all"
                      >
                        <Tooltip
                          target={'#downloadSanctionsReport'}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          contentRender={() => {
                            return (
                              <div className="m-l-tooltip-wrapper">
                                {t('labels.downloadSanctionCheckReport')}
                                <span className="generated-by">
                                  Report Generated By:{' '}
                                  {reportGeneratedDetails?.userName}
                                </span>
                              </div>
                            );
                          }}
                        />
                        <icons.download />
                      </div>
                    </Button>
                  </Restricted>
                </>
              )}
            </div>
          </div>
        </div>
        <ConfirmDialog
          dialogConfig={confirmRejectDialogConfig}
          isOpen={isRejectModalOpen}
        />

        {report && (
          <div>
            <ReportToPdf
              report={report}
              pdfFile={pdfFile}
              setPdfFile={handleSetPdf}
              isAttachPdf={isAttachPdf}
              setIsAttachPdf={setIsAttachPdf}
              setReport={setReport}
              reportType={ReportType.SanctionCheckReport}
              sanctionReportDetails={sanctionReportDetails}
            />
          </div>
        )}

        {onReportGenerate && (
          <ReportSummaryModal
            isModalVisible={onReportGenerate}
            reportSummary={reportSummary}
            handleSetReportSummary={setSanctionsReportSummary}
            handleCancelClick={handleCancelClick}
            generateReport={generateSanctionReport}
          />
        )}
      </div>

      <div className="m-l-inner-page-footer">
        <div className="row m-l-custom-eight-space-row">
          <div className="col-md-12 m-l-custom-sixteen-space-col m-l-group-button-btm-holder">
            <div className="m-l-group-button-btms">
              {!reportGeneratedDetails?.isReportGenerated &&
                !isApproved &&
                currentIndex < paramList.length && (
                  <>
                    <Restricted
                      permission={
                        vetRequestId
                          ? 'VetRequestSanctionCheck.Approve'
                          : 'SanctionCheck.Approve'
                      }
                    >
                      <Button
                        className="app-c-btn app-c-btn--primary min-btn-width"
                        onClick={() =>
                          sanctionChekRef?.current?.handleApproveButtonClick()
                        }
                      >
                        {t('buttons.select')}
                      </Button>
                    </Restricted>
                    <Restricted
                      permission={
                        vetRequestId
                          ? 'VetRequestSanctionCheck.RejectAll'
                          : 'SanctionCheck.RejectAll'
                      }
                    >
                      <Button
                        className="app-c-btn app-c-btn--secondary min-btn-width"
                        onClick={handleRejectButtonClick}
                      >
                        {t('buttons.rejectAll')}
                      </Button>
                    </Restricted>
                  </>
                )}
              {(isApproved || currentIndex === paramList.length) &&
                !vetRequestId &&
                !reportGeneratedDetails?.isReportGenerated && (
                  <Restricted permission="SanctionCheck.GenerateReport">
                    <Button
                      className="app-c-btn app-c-btn--primary min-btn-width"
                      onClick={() => {
                        generateReport();
                      }}
                    >
                      {t('buttons.generateReport')}
                    </Button>
                  </Restricted>
                )}
              {reportGeneratedDetails?.isReportGenerated && (
                <>
                  {reportGeneratedDetails?.date && (
                    <div className="u-pl u-pr">
                      <span>{t('labels.generatedOn')} :</span>
                      <span className="m-l-page-id-text">
                        {' '}
                        {apiDateAndTimeFormat(
                          reportGeneratedDetails?.date
                        )}{' '}
                      </span>
                    </div>
                  )}
                  <Restricted
                    permission={
                      vetRequestId
                        ? 'VetRequestSanctionCheck.DownloadReport'
                        : 'SanctionCheck.DownloadReport'
                    }
                  >
                    <Button
                      className="app-c-btn app-c-btn--secondary"
                      aria-label="Export-btn"
                      onClick={() => downloadAttachment()}
                      id="downloadSanctionsReport"
                    >
                      {t('labels.download')}
                    </Button>
                  </Restricted>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
