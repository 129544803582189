import * as React from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
export type LoadingState = {
  isLoading?: boolean;
  setIsLoading?: any;
  loadPanelRef?: React.RefObject<LoadPanel>;
  setLoaderMessage?: (message?: string) => void;
};
const LoaderContext = React.createContext<LoadingState>({});

function LoaderProvider(props: any) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loaderMessage, setLoaderMessage] = React.useState<string>(
    t('toast.loading')
  );
  const loadPanelRef = useRef<LoadPanel>(null);
  const handleLoaderMessage = React.useCallback((message?: string) => {
    setLoaderMessage(message ?? t('toast.loading'));
  }, []);
  return (
    <LoaderContext.Provider
      value={{
        isLoading,
        loadPanelRef,
        setIsLoading,
        setLoaderMessage: handleLoaderMessage,
      }}
    >
      {props.children}
      <LoadPanel
        ref={loadPanelRef}
        visible={isLoading}
        message={loaderMessage}
        showIndicator={true}
        shading={true}
        shadingColor="rgba(0,0,0,0)"
      />
    </LoaderContext.Provider>
  );
}

function useLoaderContext() {
  const context = React.useContext(LoaderContext);
  return context;
}

export { LoaderProvider, useLoaderContext };
