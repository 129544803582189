import { MarsApiService } from '../../../api/mars-api-service';
import { IComplianceConfig } from '../../../types/compliance-config';
import { MarsApiConfig } from '../../../api/mars-api-config';

export const getCompliance = async (
  companyId?: string,
  buId?: string,
  setIsLoading?: (isLoading: boolean) => void
): Promise<IComplianceConfig[]> => {
  try {
    const params = buId
      ? { settingArea: 'Compliance', companyId, buId }
      : { settingArea: 'Compliance', companyId };

    const data = await MarsApiService.get(
      { url: MarsApiConfig.configurations, params },
      setIsLoading
    );

    return data?.length > 0 ? data : [];
  } catch (error) {
    console.error('Error fetching compliance data:', error);
    return [];
  }
};
