import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import FormBuilder from '../../../../common/form-builder/FormBuilder';

export default function DrawForm() {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const validateLatitude = (e: any) => {
    return e.value >= -90 && e.value <= 90;
  };

  const validateLongitude = (e: any) => {
    return e.value >= -180 && e.value <= 180;
  };

  const validateRadius = (e: any) => {
    return e.value <= 50;
  };

  const customDrawFormConfig = [
    {
      class: 'col-12',
      name: 'areaName',
      label: t('labels.stsProneAreaName'),
      control: control,
      fieldType: 'text',
      isRequired: true,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-12',
      id: 'latitude',
      name: 'latitude',
      label: t('labels.latitude'),
      helpClass: 'help-text',
      control: control,
      fieldType: 'number',
      allowDecimal: true,
      helpText: t('toast.latitudeInfoText'),
      showHelpIcon: true,
      isRequired: true,
      maxLength: 100,
      allowNegative: true,

      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'custom',
          validationCallback: validateLatitude,
          message: t('errors.invalidCoordinates'),
        },
      ],
    },
    {
      class: 'col-12',
      name: 'longitude',
      label: t('labels.longitude'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      allowDecimal: true,
      id: 'longitude',
      helpClass: 'help-text',
      helpText: t('toast.longitudeInfoText'),
      showHelpIcon: true,
      allowNegative: true,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'custom',
          validationCallback: validateLongitude,
          message: t('errors.invalidCoordinates'),
        },
      ],
    },
    {
      class: 'col-12',
      name: 'circleRadius',
      label: t('labels.radius'),
      control: control,
      fieldType: 'number',
      isRequired: true,
      allowDecimal: true,
      maxLength: 100,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
        {
          type: 'custom',
          validationCallback: validateRadius,
          message: t('errors.invalidRadius'),
        },
      ],
    },
  ];
  return (
    <div className="row">
      <FormBuilder formConfig={customDrawFormConfig} />
    </div>
  );
}
