import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../types/form-builder';
import { useTranslation } from 'react-i18next';
import FormBuilder from '../../common/form-builder/FormBuilder';
import TextBox from 'devextreme-react/text-box';
import {
  AisGapData,
  ComplianceOptionvalue,
  complianceStatusList,
  LoiteringData,
  riskRatingList,
  StsComplianceData,
} from '../../../types/compliance-check';
import {
  ComplianceStatusEnum,
  ComplianceTabType,
  RiskRatingEnum,
} from '../../../enums/compliance-check-enum';
import { getCompanyType } from '../../../utils/jwt-decode';
import { CompanyType } from '../../../enums/company-type-enum';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import FileUploaderModal from '../../common/file-uploader-modal/FileUploaderModal';
import icons from '../../common/icons/icons';
import Tooltip from 'devextreme-react/tooltip';
import FileSaver from 'file-saver';
import { usePermission } from '../../../contexts/PermissionContext';

export default function CommentSection(
  props: Readonly<{
    selectedRow: AisGapData | LoiteringData | StsComplianceData;
    isReportGenerated?: boolean;
    handleAttachmentDirty: (isDirty: boolean) => void;
    handleAttachmentsVisible: (visible: boolean) => void;
    handleFiles: (file: File[]) => void;
    handleUploadFiles: (attachments: File[]) => void;
    isVisibleAttach: boolean;
    attachmentsAdded: File[];
    uploadedFiles: File[];
    editPermission: string;
    complianceType?: number;
  }>
) {
  const {
    selectedRow,
    isReportGenerated,
    handleAttachmentDirty,
    handleAttachmentsVisible,
    handleFiles,
    handleUploadFiles,
    isVisibleAttach,
    attachmentsAdded,
    uploadedFiles,
    complianceType,
    editPermission,
  } = props;
  const { control } = useFormContext();
  const { findFeature } = usePermission();

  const { t } = useTranslation();
  const companyType = getCompanyType();
  const { dateTimeInGMTFormat } = useDateFormat();

  const statusFieldRender = (data: ComplianceOptionvalue) => {
    return (
      <TextBox
        className={
          data?.optionValueCode === ComplianceStatusEnum.VerifiedTrue
            ? 'inactive-lbl'
            : data?.optionValueCode === ComplianceStatusEnum.VerifiedFalse
            ? 'active-lbl'
            : 'draft-lbl'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  const statusItemRender = (data: ComplianceOptionvalue) => {
    return (
      <div
        className={
          data?.optionValueCode === ComplianceStatusEnum.VerifiedTrue
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === ComplianceStatusEnum.VerifiedFalse
            ? 'm-l-status-item status-bg-green'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };

  const riskFieldRender = (data: ComplianceOptionvalue) => {
    return (
      <TextBox
        className={
          data?.optionValueCode === RiskRatingEnum.High
            ? 'inactive-lbl'
            : data?.optionValueCode === RiskRatingEnum.Low
            ? 'active-lbl'
            : 'draft-lbl'
        }
        defaultValue={data?.optionValueText}
        readOnly={true}
      />
    );
  };

  const riskItemRender = (data: ComplianceOptionvalue) => {
    return (
      <div
        className={
          data?.optionValueCode === RiskRatingEnum.High
            ? 'm-l-status-item status-bg-red'
            : data?.optionValueCode === RiskRatingEnum.Low
            ? 'm-l-status-item status-bg-green'
            : 'm-l-status-item status-bg-orange'
        }
      >
        {data?.optionValueText}
      </div>
    );
  };

  const filteredRiskRatingList = useMemo(() => {
    return complianceType === ComplianceTabType.STS
      ? riskRatingList.filter(
          (item) => item.optionValueCode !== RiskRatingEnum.Low
        )
      : riskRatingList;
  }, []);

  const formConfig: IFormConfig[] = [
    {
      id: 'complianceRiskRating',
      class: 'col-md-12 col-sm-12 col-lg-12 col-xs-12',
      name: 'riskRating',
      label: t('labels.riskRating'),
      control: control,
      fieldType: 'select',
      disabled: !!isReportGenerated || !findFeature(editPermission),
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: filteredRiskRatingList,
      isRequired: true,
      fieldRender: riskFieldRender,
      itemRender: riskItemRender,
      maxLength: 18,
      showHelpIcon: true,
      helpText: t('toast.riskRatingInfoText'),
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      id: 'complianceStatus',
      class: 'col-md-12 col-sm-12 col-lg-12 col-xs-12',
      name: 'status',
      label: t('labels.status'),
      control: control,
      fieldType: 'select',
      disabled: !!isReportGenerated || !findFeature(editPermission),
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      dataSource: complianceStatusList,
      isRequired: true,
      maxLength: 18,
      showHelpIcon: true,
      helpText: t('toast.complianceStatusInfoText'),
      fieldRender: statusFieldRender,
      itemRender: statusItemRender,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
    {
      class: 'col-md-12 col-sm-12 col-lg-12 col-xs-12',
      fieldWrapperClass: 'm-c-form-group mb-btm-space-24',
      fieldClass: 'm-c-text-area m-c-text-area-custom m-c-textrea-comments',
      name: 'comment',
      disabled: !!isReportGenerated || !findFeature(editPermission),
      isRequired: true,
      label: t('labels.comments'),
      control: control,
      fieldType: 'textArea',
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
    },
  ];

  const statusColor = useMemo(() => {
    if (selectedRow?.marsStatus === ComplianceStatusEnum.Open) {
      return 'status-bg-orange';
    } else if (selectedRow?.marsStatus === ComplianceStatusEnum.VerifiedFalse) {
      return 'status-bg-green';
    }
    return 'status-bg-red';
  }, [selectedRow]);

  const riskColor = useMemo(() => {
    if (selectedRow?.marsRiskRating === RiskRatingEnum.Medium) {
      return 'status-bg-orange';
    } else if (selectedRow?.marsRiskRating === RiskRatingEnum.Low) {
      return 'status-bg-green';
    }
    return 'status-bg-red';
  }, [selectedRow]);

  const statusText = useMemo(() => {
    if (selectedRow?.marsStatus === ComplianceStatusEnum.VerifiedTrue) {
      return complianceStatusList.find(
        (item) => item.optionValueCode === ComplianceStatusEnum.VerifiedTrue
      )?.optionValueText;
    } else if (selectedRow?.marsStatus === ComplianceStatusEnum.VerifiedFalse) {
      return complianceStatusList.find(
        (item) => item.optionValueCode === ComplianceStatusEnum.VerifiedFalse
      )?.optionValueText;
    } else {
      return complianceStatusList.find(
        (item) => item.optionValueCode === ComplianceStatusEnum.Open
      )?.optionValueText;
    }
  }, [selectedRow]);

  const onAddClick = () => {
    handleAttachmentsVisible(true);
  };

  const handleCancelClick = () => {
    handleAttachmentsVisible(false);
  };

  const getUploadedFiles = (data: File[]) => {
    handleAttachmentDirty(true);
    if (uploadedFiles) {
      handleUploadFiles([...uploadedFiles, ...data]);
    } else {
      handleUploadFiles(data);
    }
  };

  const renderContent = (data: string) => {
    return <div className="m-l-sidebar-tooltip">{data}</div>;
  };

  const removeFile = (index: number) => {
    const file = uploadedFiles?.slice();
    file.splice(index, 1);
    handleUploadFiles(file);
    if (uploadedFiles.length === 1) {
      handleAttachmentDirty(false);
    }
  };

  const removeFileAdded = (index: number) => {
    const file = attachmentsAdded?.slice();
    file?.splice(index, 1);
    handleFiles(file);
    handleAttachmentDirty(true);
  };

  const downloadAttachment = (file: any) => {
    api
      .get({
        url: apiConfig.deleteEditAttachment + file.attachmentId,
        responseType: 'arraybuffer',
      })
      .then((res) => {
        FileSaver.saveAs(new Blob([res]), file.fileName);
      });
  };

  return (
    <div>
      <div className="m-l-inner-page-header-left-block">
        <div className="m-l-ttl-status-wrap">
          <div className="m-l-br-ttl-hldr">
            <div className="m-l-title-block">
              <div className="m-l-page-main-heading page-navigation-link">
                {t('labels.comments')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-psc-form-panel u-p">
        <div className="row">
          <FormBuilder formConfig={formConfig} />

          {isVisibleAttach && (
            <FileUploaderModal
              isVisible={isVisibleAttach}
              handleCancelClick={handleCancelClick}
              setAttachmentsAdded={handleFiles}
              attachmentsAdded={attachmentsAdded}
              getUploadedFiles={getUploadedFiles}
            />
          )}

          {companyType !== CompanyType.Mars && (
            <>
              <div className="col-sm-6">
                <div className="m-c-form-group mb-btm-space-24">
                  <label className="m-l-input-label">
                    {t('labels.marsRiskRating')}
                  </label>
                  <div className="m-l-data-label">
                    <div className={`m-l-status-item ${riskColor}`}>
                      {selectedRow?.marsRiskRating &&
                        RiskRatingEnum[selectedRow?.marsRiskRating]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="m-c-form-group mb-btm-space-24">
                  <label className="m-l-input-label">
                    {t('labels.marsStatus')}
                  </label>
                  <div className="m-l-data-label">
                    <div className={`m-l-status-item ${statusColor}`}>
                      {statusText}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="m-c-form-group mb-btm-space-24">
                  <label className="m-l-input-label">
                    {t('labels.marsComment')}
                  </label>
                  <div className="m-l-data-label">
                    {selectedRow?.marsComment}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="col-sm-6">
            <div className="m-c-form-group mb-btm-space-24">
              <label className="m-l-input-label">
                {t('labels.lastUpdatedDateTime')}
              </label>
              {selectedRow.marsLastModifiedDate &&
                companyType === CompanyType.Mars && (
                  <div className="m-l-data-label">
                    {dateTimeInGMTFormat(selectedRow.marsLastModifiedDate)}
                  </div>
                )}
              {selectedRow.companyLastModifiedDate &&
                companyType !== CompanyType.Mars && (
                  <div className="m-l-data-label">
                    {dateTimeInGMTFormat(selectedRow.companyLastModifiedDate)}
                  </div>
                )}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="m-c-form-group mb-btm-space-24">
              <label className="m-l-input-label">
                {t('labels.lastUpdatedUser')}
              </label>
              {selectedRow.marsLastModifiedUser &&
                companyType === CompanyType.Mars && (
                  <div className="m-l-data-label">
                    {selectedRow.marsLastModifiedUser}
                  </div>
                )}
              {selectedRow.companyLastModifiedUser &&
                companyType !== CompanyType.Mars && (
                  <div className="m-l-data-label">
                    {selectedRow.companyLastModifiedUser}
                  </div>
                )}
            </div>
          </div>
          <div>
            {!isReportGenerated && findFeature(editPermission) && (
              <a
                onClick={onAddClick}
                className="m-l-email-atch-icon-hldr comment-attachments"
              >
                <icons.Attach />
              </a>
            )}
            <div className="m-l-files-attached-list attachments">
              {attachmentsAdded &&
                attachmentsAdded?.map((file: any, index: number) => {
                  return (
                    file?.fileName && (
                      <div>
                        <div className="m-l-files-attached-block" key={index}>
                          <div
                            className="m-l-files-attached-list m-l-files-download"
                            key={index}
                            onClick={() => {
                              downloadAttachment(file);
                            }}
                          >
                            <div
                              className="m-l-files-attached-list"
                              id={'name' + index}
                            >
                              <span>{file.fileName}</span>
                            </div>
                            <Tooltip
                              target={'#index'}
                              showEvent="dxhoverstart"
                              hideEvent="dxhoverend"
                              position="top"
                              contentRender={() => {
                                return (
                                  <div className="m-l-tooltip-wrapper">
                                    {file.name}
                                  </div>
                                );
                              }}
                            />
                          </div>
                          <div
                            className="m-l-files-close-icon"
                            onClick={() => removeFileAdded(index)}
                          >
                            <icons.Close />
                          </div>
                        </div>
                        <Tooltip
                          target={'#name' + index}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="top"
                          contentRender={() => renderContent(file.fileName)}
                        />
                      </div>
                    )
                  );
                })}
              {uploadedFiles &&
                uploadedFiles?.map((file: any, index: number) => {
                  return (
                    file?.name && (
                      <div className="m-l-files-attached-block" key={index}>
                        <div
                          className="m-l-files-attached-list m-l-files-download"
                          key={index}
                        >
                          <div
                            className="m-l-files-attached-list"
                            id={'uploadName' + index}
                          >
                            <span>{file.name}</span>
                          </div>
                        </div>
                        <div
                          className="m-l-files-close-icon"
                          onClick={() => removeFile(index)}
                        >
                          <icons.Close />
                        </div>
                        <Tooltip
                          target={'#uploadName' + index}
                          showEvent="dxhoverstart"
                          hideEvent="dxhoverend"
                          position="top"
                          contentRender={() => renderContent(file.name)}
                        />
                      </div>
                    )
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
