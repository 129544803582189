import Button from 'devextreme-react/button';
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDialogConfig } from '../../../../../types/dialog';
import { IModalConfig } from '../../../../../types/modal';
import { IVesselManagementForm } from '../../../../../types/vessel-management-tab';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import icons from '../../../../common/icons/icons';
import Modal from '../../../../common/modal/Modal';
import ManagementForm from '../management-form/ManagementForm';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import { useDateFormat } from '../../../../../hooks/useDateFormat';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import { isTabDirtyContext } from '../../../../../pages/vessel-info/VesselInformation';

export default function ManagementEditModal(props: {
  handleCancelClick: any;
  isVisible: boolean;
  modalId?: any;
  managementRow?: any;
  getVesselMAnagementRoles?: any;
  getHistoryDetails?: any;
  imoNumber?: string;
  getRuleList?: any;
}) {
  const {
    isVisible,
    handleCancelClick,
    modalId,
    managementRow,
    getVesselMAnagementRoles,
    getHistoryDetails,
    imoNumber,
    getRuleList,
  } = props;
  const { apiDateFormat } = useDateFormat();
  const { t } = useTranslation();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false);
  const [showConfirmNameChange, setShowConfirmNameChange] = useState(false);
  const [editable, setEditable] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const { setIsLoading } = useLoaderContext();
  const setTabDirtyFlag = useContext(isTabDirtyContext);
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      saveName(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
    },
  };
  const confirmBackDialogConfig: IDialogConfig = {
    id: 'confirmBack',
    content: t('toast.cancelWithoutSaving'),
    handleSubmit: () => {
      setShowConfirmBack(false);
      handleCancelClick(false);
      if (setTabDirtyFlag.setState) {
        setTabDirtyFlag.setState(
          setTabDirtyFlag.state == 0
            ? setTabDirtyFlag.state
            : setTabDirtyFlag.state - 1
        );
      }
    },
    handleClose: () => {
      setShowConfirmBack(false);
    },
  };
  const confirmNameChangeDialogConfig: IDialogConfig = {
    id: 'confirmNameChange',
    content: t('toast.companyNameChangeConfirm'),
    handleSubmit: () => {
      saveName(true);
    },
    handleClose: () => {
      setShowConfirmNameChange(false);
    },
  };

  const modalConfig: IModalConfig = {
    width: 1240,
    height: 380,
    visible: isVisible,
    showTitle: false,
    closeOnOutsideClick: false,
  };

  const defaultManagementFormValues: IVesselManagementForm = {
    imoNumber: '',
    roleName: '',
    companyName: '',
    companyCode: '',
    countryCode: '',
    address: '',
    email: '',
    callingCode: '',
    phoneNumber: '',
    fromDate: null,
    isCurrentCompanyChange: false,
  };

  const vesselManagementForm = useForm({
    defaultValues: defaultManagementFormValues,
  });

  const { isDirty } = vesselManagementForm.formState;

  const onClickSave = (e: any) => {
    if (e.validationGroup.validate().isValid) {
      setShowConfirmSave(true);
    }
  };

  const onClickBack = () => {
    isDirty ? setShowConfirmBack(true) : handleCancelClick();
  };

  const popupAttributes = useMemo(() => {
    return {
      id: 'elementId',
      class: 'm-l-modal-main__wrap m-l-management-edit-modal',
    };
  }, []);

  const saveName = async (isCurrentCompamyChange: boolean) => {
    const managementFormValue: IVesselManagementForm =
      vesselManagementForm.getValues();
    const data: IVesselManagementForm = {
      imoNumber: imoNumber,
      roleName: managementFormValue.roleName,
      companyName: managementFormValue.companyName,
      companyCode: managementFormValue.companyCode,
      countryCode: managementFormValue.countryCode,
      address: managementFormValue.address,
      email: managementFormValue.email,
      callingCode: managementFormValue.callingCode,
      phoneNumber: managementFormValue.phoneNumber?.toString(),
      fromDate: apiDateFormat(managementFormValue.fromDate),
      isCurrentCompanyChange: isCurrentCompamyChange,
    };
    if (modalId == 0) {
      await api
        .put({ url: apiConfig.vesselManagementRole, data: data }, setIsLoading)
        .then((res) => {
          setShowConfirmSave(false);
          if (setTabDirtyFlag.setState) {
            setTabDirtyFlag.setState(
              setTabDirtyFlag.state == 0
                ? setTabDirtyFlag.state
                : setTabDirtyFlag.state - 1
            );
          }
          if (res.isCurrentCompanyName) {
            setShowConfirmNameChange(true);
          } else {
            toast.success({ title: t('toast.savedSuccessfully') });
            handleCancelClick();
            if (getHistoryDetails) {
              getHistoryDetails();
            }
            getVesselMAnagementRoles();
            if (getRuleList) {
              getRuleList();
            }
          }
        });
    } else if (modalId == 2) {
      await api
        .post(
          {
            url: apiConfig.vesselManagementRole,
            data: data,
          },
          setIsLoading
        )
        .then(() => {
          setShowConfirmSave(false);
          if (setTabDirtyFlag.setState) {
            setTabDirtyFlag.setState(
              setTabDirtyFlag.state == 0
                ? setTabDirtyFlag.state
                : setTabDirtyFlag.state - 1
            );
          }
          toast.success({ title: t('toast.savedSuccessfully') });
          handleCancelClick();
          if (getVesselMAnagementRoles) {
            getVesselMAnagementRoles();
          }
          if (getRuleList) {
            getRuleList();
          }
        });
    } else if (modalId == 1) {
      delete data.isCurrentCompanyChange;
      await api
        .put(
          {
            url: apiConfig.vesselManagementRoleChange,
            data: data,
          },
          setIsLoading
        )
        .then(() => {
          setShowConfirmSave(false);
          if (setTabDirtyFlag.setState) {
            setTabDirtyFlag.setState(
              setTabDirtyFlag.state == 0
                ? setTabDirtyFlag.state
                : setTabDirtyFlag.state - 1
            );
          }
          toast.success({ title: t('toast.savedSuccessfully') });
          handleCancelClick();
          if (getHistoryDetails) {
            getHistoryDetails();
          }
          if (getVesselMAnagementRoles) {
            getVesselMAnagementRoles();
          }
          if (getRuleList) {
            getRuleList();
          }
        });
    }
  };

  useEffect(() => {
    if (modalId == 3) {
      setEditable(true);
    } else if (modalId == 0) {
      setIsChange(true);
    }
    if (managementRow && modalId != 1) {
      vesselManagementForm.reset(
        {
          imoNumber: imoNumber,
          roleName: managementRow.roleName,
          companyName: managementRow.companyName,
          companyCode: managementRow.companyNumber,
          countryCode: managementRow.countryCode,
          address: managementRow.address,
          email: managementRow.email,
          callingCode: managementRow.callingCode,
          phoneNumber: managementRow.phoneNumber,
          fromDate: managementRow.dateFrom,
          isCurrentCompanyChange: false,
        },
        { keepDirty: true }
      );
    } else if (modalId == 1) {
      setIsChange(true);
      vesselManagementForm.reset(
        {
          imoNumber: imoNumber,
          roleName: managementRow?.roleName,
        },
        { keepDirty: true }
      );
    }
  }, [managementRow]);

  useEffect(() => {
    if (isDirty && setTabDirtyFlag.setState) {
      setTabDirtyFlag.setState(setTabDirtyFlag.state + 1);
    }
  }, [isDirty]);

  return (
    <div className="m-l-model-popup__wrap">
      <Modal modalConfig={modalConfig} wrapperAttr={popupAttributes}>
        <div className="m-l-modal__header">
          {modalId == 0 && (
            <h2 className="modal-title">{t('labels.editCompanyInfo')}</h2>
          )}
          {modalId == 1 && (
            <h2 className="modal-title">{t('labels.changeCompanyInfo')}</h2>
          )}
          {modalId == 2 && (
            <h2 className="modal-title">{t('labels.addCompanyInfo')}</h2>
          )}
          {modalId == 3 && (
            <h2 className="modal-title">{managementRow?.roleName}</h2>
          )}
          <div className="m-l-modal-close-button-block">
            <Button
              className="app-c-btn app-c-icon-only-btn"
              onClick={onClickBack}
            >
              <icons.IconCross />
            </Button>
          </div>
        </div>
        <div className="m-l-modal__body">
          {/* <ScrollView width="100%" height="100%"> */}
          <div className="m-l-adnw-form-panel ddd">
            <div className="row m-l-custom-sixteen-space-row">
              <FormProvider {...vesselManagementForm}>
                <ManagementForm
                  editable={editable}
                  isChange={isChange}
                  imoNumber={imoNumber}
                />
              </FormProvider>
            </div>
          </div>
          {/* </ScrollView> */}
        </div>
        {!editable ? (
          <div className="m-l-modal__footer">
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onClickBack}
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              className="app-c-btn app-c-btn--primary min-btn-width"
              onClick={onClickSave}
            >
              {t('buttons.save')}
            </Button>
          </div>
        ) : null}
      </Modal>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
      <ConfirmDialog
        dialogConfig={confirmBackDialogConfig}
        isOpen={showConfirmBack}
      />
      <ConfirmDialog
        dialogConfig={confirmNameChangeDialogConfig}
        isOpen={showConfirmNameChange}
      />
    </div>
  );
}
