import React from 'react';
import Popover, { IPopoverOptions } from 'devextreme-react/popover';

interface HelpIconProps {
  className?: string;
  helpText?: string;
  id?: string;
  helpTextClass?: string;
}

const HelpIcon: React.FC<HelpIconProps> = ({
  className,
  helpText,
  id,
  helpTextClass,
}) => {
  const popoverConfig: IPopoverOptions = {
    width: 300,
    position: 'top',
    shading: true,
    shadingColor: 'rgba(0, 0, 0, 0.5)',
  };

  const animationConfig: IPopoverOptions['animation'] = {
    show: {
      type: 'pop',
      from: {
        scale: 0,
      },
      to: {
        scale: 1,
      },
    },
    hide: {
      type: 'fade',
      from: 1,
      to: 0,
    },
  };

  return (
    <>
      <span id={id} className={`dx-icon-info help-icon ${className}`}></span>

      <Popover
        target={'#' + id}
        showEvent="click"
        position={popoverConfig.position}
        width={popoverConfig.width}
        shading={popoverConfig.shading}
        shadingColor={popoverConfig.shadingColor}
        animation={animationConfig}
      >
        <div className={`help-icon-text ${helpTextClass}`}>
          {helpTextClass ? (
            <ul>
              {helpText?.split(',').map((text, index) => (
                <li key={index}>{text.trim()}</li>
              ))}
            </ul>
          ) : (
            helpText
          )}
        </div>
      </Popover>
    </>
  );
};

export default HelpIcon;
