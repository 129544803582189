import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderContext } from '../../../../../contexts/LoaderContext';
import ConfirmDialog from '../../../../common/confirm/ConfirmDialog';
import CheckBox from 'devextreme-react/check-box';
import { ComplianceReportSettingsType } from '../../../../../enums/config-enum';
import { IDialogConfig } from '../../../../../types/dialog';
import { MarsApiService as api } from '../../../../../api/mars-api-service';
import { MarsApiConfig as apiConfig } from '../../../../../api/mars-api-config';
import toast from '../../../../../utils/toast';
import ReportSettingForm from '../../report-settings/report-setting-form/ReportSettingForm';
import { IComplianceConfig } from '../../../../../types/compliance-config';
import { usePermission } from '../../../../../contexts/PermissionContext';

export default function ComplianceReportSettings(
  props: Readonly<{
    companyId: string;
    buId: string;
    saveConfig?: any;
    setSaveConfig?: any;
  }>
) {
  const { buId, saveConfig, setSaveConfig, companyId } = props;
  const { t } = useTranslation();
  const { findFeature } = usePermission();
  const { setIsLoading } = useLoaderContext();
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [reportConfigurationData, setReportConfigurationData] = useState<
    IComplianceConfig[]
  >([]);
  const [vesselDetails, setVesselDetails] = useState(false);
  const [aisDetails, setAISDetails] = useState<boolean>(false);
  const [stsDetails, setStsDetails] = useState<boolean>(false);
  const [loiteringDetails, setLoiteringDetails] = useState<boolean>(false);
  const [movementRiskDetailsToggle, setMovementRiskDetailsToggle] =
    useState<boolean>(false);
  const [marsDisclaimer, setMarsDisclaimer] = useState<boolean>(false);
  const [marsDisclaimerText, setMarsDisclaimerText] = useState<string>('');
  const [requestorDisclaimer, setRequestorDisclaimer] =
    useState<boolean>(false);
  const [requestorDisclaimerText, setRequestorDisclaimerText] =
    useState<string>('');
  const [showMarsDisclaimer, setShowMarsDisclaimer] = useState<string>('');
  const [showReqDisclaimer, setShowReqDisclaimer] = useState<string>('');
  const [disclaimerRules, setDisclaimerRules] = useState<string>('');

  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.confirmSaveChanges'),
    handleSubmit: () => {
      saveComplianceReportConfigs();
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
    handleClose: () => {
      setShowConfirmSave(false);
      setSaveConfig(false);
    },
  };

  useEffect(() => {
    if (buId) {
      setDisclaimerRules(
        'BUCompanyConfiguration.EditComplianceReportMARSDisclaimerContents'
      );
    } else {
      setDisclaimerRules(
        'CompanyConfiguration.EditComplianceReportMARSDisclaimerContents'
      );
    }
  }, []);

  const getReportData = async () => {
    if (buId && companyId) {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: ComplianceReportSettingsType.complianceReport,
            companyId: companyId,
            buId: buId,
          },
        })
        .then((response) => {
          setReportConfigurationData(response);
        });
    } else {
      await api
        .get({
          url: apiConfig.configurations,
          params: {
            settingArea: ComplianceReportSettingsType.complianceReport,
            companyId: companyId,
          },
        })
        .then((response) => {
          setReportConfigurationData(response);
        });
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  const saveComplianceReportConfigs = async () => {
    if (buId) {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: ComplianceReportSettingsType.complianceReportPost,
              buId: buId,
              companyId: companyId,
            },
            data: reportConfigurationData,
          },
          setIsLoading
        )
        .then(() => {
          getReportData();
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    } else {
      await api
        .put(
          {
            url: apiConfig.configurations,
            params: {
              configArea: ComplianceReportSettingsType.complianceReportPost,
              companyId: companyId,
            },
            data: reportConfigurationData,
          },
          setIsLoading
        )
        .then(() => {
          getReportData();
          toast.success({
            title: t('toast.changesSavedSuccessfully'),
          });
        });
    }
  };

  const handleSaveClick = async () => {
    let index = reportConfigurationData?.findIndex(
      (item: any) =>
        item?.settingType == ComplianceReportSettingsType.MARSDisclaimer
    );
    reportConfigurationData[index].settingDescription = marsDisclaimerText;
    index = reportConfigurationData?.findIndex(
      (item: any) =>
        item?.settingType == ComplianceReportSettingsType.RequestorDisclaimer
    );
    reportConfigurationData[index].settingDescription = requestorDisclaimerText;
    setShowConfirmSave(true);
  };

  const onClickCheckBox = async (e: boolean, settingType: string) => {
    const index = reportConfigurationData?.findIndex(
      (item: any) => item?.settingType == settingType
    );
    reportConfigurationData[index].settingValue = e ? 'yes' : 'no';
  };

  useEffect(() => {
    if (saveConfig) {
      handleSaveClick();
    }
  }, [saveConfig]);

  useEffect(() => {
    if (reportConfigurationData) {
      let index = reportConfigurationData?.findIndex(
        (item: IComplianceConfig) =>
          item?.settingType == ComplianceReportSettingsType.VesselDetails
      );
      setVesselDetails(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.AISDetails
      );
      setAISDetails(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.STSDetails
      );
      setStsDetails(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.LoiteringDetails
      );
      setLoiteringDetails(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.MovementRiskDetails
      );
      setMovementRiskDetailsToggle(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.MARSDisclaimer
      );
      setMarsDisclaimer(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      setMarsDisclaimerText(
        reportConfigurationData[index]?.settingDescription
          ? reportConfigurationData[index]?.settingDescription
          : ''
      );
      setShowMarsDisclaimer(
        reportConfigurationData[index]?.settingType
          ? reportConfigurationData[index]?.settingType
          : ''
      );
      index = reportConfigurationData?.findIndex(
        (item: any) =>
          item?.settingType == ComplianceReportSettingsType.RequestorDisclaimer
      );
      setRequestorDisclaimer(
        reportConfigurationData[index]?.settingValue === 'yes' ? true : false
      );
      setRequestorDisclaimerText(
        reportConfigurationData[index]?.settingDescription
      );
      setShowReqDisclaimer(reportConfigurationData[index]?.settingType);
    } else {
      setShowEditor(false);
    }
  }, [reportConfigurationData]);

  return (
    <div className="m-l-form-panel-block">
      <div className="m-l-form-panel-header-block">
        <div className="m-l-form-panel-header-left-block">
          <div className="m-l-page-small-heading">
            {t('labels.complianceCheckReportSettings')}
          </div>
        </div>
      </div>
      <div className="m-l-grid-top-header__section u-bb0">
        <div className="left-section">
          <div className="m-l-grid-top-header-list-row">
            <div className="row">
              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 col-12 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'vesselDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.VesselDetails
                      );
                      setVesselDetails(e.value);
                    }}
                    value={vesselDetails}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.vesselDetails')}</h2>
              </div>

              <div className="col-xl-auto col-lg-4 col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'AISDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.AISDetails
                      );
                      setAISDetails(e.value);
                    }}
                    value={aisDetails}
                  />
                </div>
                <h2 className="checkbox-label"> {t('labels.aisDetails')}</h2>
              </div>

              <div className="col-xl-auto col-lg-4 col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'STSDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.STSDetails
                      );
                      setStsDetails(e.value);
                    }}
                    value={stsDetails}
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.stsDetails')}</h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'LoiteringDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.LoiteringDetails
                      );
                      setLoiteringDetails(e.value);
                    }}
                    value={loiteringDetails}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.loiteringDetails')}
                </h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'MovementRiskDetailsToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.MovementRiskDetails
                      );
                      setMovementRiskDetailsToggle(e.value);
                    }}
                    value={movementRiskDetailsToggle}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.movementRiskDetails')}
                </h2>
              </div>

              <div className="col-xl-auto col-lg-4  col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'marsDisclaimerToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.MARSDisclaimer
                      );
                      setMarsDisclaimer(e.value);
                    }}
                    value={marsDisclaimer}
                    disabled={
                      buId
                        ? !findFeature(
                            'BUCompanyConfiguration.EditComplianceReportMARSDisclaimer'
                          )
                        : !findFeature(
                            'CompanyConfiguration.EditComplianceReportMARSDisclaimer'
                          )
                    }
                  />
                </div>
                <h2 className="checkbox-label">{t('labels.marsDisclaimer')}</h2>
              </div>
              <div className="col-xl-auto col-lg-4 col-sm-4 col-md-6 m-l-grid-top-header-list-column justify-content-start">
                <div className="m-c-checkbox m-c-checkbox-dark">
                  <CheckBox
                    elementAttr={{ 'data-testid': 'requestorDisclaimerToggle' }}
                    onValueChanged={(e: any) => {
                      onClickCheckBox(
                        e.value,
                        ComplianceReportSettingsType.RequestorDisclaimer
                      );
                      setRequestorDisclaimer(e.value);
                    }}
                    value={requestorDisclaimer}
                  />
                </div>
                <h2 className="checkbox-label">
                  {t('labels.requestorDisclaimer')}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="m-l-form-panel-body-block m-l-form-company-details">
        <ReportSettingForm
          requestorDisclaimer={requestorDisclaimerText}
          setRequestorDisclaimer={setRequestorDisclaimerText}
          marsDisclaimer={marsDisclaimerText}
          setMarsDisclaimer={setMarsDisclaimerText}
          showEditor={showEditor}
          buId={buId}
          showMarsDisclaimer={showMarsDisclaimer}
          showReqDisclaimer={showReqDisclaimer}
          rule={disclaimerRules}
        />
      </div>
      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={showConfirmSave}
      />
    </div>
  );
}
