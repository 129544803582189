export enum RiskRatingEnum {
  Low = 1,
  Medium = 2,
  High = 3,
}

export enum ComplianceStatusEnum {
  Open = 3,
  VerifiedTrue = 1,
  VerifiedFalse = 2,
}

export enum ComplianceTabType {
  AISGap = 1,
  Loitering = 2,
  STS = 3,
}

export enum AttachmentUploadType {
  Common = 'Z',
  Compliance = 'C',
  AisGap = 'A',
  Loitering = 'L',
  STS = 'S',
  Sanctions = 'Y',
}
