import Accordion from 'devextreme-react/accordion';
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import CheckBox from 'devextreme-react/check-box';
import dxCheckBox, { ValueChangedEvent } from 'devextreme/ui/check_box';
import { EventInfo, NativeEventInfo } from 'devextreme/events';
import {
  paramNames,
  SanctionCheckResponse,
  SanctionDetails,
  SanctionsAccordionItem,
} from '../../../types/sanctions-check';
import { ComplianceCheckContext } from '../../../contexts/ComplianceCheckContext';
import { getCurrentBuId } from '../../../utils/jwt-decode';
import toast from '../../../utils/toast';
import { useTranslation } from 'react-i18next';
import dxAccordion, { SelectionChangedEvent } from 'devextreme/ui/accordion';
import { IDialogConfig } from '../../../types/dialog';
import ConfirmDialog from '../../common/confirm/ConfirmDialog';

function SanctionCheck(
  props: {
    sanctionsCheckParams: Record<string, string | undefined>[];
    currentIndex: number;
    imoNumber: string;
    isApproved: boolean;
    rejectSanctions: () => Promise<void>;
    setIsApproved: (value: boolean) => void;
    setCurrentIndex: (value: number) => void;
  },
  ref?: any
) {
  const {
    sanctionsCheckParams,
    currentIndex,
    imoNumber,
    isApproved,
    rejectSanctions,
    setIsApproved,
    setCurrentIndex,
  } = props;
  const [accordionItems, setAccordionItems] = useState<
    SanctionsAccordionItem[]
  >([]);
  const [selectedItems, setSelectedItems] = useState<SanctionsAccordionItem[]>(
    []
  );
  const [sanctionDetails, setSanctionDetails] = useState<
    SanctionDetails[] | null
  >();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { setIsLoading, setLoaderMessage } = useLoaderContext();
  const sanctionsCheckContext = useContext(ComplianceCheckContext);
  const { t } = useTranslation();
  const confirmSaveDialogConfig: IDialogConfig = {
    id: 'confirmSave',
    content: t('toast.acceptSanctionCheckConfirm'),
    handleSubmit: () => {
      approveSelectedSanctionItems();
      setIsConfirmModalOpen(false);
    },
    handleClose: () => {
      setIsConfirmModalOpen(false);
    },
  };

  useEffect(() => {
    setAccordionItems(sanctionDetails ?? []);
    setLoaderMessage?.();
  }, [sanctionDetails]);

  useEffect(() => {
    if (sanctionsCheckContext?.sanctionsCheckId && imoNumber) {
      getApprovedSanctionDetails();
    }
  }, [imoNumber]);

  useEffect(() => {
    if (currentIndex < sanctionsCheckParams.length && currentIndex !== 0) {
      getSanctionDetails(sanctionsCheckParams[currentIndex]);
    }
    if (currentIndex >= sanctionsCheckParams.length) {
      setSanctionDetails([]);
    }
  }, [currentIndex]);

  const getApprovedSanctionDetails = async () => {
    await api
      .get(
        {
          url: apiConfig.getSavedSanctionDetails,
          params: {
            sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
            imo: imoNumber,
            currrentSanctionDetailType: 0,
          },
        },
        setIsLoading
      )
      .then((res: SanctionCheckResponse) => {
        if (res?.isAccepted) {
          setIsApproved(true);
          setSanctionDetails(res.sanctionChecks);
        } else {
          res.rejectedStep
            ? setCurrentIndex(res.rejectedStep)
            : getSanctionDetails(sanctionsCheckParams[currentIndex]);
        }
      });
  };

  const getSanctionDetails = async (
    params: Record<string, string | undefined>
  ) => {
    setLoaderMessage?.(
      t('toast.sanctionCheckLoaderMessage', { value: paramNames[currentIndex] })
    );
    await api
      .get(
        {
          url: apiConfig.getSavedSanctionDetails,
          params: {
            sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
            imo: imoNumber,
            currrentSanctionDetailType: currentIndex + 1,
            ...params,
          },
        },
        setIsLoading
      )
      .then((res: SanctionCheckResponse) => {
        setLoaderMessage?.();
        if (!res?.sanctionChecks || res?.sanctionChecks?.length === 0) {
          rejectSanctions();
        } else {
          setSanctionDetails(res?.sanctionChecks);
        }
      })
      .catch(() => setLoaderMessage?.());
  };

  const selectionChanged = (
    e: EventInfo<dxAccordion<SanctionsAccordionItem, any>> &
      SelectionChangedEvent<SanctionsAccordionItem>
  ) => {
    let newItems = [...selectedItems];
    e.removedItems.map((item) => {
      const index = newItems.indexOf(item);
      if (index >= 0) {
        newItems.splice(index, 1);
      }
    });
    if (e.addedItems.length) {
      newItems = [...newItems, ...e.addedItems];
    }
    setSelectedItems(newItems);
  };

  useImperativeHandle(ref, () => ({
    handleApproveButtonClick,
  }));

  const handleApproveButtonClick = () => {
    if (accordionItems.find((item) => item.isSelected)) {
      setIsConfirmModalOpen(true);
    } else {
      toast.error({ message: t('toast.noRecordsSelected') });
    }
  };

  const accordionTitleRender = (data: SanctionsAccordionItem) => {
    return (
      <div className="sanctions-accordion-title-container">
        {!isApproved && (
          <div className="m-c-checkbox m-c-checkbox-dark">
            <CheckBox
              onValueChanged={(e) => onCheckboxValueChange(e, data)}
              elementAttr={{ 'data-testid': 'marsRulesToggle' }}
            />
          </div>
        )}
        <div className="u-ml-sm">{data.name}</div>
      </div>
    );
  };

  const onCheckboxValueChange = useCallback(
    (
      e: NativeEventInfo<dxCheckBox, Event> & ValueChangedEvent,
      data: SanctionsAccordionItem
    ) => {
      data.isSelected = e.value;
      e.event?.stopPropagation();
    },
    []
  );

  const approveSelectedSanctionItems = async () => {
    const buId = getCurrentBuId();
    const selectedSanctionDetails = accordionItems.filter(
      (item) => !!item.isSelected
    );
    const data = {
      sanctionCheckId: sanctionsCheckContext?.sanctionsCheckId,
      sanctionChecks: selectedSanctionDetails.map((item) => {
        const newItem = { ...item };
        delete newItem.isSelected;
        return newItem;
      }),
    };
    await api
      .put(
        {
          url: apiConfig.updateSanctionCheckDetails,
          params: { buid: buId },
          data,
        },
        setIsLoading
      )
      .then((res) => {
        if (res) {
          getApprovedSanctionDetails();
          toast.success({ title: t('toast.changesSavedSuccessfully') });
        }
      });
  };

  const itemRender = (data: SanctionDetails) => {
    return (
      <div className="u-p">
        <div className="row">
          <div className="col-3">{t('labels.alias')}</div>
          <div className="col-9">
            <ul>
              {data.aliasNames?.map((item: string) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.sanctionDetails')}</div>
          <div className="col-9">
            <ul>
              {data.sanctionDetails?.map((item: string) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.links')}</div>
          <div className="col-9">
            <ul>
              {data.links?.map((item: string) => {
                return (
                  <li>
                    <a href={item} target="_blank" rel="noreferrer">
                      {item}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.otherInformation')}</div>
          <div className="col-9">
            <ul>
              {data.otherInformation?.map((item: string) => {
                return <li>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.sourceCountry')}</div>
          <div className="col-9">{data.sourceCountry}</div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.sourceDescription')}</div>
          <div className="col-9">{data.sourceDescription}</div>
        </div>
        <div className="horizontal-line u-mb-sm"></div>
        <div className="row">
          <div className="col-3">{t('labels.sourceDetails')}</div>
          <div className="col-9">
            <a href={data.sourceDetails} target="_blank" rel="noreferrer">
              {`${data.sourceIssuerName} - ${data.sourceName}`}
            </a>
          </div>
        </div>
      </div>
    );
  };

  const getNoDataText = useMemo(() => {
    if (
      !accordionItems.length &&
      currentIndex < sanctionsCheckParams.length &&
      !isApproved
    ) {
      return t('labels.fetchingData');
    } else {
      return t('labels.sanctionCheckNoDataText');
    }
  }, [accordionItems, currentIndex, isApproved]);

  return (
    <div className="m-l-accordion-panel-body-block">
      {!isApproved &&
        currentIndex < sanctionsCheckParams.length &&
        accordionItems?.length > 0 && (
          <div className="sanction-check-info-banner">
            <div className="m-l-page-create-company-info">
              <span className="dx-icon-info help-icon u-mr-sm"></span>
              <span>{t('toast.sanctionCheckInfoText')}</span>
            </div>
          </div>
        )}
      <div className="m-l-accordion__main-section">
        <div className="m-c-accordion">
          <Accordion
            collapsible={true}
            multiple={true}
            selectedItems={selectedItems}
            items={accordionItems}
            itemRender={itemRender}
            itemTitleRender={accordionTitleRender}
            onSelectionChanged={selectionChanged}
            noDataText={getNoDataText}
          />
        </div>
      </div>

      <ConfirmDialog
        dialogConfig={confirmSaveDialogConfig}
        isOpen={isConfirmModalOpen}
      />
    </div>
  );
}

export default forwardRef(SanctionCheck);
