import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { IGridConfig } from '../../../types/grid';
import { useTranslation } from 'react-i18next';
import { getCompanyType, getCurrentBuId } from '../../../utils/jwt-decode';
import { useNavigate } from 'react-router-dom';
import Grid from '../../common/grid/Grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import dxDataGrid from 'devextreme/ui/data_grid';
import { InitializedEventInfo } from 'devextreme/events';
import { MarsApiConfig as apiConfig } from '../../../api/mars-api-config';
import { MarsApiService as api } from '../../../api/mars-api-service';
import { useLoaderContext } from '../../../contexts/LoaderContext';
import { useDateFormat } from '../../../hooks/useDateFormat';
import { CompanyType } from '../../../enums/company-type-enum';

function SanctionsCheckGrid(
  props: Readonly<{
    imoNumber?: string;
    isFromSanctionsHistory?: boolean;
  }>,
  ref?: any
) {
  const { isFromSanctionsHistory, imoNumber } = props;
  const { t } = useTranslation();
  const [sanctionsCheckList, setSanctionsCheckList] = useState<any>();
  const companyType = getCompanyType();
  const navigate = useNavigate();
  const { setIsLoading } = useLoaderContext();

  const [gridInstance, setGridInstance] = useState<dxDataGrid<any, any>>();
  const buId = getCurrentBuId();
  const { dateFormat } = useDateFormat();

  const onGridInitialized = (e: InitializedEventInfo<dxDataGrid<any, any>>) => {
    setGridInstance(e.component);
  };

  useEffect(() => {
    getSanctionsList();
  }, []);

  const getSanctionsList = async () => {
    await api
      .get(
        {
          url: apiConfig.sanctionsCheckHistory,
          params: { imo: imoNumber, buid: buId },
        },
        setIsLoading
      )
      .then((res) => {
        setSanctionsCheckList(res);
      });
  };

  const onRowClick = (e: any) => {
    if (e.data) {
      navigate('/sanctions-check', {
        state: {
          imoNumber: e.data.imoNumber,
          sanctionsCheckId: e.data.sanctionCheckId,
          vetRequestId: e.data.vetRequestId,
        },
      });
    }
  };

  const exportSanctionsHistoryGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: gridInstance,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'SanctionsCheckList.xlsx'
        );
      });
    });
  };

  useImperativeHandle(ref, () => ({
    exportSanctionsHistoryGrid,
  }));

  const gridConfig: IGridConfig = {
    dataSource: sanctionsCheckList,
    initialized: onGridInitialized,
    remoteOperations: true,
    defaultColumns: [
      {
        caption: t('labels.sanctionsCheckId'),
        dataField: 'sanctionCheckId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.vesselName'),
        dataField: 'vesselName',
        dataType: 'string',
        minWidth: 150,
        visible: !isFromSanctionsHistory,
      },
      {
        caption: t('labels.IMO'),
        dataField: 'imoNumber',
        dataType: 'string',
        minWidth: 150,
        visible: !isFromSanctionsHistory,
      },
      {
        caption: t('labels.company'),
        dataField: 'companyName',
        dataType: 'string',
        minWidth: 150,
        visible: companyType == 'M',
      },
      {
        caption: t('labels.businessUnit'),
        dataField: 'buName',
        dataType: 'string',
        minWidth: 150,
        visible: companyType != 'R',
      },
      {
        caption: t('labels.requestorName'),
        dataField: 'requestedUserName',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.requestedDate'),
        dataField: 'requestedDateTime',
        dataType: 'date',
        format: dateFormat,
        minWidth: 150,
        sortOrder: 'desc',
      },
      {
        caption: t('labels.vetId'),
        dataField: 'vetRequestId',
        dataType: 'string',
        minWidth: 150,
      },
      {
        caption: t('labels.apiCount'),
        dataField: 'apiCount',
        dataType: 'string',
        minWidth: 150,
        visible: companyType === CompanyType.Mars,
      },
    ],
    showHeader: true,
    header: !isFromSanctionsHistory ? t('labels.sanctionsCheck') : '',
    hidePagination: false,
    showExport: !isFromSanctionsHistory,
    excelFileName: 'SanctionsCheckList.xlsx',
    rowClick: onRowClick,
  };
  return (
    <div
      className={
        isFromSanctionsHistory
          ? 'm-c-grid m-c-block-grid-list'
          : 'inner-wrapper page-inner-wrap'
      }
    >
      <Grid gridConfig={gridConfig} />
    </div>
  );
}

export default forwardRef(SanctionsCheckGrid);
