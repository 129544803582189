export function convertDecimalToMinutes(decimal: number, isLatitude?: boolean) {
  const absDecimal = Math.abs(decimal);

  const degrees = Math.floor(absDecimal);
  const minutesDecimal = Math.abs((absDecimal - degrees) * 60);
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);

  // Determine direction
  const direction = isLatitude
    ? decimal >= 0
      ? 'N'
      : 'S' // Latitude: N or S
    : decimal >= 0
    ? 'E'
    : 'W'; // Longitude: E or W

  return `${Math.abs(degrees)}° ${minutes}' ${seconds}" ${direction}`;
}
