export type SanctionsCheckParams = {
  vessselImo?: string;
  vesselName?: string;
  docCompanyImo?: string;
  groupOwnerCompanyImo?: string;
  docCompanyName?: string;
  groupOwnerCompanyName?: string;
};

export type SanctionDetails = {
  entityType: string;
  name: string;
  address: string[] | null;
  aliasNames: string[] | null;
  sanctionDetails: string[] | null;
  links: string[] | null;
  otherInformation: string[] | null;
  sourceId: string;
  sourceType: string;
  listDate: number;
  sourceCountry: string;
  sourceDescription: string;
  sourceDetails: string;
  sourceIssuerName: string;
  sourceName: string;
};

export type SanctionCheckResponse = {
  sanctionCheckId: string;
  isAccepted: boolean;
  rejectedStep: number;
  sanctionChecks: SanctionDetails[] | null;
};

export type SanctionsAccordionItem = SanctionDetails & {
  isSelected?: boolean;
};

export type SanctionCheckRef = {
  approveSelectedSanctionItems: () => Promise<void>;
};

export const paramNames = [
  'Vessel IMO',
  'Vessel Name',
  'DOC Company IMO',
  'DOC Company Name',
  'Group Owner IMO',
  'Group Owner Name',
];

export type SanctionsCheckList = {
  sanctionCheckId: string;
  imoNumber: string;
  vesselName: string;
  requestedUserId: number;
  requestedUserName: string;
  companyId: number;
  companyName: string;
  buId: number;
  buName: string;
  requestedDateTime: string;
  apiCount: number;
  vetRequestId: string | null;
};
