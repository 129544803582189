import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IFormConfig } from '../../../../types/form-builder';
import FormBuilder from '../../../common/form-builder/FormBuilder';
import { useTranslation } from 'react-i18next';
import icons from '../../../common/icons/icons';
import { ReactMultiEmailCustom } from 'react-multi-email-custom';
import { blackListedEmails } from '../../../../types/requestor-company';
import DateBox from 'devextreme-react/date-box';
import TextBox from 'devextreme-react/text-box';
import AddAttachment from '../../../vessel-info/attachments/add-attachment/AddAttachment';
import { useDateFormat } from '../../../../hooks/useDateFormat';
import { usePermission } from '../../../../contexts/PermissionContext';
import { MarsApiConfig as apiConfig } from '../../../../api/mars-api-config';
import { MarsApiService as api } from '../../../../api/mars-api-service';
import { parseISO } from 'date-fns';
import { IDialogConfig } from '../../../../types/dialog';
import ConfirmDialog from '../../../common/confirm/ConfirmDialog';

export default function CreateVetRequestForm(props: {
  cargoTypes: any;
  cargo: any;
  incoTerms: any;
  emails?: any;
  setEmails?: any;
  emailError?: any;
  setEmailError?: any;
  setCheckBerthFit?: any;
  imoNumber?: any;
  attachments?: any;
  setLaycanFromDate?: any;
  setLaycanToDate?: any;
  tempVetId?: string;
  setTempVetId?: any;
  attachmentsAdded: any[];
  setAttachmentsAdded: any;
  vetRequestDetails?: any;
}) {
  const { t } = useTranslation();

  const attachmentDeleteConfirmDialogConfig: IDialogConfig = {
    id: 'confirmDelete',
    content: t('toast.confirmDelete'),
    handleSubmit: () => {
      setShowDeleteConfirm(false);
    },
    handleClose: () => {
      setShowDeleteConfirm(false);
    },
  };
  const {
    cargoTypes,
    incoTerms,
    cargo,
    emails,
    setEmails,
    emailError,
    setEmailError,
    setCheckBerthFit,
    imoNumber,
    attachments,
    setLaycanFromDate,
    setLaycanToDate,
    tempVetId,
    setTempVetId,
    attachmentsAdded,
    setAttachmentsAdded,
    vetRequestDetails,
  } = props;
  const [cargos, setCargos] = useState<any[]>(cargo);
  const { control, getValues, setValue } = useFormContext();
  const cargoType = getValues()?.cargoType;
  const vetRequestId = getValues()?.vetRequestId;
  const [isVisible, setIsVisible] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteConfirmConfig, setDeleteConfirmConfig] = useState<IDialogConfig>(
    attachmentDeleteConfirmDialogConfig
  );
  // const [attachmentsAdded, setAttachmentsAdded] = useState<any>([]);
  const { findFeature } = usePermission();

  const { dateFormat, formatDate } = useDateFormat();

  useEffect(() => {
    if (cargoType != '') {
      const filteredCargoList = cargo.filter(
        (cargo: any) => cargo.parentOptionValueCode == cargoType
      );
      setCargos(filteredCargoList);
    }
  }, [cargoType]);

  useEffect(() => {
    if (attachments) {
      setAttachmentsAdded(attachments);
    }
  }, [attachments]);

  const disableCargo = () => {
    return getValues()?.cargoType == '';
  };

  const onChangeCargoType = (e: any) => {
    if (e.event) {
      setValue('vetRequestCargos', []);
      const filteredCargoList = cargo.filter(
        (cargo: any) => cargo.parentOptionValueCode == e.value
      );
      setCargos(filteredCargoList);
    }
  };

  const validateFromDate = (params: any) => {
    if (
      vetRequestDetails?.laycanFromDate &&
      String(params.value) ===
        String(parseISO(vetRequestDetails?.laycanFromDate))
    ) {
      return true;
    } else {
      const date = new Date();
      const currentDate = new Date(params.value);
      const formattedDate = date.setHours(0, 0, 0, 0);
      const formattedCurrentDate = currentDate.setHours(0, 0, 0, 0);
      return formattedDate <= formattedCurrentDate;
    }
  };

  const validateToDate = (params: any) => {
    if (
      vetRequestDetails?.laycanToDate &&
      String(params.value) === String(parseISO(vetRequestDetails?.laycanToDate))
    ) {
      return true;
    } else {
      const date = new Date();
      const currentDate = new Date(params.value);
      const formattedDate = date.setHours(0, 0, 0, 0);
      const formattedCurrentDate = currentDate.setHours(0, 0, 0, 0);
      return formattedDate <= formattedCurrentDate;
    }
  };

  const validateLaycanToDate = (params: any) => {
    const laycanFromDate = getValues()?.laycanFromDate;
    if (laycanFromDate != '' && laycanFromDate != null) {
      const fromDate = new Date(laycanFromDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() <= currentDate.getTime();
    }
    return true;
  };

  const validateLaycanFromDate = (params: any) => {
    const laycanToDate = getValues()?.laycanToDate;
    if (laycanToDate != '' && laycanToDate != null) {
      const fromDate = new Date(laycanToDate);
      const currentDate = new Date(params.value);
      return fromDate.getTime() >= currentDate.getTime();
    }
    return true;
  };

  const checkEmailvalid = (email: any) => {
    if (email) {
      const regex = new RegExp(blackListedEmails.join('|'));
      if (!regex.test(email)) {
        setEmails(email);
        setEmailError('');
      } else {
        setEmails(email);
        setEmailError('public domain emails not allowed');
      }
    }
  };

  const removeEmailFromList = (index: any, removeEmail: any, email: any) => {
    emails.filter((email1: any) => email1 != email);
    removeEmail(index);
  };

  const onchangeBerthFitCheck = (e: any) => {
    if (e.event) {
      setCheckBerthFit(true);
    }
  };

  const onChangeLaycanFromDate = (e: any) => {
    setLaycanFromDate(e.value);
  };

  const onChangeLaycanToDate = (e: any) => {
    setLaycanToDate(e.value);
  };

  const formConfig: IFormConfig[] = [
    {
      name: 'buId',
      label: t('labels.businessUnit'),
      control: control,
      fieldType: 'text',
      disabled: true,
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
    },
    {
      name: 'cargoType',
      label: t('labels.cargoType'),
      control: control,
      fieldType: 'select',
      dataSource: cargoTypes,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      onChange: onChangeCargoType,
      placeholder: t('labels.selectCargoType'),
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
    },
    {
      name: 'vetRequestCargos',
      label: t('labels.cargo'),
      control: control,
      fieldType: 'multiSelect',
      dataSource: cargos,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      showClearButton: true,
      disabled: disableCargo(),
      maxDisplayedTags: 2,
      showMultiTagOnly: false,
      placeholder: t('labels.selectCargo'),
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
    },
    {
      name: 'voyageNumber',
      label: t('labels.voyageNumber'),
      control: control,
      fieldType: 'text',
      placeholder: t('labels.enterVoyageNumber'),
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
    },
    {
      name: 'incoTerm',
      label: t('labels.incoTerms'),
      control: control,
      fieldType: 'select',
      dataSource: incoTerms,
      displayExpr: 'optionValueText',
      valueExpr: 'optionValueCode',
      isRequired: true,
      rules: [
        {
          type: 'required',
          message: t('errors.required'),
        },
      ],
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
    },
    {
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
      name: 'laycanFromDate',
      label: t('labels.laycanFromDate'),
      control: control,
      fieldType: 'date',
      onChange: onChangeLaycanFromDate,
      rules: [
        {
          type: 'custom',
          validationCallback: validateFromDate,
          message: t('errors.mustBeFutureDate'),
          ignoreEmptyValue: true,
        },
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateLaycanFromDate,
          message: t('errors.dateBeforeLaycanToDate'),
          ignoreEmptyValue: true,
        },
      ],
      placeholder: t('labels.dataPlaceholder'),
    },
    {
      class: 'col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12',
      name: 'laycanToDate',
      label: t('labels.laycanToDate'),
      control: control,
      fieldType: 'date',
      onChange: onChangeLaycanToDate,
      rules: [
        {
          type: 'custom',
          validationCallback: validateToDate,
          message: t('errors.mustBeFutureDate'),
          ignoreEmptyValue: true,
        },
        {
          type: 'custom',
          reevaluate: true,
          validationCallback: validateLaycanToDate,
          message: t('errors.dateAfterLaycanFromDate'),
          ignoreEmptyValue: true,
        },
      ],
      placeholder: t('labels.dataPlaceholder'),
    },
    {
      class:
        'col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12 u-mt-xl check-box-vet-req',
      name: 'berthFitCheck',
      label: t('labels.checkBerthFit'),
      control: control,
      fieldType: 'checkbox',
      onChange: onchangeBerthFitCheck,
      isHidden: !findFeature('BerthFitVet.BerthfitCheck'),
    },
    {
      class:
        'col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12 u-mt-xl check-box-vet-req',
      name: 'complianceChecks',
      label: 'Compliance Check',
      control: control,
      fieldType: 'checkbox',
      isHidden: !findFeature('VetRequestComplianceCheck.ComplianceCheck'),
    },
    {
      class:
        'col-xl-2 col-lg-2 col-md-6 col-sm-6 col-xs-12 u-mt-xl check-box-vet-req',
      name: 'sanctionChecks',
      label: 'Sanctions Check',
      control: control,
      fieldType: 'checkbox',
      isHidden: !findFeature('VetRequestSanctionCheck.SanctionCheck'),
    },
  ];

  const handleCancelClick = () => {
    setIsVisible(false);
  };

  const onAddClick = () => {
    setIsVisible(true);
  };

  const onClickDeleteAttachment = (attachment: any) => {
    setDeleteConfirmConfig({
      ...attachmentDeleteConfirmDialogConfig,
      handleSubmit: () => {
        setShowDeleteConfirm(false);
        deleteAttachment(attachment);
      },
    });
    setShowDeleteConfirm(true);
  };

  const deleteAttachment = (attachment: any) => {
    api
      .delete({ url: apiConfig.attachments + attachment.attachmentId })
      .then((res) => {
        if (res) {
          const newAttachmennts = attachmentsAdded.filter(
            (item) => item.attachmentId !== attachment.attachmentId
          );
          setAttachmentsAdded(newAttachmennts);
        }
      });
  };

  return (
    <form>
      <div className="m-l-form-panel-body-block m-l-rl-pnl-body-block">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div className="mb-btm-space m-c-form-group">
              <label className="m-l-input-label">Date</label>
              <Controller
                name="requestDate"
                control={control}
                render={({ field }) => (
                  <DateBox
                    className="m-c-input-control m-c-date-picker"
                    type="date"
                    name="requestDate"
                    value={field.value}
                    displayFormat={dateFormat}
                    onValueChanged={(e: any) => {
                      field.onChange(e.value);
                    }}
                    disabled={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div className="mb-btm-space m-c-form-group">
              <label className="m-l-input-label">Requestor Name</label>
              <Controller
                name="requestorUserId"
                control={control}
                render={({ field }) => (
                  <TextBox
                    className="m-c-input-control"
                    name="requestorUserId"
                    value={field.value}
                    onValueChanged={(e: any) => {
                      field.onChange(e.value);
                    }}
                    disabled={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
            <div className="mb-btm-space m-c-form-group">
              <label className="m-l-input-label">Email</label>
              <div
                className={
                  'm-l-custom-email-input ' +
                  (emailError != '' ? 'm-l-validation' : '')
                }
              >
                <ReactMultiEmailCustom
                  emails={emails}
                  onChange={(email: string[]) => {
                    checkEmailvalid(email);
                  }}
                  getLabel={(
                    email: string,
                    index: number,
                    removeEmail: (index: number) => void
                  ) => {
                    return (
                      <div data-tag key={index} className="data-tag-holder">
                        <div className="data-tag-content">{email}</div>
                        <span
                          className="data-tag-close"
                          data-tag-handle
                          onClick={() =>
                            removeEmailFromList(index, removeEmail, email)
                          }
                        >
                          <icons.Close />
                        </span>
                      </div>
                    );
                  }}
                ></ReactMultiEmailCustom>
              </div>
              {emailError != '' && (
                <>
                  <div className="data-tag-validation">{emailError}</div>
                </>
              )}
            </div>
          </div>
          <FormBuilder formConfig={formConfig} />
          {
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div className="mb-btm-space m-c-form-group m-c-full-hght-fl-upld">
                <div className="m-l-upld-fl-wrapper">
                  <div className="m-l-atch-icon-holder u-mb8">
                    <icons.Attach />
                    <a className="m-l-add-atch-txt" onClick={onAddClick}>
                      {t('labels.addAnAttachment')}
                    </a>
                  </div>
                  {/* <div className="m-l-upld-fl-holder">
                    {attachmentsAdded &&
                      attachmentsAdded?.map((file: any, index: number) => {
                        return (
                          file?.fileName && (
                            <div className="m-l-fl-wrapper" key={index}>
                              <span className="m-l-fl-close">
                                {' '}
                                <icons.Close />
                                {file.fileName}
                              </span>
                              <div
                                  className="m-l-files-close-icon"
                                  onClick={() => deleteAttachment(file)}
                                >
                                  <icons.Close />
                                </div>
                            </div>
                          )
                        );
                      })}
                  </div> */}
                  <div className="m-l-files-attached-list">
                    {attachmentsAdded &&
                      attachmentsAdded?.map((file: any, index: number) => {
                        return (
                          file?.fileName && (
                            <div
                              className="m-l-files-attached-block"
                              key={index}
                            >
                              <div className="m-l-files-attached-list">
                                <span style={{ maxWidth: '200px' }}>
                                  {file.fileName}
                                </span>
                              </div>
                              {!vetRequestId && (
                                <div
                                  className="m-l-files-close-icon"
                                  onClick={() => onClickDeleteAttachment(file)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <icons.Close />
                                </div>
                              )}
                            </div>
                          )
                        );
                      })}
                  </div>

                  {/* <div className="m-l-upld-fl-holder">
                  <div className="m-l-fl-wrapper">
                    <span className="m-l-fl-close">
                      {' '}
                      <icons.Close />
                    </span>
                    PSC.PDF
                  </div>
                  <div className="m-l-fl-wrapper">
                    <span className="m-l-fl-close">
                      {' '}
                      <icons.Close />
                    </span>
                    PSC.PDF
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          }
        </div>
      </div>

      <ConfirmDialog
        dialogConfig={deleteConfirmConfig}
        isOpen={showDeleteConfirm}
      />
      {isVisible ? (
        <AddAttachment
          vetRequestId={vetRequestId}
          isVisible={isVisible}
          handleCancelClick={handleCancelClick}
          imoNumber={imoNumber}
          setAttachmentsAdded={setAttachmentsAdded}
          attachmentsAdded={attachmentsAdded}
          tempVetId={tempVetId}
          setTempVetId={setTempVetId}
          isFromVetCreation={vetRequestId ? false : true}
        />
      ) : null}
    </form>
  );
}
